import {
  AbsoluteFill,
  interpolate,
  spring,
  useCurrentFrame,
  useVideoConfig
} from 'remotion';
import { loadFont } from '@remotion/google-fonts/Inter';
import React, { CSSProperties } from 'react';

interface SpringTextProps {
  text: string;
  durationInFrames: number;
  style?: CSSProperties;
  fromSize?: number;
  toSize?: number;
  damping?: number;
  mass?: number;
  type?: 'text' | 'emoji' | 'hook';
}

const { fontFamily } = loadFont();

export const SpringText: React.FC<SpringTextProps> = ({
  text,
  durationInFrames,
  style = {},
  fromSize = 0.7,
  toSize = 1,
  damping,
  mass,
  type = 'text'
}) => {
  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  const springConfig =
    type === 'emoji'
      ? {
          damping: damping ?? 7,
          mass: mass ?? 1,
          stiffness: 150
        }
      : {
          damping: damping ?? 12,
          mass: mass ?? 0.5
        };

  const scale = spring({
    fps,
    frame,
    config: springConfig,
    from: fromSize,
    to: toSize
  });

  const finalScale =
    type === 'emoji'
      ? interpolate(
          frame,
          [durationInFrames - 10, durationInFrames],
          [toSize, 0.1]
        )
      : scale;

  const effectiveScale =
    type === 'emoji' && frame > durationInFrames - 15 ? finalScale : scale;

  const defaultStyle: CSSProperties =
    type === 'hook'
      ? {
          padding: '9px 18px',
          gap: '18px',
          background: '#FFFFFF',
          borderRadius: '18px',
          fontFamily,
          fontStyle: 'normal',
          fontWeight: 800,
          fontSize: '81px',
          lineHeight: '95px',
          textAlign: 'center',
          letterSpacing: '-1.62px',
          color: '#344054',
          display: 'inline-block'
        }
      : {
          fontSize: '74px',
          color: '#FFFFFF',
          textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
          fontWeight: 'bold',
          lineHeight: '1.2'
        };

  return (
    <AbsoluteFill className="relative w-full">
      <div
        className="absolute w-full"
        style={
          {
            top: '9%',
            transform: 'translateY(-50%)',
            textAlign: 'center',
            padding: '0 5%',
            position: 'absolute'
          } as CSSProperties
        }
      >
        <div
          style={
            {
              ...defaultStyle,
              ...style,
              transform: `scale(${effectiveScale})`
            } as CSSProperties
          }
        >
          {text}
        </div>
      </div>
    </AbsoluteFill>
  );
};
