import '@szhsin/react-menu/dist/index.css';
import 'firebase/compat/firestore';
import {
  ControlledMenuProps,
  ControlledMenu as MenuInner,
  MenuItem as MenuItemInner
} from '@szhsin/react-menu';
import { Rating, Star } from '@smastrom/react-rating';
import { ReactNode, useRef, useState } from 'react';
import { cleanupSelection, loopThroughDivs } from '../../utils/divs';
import Icon, { IconType } from './Icon';

import '@smastrom/react-rating/style.css';
import { Asset } from '../../slideshow/types';
import { NewJPButton } from '../GeneralComponents';
import { assetSummaryDropdownSettings } from '@/recoil/atoms/dropdown.atoms';
import { useRecoilState } from 'recoil';
import JPInput from './JPInput';
import Pill from './Pill';
import firebase from 'firebase/compat/app';

interface DropdownDefault {
  name: string | React.JSX.Element;
  icon?: string;
  onClick: () => void;
  childMenu?: boolean;
  type?: DROPDOWN_ITEMS_TYPE.DEFAULT;
  pillText?: string;
  applyPremiumGate?: boolean;
  settings?: {
    height?: number;
    hoverBackground?: string;
    loadingColor?: string;
    color?: 'default' | 'danger';
  };
}

interface DropdownRating {
  name?: string;
  rating: number | firebase.firestore.FieldValue | null;
  status: 'loading' | 'loaded' | 'tbd';
  onRatingChange: (rating: number) => void;
  type: DROPDOWN_ITEMS_TYPE.RATING;
  settings?: {
    height: number;
  };
}
interface DropdownEditKeyword {
  name?: string;
  icon: string;
  type: DROPDOWN_ITEMS_TYPE.EDIT_KEYWORD;
  settings?: {
    height: number;
  };
  childMenu?: boolean;
  back: () => void;
  submit: (value: string) => void;
  originalKeyword?: string;
  pillText?: string;
}
interface DropdownDivider {
  type: DROPDOWN_ITEMS_TYPE.DIVIDER;
}

type DropdownOptions =
  | DropdownDefault
  | DropdownRating
  | DropdownDivider
  | DropdownEditKeyword;

interface DropdownProps {
  id?: string;
  open?: boolean;
  setOpen?: any;
  options?: DropdownOptions[];
  targetElement?: ReactNode;
  handleUserMediaUpload?: (files: FileList) => void;
}

enum DROPDOWN_ITEMS_TYPE {
  DEFAULT = 'default',
  RATING = 'rating',
  DIVIDER = 'divider',
  EDIT_KEYWORD = 'edit-keyword'
}

const DEFAULT_DROPDOWN_SETTINGS = {
  fromElement: null,
  toElement: null,
  mode: null,
  opened: false,
  position: { x: null, y: null }
};

const ControlledMenu = (props: ControlledMenuProps) => (
  <MenuInner
    {...props}
    menuClassName={
      'transition-opacity	!p-0 !shadow-3xl !-mt-2 !min-w-[200px] divide-y divide-gray-100 overflow-hidden !rounded-lg !border !border-gray-200 bg-white  !ring-1 !ring-black !ring-opacity-5 focus:outline-none !left-0 !top-0'
    }
  />
);
const MenuItem = (props) => (
  <MenuItemInner
    {...props}
    className={'!hover:bg-transparent !cursor-auto !bg-transparent !p-0 '}
  />
);

export default function Dropdown(props: DropdownProps) {
  const { targetElement } = props;
  const options: DropdownOptions[] = props.options || [
    {
      name: 'Generate stock video',
      icon: 'mi-webcam',
      onClick: () => {}
    }
  ];
  const ref = useRef(null);
  const { open, setOpen } = props;
  const [dropdownSettings, setDropdownSettings] = useRecoilState(
    assetSummaryDropdownSettings
  );

  return (
    <div
      className="relative inline-block rounded-md text-left"
      onContextMenu={(e) => e.preventDefault()}
    >
      {/* Use as positioning the ControlledMenu */}
      {!targetElement ? (
        <div className="" ref={ref}></div>
      ) : (
        <div ref={ref}>{targetElement}</div>
      )}
      <ControlledMenu
        anchorRef={ref}
        id={props.id}
        state={open ? 'open' : 'closed'}
        menuClassName=""
        position="anchor"
        reposition="auto"
        direction="top"
        viewScroll="auto"
        onClose={() => {
          if (setOpen) {
            setOpen(false);
          }
          if (open) return;
          cleanupSelection();
        }}
      >
        <>
          {options.map((opt, index) => {
            if (opt.type === DROPDOWN_ITEMS_TYPE.DIVIDER) {
              return (
                <MenuItem key={index}>
                  <div
                    className={` flex h-[1px] w-full items-center bg-grey-200 text-sm`}
                  ></div>
                </MenuItem>
              );
            }
            if (opt.type === DROPDOWN_ITEMS_TYPE.RATING) {
              return (
                <MenuItem key={index}>
                  <div
                    style={{ height: opt?.settings?.height }}
                    className={`group flex w-full flex-col px-4 py-2.5 text-sm text-gray-600`}
                  >
                    <div className={`duration-75`}>{opt.name}</div>
                    <Rating
                      className="-ml-0.5 mt-2 max-w-[106px]"
                      itemStyles={{
                        activeFillColor: '#FCBF49',
                        inactiveFillColor: '#EAECF0',
                        itemShapes: Star
                      }}
                      readOnly={!!opt.rating}
                      value={opt.rating as number}
                      onChange={(newRating) => opt.onRatingChange(newRating)}
                    />
                  </div>
                </MenuItem>
              );
            }
            if (opt.type === DROPDOWN_ITEMS_TYPE.EDIT_KEYWORD) {
              console.log('opt', opt);
              return (
                <MenuItem key={index}>
                  <div
                    style={{ height: opt?.settings?.height }}
                    className={`group relative flex w-full flex-col text-sm text-gray-600`}
                  >
                    <div className="flex w-full border border-b border-solid border-gray-100 ">
                      <div
                        className="flex-0 flex cursor-pointer flex-row px-4 py-4"
                        onClick={() => {
                          opt.back();
                        }}
                      >
                        <Icon className="mr-2" type="mi-arrow-left" />
                        <div>Back</div>
                      </div>
                    </div>
                    <div className="flex w-full flex-col px-4 py-2.5 text-gray-600">
                      <form
                        onSubmit={(event) => {
                          event.preventDefault();
                          const formData = new FormData(
                            event.target as HTMLFormElement
                          );
                          const keyword = formData.get('keyword');
                          return opt.submit(keyword as string);
                        }}
                      >
                        <div>Update keyword to generate again</div>
                        <JPInput
                          name="keyword"
                          placeholder="Bus, London, Crowded"
                          className="my-3 w-[244px]"
                          defaultValue={opt.originalKeyword}
                        />
                        <NewJPButton
                          width="full"
                          size="md"
                          text="Generate"
                          type="submit"
                        />
                      </form>
                    </div>
                  </div>
                </MenuItem>
              );
            }
            return (
              <MenuItem key={index}>
                {({ hover }) => (
                  <button
                    style={{ height: opt?.settings?.height }}
                    className={`${
                      hover || (opt && opt.icon === 'loading')
                        ? `${
                            opt?.settings?.hoverBackground ?? 'bg-blue-600'
                          } text-white`
                        : `${
                            opt?.settings?.color === 'danger'
                              ? 'text-red-600'
                              : 'text-gray-600'
                          }`
                    } group flex w-full items-center px-4 py-2.5 text-sm ${
                      opt.icon && opt.icon === 'loading'
                        ? 'cursor-not-allowed'
                        : 'cursor-pointer'
                    }`}
                    onClick={(e) => {
                      if (opt.name === 'Switch to other B-roll') {
                        setDropdownSettings((prevState) => ({
                          ...prevState,
                          childMenuOpened: !prevState.childMenuOpened
                        }));
                      } else if (opt.onClick) {
                        e.preventDefault();
                        e.stopPropagation();
                        opt.onClick();
                      }
                    }}
                  >
                    {opt.icon && (
                      <Icon
                        color={
                          hover || (opt.icon && opt.icon === 'loading')
                            ? opt.settings?.color ?? 'white'
                            : opt.settings?.color ?? '#344054'
                        }
                        type={opt.icon}
                        className={`m-0 duration-75`}
                      />
                    )}
                    <div className="ml-3 flex min-h-[28px] flex-row items-center gap-2 duration-75">
                      {opt.name}
                      {opt.applyPremiumGate && (
                        <div className="rounded-full bg-orange-100 p-0.5">
                          <Icon size={14} type={IconType.Lightning} />
                        </div>
                      )}
                      {opt.pillText && <Pill text={opt.pillText} />}
                    </div>
                  </button>
                )}
              </MenuItem>
            );
          })}
        </>
      </ControlledMenu>
    </div>
  );
}

type DropdownMode =
  | 'edit'
  | 'create'
  | 'loading'
  | 'randomize'
  | 'delete'
  | 'analyze'
  | 'edit-keyword'
  | 'loading-user-upload'
  | 'delete-user-upload'
  | 'gallery-picker'
  | 'adjust-timestamp'
  | '';

const setupDropdownMode = (mode: DropdownMode) => {
  if (['randomize', 'delete'].includes(mode)) return 'loading';
  return mode;
};

interface IDropdownSettings {
  fromElement: Element;
  toElement: Element;
  mode: DropdownMode;
  loadingType?: DropdownMode;
  opened: boolean;
  childMenuOpened?: boolean;
  position: { x: number; y: number };
  activeAsset?: Asset;
}

export type { DropdownMode, IDropdownSettings, DropdownOptions };
export { setupDropdownMode, DEFAULT_DROPDOWN_SETTINGS, DROPDOWN_ITEMS_TYPE };
