import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState
} from 'react';

import {
  isGalleryPickerModalOpen,
  isGifPickerModalOpen,
  isSearchImageModalOpen
} from '@/recoil/atoms/galleryPicker.atoms';
import { useRecoilState } from 'recoil';

export type GalleryMode =
  | 'gif'
  | 'googleImage'
  | 'stockLibrary'
  | 'favourites'
  | 'upload';

interface GalleryModalContextType {
  galleryMode: GalleryMode;
  isGalleryModalOpen: boolean;
  setGalleryMode: (mode: GalleryMode) => void;
  setIsGalleryModalOpen: (value: boolean) => void;
  closeGalleryModal: () => void;
}

const GalleryModalContext = createContext<GalleryModalContextType | undefined>(
  undefined
);

export const GalleryModalProvider = ({ children }: { children: ReactNode }) => {
  const [galleryMode, setGalleryMode] = useState<GalleryMode>('stockLibrary');
  const [isGalleryModalOpen, setIsGalleryModalOpen] = useState<boolean>(false);

  const closeGalleryModal = () => {
    setIsGalleryModalOpen(false);
  };

  useEffect(() => {
    console.log('galleryMode', galleryMode);
  }, [galleryMode]);
  return (
    <GalleryModalContext.Provider
      value={{
        galleryMode,
        setGalleryMode,
        isGalleryModalOpen,
        setIsGalleryModalOpen,
        closeGalleryModal
      }}
    >
      {children}
    </GalleryModalContext.Provider>
  );
};

export const useGalleryModal = () => {
  const context = useContext(GalleryModalContext);
  if (!context)
    throw new Error('useGalleryModal must be used within GalleryModalProvider');
  return context;
};
