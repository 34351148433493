import { Colours } from '../../components/Colours';
import { FPS } from '../Video';
import {
  SlideshowPreferences,
  SubtitleItemWithPreference,
  SubtitleOptions,
  SubtitleWordLimit
} from '../types';
import { TextOutput } from '@/audiogram/components/TextSequence';
import { TextSequence } from '../components/TextSequence';
import { subtitlesWordLimit } from '../../recoil/atoms/subtitleStyleOptions.atoms';
import { useCurrentFrame } from 'remotion';
import { useRecoilValue } from 'recoil';
import React, { useMemo } from 'react';
import nlpBase from 'compromise';
import plg from 'compromise-stats';

nlpBase.plugin(plg);

const toDecimal = (input: number, decimal = 2) => {
  return parseFloat(input.toFixed(decimal)).toString();
};

function endsWithPunctuation(str) {
  const punctuation = /[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/g;
  return punctuation.test(str.charAt(str.length - 1));
}

export const generatePrettySubtitles = (
  words: SubtitleItemWithPreference[],
  maxCharactersPerLine = SubtitleWordLimit.MORE
) => {
  if (maxCharactersPerLine === SubtitleWordLimit.ONE) return;
  let results: TextOutput[] = [];
  let lastLineEndTime = 0;
  let line: TextOutput & { details: SubtitleItemWithPreference[] } = {
    c: '',
    f:
      results.length <= 1
        ? toDecimal(lastLineEndTime + 0)
        : toDecimal(lastLineEndTime + 0.02),
    t: toDecimal(lastLineEndTime + 0.2),
    details: []
  };

  words.forEach((word, index) => {
    const currentContent = word.c.trim();
    const strippedContent = currentContent.replace(/[^\w\s]/g, '');
    if (
      line.c.length + strippedContent.length + 2 >= maxCharactersPerLine &&
      index !== 0 // Skip the first word as it's always added
    ) {
      results.push(line);
      line = {
        c: '',
        f: word.f || '', // Set line.f based on word.f or keep it empty
        t: '',
        details: []
      };
    }
    line.c += ` ${currentContent}`; // Add current word to the line
    line.c = line.c.trim();
    line.details.push(word);
    if (word.t) {
      line.t = word.t; // Set line.t based on word.t
    }
  });

  // Push the last line
  if (line.c !== '') {
    results.push(line);
  }

  return { results };
};

export const DefaultOption: React.FC<{
  preferences: SlideshowPreferences;
  subtitlesContent: SubtitleItemWithPreference[];
}> = ({ preferences, subtitlesContent }) => {
  const frame = useCurrentFrame();
  const currentTime = frame / FPS;
  const recoilWordLimit = useRecoilValue(subtitlesWordLimit);

  const { pathname } = window.location;
  const isExportSlideshowOpened = pathname === '/export-slideshow';
  const wordLimit = !isExportSlideshowOpened
    ? preferences.wordLimit
    : recoilWordLimit;

  const prettySubtitles = useMemo(() => {
    if (wordLimit === SubtitleWordLimit.ONE) return subtitlesContent;
    const { results } = generatePrettySubtitles(subtitlesContent, wordLimit);
    return results;
  }, [subtitlesContent, wordLimit]);
  return (
    <>
      {preferences &&
        prettySubtitles !== null &&
        prettySubtitles?.length > 0 &&
        (prettySubtitles as SubtitleItemWithPreference[] | TextOutput[]).map(
          (each, idx) => {
            const isActiveSequence =
              currentTime > Number(each.f) && currentTime <= Number(each.t);
            if (!isActiveSequence) {
              return null;
            }

            return (
              <TextSequence
                key={idx}
                subArrayPosition={idx}
                text={each}
                preferences={preferences}
                currentWordTiming={each}
                currentTime={currentTime}
              />
            );
          }
        )}
    </>
  );
};

const SUBTITLE_VS_STYLE: { [key in SubtitleOptions]?: React.CSSProperties } = {
  [SubtitleOptions.REELS]: {
    backgroundColor: Colours.Transparent,
    outline: '1px',
    outlineColor: 'black',
    textShadow: 'rgba(16, 24, 40, 1) 0px 10px 70px',
    letterSpacing: '-4px',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    gap: '0.2em',
    width: '100%'
  }
};

const SUBTITLE_CONTAINER_VS_STYLE = {
  [SubtitleOptions.REELS]: {
    width: '80%',
    left: '50%',
    transform: 'translate(-50%, 0%)',
    display: 'flex',
    justifyContent: 'center'
  }
};

const insertNewlineAtHalf = (sentence: string) => {
  const midIndex = Math.floor(sentence.length / 2);

  // Find the nearest space before the midIndex to avoid splitting a word
  let newlineIndex = sentence.lastIndexOf(' ', midIndex);

  const beforeNewlineLength = newlineIndex;
  const afterNewlineLength = sentence.length - newlineIndex;

  // If the first half becomes longer than the second half,
  // we need to find the next space after midIndex instead
  if (beforeNewlineLength > afterNewlineLength) {
    newlineIndex = sentence.indexOf(' ', midIndex);
  }

  // Insert newline character at the determined position
  return (
    sentence.substring(0, newlineIndex) +
    '\n' +
    sentence.substring(newlineIndex + 1)
  );
};
