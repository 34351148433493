// Keep existing compat imports
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import firebase from 'firebase/compat/app';

const devFirebaseConfig = {
  apiKey: 'AIzaSyB6Bs6Df-PxpCtJtgjKPQwNzlCCxdQJUo8',
  authDomain: 'auth-dev.jupitrr.com',
  databaseURL: 'https://jpt-dev-bd5c3.firebaseio.com',
  projectId: 'jpt-dev-bd5c3',
  storageBucket: 'jpt-dev-bd5c3.appspot.com',
  messagingSenderId: '814873421385',
  appId: '1:814873421385:web:e85b0cd13a84d6acd98198',
  measurementId: 'G-0LY9NBVQ13'
};

const prodFirebaseConfig = {
  apiKey: 'AIzaSyBeD_a8LwC0lUI0pU7SSDSNg4Nnj5zOKJY',
  authDomain: 'auth.jupitrr.com',
  databaseURL: 'https://jpt-prod.firebaseio.com',
  projectId: 'jpt-prod',
  storageBucket: 'jpt-prod.appspot.com',
  messagingSenderId: '137786246442',
  appId: '1:137786246442:web:d70440bcda11e168420a53'
};

const firebaseConfig =
  process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? prodFirebaseConfig
    : devFirebaseConfig;

if (!firebase.apps.length) firebase.initializeApp(firebaseConfig);

const firestore = firebase.firestore();
firestore.settings({
  experimentalAutoDetectLongPolling: true
});

const adminUsersPath = 'admin_users';
const collectionPath = 'media_managers';
const deletedUsersPath = 'deleted_users';
const deletedUsersCountPath = 'deleted_users_count';

export {
  firestore,
  adminUsersPath,
  collectionPath,
  deletedUsersPath,
  deletedUsersCountPath
};
