import {
  Composition,
  cancelRender,
  continueRender,
  delayRender,
  getInputProps,
  getRemotionEnvironment
} from 'remotion';
import { Dimension } from './utils/dimension';
import { DimensionType, getDimensionByName } from './utils/dimension';
import { GenerateSlideshowVideoParams } from './types';
import { SlideshowRender } from './Slideshow/SlideshowRender';
import { getVideoDuration } from './utils/general';
import { sampleProps } from './tests/test';
import { useDefaultFont } from './utils/use-fonts';
import React, { useEffect, useState } from 'react';

import { RecoilRoot } from 'recoil';

export const FPS = 30;
export const MAX_NUMBER_OF_LINES = 2;
export const NUMBER_OF_SAMPLES = 16; //

const isDev = false; // Set this as true for local development
export const Video: React.FC = (props) => {
  const inputProps: GenerateSlideshowVideoParams = isDev
    ? sampleProps
    : getRemotionEnvironment().isPlayer
    ? (props as any)
    : getInputProps() || {};
  useDefaultFont();
  const [handle] = useState(() => delayRender());
  const [duration, setDuration] = useState(1); // at the very minimum render a video for 10 seconds. (Please modify this for modifications)

  useEffect(() => {
    if (!inputProps || !inputProps.assets) return;
    if (duration !== 1) {
      return continueRender(handle);
    }
    getVideoDurationWrapper().then();
  }, [inputProps]);

  const getVideoDurationWrapper = async () => {
    try {
      const audioDuration = await getVideoDuration(inputProps);
      setDuration(Math.round((audioDuration + 1) * FPS));
      continueRender(handle);
    } catch (err) {
      console.error(err);
      cancelRender(err);
    }
  };
  const currentDimension: Dimension =
    inputProps.preferences && inputProps.preferences.dimensionType
      ? getDimensionByName(inputProps.preferences.dimensionType)
      : getDimensionByName(DimensionType.landscape);

  return (
    <>
      <RecoilRoot>
        <Composition
          id={'SlideshowRender'}
          component={SlideshowRender}
          durationInFrames={duration}
          fps={FPS}
          width={currentDimension.W}
          height={currentDimension.H}
          defaultProps={isDev ? (sampleProps as any) : undefined}
        />
      </RecoilRoot>
    </>
  );
};
