import { API_URL } from '../models/constant';
import { LocalStorageItems, getItem } from '../utils/generalUtilities';
import { findClosestRegion } from '@/utils/latency';
import { splitFile } from '../utils/file';
import { toast } from 'react-toastify';
import React, { createContext, useContext, useEffect, useState } from 'react';
import _ from 'lodash';
import axios from 'axios';
import fetchWrapper, { MethodType } from '../utils/fetchWrapper';
export interface StartAsyncUploadResponse {
  upload_id: string;
  data: StartAsyncUploadResponseData;
  file_name: string;
}

export interface StartAsyncUploadResponseData {
  AbortDate: Date;
  AbortRuleId: string;
  ServerSideEncryption: string;
  Bucket: string;
  Key: string;
  UploadId: string;
}

export interface FileUploadContextValue {
  cleanUp: () => void;
  startUpload: (
    fileObjects: { file: File; name: string; isStock?: boolean }[]
  ) => Promise<{ url: string; name: string }[]>;
  uploadPercent: number[];
}

const AsyncFileUploaderContext = createContext<
  FileUploadContextValue | undefined
>(undefined);

export const AsyncFileUploaderProvider = (props: any) => {
  const routeGroup = '/medias';
  const [uploadPercent, setUploadPercent] = useState<number[]>([]);

  const startUpload = async (
    fileObjects: { file: File; name: string; isStock?: boolean }[]
  ): Promise<{ url: string; name: string }[]> => {
    setUploadPercent(new Array(fileObjects.length).fill(0));
    const closestRegion = await findClosestRegion();
    console.log(closestRegion);
    if (closestRegion.latency > 1000) {
      const msg =
        'Your network is a bit unstable, please be patient while we are uploading your file';
      console.error(msg);
      toast.warn(msg);
    }
    const uploads = fileObjects.map(async (fileObject, index) => {
      let file = fileObject.file;
      const partSize = 5 * 1024 * 1024; // 5 MB
      const fileParts = splitFile(file, partSize);
      const requestBody = {
        mime_type: file.type,
        file_name: fileObject.name,
        is_stock: fileObject.isStock
      };
      const startRes: StartAsyncUploadResponse = (await fetchWrapper(
        `${API_URL + routeGroup}/async/start?region=${closestRegion.region}`,
        MethodType.POST,
        JSON.stringify(requestBody),
        getItem(LocalStorageItems.ACCESS_TOKEN)
      )) as any;

      const uploadedParts = [];
      let success = true;
      let partsUploaded = 0;

      await Promise.allSettled(
        fileParts.map(async (part, i) => {
          const partNumber = i + 1;
          let retries = 0;
          let uploadedThisPart = false;
          while (!uploadedThisPart && retries < 5) {
            try {
              const { signed_url: signedUrl } = (await fetchWrapper(
                `${API_URL + routeGroup}/async/sign-part-url?region=${
                  closestRegion.region
                }`,
                MethodType.POST,
                JSON.stringify({
                  upload_id: startRes.upload_id,
                  part_number: partNumber,
                  file_name: startRes.data.Key
                }),
                getItem(LocalStorageItems.ACCESS_TOKEN)
              )) as any;

              const { headers } = await axios.put(signedUrl, fileParts[i], {
                headers: {
                  'Content-Type': file.type
                }
              });

              uploadedParts.push({
                ETag: headers.etag,
                PartNumber: partNumber
              });

              uploadedThisPart = true;
              partsUploaded++;
            } catch (err) {
              success = false;
              console.error('Error uploading part', err);
              retries++;

              if (retries >= 20) {
                const msg =
                  'Failed to upload part after 20 retries, aborting upload';
                console.error(msg);
                toast.error(msg);

                // Call your server to abort the upload here
              } else {
                if (retries % 10 === 0) {
                  const msg =
                    'Your network is a bit unstable, please be patient while we are uploading your file';
                  console.error(msg);
                  toast.warn(msg);
                }
              }
            }
          }
          if (!success) return;
          setUploadPercent((prevUploadPercent) => {
            const updatedProgress = [...prevUploadPercent];
            updatedProgress[index] = (partsUploaded / fileParts.length) * 100;
            console.log(updatedProgress);
            return updatedProgress;
          });
        })
      );
      if (!success) {
        const msg = 'Failed to upload file, please try again later';
        console.error(msg);
        // toast.error(msg);
        setUploadPercent((prevUploadPercent) => {
          const updatedProgress = [...prevUploadPercent];
          updatedProgress[index] = 100;
          return updatedProgress;
        });
        return null;
      }
      const res = (await fetchWrapper(
        `${API_URL + routeGroup}/async/complete?region=${closestRegion.region}`,
        MethodType.POST,
        JSON.stringify({
          upload_id: startRes.upload_id,
          file_name: startRes.data.Key,
          parts: _.orderBy(uploadedParts, ['PartNumber'], ['asc'])
        }),
        getItem(LocalStorageItems.ACCESS_TOKEN)
      )) as any;
      setUploadPercent((prevUploadPercent) => {
        const updatedProgress = [...prevUploadPercent];
        updatedProgress[index] = 100;
        return updatedProgress;
      });

      /* Shitty Amazon returns 'https://jupitrr-staging-playground.s3-accelerate.amazonaws.com/8pamTB10LRdfMogmS0kXBxT4yPw1%2Fslideshow-2kW3Vo8qJEHpVk3K59pWbP--rQ2Rs1kJ7QipBz2MRDM1Er.mp4' instead of 'https://jupitrr-staging-playground.s3-accelerate.amazonaws.com/8pamTB10LRdfMogmS0kXBxT4yPw1/slideshow-2kW3Vo8qJEHpVk3K59pWbP--rQ2Rs1kJ7QipBz2MRDM1Er.mp4', and for some reason decodeURI does not resolve this issue */
      return {
        url: res.data.Location.replace('%2F', '/') as string,
        name: fileObject.name
      };
    });

    const results = await Promise.allSettled(uploads);
    const successfulUploads = (
      results.filter(
        (result) => result.status === 'fulfilled'
      ) as PromiseFulfilledResult<{
        url: string;
        name: string;
      }>[]
    )
      .map((result) => result.value)
      .filter((result) => result !== null);

    // Reset progress when all uploads are done
    setUploadPercent([]);
    return successfulUploads;
  };

  const cleanUp = () => {
    setUploadPercent([]);
  };

  return (
    <AsyncFileUploaderContext.Provider
      value={{ cleanUp, startUpload, uploadPercent }}
    >
      {props.children}
    </AsyncFileUploaderContext.Provider>
  );
};

export const useAsyncFileUploader = () => {
  const context = useContext(AsyncFileUploaderContext);
  if (context === undefined) {
    throw new Error(
      'useFileUploader must be used within a FileUploaderContext'
    );
  }
  return context;
};
