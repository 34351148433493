import { plans } from '@/models/plans';

export const fbPurchaseAds = (paidPlan: string, subscriptionId?: string, interval?: string) => {
    const planData = plans.find(
        (p) => paidPlan.toLowerCase() === p.name.toLowerCase()
    );
    let price = planData.monthlyPrice;
    if (interval === 'yearly') {
        price = planData.annualPrice * 12;
    }
    const fbParams: any = {
        currency: 'USD',
        value: price,
        contents: [
            {
                item_id: planData.name,
                item_name: planData.name,
                price: price,
                interval: interval,
                quantity: 1
            }
        ]
    };
    if (subscriptionId) fbParams.transaction_id = subscriptionId;
    if ((window as any).fbq) {
        (window as any).fbq('track', 'Purchase', fbParams);
    } else {
        console.log('fbq not found in window object');
    }
};
