import { Asset } from '../types';
import {
  BLENDED_TOP_CONTAINER_HEIGHT,
  MASK_IMAGE_GRADIENT,
  SLIDESHOW_FILLER_SECONDS
} from '../utils/constant';
import { FPS } from '../Video';
import { Img, Sequence, interpolate, useCurrentFrame } from 'remotion';
import React, { useEffect, useState } from 'react';
import calculateFPS from '../utils/FPS';

const ImageContainer = ({ asset, index }: { asset: Asset; index?: number }) => {
  const from = Math.max(0, Number(asset.f) - SLIDESHOW_FILLER_SECONDS);
  const to = Number(asset.t) + SLIDESHOW_FILLER_SECONDS;
  let durationInFrames = Math.max(10, Math.round(Math.abs(to - from) * FPS));
  const [url, setUrl] = useState(
    asset.url ||
      'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
  );
  useEffect(() => setUrl(asset.url), [asset.url]);

  const frame = useCurrentFrame();
  const isFirstVideo = parseInt(asset.f) === 0;
  const opacity = !isFirstVideo
    ? interpolate(
        frame,
        [
          0,
          10,
          Math.max(11, durationInFrames - 10),
          Math.max(12, durationInFrames)
        ],
        // v--v---v----------------------v
        [0, 1, 1, 0]
      )
    : undefined;

  const scale = interpolate(
    frame,
    [0, durationInFrames],
    // v--v---v----------------------v
    [1, 1.1]
  );

  const dimensionStyles: React.CSSProperties = (() => {
    switch (asset.preferences?.object_fit) {
      case 'contain':
        return {
          width: '52%',
          height: 'auto',
          objectFit: 'contain',
          position: 'absolute',
          top: asset.preferences?.y ? `${asset.preferences.y}%` : '7%',
          left: '50%',
          transform: 'translateX(-50%)'
        };
      case 'contain-background-blur':
        return {
          width: '100%',
          height: 'auto',
          objectFit: 'contain',
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)'
        };
      case 'contain-blended-top':
        return {
          width: '100%',
          height: BLENDED_TOP_CONTAINER_HEIGHT,
          objectFit: 'cover',
          position: 'absolute',
          top: '0%',
          left: '0%',
          transform: 'translateX(-50%)',
          maskImage: MASK_IMAGE_GRADIENT
        };
      default:
        return {
          width: asset.preferences?.w ? `${asset.preferences.w}%` : '100%',
          height: asset.preferences?.h ? `${asset.preferences.h}%` : '100%',
          top: asset.preferences?.y ? `${asset.preferences.y}%` : 0,
          left: asset.preferences?.x ? `${asset.preferences.x}%` : 0,
          objectFit: 'cover',
          position: 'absolute'
        };
    }
  })();

  const objectFitTransform = React.useMemo(() => {
    switch (asset.preferences?.object_fit) {
      case 'contain':
        return 'translateX(-50%)';
      case 'contain-background-blur':
        return 'translate(-50%, -50%)';
      default:
        return '';
    }
  }, [asset.preferences?.object_fit]);

  const visualStyles = React.useMemo(
    () => ({
      opacity,
      zIndex: 2,
      transform: `${objectFitTransform} scale(${scale})`
    }),
    [objectFitTransform, opacity, scale]
  );

  return (
    <>
      {asset.preferences?.object_fit === 'contain-background-blur' && (
        <Img
          style={{
            position: 'absolute',
            top: '-1%',
            left: '-1%',
            width: '102%',
            height: '102%',
            objectFit: 'cover',
            filter: 'blur(20px)',
            zIndex: 1
          }}
          src={url}
        />
      )}
      <Img
        pauseWhenLoading
        style={{
          ...dimensionStyles,
          ...visualStyles
        }}
        src={
          url ||
          'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
        }
        onError={() =>
          setUrl(
            'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
          )
        }
      />
    </>
  );
};

export default ImageContainer;
