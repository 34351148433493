import {
  DimensionType,
  SubtitleOptions,
  VideoTemplateColours,
  VideoTemplateFonts
} from '../types';
import { EMPTY_AUDIO } from '../utils/constant';
import { preset_aesthetic, preset_bold, preset_clean, preset_power, preset_subtle } from './presets';

export const emptyData = [];

export const bigTitle = [
  {
    c: 'export page display transferred.',
    f: 0,
    t: 3.28
  },
  {
    c: 'Transcript on headed transcript',
    f: 3.69,
    t: 5.880000000000001
  },
  {
    c: 'Research remove underlying f',
    f: 5.92,
    t: 7.5200000000000005
  },
  {
    c: 'jail t make it more homogeneous.',
    f: 7.56,
    t: 9.690000000000001
  },
  {
    c: 'GP head or avatar Monty request',
    f: 9.71,
    t: 12.280000000000001
  },
  {
    c: 'miscellaneous fixes Media manager',
    f: 12.48,
    t: 14.72
  },
  {
    c: 'for implementing real time',
    f: 14.77,
    t: 16.080000000000002
  },
  {
    c: 'progress updates videos',
    f: 16.12,
    t: 17.43
  }
];

export const wordByWord = [
  {
    f: 0.17,
    end: 0.97,
    c: 'you'
  },
  {
    f: 0.98,
    end: 1.35,
    c: 'can'
  },
  {
    f: 1.35,
    end: 1.87,
    c: 'shape'
  },
  {
    f: 1.88,
    end: 2.04,
    c: 'and'
  },
  {
    f: 2.04,
    end: 2.89,
    c: 'create'
  },
  {
    f: 2.9,
    end: 3.05,
    c: 'a'
  },
  {
    f: 3.05,
    end: 3.83,
    c: 'business'
  },
  {
    f: 3.84,
    end: 4.08,
    c: 'that'
  },
  {
    f: 4.08,
    end: 5.96,
    c: '1000%'
  },
  {
    f: 5.97,
    end: 6.61,
    c: 'serves'
  },
  {
    f: 6.61,
    end: 6.72,
    c: 'the'
  },
  {
    f: 6.72,
    end: 7.13,
    c: 'kind'
  },
  {
    f: 7.13,
    end: 7.23,
    c: 'of'
  },
  {
    f: 7.23,
    end: 7.91,
    c: 'life'
  },
  {
    f: 7.92,
    end: 8.18,
    c: 'that'
  },
  {
    id: 15,
    f: 8.18,
    end: 8.3,
    c: 'you'
  },
  {
    f: 8.3,
    end: 8.66,
    c: 'want'
  },
  {
    f: 8.66,
    end: 8.79,
    c: 't'
  },
  {
    f: 8.79,
    end: 9.47,
    c: 'lead.'
  }
];

export const wordByWordNew = [
  {
    f: 7.4,
    t: 7.75,
    c: 'f'
  },
  {
    f: 7.75,
    t: 7.84,
    c: 'the'
  },
  {
    f: 7.84,
    t: 8.32,
    c: 'faculty'
  },
  {
    f: 8.32,
    t: 8.44,
    c: 'of'
  },
  {
    f: 8.44,
    t: 8.99,
    c: 'Pharmacy'
  },
  {
    f: 8.99,
    t: 9.14,
    c: 'and'
  },
  {
    f: 9.14,
    t: 9.76,
    c: 'pharmaceutical'
  }
];

const subtitles1 = [
  {
    f: 0.17,
    t: 0.97,
    c: 'you'
  },
  {
    f: 0.98,
    t: 1.35,
    c: 'can'
  },
  {
    f: 1.35,
    t: 1.87,
    c: 'shape'
  },
  {
    f: 1.88,
    t: 2.04,
    c: 'and'
  },
  {
    f: 2.04,
    t: 2.89,
    c: 'create'
  },
  {
    f: 2.9,
    t: 3.05,
    c: 'a'
  },
  {
    f: 3.05,
    t: 3.83,
    c: 'business'
  },
  {
    f: 3.84,
    t: 4.08,
    c: 'that'
  },
  {
    f: 4.08,
    t: 5.96,
    c: '1000%'
  },
  {
    f: 5.97,
    t: 6.61,
    c: 'serves'
  },
  {
    id: 10,
    f: 6.61,
    t: 6.72,
    c: 'the'
  },
  {
    id: 11,
    f: 6.72,
    t: 7.13,
    c: 'kind'
  },
  {
    id: 12,
    f: 7.13,
    t: 7.23,
    c: 'of'
  },
  {
    id: 13,
    f: 7.23,
    t: 7.91,
    c: 'life'
  },
  {
    id: 14,
    f: 7.92,
    t: 8.18,
    c: 'that'
  },
  {
    id: 15,
    f: 8.18,
    t: 8.3,
    c: 'you'
  },
  {
    id: 16,
    f: 8.3,
    t: 8.66,
    c: 'want'
  },
  {
    id: 17,
    f: 8.66,
    t: 8.79,
    c: 'to'
  },
  {
    id: 18,
    f: 8.79,
    t: 9.47,
    c: 'lead.'
  }
];

const subtitles2 = [
  {
    f: 2,
    t: 4,
    c: 'My vacation in Hawaii',
    fontFamily: VideoTemplateFonts.Lexend,
    subtitleType: SubtitleOptions.WORD_BY_WORD,
    color: 'white'
  },
  {
    f: 6,
    t: 7,
    c: 'Thanks for watching!',
    fontFamily: VideoTemplateFonts['Circular Std'],
    subtitleType: SubtitleOptions.CC,
    color: 'black'
  }
];
const samplePropsDefault: any = {
  preferences: {
    color: VideoTemplateColours.WHITE,
    subtitleType: SubtitleOptions.BIG_TITLE,
    dimensionType: DimensionType.portrait,
    fontFamily: VideoTemplateFonts['Unbounded']
  },
  assets: [],
  subtitlesContent: bigTitle // bigTitle, emptyData
};

const params1: any = {
  assets: [
    {
      type: 'image',
      url: 'https://picsum.photos/600/800',
      f: 0,
      t: 5,
      preferences: {
        w: 80,
        h: 80,
        x: 10,
        y: 10,
        z: 2
      }
    },
    {
      type: 'image',
      url: 'https://picsum.photos/400/600',
      f: 2,
      t: 6.5,
      preferences: {
        w: 50,
        h: 50,
        x: 25,
        y: 25,
        z: 3
      }
    },
    {
      type: 'audio',
      url: EMPTY_AUDIO,
      f: 0,
      t: 7.5
    }
  ],
  subtitlesContent: subtitles1,
  preferences: {
    templateColor: VideoTemplateColours.GRADIENT_GREEN,
    dimensionType: DimensionType.landscape,
    fontFamily: VideoTemplateFonts['Playfair Display'],
    subtitleType: SubtitleOptions.WORD_BY_WORD,
    color: 'white'
  }
};

const params2: any = {
  assets: [
    {
      type: 'video',
      url: 'https://example.com/video1.mp4',
      f: 0,
      t: 5,
      preferences: {
        w: 50,
        h: 50,
        x: 25,
        y: 25,
        z: 1
      }
    },
    {
      type: 'image',
      url: 'https://picsum.photos/800/400',
      f: 3,
      t: 7,
      preferences: {
        w: 80,
        h: 80,
        x: 10,
        y: 10,
        z: 2
      }
    }
  ],
  subtitlesContent: [
    {
      f: 1,
      t: 3,
      c: 'My favorite moments',
      fontFamily: VideoTemplateFonts.Quicksand,
      subtitleType: SubtitleOptions.NONE,
      color: 'white'
    },
    {
      f: 6,
      t: 7,
      c: 'The end',
      fontFamily: VideoTemplateFonts.Solitreo,
      subtitleType: SubtitleOptions.CC,
      color: 'black'
    }
  ],
  preferences: {
    templateColor: VideoTemplateColours.CORAL_RED,
    dimensionType: DimensionType.portrait,
    fontFamily: VideoTemplateFonts.Unbounded,
    subtitleType: SubtitleOptions.WORD_BY_WORD,
    color: 'white'
  }
};

const params3: any = {
  assets: [
    {
      type: 'image',
      url: 'https://picsum.photos/600/400',
      f: 0,
      t: 5,
      preferences: {
        w: 50,
        h: 50,
        x: 25,
        y: 25,
        z: 1
      }
    },
    {
      type: 'audio',
      url: EMPTY_AUDIO,
      f: 0,
      t: 7.5
    },
    {
      type: 'video',
      url: 'https://example.com/video1.mp4',
      f: 5,
      t: 7,
      preferences: {
        w: 80,
        h: 80,
        x: 10,
        y: 10,
        z: 2
      }
    }
  ],
  subtitlesContent: subtitles1,
  preferences: {
    templateColor: VideoTemplateColours.GRADIENT_BLUE_GREEN,
    dimensionType: DimensionType.landscape,
    fontFamily: VideoTemplateFonts['Playfair Display'],
    subtitleType: SubtitleOptions.BIG_TITLE,
    color: 'white'
  }
};

const params4 = {
  last_updated: '2023-05-09T20:07:10.120Z',
  subtitle_status: 'generated',
  duration: 60.348005,
  id: '384XGkrNh8QVjYLPjFZveN',
  created_at: '2023-05-09T15:37:19.136Z',
  author_id: '9w3hCFVE0RReEzT44WfNxADDfbV2',
  assets: [
    {
      type: 'video',
      url: 'https://jupitrr-staging-playground.s3-ap-southeast-1.amazonaws.com/9w3hCFVE0RReEzT44WfNxADDfbV2/slideshow-384XGkrNh8QVjYLPjFZveN--tFHajuXALHS4gP4mY68GQ5.mp4',
      f: 0,
      t: 60.348005,
      id: 'tFHajuXALHS4gP4mY68GQ5'
    },
    {
      f: 5.92,
      preferences: {
        z: 1
      },
      url: 'https://player.vimeo.com/external/421908337.sd.mp4?s=d002225d62c287ea2b15434e271781e666f89d76&profile_id=165&oauth2_token_id=57447761',
      type: 'video',
      id: '4LcU2sTaaiARp23tHKQ8ym',
      t: 7.97
    }
  ],
  preferences: {},
  subtitlesContent: [
    {
      f: 0.19,
      t: 0.3,
      c: 'In'
    },
    {
      f: 0.31,
      t: 0.32,
      c: 'a'
    },
    {
      f: 0.33,
      t: 0.63,
      c: 'single'
    },
    {
      f: 0.64,
      t: 0.83,
      c: 'minute,'
    },
    {
      f: 0.84,
      t: 0.97,
      c: 'your'
    },
    {
      f: 0.98,
      t: 1.19,
      c: 'body'
    },
    {
      f: 1.2,
      t: 1.59,
      c: 'produces'
    },
    {
      f: 1.6,
      t: 2.25,
      c: '120'
    },
    {
      f: 2.26,
      t: 2.37,
      c: 'to'
    },
    {
      f: 2.38,
      t: 2.93,
      c: '180'
    },
    {
      f: 2.94,
      t: 3.22,
      c: 'million'
    },
    {
      f: 3.23,
      t: 3.35,
      c: 'red'
    },
    {
      f: 3.36,
      t: 3.56,
      c: 'blood'
    },
    {
      f: 3.57,
      t: 3.86,
      c: 'cells.'
    },
    {
      f: 3.87,
      t: 4.05,
      c: 'People'
    },
    {
      f: 4.06,
      t: 4.26,
      c: 'ask'
    },
    {
      f: 4.27,
      t: 4.51,
      c: 'Google'
    },
    {
      f: 4.52,
      t: 5.04,
      c: '2.4'
    },
    {
      f: 5.05,
      t: 5.33,
      c: 'million'
    },
    {
      f: 5.34,
      t: 5.78,
      c: 'questions'
    },
    {
      f: 5.79,
      t: 5.91,
      c: 'and'
    },
    {
      f: 5.92,
      t: 6.37,
      c: '25'
    },
    {
      f: 6.38,
      t: 6.65,
      c: 'million'
    },
    {
      f: 6.66,
      t: 6.88,
      c: 'Coca'
    },
    {
      f: 6.89,
      t: 7.09,
      c: 'Cola'
    },
    {
      f: 7.1,
      t: 7.41,
      c: 'products'
    },
    {
      f: 7.42,
      t: 7.52,
      c: 'are'
    },
    {
      f: 7.53,
      t: 7.97,
      c: 'consumed.'
    },
    {
      f: 7.98,
      t: 8.17,
      c: 'Many'
    },
    {
      f: 8.18,
      t: 8.26,
      c: 'of'
    },
    {
      f: 8.27,
      t: 8.44,
      c: 'those'
    },
    {
      f: 8.45,
      t: 8.74,
      c: 'bottles'
    },
    {
      f: 8.75,
      t: 8.84,
      c: 'will'
    },
    {
      f: 8.85,
      t: 8.97,
      c: 'end'
    },
    {
      f: 8.98,
      t: 9.08,
      c: 'up'
    },
    {
      f: 9.09,
      t: 9.19,
      c: 'in'
    },
    {
      f: 9.2,
      t: 9.21,
      c: 'a'
    },
    {
      f: 9.22,
      t: 9.66,
      c: 'landfill'
    },
    {
      f: 9.67,
      t: 9.78,
      c: 'where'
    },
    {
      f: 9.79,
      t: 9.86,
      c: 'the'
    },
    {
      f: 9.87,
      t: 10.07,
      c: 'World'
    },
    {
      f: 10.08,
      t: 10.29,
      c: 'Bank'
    },
    {
      f: 10.3,
      t: 10.7,
      c: 'estimates'
    },
    {
      f: 10.71,
      t: 10.77,
      c: 'we'
    },
    {
      f: 10.78,
      t: 11.08,
      c: 'produce'
    },
    {
      f: 11.09,
      t: 11.52,
      c: '£5'
    },
    {
      f: 11.53,
      t: 11.71,
      c: 'million'
    },
    {
      f: 11.72,
      t: 11.79,
      c: 'of'
    },
    {
      f: 11.8,
      t: 12.18,
      c: 'garbage.'
    },
    {
      f: 12.19,
      t: 12.67,
      c: '108'
    },
    {
      f: 12.68,
      t: 12.93,
      c: 'human'
    },
    {
      f: 12.94,
      t: 13.15,
      c: 'lives'
    },
    {
      f: 13.16,
      t: 13.23,
      c: 'will'
    },
    {
      f: 13.24,
      t: 13.33,
      c: 'be'
    },
    {
      f: 13.34,
      t: 13.58,
      c: 'lost'
    },
    {
      f: 13.59,
      t: 13.65,
      c: 'in'
    },
    {
      f: 13.66,
      t: 13.79,
      c: 'this'
    },
    {
      f: 13.8,
      t: 14.06,
      c: 'minute'
    },
    {
      f: 14.07,
      t: 14.16,
      c: 'and'
    },
    {
      f: 14.17,
      t: 14.22,
      c: 'an'
    },
    {
      f: 14.23,
      t: 14.51,
      c: 'adult'
    },
    {
      f: 14.52,
      t: 14.66,
      c: 'male'
    },
    {
      f: 14.67,
      t: 14.83,
      c: 'will'
    },
    {
      f: 14.84,
      t: 14.99,
      c: 'lose'
    },
    {
      f: 15,
      t: 15.45,
      c: '96'
    },
    {
      f: 15.46,
      t: 15.78,
      c: 'million'
    },
    {
      f: 15.79,
      t: 16.15,
      c: 'cells.'
    },
    {
      f: 16.16,
      t: 16.56,
      c: 'Fortunately,'
    },
    {
      f: 16.57,
      t: 16.96,
      c: '96'
    },
    {
      f: 16.97,
      t: 17.22,
      c: 'million'
    },
    {
      f: 17.23,
      t: 17.45,
      c: 'cells'
    },
    {
      f: 17.46,
      t: 17.77,
      c: 'divide'
    },
    {
      f: 17.78,
      t: 18.18,
      c: 'replacing'
    },
    {
      f: 18.19,
      t: 18.35,
      c: 'those'
    },
    {
      f: 18.36,
      t: 18.58,
      c: 'laws.'
    },
    {
      f: 18.59,
      t: 18.91,
      c: 'Speaking'
    },
    {
      f: 18.92,
      t: 19,
      c: 'of'
    },
    {
      f: 19.01,
      t: 19.49,
      c: 'divisions'
    },
    {
      f: 19.5,
      t: 19.58,
      c: 'in'
    },
    {
      f: 19.59,
      t: 19.66,
      c: 'the'
    },
    {
      f: 19.67,
      t: 19.96,
      c: 'US,'
    },
    {
      f: 19.97,
      t: 20.11,
      c: 'a'
    },
    {
      f: 20.12,
      t: 20.72,
      c: '1.5'
    },
    {
      f: 20.73,
      t: 20.95,
      c: 'people'
    },
    {
      f: 20.96,
      t: 21.05,
      c: 'get'
    },
    {
      f: 21.06,
      t: 21.46,
      c: 'divorced'
    },
    {
      f: 21.47,
      t: 21.62,
      c: 'while'
    },
    {
      f: 21.63,
      t: 22.17,
      c: 'worldwide'
    },
    {
      f: 22.18,
      t: 22.9,
      c: '116'
    },
    {
      f: 22.91,
      t: 23.16,
      c: 'people'
    },
    {
      f: 23.17,
      t: 23.27,
      c: 'will'
    },
    {
      f: 23.28,
      t: 23.39,
      c: 'get'
    },
    {
      f: 23.4,
      t: 23.7,
      c: 'married.'
    },
    {
      f: 23.71,
      t: 24.65,
      c: '82,300'
    },
    {
      f: 24.66,
      t: 24.85,
      c: 'people'
    },
    {
      f: 24.86,
      t: 25,
      c: 'have'
    },
    {
      f: 25.01,
      t: 25.28,
      c: 'sex,'
    },
    {
      f: 25.29,
      t: 25.37,
      c: 'but'
    },
    {
      f: 25.38,
      t: 25.59,
      c: 'only'
    },
    {
      f: 25.6,
      t: 26.43,
      c: '258'
    },
    {
      f: 26.44,
      t: 26.75,
      c: 'babies'
    },
    {
      f: 26.76,
      t: 26.88,
      c: 'will'
    },
    {
      f: 26.89,
      t: 26.98,
      c: 'be'
    },
    {
      f: 26.99,
      t: 27.33,
      c: 'born'
    },
    {
      f: 27.34,
      t: 27.54,
      c: 'and'
    },
    {
      f: 27.55,
      t: 27.56,
      c: 'a'
    },
    {
      f: 27.57,
      t: 27.9,
      c: 'fetus'
    },
    {
      f: 27.91,
      t: 27.98,
      c: 'of'
    },
    {
      f: 27.99,
      t: 28.39,
      c: 'developing'
    },
    {
      f: 28.4,
      t: 28.75,
      c: 'girls'
    },
    {
      f: 28.76,
      t: 28.85,
      c: 'at'
    },
    {
      f: 28.86,
      t: 28.87,
      c: 'a'
    },
    {
      f: 28.88,
      t: 29.02,
      c: 'rate'
    },
    {
      f: 29.03,
      t: 29.12,
      c: 'of'
    },
    {
      f: 29.13,
      t: 29.44,
      c: '200'
    },
    {
      f: 29.59,
      t: 30.2,
      c: '1000'
    },
    {
      f: 30.21,
      t: 30.32,
      c: 'per'
    },
    {
      f: 30.33,
      t: 30.64,
      c: 'minute.'
    },
    {
      f: 30.64,
      t: 30.73,
      c: 'So'
    },
    {
      f: 30.73,
      t: 30.89,
      c: "it's"
    },
    {
      f: 30.91,
      t: 31.02,
      c: 'no'
    },
    {
      f: 31.03,
      t: 31.25,
      c: 'wonder'
    },
    {
      f: 31.26,
      t: 31.35,
      c: 'that'
    },
    {
      f: 31.36,
      t: 31.38,
      c: 'a'
    },
    {
      f: 31.39,
      t: 31.78,
      c: 'computer'
    },
    {
      f: 31.8,
      t: 32.27,
      c: 'simulation'
    },
    {
      f: 32.28,
      t: 32.52,
      c: 'takes'
    },
    {
      f: 32.53,
      t: 32.9,
      c: '60'
    },
    {
      f: 32.91,
      t: 33.58,
      c: 'quadrillion'
    },
    {
      f: 33.59,
      t: 33.78,
      c: 'bites'
    },
    {
      f: 33.79,
      t: 33.84,
      c: 'to'
    },
    {
      f: 33.85,
      t: 34.26,
      c: 'simulate'
    },
    {
      f: 34.26,
      t: 34.27,
      c: 'a'
    },
    {
      f: 34.28,
      t: 34.52,
      c: 'minute.'
    },
    {
      f: 34.53,
      t: 34.65,
      c: 'An'
    },
    {
      f: 34.65,
      t: 34.91,
      c: 'average'
    },
    {
      f: 34.92,
      t: 35.01,
      c: 'of'
    },
    {
      f: 35.02,
      t: 35.6,
      c: '1.38'
    },
    {
      f: 35.62,
      t: 36.09,
      c: 'micrometers'
    },
    {
      f: 36.1,
      t: 36.22,
      c: 'of'
    },
    {
      f: 36.22,
      t: 36.59,
      c: 'rainfall'
    },
    {
      f: 36.6,
      t: 36.79,
      c: 'around'
    },
    {
      f: 36.8,
      t: 36.84,
      c: 'the'
    },
    {
      f: 36.85,
      t: 37.13,
      c: 'world'
    },
    {
      f: 37.13,
      t: 37.26,
      c: 'which'
    },
    {
      f: 37.26,
      t: 37.35,
      c: 'is'
    },
    {
      f: 37.37,
      t: 37.88,
      c: '4.7'
    },
    {
      f: 37.9,
      t: 38.22,
      c: 'billion'
    },
    {
      f: 38.23,
      t: 38.62,
      c: 'bathtubs'
    },
    {
      f: 38.63,
      t: 38.69,
      c: 'of'
    },
    {
      f: 38.7,
      t: 38.94,
      c: 'water'
    },
    {
      f: 38.95,
      t: 39.13,
      c: 'every'
    },
    {
      f: 39.13,
      t: 39.33,
      c: 'minute.'
    },
    {
      f: 39.33,
      t: 39.44,
      c: 'And'
    },
    {
      f: 39.44,
      t: 39.51,
      c: 'with'
    },
    {
      f: 39.52,
      t: 39.63,
      c: 'the'
    },
    {
      f: 39.63,
      t: 39.95,
      c: 'storm'
    },
    {
      f: 39.97,
      t: 40.16,
      c: 'comes'
    },
    {
      f: 40.17,
      t: 40.72,
      c: 'approximately'
    },
    {
      f: 40.72,
      t: 41.33,
      c: '6000'
    },
    {
      f: 41.33,
      t: 41.54,
      c: 'volts'
    },
    {
      f: 41.55,
      t: 41.69,
      c: 'of'
    },
    {
      f: 41.69,
      t: 41.9,
      c: 'cloud'
    },
    {
      f: 41.91,
      t: 42.01,
      c: 'to'
    },
    {
      f: 42.02,
      t: 42.28,
      c: 'ground.'
    },
    {
      f: 42.29,
      t: 42.54,
      c: 'Lightning'
    },
    {
      f: 42.55,
      t: 42.8,
      c: 'hitting'
    },
    {
      f: 42.81,
      t: 42.85,
      c: 'the'
    },
    {
      f: 42.87,
      t: 43.13,
      c: 'earth.'
    },
    {
      f: 43.13,
      t: 43.22,
      c: 'A'
    },
    {
      f: 43.22,
      t: 44.01,
      c: '£150'
    },
    {
      f: 44.02,
      t: 44.28,
      c: 'person'
    },
    {
      f: 44.29,
      t: 44.67,
      c: 'expends'
    },
    {
      f: 44.69,
      t: 45.22,
      c: '1.1'
    },
    {
      f: 45.22,
      t: 45.63,
      c: 'calories'
    },
    {
      f: 45.63,
      t: 45.7,
      c: 'of'
    },
    {
      f: 45.72,
      t: 45.97,
      c: 'energy'
    },
    {
      f: 45.97,
      t: 46.08,
      c: 'per'
    },
    {
      f: 46.09,
      t: 46.29,
      c: 'minute'
    },
    {
      f: 46.3,
      t: 46.44,
      c: 'while'
    },
    {
      f: 46.45,
      t: 46.83,
      c: 'sleeping'
    },
    {
      f: 46.84,
      t: 46.97,
      c: 'while'
    },
    {
      f: 46.97,
      t: 47.08,
      c: 'the'
    },
    {
      f: 47.08,
      t: 47.24,
      c: 'sun'
    },
    {
      f: 47.26,
      t: 47.62,
      c: 'provides'
    },
    {
      f: 47.63,
      t: 47.69,
      c: 'us'
    },
    {
      f: 47.69,
      t: 47.81,
      c: 'with'
    },
    {
      f: 47.83,
      t: 48.92,
      c: '83.33'
    },
    {
      f: 48.94,
      t: 49.3,
      c: 'terawatt'
    },
    {
      f: 49.31,
      t: 49.44,
      c: 'of'
    },
    {
      f: 49.44,
      t: 49.79,
      c: 'energy.'
    },
    {
      f: 49.8,
      t: 49.97,
      c: "Here's"
    },
    {
      f: 49.98,
      t: 50.08,
      c: "we'll"
    },
    {
      f: 50.08,
      t: 50.37,
      c: 'complete'
    },
    {
      f: 50.38,
      t: 50.84,
      c: '1800'
    },
    {
      f: 50.85,
      t: 51.19,
      c: 'kilometers'
    },
    {
      f: 51.2,
      t: 51.28,
      c: 'of'
    },
    {
      f: 51.29,
      t: 51.4,
      c: 'its'
    },
    {
      f: 51.4,
      t: 51.94,
      c: '740'
    },
    {
      f: 51.95,
      t: 52.2,
      c: 'million'
    },
    {
      f: 52.22,
      t: 52.37,
      c: 'around'
    },
    {
      f: 52.38,
      t: 52.42,
      c: 'the'
    },
    {
      f: 52.44,
      t: 52.63,
      c: 'sun'
    },
    {
      f: 52.63,
      t: 52.88,
      c: 'moving'
    },
    {
      f: 52.9,
      t: 53.65,
      c: '1034'
    },
    {
      f: 53.65,
      t: 53.9,
      c: 'times'
    },
    {
      f: 53.9,
      t: 54.22,
      c: 'faster'
    },
    {
      f: 54.23,
      t: 54.38,
      c: 'than'
    },
    {
      f: 54.38,
      t: 54.4,
      c: 'a'
    },
    {
      f: 54.4,
      t: 54.69,
      c: 'Cheetah.'
    },
    {
      f: 54.7,
      t: 55.26,
      c: '70,000'
    },
    {
      f: 55.27,
      t: 55.51,
      c: 'hours'
    },
    {
      f: 55.51,
      t: 55.58,
      c: 'of'
    },
    {
      f: 55.58,
      t: 55.88,
      c: 'Netflix'
    },
    {
      f: 55.88,
      t: 55.98,
      c: 'Star'
    },
    {
      f: 55.99,
      t: 56.26,
      c: 'watch'
    },
    {
      f: 56.27,
      t: 56.58,
      c: '300'
    },
    {
      f: 56.59,
      t: 56.77,
      c: 'hours'
    },
    {
      f: 56.78,
      t: 56.84,
      c: 'are'
    },
    {
      f: 56.85,
      t: 57.16,
      c: 'uploaded'
    },
    {
      f: 57.17,
      t: 57.24,
      c: 'to'
    },
    {
      f: 57.26,
      t: 57.55,
      c: 'youtube'
    },
    {
      f: 57.56,
      t: 57.65,
      c: 'and'
    },
    {
      f: 57.65,
      t: 57.69,
      c: 'you'
    },
    {
      f: 57.7,
      t: 57.79,
      c: 'can'
    },
    {
      f: 57.8,
      t: 58.01,
      c: 'watch'
    },
    {
      f: 58.01,
      t: 58.09,
      c: 'this'
    },
    {
      f: 58.1,
      t: 58.38,
      c: 'video'
    },
    {
      f: 58.38,
      t: 58.51,
      c: 'and'
    },
    {
      f: 58.52,
      t: 59.02,
      c: 'subscribe.'
    }
  ]
};

const params5 = {
  author_id: 'pqwv0yR6IqUkiCjtKeyUyzNn09h2',
  duration: 32.39,
  subtitle_status: 'generated',
  preferences: {
    positionY: 61,
    dimensionType: 'landscape',
    fontFamily: 'Solitreo',
    hideOnboarding: true,
    fontSize: 108,
    wordLimit: 35
  },
  subtitle_url:
    'https://jupitrr-staging-playground.s3.ap-southeast-1.amazonaws.com/pqwv0yR6IqUkiCjtKeyUyzNn09h2/slideshow-7ATeH6JzSfmppFUwrThcsw-source.json?q=1683905805129',
  last_updated: '2023-05-10T20:50:14.859Z',
  id: '7ATeH6JzSfmppFUwrThcsw',
  assets: [
    {
      t: '27.729433',
      f: '0',
      offset_time: 0,
      id: 'cY813mR9v8ceoV7yFRPAxi',
      type: 'video',
      url: 'https://player.vimeo.com/external/483983859.hd.mp4?s=863e3a3664cddbe22db936b6537a1c847453ba49&profile_id=175&oauth2_token_id=57447761'
    }
  ],
  subtitle_status: 'generated',
  duration: 27.729433,
  preferences: {
    positionY: 51,
    dimensionType: 'landscape',
    fontFamily: 'Dancing Script',
    color: '#9a02f7',
    hideOnboarding: true,
    fontSize: 114,
    wordLimit: 35,
    animationColor: '#bebcbf'
  },
  is_debian: true,
  subtitlesContent: [
    {
      t: 0.3,
      f: 0.19,
      c: 'In'
    },
    {
      t: 0.32,
      f: 0.31,
      c: 'a'
    },
    {
      t: 0.63,
      f: 0.33,
      c: 'single'
    },
    {
      c: 'minute,',
      t: 0.83,
      f: 0.64
    },
    {
      c: 'your',
      f: 0.84,
      t: 0.97
    },
    {
      t: 1.19,
      f: 0.98,
      c: 'body'
    },
    {
      f: 1.2,
      t: 1.59,
      c: 'produces'
    },
    {
      c: '120',
      f: 1.6,
      t: 2.25
    },
    {
      t: 2.37,
      f: 2.26,
      c: 'to'
    },
    {
      c: '180',
      t: 2.93,
      f: 2.38
    },
    {
      f: 2.94,
      c: 'million',
      t: 3.22
    },
    {
      c: 'red',
      f: 3.23,
      t: 3.35
    },
    {
      t: 3.56,
      c: 'blood',
      f: 3.36
    },
    {
      f: 3.57,
      c: 'cells.',
      t: 3.86
    },
    {
      c: 'People',
      f: 3.87,
      t: 4.05
    },
    {
      f: 4.06,
      c: 'ask',
      t: 4.26
    },
    {
      f: 4.27,
      c: 'Google',
      t: 4.51
    },
    {
      f: 4.52,
      t: 5.04,
      c: '2.4'
    },
    {
      t: 5.33,
      f: 5.05,
      c: 'million'
    },
    {
      c: 'questions',
      f: 5.34,
      t: 5.78
    },
    {
      c: 'and',
      t: 5.91,
      f: 5.79
    },
    {
      c: '25',
      t: 6.37,
      f: 5.92
    },
    {
      t: 6.65,
      f: 6.38,
      c: 'million'
    },
    {
      f: 6.66,
      c: 'Coca',
      t: 6.88
    },
    {
      c: 'Cola',
      t: 7.09,
      f: 6.89
    },
    {
      t: 7.41,
      c: 'products',
      f: 7.1
    },
    {
      c: 'are',
      f: 7.42,
      t: 7.52
    },
    {
      t: 7.97,
      f: 7.53,
      c: 'consumed.'
    },
    {
      c: 'Many',
      t: 8.17,
      f: 7.98
    },
    {
      t: 8.26,
      c: 'of',
      f: 8.18
    },
    {
      f: 8.27,
      c: 'those',
      t: 8.44
    },
    {
      t: 8.74,
      f: 8.45,
      c: 'bottles'
    },
    {
      f: 8.75,
      t: 8.84,
      c: 'will'
    },
    {
      f: 8.85,
      c: 'end',
      t: 8.97
    },
    {
      t: 9.08,
      f: 8.98,
      c: 'up'
    },
    {
      f: 9.09,
      c: 'in',
      t: 9.19
    },
    {
      c: 'a',
      f: 9.2,
      t: 9.21
    },
    {
      c: 'landfill',
      t: 9.66,
      f: 9.22
    },
    {
      f: 9.67,
      c: 'where',
      t: 9.78
    },
    {
      c: 'the',
      f: 9.79,
      t: 9.86
    },
    {
      c: 'World',
      t: 10.07,
      f: 9.87
    },
    {
      t: 10.29,
      c: 'Bank',
      f: 10.08
    },
    {
      c: 'estimates',
      t: 10.7,
      f: 10.3
    },
    {
      f: 10.71,
      c: 'we',
      t: 10.77
    },
    {
      f: 10.78,
      t: 11.08,
      c: 'produce'
    },
    {
      c: '£5',
      t: 11.52,
      f: 11.09
    },
    {
      f: 11.53,
      c: 'million',
      t: 11.71
    },
    {
      t: 11.79,
      c: 'of',
      f: 11.72
    },
    {
      c: 'garbage.',
      f: 11.8,
      t: 12.18
    },
    {
      f: 12.19,
      t: 12.67,
      c: '108'
    },
    {
      c: 'human',
      t: 12.93,
      f: 12.68
    },
    {
      t: 13.15,
      c: 'lives',
      f: 12.94
    },
    {
      c: 'will',
      t: 13.23,
      f: 13.16
    },
    {
      t: 13.33,
      c: 'be',
      f: 13.24
    },
    {
      f: 13.34,
      t: 13.58,
      c: 'lost'
    },
    {
      t: 13.65,
      f: 13.59,
      c: 'in'
    },
    {
      t: 13.79,
      f: 13.66,
      c: 'this'
    },
    {
      f: 13.8,
      c: 'minute',
      t: 14.06
    },
    {
      c: 'and',
      t: 14.16,
      f: 14.07
    },
    {
      t: 14.22,
      f: 14.17,
      c: 'an'
    },
    {
      f: 14.23,
      c: 'adult',
      t: 14.51
    },
    {
      t: 14.66,
      c: 'male',
      f: 14.52
    },
    {
      c: 'will',
      t: 14.83,
      f: 14.67
    },
    {
      f: 14.84,
      t: 14.99,
      c: 'lose'
    },
    {
      f: 15,
      t: 15.45,
      c: '96'
    },
    {
      t: 15.78,
      c: 'million',
      f: 15.46
    },
    {
      c: 'cells.',
      f: 15.79,
      t: 16.15
    },
    {
      c: 'Fortunately,',
      t: 16.56,
      f: 16.16
    },
    {
      f: 16.57,
      t: 16.96,
      c: '96'
    },
    {
      f: 16.97,
      c: 'million',
      t: 17.22
    },
    {
      c: 'cells',
      f: 17.23,
      t: 17.45
    },
    {
      f: 17.46,
      c: 'divide',
      t: 17.77
    },
    {
      c: 'replacing',
      f: 17.78,
      t: 18.18
    },
    {
      f: 18.19,
      t: 18.35,
      c: 'those'
    },
    {
      c: 'laws.',
      t: 18.58,
      f: 18.36
    },
    {
      f: 18.59,
      t: 18.91,
      c: 'Speaking'
    },
    {
      c: 'of',
      t: 19,
      f: 18.92
    },
    {
      c: 'divisions',
      f: 19.01,
      t: 19.49
    },
    {
      f: 19.5,
      c: 'in',
      t: 19.58
    },
    {
      c: 'the',
      f: 19.59,
      t: 19.66
    },
    {
      t: 19.96,
      f: 19.67,
      c: 'US,'
    },
    {
      t: 20.11,
      c: 'a',
      f: 19.97
    },
    {
      t: 20.72,
      f: 20.12,
      c: '1.5'
    },
    {
      c: 'people',
      t: 20.95,
      f: 20.73
    },
    {
      c: 'get',
      f: 20.96,
      t: 21.05
    },
    {
      f: 21.06,
      c: 'divorced',
      t: 21.46
    },
    {
      t: 21.62,
      f: 21.47,
      c: 'while'
    },
    {
      f: 21.63,
      c: 'worldwide',
      t: 22.17
    },
    {
      t: 22.9,
      c: '116',
      f: 22.18
    },
    {
      f: 22.91,
      t: 23.16,
      c: 'people'
    },
    {
      t: 23.27,
      f: 23.17,
      c: 'will'
    },
    {
      c: 'get',
      t: 23.39,
      f: 23.28
    },
    {
      t: 23.7,
      f: 23.4,
      c: 'married.'
    },
    {
      c: '82,300',
      f: 23.71,
      t: 24.65
    },
    {
      c: 'people',
      f: 24.66,
      t: 24.85
    },
    {
      f: 24.86,
      c: 'have',
      t: 25
    },
    {
      f: 25.01,
      c: 'sex,',
      t: 25.28
    },
    {
      t: 25.37,
      c: 'but',
      f: 25.29
    },
    {
      t: 25.59,
      f: 25.38,
      c: 'only'
    },
    {
      t: 26.43,
      c: '258',
      f: 25.6
    },
    {
      f: 26.44,
      t: 26.75,
      c: 'babies'
    },
    {
      c: 'will',
      f: 26.76,
      t: 26.88
    },
    {
      t: 26.98,
      c: 'be',
      f: 26.89
    },
    {
      t: 27.33,
      c: 'born',
      f: 26.99
    },
    {
      t: 27.54,
      c: 'and',
      f: 27.34
    },
    {
      f: 27.55,
      c: 'a',
      t: 27.56
    },
    {
      f: 27.57,
      c: 'fetus',
      t: 27.9
    },
    {
      c: 'of',
      f: 27.91,
      t: 27.98
    },
    {
      c: 'developing',
      t: 28.39,
      f: 27.99
    },
    {
      c: 'girls',
      t: 28.75,
      f: 28.4
    },
    {
      f: 28.76,
      c: 'at',
      t: 28.85
    },
    {
      t: 28.87,
      f: 28.86,
      c: 'a'
    },
    {
      c: 'rate',
      f: 28.88,
      t: 29.02
    },
    {
      c: 'of',
      f: 29.03,
      t: 29.12
    },
    {
      c: '200',
      f: 29.13,
      t: 29.44
    },
    {
      f: 29.59,
      t: 30.2,
      c: '1000'
    },
    {
      t: 30.32,
      f: 30.21,
      c: 'per'
    },
    {
      c: 'minute.',
      f: 30.33,
      t: 30.64
    },
    {
      c: 'So',
      f: 30.64,
      t: 30.73
    },
    {
      t: 30.89,
      f: 30.73,
      c: "it's"
    },
    {
      f: 30.91,
      c: 'no',
      t: 31.02
    },
    {
      t: 31.25,
      f: 31.03,
      c: 'wonder'
    },
    {
      c: 'that',
      f: 31.26,
      t: 31.35
    },
    {
      c: 'a',
      t: 31.38,
      f: 31.36
    },
    {
      c: 'computer',
      f: 31.39,
      t: 31.78
    },
    {
      c: 'simulation',
      f: 31.8,
      t: 32.27
    },
    {
      t: 32.52,
      f: 32.28,
      c: 'takes'
    },
    {
      f: 32.53,
      c: '60',
      t: 32.9
    },
    {
      c: 'quadrillion',
      t: 33.58,
      f: 32.91
    },
    {
      f: 33.59,
      c: 'bites',
      t: 33.78
    },
    {
      c: 'to',
      f: 33.79,
      t: 33.84
    },
    {
      t: 34.26,
      f: 33.85,
      c: 'simulate'
    },
    {
      c: 'a',
      f: 34.26,
      t: 34.27
    },
    {
      c: 'minute.',
      t: 34.52,
      f: 34.28
    },
    {
      t: 34.65,
      c: 'An',
      f: 34.53
    },
    {
      t: 34.91,
      f: 34.65,
      c: 'average'
    },
    {
      f: 34.92,
      t: 35.01,
      c: 'of'
    },
    {
      c: '1.38',
      f: 35.02,
      t: 35.6
    },
    {
      t: 36.09,
      f: 35.62,
      c: 'micrometers'
    },
    {
      f: 36.1,
      t: 36.22,
      c: 'of'
    },
    {
      c: 'rainfall',
      f: 36.22,
      t: 36.59
    },
    {
      c: 'around',
      t: 36.79,
      f: 36.6
    },
    {
      c: 'the',
      t: 36.84,
      f: 36.8
    },
    {
      f: 36.85,
      c: 'world',
      t: 37.13
    },
    {
      t: 37.26,
      c: 'which',
      f: 37.13
    },
    {
      c: 'is',
      f: 37.26,
      t: 37.35
    },
    {
      c: '4.7',
      f: 37.37,
      t: 37.88
    },
    {
      f: 37.9,
      c: 'billion',
      t: 38.22
    },
    {
      c: 'bathtubs',
      f: 38.23,
      t: 38.62
    },
    {
      c: 'of',
      f: 38.63,
      t: 38.69
    },
    {
      t: 38.94,
      c: 'water',
      f: 38.7
    },
    {
      c: 'every',
      f: 38.95,
      t: 39.13
    },
    {
      t: 39.33,
      f: 39.13,
      c: 'minute.'
    },
    {
      t: 39.44,
      c: 'And',
      f: 39.33
    },
    {
      f: 39.44,
      t: 39.51,
      c: 'with'
    },
    {
      c: 'the',
      t: 39.63,
      f: 39.52
    },
    {
      t: 39.95,
      f: 39.63,
      c: 'storm'
    },
    {
      f: 39.97,
      t: 40.16,
      c: 'comes'
    },
    {
      f: 40.17,
      c: 'approximately',
      t: 40.72
    },
    {
      f: 40.72,
      c: '6000',
      t: 41.33
    },
    {
      t: 41.54,
      f: 41.33,
      c: 'volts'
    },
    {
      f: 41.55,
      t: 41.69,
      c: 'of'
    },
    {
      c: 'cloud',
      t: 41.9,
      f: 41.69
    },
    {
      c: 'to',
      t: 42.01,
      f: 41.91
    },
    {
      f: 42.02,
      t: 42.28,
      c: 'ground.'
    },
    {
      t: 42.54,
      c: 'Lightning',
      f: 42.29
    },
    {
      f: 42.55,
      c: 'hitting',
      t: 42.8
    },
    {
      c: 'the',
      t: 42.85,
      f: 42.81
    },
    {
      t: 43.13,
      f: 42.87,
      c: 'earth.'
    },
    {
      c: 'A',
      t: 43.22,
      f: 43.13
    },
    {
      f: 43.22,
      t: 44.01,
      c: '£150'
    },
    {
      c: 'person',
      f: 44.02,
      t: 44.28
    },
    {
      c: 'expends',
      f: 44.29,
      t: 44.67
    },
    {
      c: '1.1',
      f: 44.69,
      t: 45.22
    },
    {
      t: 45.63,
      f: 45.22,
      c: 'calories'
    },
    {
      c: 'of',
      f: 45.63,
      t: 45.7
    },
    {
      f: 45.72,
      t: 45.97,
      c: 'energy'
    },
    {
      c: 'per',
      t: 46.08,
      f: 45.97
    },
    {
      t: 46.29,
      c: 'minute',
      f: 46.09
    },
    {
      c: 'while',
      t: 46.44,
      f: 46.3
    },
    {
      t: 46.83,
      c: 'sleeping',
      f: 46.45
    },
    {
      c: 'while',
      f: 46.84,
      t: 46.97
    },
    {
      c: 'the',
      t: 47.08,
      f: 46.97
    },
    {
      t: 47.24,
      f: 47.08,
      c: 'sun'
    },
    {
      t: 47.62,
      f: 47.26,
      c: 'provides'
    },
    {
      t: 47.69,
      f: 47.63,
      c: 'us'
    },
    {
      c: 'with',
      f: 47.69,
      t: 47.81
    },
    {
      c: '83.33',
      f: 47.83,
      t: 48.92
    },
    {
      f: 48.94,
      c: 'terawatt',
      t: 49.3
    },
    {
      f: 49.31,
      t: 49.44,
      c: 'of'
    },
    {
      t: 49.79,
      c: 'energy.',
      f: 49.44
    },
    {
      c: "Here's",
      t: 49.97,
      f: 49.8
    },
    {
      t: 50.08,
      c: "we'll",
      f: 49.98
    },
    {
      t: 50.37,
      f: 50.08,
      c: 'complete'
    },
    {
      c: '1800',
      t: 50.84,
      f: 50.38
    },
    {
      c: 'kilometers',
      t: 51.19,
      f: 50.85
    },
    {
      t: 51.28,
      f: 51.2,
      c: 'of'
    },
    {
      f: 51.29,
      c: 'its',
      t: 51.4
    },
    {
      c: '740',
      f: 51.4,
      t: 51.94
    },
    {
      f: 51.95,
      c: 'million',
      t: 52.2
    },
    {
      f: 52.22,
      t: 52.37,
      c: 'around'
    },
    {
      t: 52.42,
      f: 52.38,
      c: 'the'
    },
    {
      c: 'sun',
      t: 52.63,
      f: 52.44
    },
    {
      c: 'moving',
      t: 52.88,
      f: 52.63
    },
    {
      t: 53.65,
      f: 52.9,
      c: '1034'
    },
    {
      c: 'times',
      f: 53.65,
      t: 53.9
    },
    {
      f: 53.9,
      t: 54.22,
      c: 'faster'
    },
    {
      t: 54.38,
      c: 'than',
      f: 54.23
    },
    {
      t: 54.4,
      c: 'a',
      f: 54.38
    },
    {
      c: 'Cheetah.',
      f: 54.4,
      t: 54.69
    },
    {
      c: '70,000',
      f: 54.7,
      t: 55.26
    },
    {
      f: 55.27,
      c: 'hours',
      t: 55.51
    },
    {
      t: 55.58,
      f: 55.51,
      c: 'of'
    },
    {
      f: 55.58,
      t: 55.88,
      c: 'Netflix'
    },
    {
      f: 55.88,
      c: 'Star',
      t: 55.98
    },
    {
      t: 56.26,
      f: 55.99,
      c: 'watch'
    },
    {
      f: 56.27,
      t: 56.58,
      c: '300'
    },
    {
      f: 56.59,
      c: 'hours',
      t: 56.77
    },
    {
      t: 56.84,
      c: 'are',
      f: 56.78
    },
    {
      t: 57.16,
      c: 'uploaded',
      f: 56.85
    },
    {
      t: 57.24,
      c: 'to',
      f: 57.17
    },
    {
      t: 57.55,
      c: 'youtube',
      f: 57.26
    },
    {
      t: 57.65,
      f: 57.56,
      c: 'and'
    },
    {
      c: 'you',
      t: 57.69,
      f: 57.65
    },
    {
      f: 57.7,
      t: 57.79,
      c: 'can'
    },
    {
      f: 57.8,
      t: 58.01,
      c: 'watch'
    },
    {
      c: 'this',
      f: 58.01,
      t: 58.09
    },
    {
      t: 58.38,
      f: 58.1,
      c: 'video'
    },
    {
      c: 'and',
      f: 58.38,
      t: 58.51
    },
    {
      f: 58.52,
      c: 'subscribe.',
      t: 59.02
    }
  ]
};

const params6 = {
  created_at: '2024-05-01T13:52:18.182Z',
  id: 'mndi3Eoxb9gUzgrcqnkizX',
  author_id: 'fdsjxXii7NUOZ7MN8gvN6hs1EQo2',
  last_updated: '2024-05-01T13:52:19.693Z',
  assets: [
    {
      t: '29.805714',
      f: '0',
      offset_time: 0,
      id: 'jxGd8PqRUvsCbsSTM41Fcw',
      type: 'audio',
      url: 'https://jupitrr-staging-playground.s3.ap-southeast-1.amazonaws.com/fdsjxXii7NUOZ7MN8gvN6hs1EQo2/slideshow-mndi3Eoxb9gUzgrcqnkizX--mRLf2rgZrFPj1pQCRJo7gD.mp3'
    },
    {
      t: '15.58',
      manual_keyword: 'book topic',
      f: 14.6,
      original_keyword: 'book topic',
      rating: null,
      offset_time: 0,
      id: 'cVjBNXM1zdiFL9YqsBNgJo',
      type: 'video',
      url: 'https://player.vimeo.com/progressive_redirect/playback/394683124/rendition/1440p/file.mp4?loc=external&oauth2_token_id=1747418641&signature=2a8a004f667ec200789895963cf5fcf13fa2d9162a1906caf135ad1ce129d568'
    },
    {
      t: '29.16',
      f: 27.62,
      original_keyword: 'happiness, Excited',
      offset_time: 0,
      id: 'wrUswvdzb2zLWWZZLdyjhW',
      type: 'video',
      url: 'https://player.vimeo.com/progressive_redirect/playback/491226726/rendition/2160p/file.mp4?loc=external&oauth2_token_id=1747418641&signature=0ae55731067fe1dd8cc21dcfaf8265b5323114a380529ebcfdc80fef90f40289'
    },
    {
      t: '27.58',
      f: 22.5,
      original_keyword: 'making money, Joyful',
      offset_time: 0,
      id: 'a3bK1A3jr5HqmoWTYt4KsS',
      type: 'video',
      url: 'https://player.vimeo.com/progressive_redirect/playback/483271785/rendition/1080p/file.mp4?loc=external&oauth2_token_id=1747418641&signature=bf33eb0fda4e0500e1b1901594073e1f7225edc0a9aab11344eab326d8b9fe25'
    },
    {
      t: '22.50',
      f: 19.16,
      original_keyword: 'passion, Assertive',
      offset_time: 0,
      id: '7naDeVc7wyjFVjsLCBfwYi',
      type: 'video',
      url: 'https://player.vimeo.com/progressive_redirect/playback/510526574/rendition/1440p/file.mp4?loc=external&oauth2_token_id=1747418641&signature=4c2c77272b88be59c835f657045f29fca71090e86583ba44a670c7587a7e53f8'
    },
    {
      t: '18.96',
      f: 16.4,
      original_keyword: 'topic, Confused',
      offset_time: 0,
      id: 'anMsJu229qQ1KLf1dqxSQf',
      type: 'video',
      url: 'https://player.vimeo.com/progressive_redirect/playback/465644629/rendition/360p/file.mp4?loc=external&oauth2_token_id=1747418641&signature=0292b2b7ff404525074b237bfbf334790f0ae20dfcdb7890a8ca9e1cb3c0e872'
    },
    {
      t: '14.50',
      f: 13.28,
      original_keyword: 'Joyful, Books',
      offset_time: 0,
      id: 'xiLMtw8dAyLHmyC9V5XfnL',
      type: 'video',
      url: 'https://player.vimeo.com/progressive_redirect/playback/464977405/rendition/540p/file.mp4?loc=external&oauth2_token_id=1747418641&signature=96dbf20d374299eeed9abc434962ca1f23d48272635dc01ef960329901552298'
    },
    {
      t: '12.66',
      f: 9.98,
      original_keyword: 'Excited, Desert Island',
      offset_time: 0,
      id: 'gMWR4bkt5UeQVTF95KWWaR',
      type: 'video',
      url: 'https://player.vimeo.com/progressive_redirect/playback/485398742/rendition/1080p/file.mp4?loc=external&oauth2_token_id=1747418641&signature=41eeb2f1b69936071ca169bc74d72043b31999c03b2b37008df4837adae6a25f'
    },
    {
      t: '9.20',
      f: 7.76,
      original_keyword: 'Assertive, Passion',
      offset_time: 0,
      id: 'ch18FhRAce8DNbeCdZBNaM',
      type: 'video',
      url: 'https://player.vimeo.com/progressive_redirect/playback/434414104/rendition/1440p/file.mp4?loc=external&oauth2_token_id=1747418641&signature=4b24bc73c17b05449d4ba1214cf23f06a5edf3b1064f2d89ec9e0aa2619429b8'
    },
    {
      t: '7.66',
      f: 7.02,
      original_keyword: 'Confusion, Thing',
      offset_time: 0,
      id: 'bA3HMbrTZSRfDdrcR5pKKR',
      type: 'video',
      url: 'https://player.vimeo.com/progressive_redirect/playback/511038989/rendition/720p/file.mp4?loc=external&oauth2_token_id=1747418641&signature=a0651f5b13def59add69d6c442f3f9c201c5a3c9d3877ddd697d9eb0f41e4532'
    },
    {
      t: '6.88',
      f: 4.88,
      original_keyword: 'money, Sad',
      offset_time: 0,
      id: 'vjSxe6GcDPBp1s4aRFLqPS',
      type: 'video',
      url: 'https://player.vimeo.com/progressive_redirect/playback/537890841/rendition/240p/file.mp4?loc=external&oauth2_token_id=1747418641&signature=6f605d711cce766742ee53a3190b5c2deb0f8fa9be359546ce992e7be0d782ed'
    },
    {
      t: '4.82',
      f: 3.28,
      original_keyword: 'vibes, Joyful',
      offset_time: 0,
      id: 'gZGCPY9m2ERj455iL8wMyY',
      type: 'video',
      url: 'https://player.vimeo.com/progressive_redirect/playback/507832186/rendition/540p/file.mp4?loc=external&oauth2_token_id=1747418641&signature=d9f666dff06db81353f2559ea1b03d368dc2e35dd43d817b4dde1b6489cd0b5b'
    },
    {
      t: '3.18',
      manual_keyword: 'fun activity',
      f: 0.06,
      original_keyword: 'fun activity',
      rating: null,
      offset_time: 0,
      id: 'hrYzow5JWWZWxkcSoUsHuS',
      type: 'video',
      url: 'https://player.vimeo.com/progressive_redirect/playback/436749744/rendition/540p/file.mp4?loc=external&oauth2_token_id=1747418641&signature=8f863530dddde484332756764e4fc0ec4f5f5b4495a86271a14d84b53e87549f'
    }
  ],
  subtitle_status: 'generated',
  duration: 29.805714,
  preferences: {
    positionY: 61,
    dimensionType: 'landscape',
    fontFamily: 'Solitreo',
    hideOnboarding: true,
    fontSize: 108,
    wordLimit: 1
  },
  is_debian: true,
  subtitlesContent: [
    {
      t: 0.3,
      f: 0.19,
      c: 'In'
    },
    {
      t: 0.32,
      f: 0.31,
      c: 'a'
    },
    {
      t: 0.63,
      f: 0.33,
      c: 'single'
    },
    {
      c: 'minute,',
      t: 0.83,
      f: 0.64
    },
    {
      c: 'your',
      f: 0.84,
      t: 0.97
    },
    {
      t: 1.19,
      f: 0.98,
      c: 'body'
    },
    {
      f: 1.2,
      t: 1.59,
      c: 'produces'
    },
    {
      c: '120',
      f: 1.6,
      t: 2.25
    },
    {
      t: 2.37,
      f: 2.26,
      c: 'to'
    },
    {
      c: '180',
      t: 2.93,
      f: 2.38
    },
    {
      f: 2.94,
      c: 'million',
      t: 3.22
    },
    {
      c: 'red',
      f: 3.23,
      t: 3.35
    },
    {
      t: 3.56,
      c: 'blood',
      f: 3.36
    },
    {
      f: 3.57,
      c: 'cells.',
      t: 3.86
    },
    {
      c: 'People',
      f: 3.87,
      t: 4.05
    },
    {
      f: 4.06,
      c: 'ask',
      t: 4.26
    },
    {
      f: 4.27,
      c: 'Google',
      t: 4.51
    },
    {
      f: 4.52,
      t: 5.04,
      c: '2.4'
    },
    {
      t: 5.33,
      f: 5.05,
      c: 'million'
    },
    {
      c: 'questions',
      f: 5.34,
      t: 5.78
    },
    {
      c: 'and',
      t: 5.91,
      f: 5.79
    },
    {
      c: '25',
      t: 6.37,
      f: 5.92
    },
    {
      t: 6.65,
      f: 6.38,
      c: 'million'
    },
    {
      f: 6.66,
      c: 'Coca',
      t: 6.88
    },
    {
      c: 'Cola',
      t: 7.09,
      f: 6.89
    },
    {
      t: 7.41,
      c: 'products',
      f: 7.1
    },
    {
      c: 'are',
      f: 7.42,
      t: 7.52
    },
    {
      t: 7.97,
      f: 7.53,
      c: 'consumed.'
    },
    {
      c: 'Many',
      t: 8.17,
      f: 7.98
    },
    {
      t: 8.26,
      c: 'of',
      f: 8.18
    },
    {
      f: 8.27,
      c: 'those',
      t: 8.44
    },
    {
      t: 8.74,
      f: 8.45,
      c: 'bottles'
    },
    {
      f: 8.75,
      t: 8.84,
      c: 'will'
    },
    {
      f: 8.85,
      c: 'end',
      t: 8.97
    },
    {
      t: 9.08,
      f: 8.98,
      c: 'up'
    },
    {
      f: 9.09,
      c: 'in',
      t: 9.19
    },
    {
      c: 'a',
      f: 9.2,
      t: 9.21
    },
    {
      c: 'landfill',
      t: 9.66,
      f: 9.22
    },
    {
      f: 9.67,
      c: 'where',
      t: 9.78
    },
    {
      c: 'the',
      f: 9.79,
      t: 9.86
    },
    {
      c: 'World',
      t: 10.07,
      f: 9.87
    },
    {
      t: 10.29,
      c: 'Bank',
      f: 10.08
    },
    {
      c: 'estimates',
      t: 10.7,
      f: 10.3
    },
    {
      f: 10.71,
      c: 'we',
      t: 10.77
    },
    {
      f: 10.78,
      t: 11.08,
      c: 'produce'
    },
    {
      c: '£5',
      t: 11.52,
      f: 11.09
    },
    {
      f: 11.53,
      c: 'million',
      t: 11.71
    },
    {
      t: 11.79,
      c: 'of',
      f: 11.72
    },
    {
      c: 'garbage.',
      f: 11.8,
      t: 12.18
    },
    {
      f: 12.19,
      t: 12.67,
      c: '108'
    },
    {
      c: 'human',
      t: 12.93,
      f: 12.68
    },
    {
      t: 13.15,
      c: 'lives',
      f: 12.94
    },
    {
      c: 'will',
      t: 13.23,
      f: 13.16
    },
    {
      t: 13.33,
      c: 'be',
      f: 13.24
    },
    {
      f: 13.34,
      t: 13.58,
      c: 'lost'
    },
    {
      t: 13.65,
      f: 13.59,
      c: 'in'
    },
    {
      t: 13.79,
      f: 13.66,
      c: 'this'
    },
    {
      f: 13.8,
      c: 'minute',
      t: 14.06
    },
    {
      c: 'and',
      t: 14.16,
      f: 14.07
    },
    {
      t: 14.22,
      f: 14.17,
      c: 'an'
    },
    {
      f: 14.23,
      c: 'adult',
      t: 14.51
    },
    {
      t: 14.66,
      c: 'male',
      f: 14.52
    },
    {
      c: 'will',
      t: 14.83,
      f: 14.67
    },
    {
      f: 14.84,
      t: 14.99,
      c: 'lose'
    },
    {
      f: 15,
      t: 15.45,
      c: '96'
    },
    {
      t: 15.78,
      c: 'million',
      f: 15.46
    },
    {
      c: 'cells.',
      f: 15.79,
      t: 16.15
    },
    {
      c: 'Fortunately,',
      t: 16.56,
      f: 16.16
    },
    {
      f: 16.57,
      t: 16.96,
      c: '96'
    },
    {
      f: 16.97,
      c: 'million',
      t: 17.22
    },
    {
      c: 'cells',
      f: 17.23,
      t: 17.45
    },
    {
      f: 17.46,
      c: 'divide',
      t: 17.77
    },
    {
      c: 'replacing',
      f: 17.78,
      t: 18.18
    },
    {
      f: 18.19,
      t: 18.35,
      c: 'those'
    },
    {
      c: 'laws.',
      t: 18.58,
      f: 18.36
    },
    {
      f: 18.59,
      t: 18.91,
      c: 'Speaking'
    },
    {
      c: 'of',
      t: 19,
      f: 18.92
    },
    {
      c: 'divisions',
      f: 19.01,
      t: 19.49
    },
    {
      f: 19.5,
      c: 'in',
      t: 19.58
    },
    {
      c: 'the',
      f: 19.59,
      t: 19.66
    },
    {
      t: 19.96,
      f: 19.67,
      c: 'US,'
    },
    {
      t: 20.11,
      c: 'a',
      f: 19.97
    },
    {
      t: 20.72,
      f: 20.12,
      c: '1.5'
    },
    {
      c: 'people',
      t: 20.95,
      f: 20.73
    },
    {
      c: 'get',
      f: 20.96,
      t: 21.05
    },
    {
      f: 21.06,
      c: 'divorced',
      t: 21.46
    },
    {
      t: 21.62,
      f: 21.47,
      c: 'while'
    },
    {
      f: 21.63,
      c: 'worldwide',
      t: 22.17
    },
    {
      t: 22.9,
      c: '116',
      f: 22.18
    },
    {
      f: 22.91,
      t: 23.16,
      c: 'people'
    },
    {
      t: 23.27,
      f: 23.17,
      c: 'will'
    },
    {
      c: 'get',
      t: 23.39,
      f: 23.28
    },
    {
      t: 23.7,
      f: 23.4,
      c: 'married.'
    },
    {
      c: '82,300',
      f: 23.71,
      t: 24.65
    },
    {
      c: 'people',
      f: 24.66,
      t: 24.85
    },
    {
      f: 24.86,
      c: 'have',
      t: 25
    },
    {
      f: 25.01,
      c: 'sex,',
      t: 25.28
    },
    {
      t: 25.37,
      c: 'but',
      f: 25.29
    },
    {
      t: 25.59,
      f: 25.38,
      c: 'only'
    },
    {
      t: 26.43,
      c: '258',
      f: 25.6
    },
    {
      f: 26.44,
      t: 26.75,
      c: 'babies'
    },
    {
      c: 'will',
      f: 26.76,
      t: 26.88
    },
    {
      t: 26.98,
      c: 'be',
      f: 26.89
    },
    {
      t: 27.33,
      c: 'born',
      f: 26.99
    },
    {
      t: 27.54,
      c: 'and',
      f: 27.34
    },
    {
      f: 27.55,
      c: 'a',
      t: 27.56
    },
    {
      f: 27.57,
      c: 'fetus',
      t: 27.9
    },
    {
      c: 'of',
      f: 27.91,
      t: 27.98
    },
    {
      c: 'developing',
      t: 28.39,
      f: 27.99
    },
    {
      c: 'girls',
      t: 28.75,
      f: 28.4
    },
    {
      f: 28.76,
      c: 'at',
      t: 28.85
    },
    {
      t: 28.87,
      f: 28.86,
      c: 'a'
    },
    {
      c: 'rate',
      f: 28.88,
      t: 29.02
    },
    {
      c: 'of',
      f: 29.03,
      t: 29.12
    },
    {
      c: '200',
      f: 29.13,
      t: 29.44
    },
    {
      f: 29.59,
      t: 30.2,
      c: '1000'
    },
    {
      t: 30.32,
      f: 30.21,
      c: 'per'
    },
    {
      c: 'minute.',
      f: 30.33,
      t: 30.64
    },
    {
      c: 'So',
      f: 30.64,
      t: 30.73
    },
    {
      t: 30.89,
      f: 30.73,
      c: "it's"
    },
    {
      f: 30.91,
      c: 'no',
      t: 31.02
    },
    {
      t: 31.25,
      f: 31.03,
      c: 'wonder'
    },
    {
      c: 'that',
      f: 31.26,
      t: 31.35
    },
    {
      c: 'a',
      t: 31.38,
      f: 31.36
    },
    {
      c: 'computer',
      f: 31.39,
      t: 31.78
    },
    {
      c: 'simulation',
      f: 31.8,
      t: 32.27
    },
    {
      t: 32.52,
      f: 32.28,
      c: 'takes'
    },
    {
      f: 32.53,
      c: '60',
      t: 32.9
    },
    {
      c: 'quadrillion',
      t: 33.58,
      f: 32.91
    },
    {
      f: 33.59,
      c: 'bites',
      t: 33.78
    },
    {
      c: 'to',
      f: 33.79,
      t: 33.84
    },
    {
      t: 34.26,
      f: 33.85,
      c: 'simulate'
    },
    {
      c: 'a',
      f: 34.26,
      t: 34.27
    },
    {
      c: 'minute.',
      t: 34.52,
      f: 34.28
    },
    {
      t: 34.65,
      c: 'An',
      f: 34.53
    },
    {
      t: 34.91,
      f: 34.65,
      c: 'average'
    },
    {
      f: 34.92,
      t: 35.01,
      c: 'of'
    },
    {
      c: '1.38',
      f: 35.02,
      t: 35.6
    },
    {
      t: 36.09,
      f: 35.62,
      c: 'micrometers'
    },
    {
      f: 36.1,
      t: 36.22,
      c: 'of'
    },
    {
      c: 'rainfall',
      f: 36.22,
      t: 36.59
    },
    {
      c: 'around',
      t: 36.79,
      f: 36.6
    },
    {
      c: 'the',
      t: 36.84,
      f: 36.8
    },
    {
      f: 36.85,
      c: 'world',
      t: 37.13
    },
    {
      t: 37.26,
      c: 'which',
      f: 37.13
    },
    {
      c: 'is',
      f: 37.26,
      t: 37.35
    },
    {
      c: '4.7',
      f: 37.37,
      t: 37.88
    },
    {
      f: 37.9,
      c: 'billion',
      t: 38.22
    },
    {
      c: 'bathtubs',
      f: 38.23,
      t: 38.62
    },
    {
      c: 'of',
      f: 38.63,
      t: 38.69
    },
    {
      t: 38.94,
      c: 'water',
      f: 38.7
    },
    {
      c: 'every',
      f: 38.95,
      t: 39.13
    },
    {
      t: 39.33,
      f: 39.13,
      c: 'minute.'
    },
    {
      t: 39.44,
      c: 'And',
      f: 39.33
    },
    {
      f: 39.44,
      t: 39.51,
      c: 'with'
    },
    {
      c: 'the',
      t: 39.63,
      f: 39.52
    },
    {
      t: 39.95,
      f: 39.63,
      c: 'storm'
    },
    {
      f: 39.97,
      t: 40.16,
      c: 'comes'
    },
    {
      f: 40.17,
      c: 'approximately',
      t: 40.72
    },
    {
      f: 40.72,
      c: '6000',
      t: 41.33
    },
    {
      t: 41.54,
      f: 41.33,
      c: 'volts'
    },
    {
      f: 41.55,
      t: 41.69,
      c: 'of'
    },
    {
      c: 'cloud',
      t: 41.9,
      f: 41.69
    },
    {
      c: 'to',
      t: 42.01,
      f: 41.91
    },
    {
      f: 42.02,
      t: 42.28,
      c: 'ground.'
    },
    {
      t: 42.54,
      c: 'Lightning',
      f: 42.29
    },
    {
      f: 42.55,
      c: 'hitting',
      t: 42.8
    },
    {
      c: 'the',
      t: 42.85,
      f: 42.81
    },
    {
      t: 43.13,
      f: 42.87,
      c: 'earth.'
    },
    {
      c: 'A',
      t: 43.22,
      f: 43.13
    },
    {
      f: 43.22,
      t: 44.01,
      c: '£150'
    },
    {
      c: 'person',
      f: 44.02,
      t: 44.28
    },
    {
      c: 'expends',
      f: 44.29,
      t: 44.67
    },
    {
      c: '1.1',
      f: 44.69,
      t: 45.22
    },
    {
      t: 45.63,
      f: 45.22,
      c: 'calories'
    },
    {
      c: 'of',
      f: 45.63,
      t: 45.7
    },
    {
      f: 45.72,
      t: 45.97,
      c: 'energy'
    },
    {
      c: 'per',
      t: 46.08,
      f: 45.97
    },
    {
      t: 46.29,
      c: 'minute',
      f: 46.09
    },
    {
      c: 'while',
      t: 46.44,
      f: 46.3
    },
    {
      t: 46.83,
      c: 'sleeping',
      f: 46.45
    },
    {
      c: 'while',
      f: 46.84,
      t: 46.97
    },
    {
      c: 'the',
      t: 47.08,
      f: 46.97
    },
    {
      t: 47.24,
      f: 47.08,
      c: 'sun'
    },
    {
      t: 47.62,
      f: 47.26,
      c: 'provides'
    },
    {
      t: 47.69,
      f: 47.63,
      c: 'us'
    },
    {
      c: 'with',
      f: 47.69,
      t: 47.81
    },
    {
      c: '83.33',
      f: 47.83,
      t: 48.92
    },
    {
      f: 48.94,
      c: 'terawatt',
      t: 49.3
    },
    {
      f: 49.31,
      t: 49.44,
      c: 'of'
    },
    {
      t: 49.79,
      c: 'energy.',
      f: 49.44
    },
    {
      c: "Here's",
      t: 49.97,
      f: 49.8
    },
    {
      t: 50.08,
      c: "we'll",
      f: 49.98
    },
    {
      t: 50.37,
      f: 50.08,
      c: 'complete'
    },
    {
      c: '1800',
      t: 50.84,
      f: 50.38
    },
    {
      c: 'kilometers',
      t: 51.19,
      f: 50.85
    },
    {
      t: 51.28,
      f: 51.2,
      c: 'of'
    },
    {
      f: 51.29,
      c: 'its',
      t: 51.4
    },
    {
      c: '740',
      f: 51.4,
      t: 51.94
    },
    {
      f: 51.95,
      c: 'million',
      t: 52.2
    },
    {
      f: 52.22,
      t: 52.37,
      c: 'around'
    },
    {
      t: 52.42,
      f: 52.38,
      c: 'the'
    },
    {
      c: 'sun',
      t: 52.63,
      f: 52.44
    },
    {
      c: 'moving',
      t: 52.88,
      f: 52.63
    },
    {
      t: 53.65,
      f: 52.9,
      c: '1034'
    },
    {
      c: 'times',
      f: 53.65,
      t: 53.9
    },
    {
      f: 53.9,
      t: 54.22,
      c: 'faster'
    },
    {
      t: 54.38,
      c: 'than',
      f: 54.23
    },
    {
      t: 54.4,
      c: 'a',
      f: 54.38
    },
    {
      c: 'Cheetah.',
      f: 54.4,
      t: 54.69
    },
    {
      c: '70,000',
      f: 54.7,
      t: 55.26
    },
    {
      f: 55.27,
      c: 'hours',
      t: 55.51
    },
    {
      t: 55.58,
      f: 55.51,
      c: 'of'
    },
    {
      f: 55.58,
      t: 55.88,
      c: 'Netflix'
    },
    {
      f: 55.88,
      c: 'Star',
      t: 55.98
    },
    {
      t: 56.26,
      f: 55.99,
      c: 'watch'
    },
    {
      f: 56.27,
      t: 56.58,
      c: '300'
    },
    {
      f: 56.59,
      c: 'hours',
      t: 56.77
    },
    {
      t: 56.84,
      c: 'are',
      f: 56.78
    },
    {
      t: 57.16,
      c: 'uploaded',
      f: 56.85
    },
    {
      t: 57.24,
      c: 'to',
      f: 57.17
    },
    {
      t: 57.55,
      c: 'youtube',
      f: 57.26
    },
    {
      t: 57.65,
      f: 57.56,
      c: 'and'
    },
    {
      c: 'you',
      t: 57.69,
      f: 57.65
    },
    {
      f: 57.7,
      t: 57.79,
      c: 'can'
    },
    {
      f: 57.8,
      t: 58.01,
      c: 'watch'
    },
    {
      c: 'this',
      f: 58.01,
      t: 58.09
    },
    {
      t: 58.38,
      f: 58.1,
      c: 'video'
    },
    {
      c: 'and',
      f: 58.38,
      t: 58.51
    },
    {
      f: 58.52,
      c: 'subscribe.',
      t: 59.02
    }
  ]
};

const params8 = {
  subtitlesContent: [],
  subtitlesUrl:
    'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/pLdFFnGWTScQe7Nsm9WVC26OoYJ2/slideshow-8oaUaPWtbQEM4UEWtMnbQS-source.json?q=1722874767570',
  created_at: '2024-08-05T15:15:24.597Z',
  id: '8oaUaPWtbQEM4UEWtMnbQS',
  author_id: 'pLdFFnGWTScQe7Nsm9WVC26OoYJ2',
  duration: '202.945306',
  video_title: 'O Poder De Jesus Cristo',
  file_size_in_MB: 4.53,
  subtitle_status: 'generated',
  last_updated: '2024-08-05T15:16:42.396Z',
  preferences: {
    positionY: 20,
    subtitleType: 'Reels',
    dimensionType: 'landscape',
    capitalization: false,
    fontFamily: 'PoetsenOne',
    color: '#FDE047',
    hideOnboarding: true,
    primaryColor: '#FFFFFF',
    fontSize: 74,
    wordLimit: 35,
    animationColor: '#a955f7',
    animation: 'Box Highlight'
  },
  url: 'https://remotionlambda-d5o59m07tl.s3.ap-southeast-1.amazonaws.com/renders/iye4yxwxgg/out.mp4',
  assets: [
    {
      t: '65.46',
      f: 64.44,
      original_keyword: 'restraint, control',
      offset_time: 0,
      id: 'cUDQqjVCShrXfMSGtm3T14',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/7586157/7586157-hd_1080_1920_24fps.mp4'
    },
    {
      t: '163.69',
      f: 163.25,
      original_keyword: 'Thank you.',
      offset_time: 0,
      id: 'w35uyFABD9UHk5vmWxs2vX',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/5981416/5981416-hd_1080_1920_24fps.mp4'
    },
    {
      t: '157.91',
      f: 150.95,
      original_keyword: 'perseverance',
      offset_time: 0,
      id: 'u7KfaK4wAAc6aaiBE9riEw',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/7699888/7699888-hd_1080_1920_25fps.mp4'
    },
    {
      t: '149.21',
      f: 144.23,
      original_keyword: 'belief',
      offset_time: 0,
      id: 'w9eCSZXtcpniUZeFHEUoEa',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/5199835/5199835-hd_1080_1920_25fps.mp4'
    },
    {
      t: '143.12',
      f: 141.66,
      original_keyword: 'succeed, achieve',
      offset_time: 0,
      id: 'dGRKjcx1aLNevwa42gFDRw',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/5197678/5197678-hd_1080_1920_25fps.mp4'
    },
    {
      t: '141.66',
      f: 137.38,
      original_keyword: 'succeed, achieve',
      offset_time: 0,
      id: '14V9yK2wKy7oZVvj3mW9yR',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/6265099/6265099-hd_1080_1920_30fps.mp4'
    },
    {
      t: '136.72',
      f: 134.46,
      original_keyword: 'slave',
      offset_time: 0,
      id: 'ni7Qbo53EV97cfNdxDdzpE',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/6282306/6282306-hd_1080_1920_30fps.mp4'
    },
    {
      t: '133.66',
      f: 129.96,
      original_keyword: 'belief',
      offset_time: 0,
      id: 'eWcgxkRSWtXKev5qHZoKvH',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/5199861/5199861-hd_1080_1920_25fps.mp4'
    },
    {
      t: '129.08',
      f: 128.08,
      original_keyword: 'elephant',
      offset_time: 0,
      id: 'xvhTnW7BSY6QTWeB7ymAtU',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/7008032/7008032-hd_1080_1920_25fps.mp4'
    },
    {
      t: '125.44',
      f: 122.38,
      original_keyword: 'failure',
      offset_time: 0,
      id: '3xtNdfEeiuiVLoEqY68H2P',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/5377976/5377976-hd_1080_1920_25fps.mp4'
    },
    {
      t: '122.38',
      f: 117.92,
      original_keyword: 'failure',
      offset_time: 0,
      id: 'vrT5CB8uC8cmoDitytTV54',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/5182776/5182776-hd_1080_1920_25fps.mp4'
    },
    {
      t: '117.92',
      f: 110.79,
      original_keyword: 'failure',
      offset_time: 0,
      id: '6JMBSE52GrAAU9gSSL2fwZ',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/6837715/6837715-hd_1080_1920_30fps.mp4'
    },
    {
      t: '109.79',
      f: 104.39,
      original_keyword: 'elephants',
      offset_time: 0,
      id: 'uMuYbDFLfDYKpig4dgezCE',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/11760754/11760754-hd_720_1366_30fps.mp4'
    },
    {
      t: '104.39',
      f: 101.23,
      original_keyword: 'self-reflection',
      offset_time: 0,
      id: '3gGbkFVA4iZeUSYitB6Mid',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/4678261/4678261-hd_1080_1920_25fps.mp4'
    },
    {
      t: '98.07',
      f: 92.5,
      original_keyword: 'stuck',
      offset_time: 0,
      id: 'uCu4WF25bQTJTdteLYNnZR',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/3992523/3992523-hd_720_1366_50fps.mp4'
    },
    {
      t: '92.32',
      f: 85.46,
      original_keyword: 'animals',
      offset_time: 0,
      id: 'mxBYaK3U2CnqStX3w2x8Nx',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/5968893/5968893-hd_1080_1920_30fps.mp4'
    },
    {
      t: '84.82',
      f: 81.94,
      original_keyword: 'amazed',
      offset_time: 0,
      id: '4fdZcYBPLyUXd2b64SJDqn',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/5259230/5259230-hd_720_1366_30fps.mp4'
    },
    {
      t: '81.25',
      f: 78.71,
      original_keyword: 'trainer',
      offset_time: 0,
      id: 'wAMStpVQ2QVsALRNtVDRYz',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/5319754/5319754-hd_1080_1920_25fps.mp4'
    },
    {
      t: '77.59',
      f: 74.41,
      original_keyword: 'freedom',
      offset_time: 0,
      id: 'uVsAkpRs9FweCQAYrUN3iB',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/6117571/6117571-hd_720_1366_25fps.mp4'
    },
    {
      t: '74.09',
      f: 71.39,
      original_keyword: 'rope',
      offset_time: 0,
      id: 'hz2zS61ZiabDdJ6yAEUw52',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/6254849/6254849-hd_1080_1920_30fps.mp4'
    },
    {
      t: '70.79',
      f: 68.41,
      original_keyword: 'belief',
      offset_time: 0,
      id: 'dQGJfB6RoVx4ZCmGRvEaqK',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/5385957/5385957-hd_720_1366_25fps.mp4'
    },
    {
      t: '68.41',
      f: 66.45,
      original_keyword: 'conditioning',
      offset_time: 0,
      id: '4wbkw8Npua8JuarPQt9bNk',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/8477593/8477593-hd_1080_1920_25fps.mp4'
    },
    {
      t: '64.44',
      f: 59.72,
      original_keyword: 'rope',
      offset_time: 0,
      id: 'dTXTsQFYBTSkVrCMXpggUT',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/2785536/2785536-hd_1080_1920_25fps.mp4'
    },
    {
      t: '58.82',
      f: 54.02,
      original_keyword: 'trainer',
      offset_time: 0,
      id: '7yikbPLzuwMaX81tHusHyT',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/5319754/5319754-hd_1080_1920_25fps.mp4'
    },
    {
      t: '52.62',
      f: 44.74,
      original_keyword: 'trainer',
      offset_time: 0,
      id: 'dGm2xwE16FA16NJpuxqzkb',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/3694919/3694919-hd_1080_1920_30fps.mp4'
    },
    {
      t: '43.54',
      f: 35.26,
      original_keyword: 'elephants, amazed',
      offset_time: 0,
      id: 'fga61wo84pT6Xz4hcKRVe8',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/8627833/8627833-hd_1080_1920_25fps.mp4'
    },
    {
      t: '34.32',
      f: 32.35,
      original_keyword: 'escape',
      offset_time: 0,
      id: '2J5ix4XV3tpqz9GxDPiLMS',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/9565655/9565655-hd_720_1366_25fps.mp4'
    },
    {
      t: '31.51',
      f: 25.81,
      original_keyword: 'escape',
      offset_time: 0,
      id: 'ddeXa7EB8ApFSWMyEw9LPd',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/7120818/7120818-hd_720_1366_25fps.mp4'
    },
    {
      t: '25.15',
      f: 20.35,
      original_keyword: 'restraint, self-control',
      offset_time: 0,
      id: '4X1Co8ZgB2m8peWurkzbSf',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/4864929/4864929-hd_720_1366_25fps.mp4'
    },
    {
      t: '19.65',
      f: 17.47,
      original_keyword: 'elephant',
      offset_time: 0,
      id: 'wrERUhnP18rB6ZHU1mqeCt',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/11760752/11760752-hd_720_1366_30fps.mp4'
    },
    {
      t: '16.92',
      f: 10.22,
      original_keyword: 'confusion',
      offset_time: 0,
      id: 'a4EWfQtHSUTd6LMN2ishNR',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/4874628/4874628-hd_1066_1920_25fps.mp4'
    },
    {
      t: '9.32',
      f: 8.56,
      original_keyword: 'surprise',
      offset_time: 0,
      id: 'kvLWqp1kAL6X4HKHUkhc6b',
      type: 'video',
      url: 'https://videos.pexels.com/video-files/4883833/4883833-hd_1080_1920_30fps.mp4'
    },
    {
      t: '166.739592',
      f: '0',
      id: 'vAQqgj5e2Q5AnWDaEoFUbw',
      type: 'audio',
      url: 'https://jupitrr-staging-playground.s3-accelerate.amazonaws.com/mSPISalz58YzrL6SYiebgzg8m0K2/slideshow-oXW8t1KVPZfTKSDiPKNZJu--vAQqgj5e2Q5AnWDaEoFUbw.mp3'
    },
    {
      t: '7.36',
      f: '1.9',
      original_keyword: 'This is a very long preview',
      id: 'b6Fe9PFPLtp1HYjLxrZTZX',
      type: 'overlay',
      url: '',
      overlay_preferences: {
        backgroundColor: '#4A5588',
        textPosition: {
          justifyContent: 'flex-start', alignItems: 'flex-end'
        },
        fontFamily: 'Circular Std',
        fontSize: 50,
        textColor: 'black',
        backgroundType: 'transparent'
      }
    }
  ],
  is_debian: true
};
const param9 = {
  subtitlesContent: [],
  subtitlesUrl:
    'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/pLdFFnGWTScQe7Nsm9WVC26OoYJ2/slideshow-8oaUaPWtbQEM4UEWtMnbQS-source.json?q=1722874767570',
  created_at: '2024-07-24T15:52:43.180Z',
  id: 'qSNBzT8MRMcR29cQ2oBq7Q',
  author_id: 'mSPISalz58YzrL6SYiebgzg8m0K2',
  subtitle_status: 'generated',
  duration: 27.729433,
  url: 'https://remotionlambda-d5o59m07tl.s3.ap-southeast-1.amazonaws.com/renders/rxlb2lc6ru/out.mp4',
  preferences: {
    positionY: 20,
    subtitleType: 'Reels',
    dimensionType: 'landscape',
    fontFamily: 'PoetsenOne',
    capitalization: false,
    color: '#FDE047',
    hideOnboarding: true,
    primaryColor: '#FFFFFF',
    fontSize: 74,
    wordLimit: 35,
    animationColor: '#a955f7',
    animation: 'Box Highlight'
  },
  last_updated: '2024-08-02T09:12:43.617Z'

}


const param10 = {
  'id': 'iTogLjzQsiARHDBh7K9UiS',
  'author_id': 'SpRadyvRImTaERdtx0wE4KyM6lT2',
  'created_at': '2024-11-19T15:56:12.946Z',
  'initial_asset_status': 'tbd',
  'generate_initial_assets': false,
  'duration': '52.218833',
  'video_title': "Harris' linkedin video 1",
  'file_size_in_MB': 123.43,
  'subtitle_status': 'generated',
  'thumbnail_url': 'https://jupitrr-ugc.s3-accelerate.amazonaws.com/SpRadyvRImTaERdtx0wE4KyM6lT2/slideshow-iTogLjzQsiARHDBh7K9UiS--4Xrt1r6f9h4s7fAdWRSYcx.mp4',
  'watermark': {
    'position': {
      'justifyContent': 'center',
      'alignItems': 'flex-end'
    },
    'size': 20
    // 'url': 'https://jupitrr-ugc.s3-accelerate.amazonaws.com/SpRadyvRImTaERdtx0wE4KyM6lT2/slideshow-iTogLjzQsiARHDBh7K9UiS--wC48myjLWQjeAQB3rDrn3n--user-upload.jpeg'
  },

  'last_updated': '2024-11-19T18:37:04.430Z',
  'url': 'https://remotionlambda-d5o59m07tl.s3.ap-southeast-1.amazonaws.com/renders/rrx5xcgn3x/out.mp4',
  'assets': [
    {
      'url': 'https://jupitrr-ugc.s3-accelerate.amazonaws.com/SpRadyvRImTaERdtx0wE4KyM6lT2/slideshow-iTogLjzQsiARHDBh7K9UiS--wC48myjLWQjeAQB3rDrn3n--user-upload.jpeg',
      'f': '16.68',
      't': '19.06',
      'id': 'wC48myjLWQjeAQB3rDrn3n',
      'type': 'image'
    },
    {
      'text': "🚨 Don't Let Your Business Die With You!",
      'id': 'jc3yocafufHchyHevbVwew',
      'type': 'hook',
      't': '3',
      'f': '0'
    },
    {
      'id': '4Xrt1r6f9h4s7fAdWRSYcx',
      'mediaconvert_job_status': 'COMPLETE',
      'url': 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/SpRadyvRImTaERdtx0wE4KyM6lT2/slideshow-iTogLjzQsiARHDBh7K9UiS--4Xrt1r6f9h4s7fAdWRSYcx_compressed.mp4',
      'type': 'video',
      'mediaconvert_job_id': '1732041839519-lnllmx',
      't': '52.218833',
      'f': '0'
    },
    {
      'id': '4Xrt1r6f9h4s7fAdWRSYcx',
      'mediaconvert_job_status': 'COMPLETE',
      'url': 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/SpRadyvRImTaERdtx0wE4KyM6lT2/slideshow-iTogLjzQsiARHDBh7K9UiS--4Xrt1r6f9h4s7fAdWRSYcx_compressed.mp4',
      'type': 'a-roll-transition',
      'preferences': {
        'transition': 'quick-zoom-in'
      },
      't': '3.818833',
      'f': '2.5'
    },
    {
      'id': '4Xrt1r6f9h4s7fAdWRSYcx',
      'mediaconvert_job_status': 'COMPLETE',
      'url': 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/SpRadyvRImTaERdtx0wE4KyM6lT2/slideshow-iTogLjzQsiARHDBh7K9UiS--4Xrt1r6f9h4s7fAdWRSYcx_compressed.mp4',
      'type': 'a-roll-transition',
      'preferences': {
        'transition': 'zoom-in-zoom-out'
      },
      't': '14.818833',
      'f': '6.5'
    }
    // {
    //   't': '13.56',
    //   'type': 'image',
    //   'f': '12.06',
    //   'url': 'https://jupitrr-ugc.s3-accelerate.amazonaws.com/SpRadyvRImTaERdtx0wE4KyM6lT2/slideshow-iTogLjzQsiARHDBh7K9UiS--5wA3m7yLZ15mi9c2tSH9Mn--user-upload.JPG',
    //   'id': '5wA3m7yLZ15mi9c2tSH9Mn'
    // },
    // {
    //   'f': '5.18',
    //   'offset_time': 0,
    //   'id': '54r6AZzUVe8Ahr6kPuSigP',
    //   't': '6.16',
    //   'url': 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/SpRadyvRImTaERdtx0wE4KyM6lT2/trimmed-videos/7712365-hd_720_1280_30fps.mp4',
    //   'type': 'video',
    //   'stock_media_source': 'https://videos.pexels.com/video-files/7712365/7712365-hd_720_1280_30fps.mp4',
    //   'original_keyword': 'unique',
    //   'manual_keyword': 'graduation'
    // },
    // {
    //   'f': '6.54',
    //   'stock_media_source': 'https://media2.giphy.com/media/l4Epe8gPvTodwd8CQ/giphy.gif?cid=12de9f12z9pd96ei5xxsjxv3gpll6na6i9j87nqjmllatwjn&ep=v1_gifs_search&rid=giphy.gif&ct=g',
    //   'manual_keyword': 'job hunting',
    //   't': '7.86',
    //   'type': 'gif',
    //   'id': 'oM1i363TKPXXhD95Cxpp67',
    //   'url': 'https://jupitrr-ugc.s3-accelerate.amazonaws.com/SpRadyvRImTaERdtx0wE4KyM6lT2/stocks%2Fslideshow-iTogLjzQsiARHDBh7K9UiS--oM1i363TKPXXhD95Cxpp67.gif'
    // },
    // {
    //   'id': 'wPuy8zu15NcnQ4S7H77Q7A',
    //   'f': '9.4',
    //   'url': 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/SpRadyvRImTaERdtx0wE4KyM6lT2/trimmed-videos/6775037-hd_1080_1920_30fps.mp4',
    //   't': '10.42',
    //   'offset_time': 0,
    //   'original_keyword': 'startup',
    //   'manual_keyword': 'startup booth',
    //   'stock_media_source': 'https://videos.pexels.com/video-files/6775037/6775037-hd_1080_1920_30fps.mp4',
    //   'type': 'video'
    // },
    // {
    //   't': '15.38',
    //   'original_keyword': 'Hong Kong',
    //   'id': 'hDjXuios2SrFP1CJ4J5Rox',
    //   'stock_media_source': 'https://videos.pexels.com/video-files/13082564/13082564-hd_720_1280_30fps.mp4',
    //   'url': 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/SpRadyvRImTaERdtx0wE4KyM6lT2/trimmed-videos/13082564-hd_720_1280_30fps.mp4',
    //   'offset_time': 0,
    //   'type': 'video',
    //   'manual_keyword': 'Hong Kong',
    //   'f': '13.62'
    // },
    // {
    //   'type': 'video',
    //   'offset_time': 0,
    //   'url': 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/SpRadyvRImTaERdtx0wE4KyM6lT2/trimmed-videos/5147443-hd_1080_1920_30fps.mp4',
    //   'original_keyword': 'Hong Kong',
    //   'id': 'arAyEQ7pRgnWjVjtZreEWo',
    //   't': '15.38',
    //   'f': 14.6,
    //   'stock_media_source': 'https://videos.pexels.com/video-files/5147443/5147443-hd_1080_1920_30fps.mp4'
    // },
    // {
    //   'type': 'gif',
    //   'f': '26.54',
    //   'id': 'sSPC45q37rVG1rvJQMdfnA',
    //   'stock_media_source': 'https://media3.giphy.com/media/ylOz11YRmyeBGKDBNH/giphy.gif?cid=12de9f12e1imav7khgvj3smnmu2ko2rucauzlj9bd7leuhk6&ep=v1_gifs_search&rid=giphy.gif&ct=g',
    //   'manual_keyword': 'hardwork',
    //   't': '27.84',
    //   'url': 'https://jupitrr-ugc.s3-accelerate.amazonaws.com/SpRadyvRImTaERdtx0wE4KyM6lT2/stocks%2Fslideshow-iTogLjzQsiARHDBh7K9UiS--sSPC45q37rVG1rvJQMdfnA.gif'
    // },
    // {
    //   'id': 'xqBv2cLLNSNWrZdH9B92Ek',
    //   't': '32.17',
    //   'type': 'gif',
    //   'stock_media_source': 'https://media4.giphy.com/media/3o7ZetIsjtbkgNE1I4/giphy.gif?cid=12de9f12rlmowvxdg6gi6bqk571lskk8yol03o9ediqtdfy7&ep=v1_gifs_search&rid=giphy.gif&ct=g',
    //   'manual_keyword': 'runnn',
    //   'f': '29.76',
    //   'url': 'https://jupitrr-ugc.s3-accelerate.amazonaws.com/SpRadyvRImTaERdtx0wE4KyM6lT2/stocks%2Fslideshow-iTogLjzQsiARHDBh7K9UiS--xqBv2cLLNSNWrZdH9B92Ek.gif'
    // },
    // {
    //   'stock_media_source': 'https://videos.pexels.com/video-files/5741025/5741025-hd_1080_1920_30fps.mp4',
    //   'f': '34.63',
    //   'type': 'video',
    //   'manual_keyword': 'experiment scientist',
    //   'offset_time': 0,
    //   't': '36.23',
    //   'original_keyword': 'innovation',
    //   'url': 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/SpRadyvRImTaERdtx0wE4KyM6lT2/trimmed-videos/5741025-hd_1080_1920_30fps.mp4',
    //   'id': '2pSXJc6ej5cgNPwLnBjAau'
    // },
    // {
    //   'manual_keyword': 'meditation',
    //   'type': 'video',
    //   'f': '40.93',
    //   'url': 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/SpRadyvRImTaERdtx0wE4KyM6lT2/trimmed-videos/5992585-hd_1080_1920_25fps.mp4',
    //   'stock_media_source': 'https://videos.pexels.com/video-files/5992585/5992585-hd_1080_1920_25fps.mp4',
    //   'id': 'enAvmhjNtDN8PjUADtBJtX',
    //   'original_keyword': 'patience',
    //   't': '42.11',
    //   'offset_time': 0
    // },
    // {
    //   'url': 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/SpRadyvRImTaERdtx0wE4KyM6lT2/trimmed-videos/3188892-hd_1080_1920_25fps.mp4',
    //   't': '49.49',
    //   'offset_time': 0,
    //   'id': 'rJcniDLfJyCyxsb51XiALq',
    //   'original_keyword': 'celebration',
    //   'type': 'video',
    //   'manual_keyword': 'celebration',
    //   'stock_media_source': 'https://videos.pexels.com/video-files/3188892/3188892-hd_1080_1920_25fps.mp4',
    //   'f': '48.53'
    // },
    // {
    //   'original_keyword': 'advice',
    //   'url': 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/SpRadyvRImTaERdtx0wE4KyM6lT2/trimmed-videos/8774182-hd_720_1280_25fps.mp4',
    //   'manual_keyword': 'advice',
    //   'type': 'video',
    //   'stock_media_source': 'https://videos.pexels.com/video-files/8774182/8774182-hd_720_1280_25fps.mp4',
    //   'f': '21.84',
    //   'id': 'mnTu3GBpg4gogaDEahSKht',
    //   't': '22.46',
    //   'offset_time': 0
    // }
  ],
  'preferences': {
    'hidePromotion': false,
    'positionY': 23,
    'capitalization': false,
    'fontSize': 74,
    'animationColor': '#d00000',
    'hideOnboarding': true,
    'primaryColor': '#ffffff',
    'color': '#fff36a',
    'dimensionType': 'portrait',
    'fontFamily': 'Playfair Display',
    'subtitleType': 'Reels',
    'animation': 'Box Highlight',
    'wordLimit': 20
  }

};

const subtitlesContent = [
  {
    'f': 0.06,
    't': 0.16,
    'c': 'If'
  },
  {
    'f': 0.16,
    't': 0.26,
    'c': 'I'
  },
  {
    'f': 0.26,
    't': 0.4,
    'c': 'can'
  },
  {
    'f': 0.4,
    't': 0.54,
    'c': 'only'
  },
  {
    'f': 0.54,
    't': 0.7,
    'c': 'give'
  },
  {
    'f': 0.7,
    't': 0.82,
    'c': 'one'
  },
  {
    'f': 0.82,
    't': 1.1,
    'c': 'advice'
  },
  {
    'f': 1.26,
    't': 1.36,
    'c': 'to'
  },
  {
    'f': 1.36,
    't': 1.5,
    'c': 'my'
  },
  {
    'f': 1.5,
    't': 1.9,
    'c': '22'
  },
  {
    'f': 1.9,
    't': 2.06,
    'c': '- year'
  },
  {
    'f': 2.06,
    't': 2.16,
    'c': '- old'
  },
  {
    'f': 2.16,
    't': 2.4,
    'c': 'self,'
  },
  {
    'f': 2.8,
    't': 2.9,
    'c': 'here'
  },
  {
    'f': 2.9,
    't': 3.02,
    'c': 'is'
  },
  {
    'f': 3.02,
    't': 3.12,
    'c': 'what'
  },
  {
    'f': 3.12,
    't': 3.26,
    'c': 'I'
  },
  {
    'f': 3.26,
    't': 3.36,
    'c': 'will'
  },
  {
    'f': 3.36,
    't': 3.56,
    'c': 'say.'
  },
  {
    'f': 4.1,
    't': 4.2,
    'c': 'Seven'
  },
  {
    'f': 4.2,
    't': 4.38,
    'c': 'years'
  },
  {
    'f': 4.38,
    't': 4.62,
    'c': 'ago,'
  },
  {
    'f': 4.76,
    't': 5.18,
    'c': 'unlike'
  },
  {
    'f': 5.18,
    't': 5.58,
    'c': 'any'
  },
  {
    'f': 5.58,
    't': 5.78,
    'c': 'other'
  },
  {
    'f': 5.78,
    't': 6.16,
    'c': 'graduates,'
  },
  {
    'f': 6.54,
    't': 6.64,
    'c': 'I'
  },
  {
    'f': 6.64,
    't': 6.8,
    'c': 'did'
  },
  {
    'f': 6.8,
    't': 7,
    'c': 'not'
  },
  {
    'f': 7.18,
    't': 7.28,
    'c': 'apply'
  },
  {
    'f': 7.28,
    't': 7.56,
    'c': 'for'
  },
  {
    'f': 7.64,
    't': 7.76,
    'c': 'any'
  },
  {
    'f': 7.76,
    't': 7.86,
    'c': 'jobs.'
  },
  {
    'f': 8.58,
    't': 8.68,
    'c': "It's"
  },
  {
    'f': 8.68,
    't': 8.9,
    'c': 'because'
  },
  {
    'f': 9.1,
    't': 9.2,
    'c': 'I'
  },
  {
    'f': 9.2,
    't': 9.4,
    'c': 'heard'
  },
  {
    'f': 9.4,
    't': 9.48,
    'c': 'a'
  },
  {
    'f': 9.48,
    't': 9.8,
    'c': 'term'
  },
  {
    'f': 9.8,
    't': 10,
    'c': 'called'
  },
  {
    'f': 10,
    't': 10.42,
    'c': '"startup",'
  },
  {
    'f': 11.02,
    't': 11.12,
    'c': 'and'
  },
  {
    'f': 11.12,
    't': 11.26,
    'c': 'it'
  },
  {
    'f': 11.26,
    't': 11.46,
    'c': 'sounds'
  },
  {
    'f': 11.46,
    't': 11.76,
    'c': 'really'
  },
  {
    'f': 11.76,
    't': 11.96,
    'c': 'cool,'
  },
  {
    'f': 12.18,
    't': 12.58,
    'c': 'but'
  },
  {
    'f': 12.84,
    't': 12.96,
    'c': 'totally'
  },
  {
    'f': 12.96,
    't': 13.58,
    'c': 'unpopular'
  },
  {
    'f': 13.62,
    't': 13.78,
    'c': 'back'
  },
  {
    'f': 13.78,
    't': 13.94,
    'c': 'in'
  },
  {
    'f': 13.94,
    't': 14.08,
    'c': 'those'
  },
  {
    'f': 14.08,
    't': 14.34,
    'c': 'days,'
  },
  {
    'f': 14.6,
    't': 14.8,
    'c': 'especially'
  },
  {
    'f': 14.8,
    't': 15.02,
    'c': 'in'
  },
  {
    'f': 15.02,
    't': 15.14,
    'c': 'Hong'
  },
  {
    'f': 15.14,
    't': 15.38,
    'c': 'Kong.'
  },
  {
    'f': 16.18,
    't': 16.3,
    'c': 'And'
  },
  {
    'f': 16.3,
    't': 16.62,
    'c': 'yeah,'
  },
  {
    'f': 16.68,
    't': 17.02,
    'c': "that's"
  },
  {
    'f': 17.02,
    't': 17.12,
    'c': 'how'
  },
  {
    'f': 17.12,
    't': 17.26,
    'c': 'I'
  },
  {
    'f': 17.4,
    't': 17.54,
    'c': 'started'
  },
  {
    'f': 17.54,
    't': 17.78,
    'c': 'my'
  },
  {
    'f': 17.78,
    't': 18.12,
    'c': 'first'
  },
  {
    'f': 18.12,
    't': 18.4,
    'c': 'startup.'
  },
  {
    'f': 18.96,
    't': 19.06,
    'c': 'If'
  },
  {
    'f': 19.06,
    't': 19.18,
    'c': 'I'
  },
  {
    'f': 19.18,
    't': 19.3,
    'c': 'can'
  },
  {
    'f': 19.3,
    't': 19.44,
    'c': 'only'
  },
  {
    'f': 19.44,
    't': 19.62,
    'c': 'give'
  },
  {
    'f': 19.62,
    't': 19.76,
    'c': 'one'
  },
  {
    'f': 19.76,
    't': 20,
    'c': 'advice'
  },
  {
    'f': 20.18,
    't': 20.28,
    'c': 'to'
  },
  {
    'f': 20.28,
    't': 20.44,
    'c': 'my'
  },
  {
    'f': 20.44,
    't': 20.8,
    'c': '22'
  },
  {
    'f': 20.8,
    't': 20.94,
    'c': 'year'
  },
  {
    'f': 20.94,
    't': 21.04,
    'c': 'old'
  },
  {
    'f': 21.04,
    't': 21.26,
    'c': 'self,'
  },
  {
    'f': 21.84,
    't': 21.94,
    'c': "here's"
  },
  {
    'f': 21.94,
    't': 22.04,
    'c': 'what'
  },
  {
    'f': 22.04,
    't': 22.16,
    'c': 'I'
  },
  {
    'f': 22.16,
    't': 22.28,
    'c': 'will'
  },
  {
    'f': 22.28,
    't': 22.46,
    'c': 'say.'
  },
  {
    'f': 22.8,
    't': 23.02,
    'c': 'Macro'
  },
  {
    'f': 23.02,
    't': 23.34,
    'c': 'patience,'
  },
  {
    'f': 23.56,
    't': 23.92,
    'c': 'micro'
  },
  {
    'f': 23.92,
    't': 24.02,
    'c': 'speed.'
  },
  {
    'f': 24.94,
    't': 25.2,
    'c': 'Speed'
  },
  {
    'f': 25.2,
    't': 25.28,
    'c': 'to'
  },
  {
    'f': 25.28,
    't': 25.44,
    'c': 'me'
  },
  {
    'f': 25.44,
    't': 25.78,
    'c': 'is'
  },
  {
    'f': 25.78,
    't': 26,
    'c': 'not'
  },
  {
    'f': 26,
    't': 26.26,
    'c': 'mainly'
  },
  {
    'f': 26.26,
    't': 26.52,
    'c': 'about'
  },
  {
    'f': 26.54,
    't': 26.88,
    'c': 'how'
  },
  {
    'f': 26.88,
    't': 27.12,
    'c': 'fast'
  },
  {
    'f': 27.12,
    't': 27.24,
    'c': 'you'
  },
  {
    'f': 27.24,
    't': 27.32,
    'c': 'can'
  },
  {
    'f': 27.32,
    't': 27.5,
    'c': 'get'
  },
  {
    'f': 27.5,
    't': 27.64,
    'c': 'work'
  },
  {
    'f': 27.64,
    't': 27.84,
    'c': 'done,'
  },
  {
    'f': 28.44,
    't': 28.54,
    'c': 'but'
  },
  {
    'f': 28.54,
    't': 28.68,
    'c': "it's"
  },
  {
    'f': 28.68,
    't': 28.86,
    'c': 'more'
  },
  {
    'f': 28.86,
    't': 29.14,
    'c': 'about'
  },
  {
    'f': 29.14,
    't': 29.56,
    'c': 'how'
  },
  {
    'f': 29.58,
    't': 29.68,
    'c': 'you'
  },
  {
    'f': 29.68,
    't': 29.76,
    'c': 'can'
  },
  {
    'f': 29.76,
    't': 29.96,
    'c': 'get'
  },
  {
    'f': 29.96,
    't': 30.2,
    'c': 'out'
  },
  {
    'f': 30.2,
    't': 30.4,
    'c': 'of'
  },
  {
    'f': 30.4,
    't': 30.72,
    'c': 'each'
  },
  {
    'f': 30.72,
    't': 30.96,
    'c': 'plateau'
  },
  {
    'f': 30.96,
    't': 31.28,
    'c': 'fast.'
  },
  {
    'f': 32.05,
    't': 32.17,
    'c': 'which'
  },
  {
    'f': 32.17,
    't': 32.39,
    'c': 'nobody'
  },
  {
    'f': 32.39,
    't': 32.61,
    'c': 'talks'
  },
  {
    'f': 32.61,
    't': 32.71,
    'c': 'about.'
  },
  {
    'f': 33.29,
    't': 33.39,
    'c': 'If'
  },
  {
    'f': 33.39,
    't': 33.51,
    'c': 'you'
  },
  {
    'f': 33.51,
    't': 33.65,
    'c': 'hit'
  },
  {
    'f': 33.65,
    't': 33.75,
    'c': 'a'
  },
  {
    'f': 33.75,
    't': 33.95,
    'c': 'plateau,'
  },
  {
    'f': 34.53,
    't': 34.63,
    'c': 'try'
  },
  {
    'f': 34.63,
    't': 34.97,
    'c': 'something'
  },
  {
    'f': 34.97,
    't': 35.17,
    'c': 'new,'
  },
  {
    'f': 35.65,
    't': 35.93,
    'c': 'experiment'
  },
  {
    'f': 35.93,
    't': 36.23,
    'c': 'it,'
  },
  {
    'f': 36.73,
    't': 36.83,
    'c': 'tweak'
  },
  {
    'f': 36.83,
    't': 37.13,
    'c': 'something,'
  },
  {
    'f': 37.93,
    't': 38.03,
    'c': 'launch'
  },
  {
    'f': 38.11,
    't': 38.21,
    'c': 'quick'
  },
  {
    'f': 38.21,
    't': 38.33,
    'c': 'to'
  },
  {
    'f': 38.33,
    't': 38.39,
    'c': 'the'
  },
  {
    'f': 38.39,
    't': 38.69,
    'c': 'market'
  },
  {
    'f': 38.69,
    't': 39.13,
    'c': 'and'
  },
  {
    'f': 39.13,
    't': 39.31,
    'c': 'get'
  },
  {
    'f': 39.31,
    't': 39.59,
    'c': 'feedback.'
  },
  {
    'f': 40.07,
    't': 40.51,
    'c': 'If'
  },
  {
    'f': 40.53,
    't': 40.63,
    'c': "you're"
  },
  {
    'f': 40.63,
    't': 40.77,
    'c': 'not'
  },
  {
    'f': 40.77,
    't': 40.93,
    'c': 'there'
  },
  {
    'f': 40.93,
    't': 41.17,
    'c': 'yet,'
  },
  {
    'f': 41.33,
    't': 41.71,
    'c': 'be'
  },
  {
    'f': 41.71,
    't': 42.11,
    'c': 'patient.'
  },
  {
    'f': 42.93,
    't': 43.19,
    'c': 'Startups'
  },
  {
    'f': 43.27,
    't': 43.37,
    'c': 'are'
  },
  {
    'f': 43.37,
    't': 43.73,
    'c': 'really'
  },
  {
    'f': 43.73,
    't': 44.07,
    'c': 'hard.'
  },
  {
    'f': 44.75,
    't': 44.85,
    'c': 'Success'
  },
  {
    'f': 44.85,
    't': 45.37,
    'c': "won't"
  },
  {
    'f': 45.37,
    't': 45.53,
    'c': 'come'
  },
  {
    'f': 45.53,
    't': 45.73,
    'c': 'in'
  },
  {
    'f': 45.73,
    't': 45.85,
    'c': 'just'
  },
  {
    'f': 45.85,
    't': 46.03,
    'c': 'a'
  },
  {
    'f': 46.07,
    't': 46.27,
    'c': 'year'
  },
  {
    'f': 46.27,
    't': 46.41,
    'c': 'or'
  },
  {
    'f': 46.41,
    't': 46.61,
    'c': 'two.'
  },
  {
    'f': 46.87,
    't': 47.31,
    'c': 'And'
  },
  {
    'f': 47.33,
    't': 47.51,
    'c': "don't"
  },
  {
    'f': 47.51,
    't': 47.67,
    'c': 'forget'
  },
  {
    'f': 47.67,
    't': 47.81,
    'c': 'this,'
  },
  {
    'f': 48.53,
    't': 48.71,
    'c': 'always'
  },
  {
    'f': 48.71,
    't': 48.97,
    'c': 'celebrate'
  },
  {
    'f': 48.97,
    't': 49.27,
    'c': 'small'
  },
  {
    'f': 49.27,
    't': 49.49,
    'c': 'wins,'
  },
  {
    'f': 49.95,
    't': 50.07,
    'c': 'and'
  },
  {
    'f': 50.07,
    't': 50.81,
    'c': 'enjoy'
  },
  {
    'f': 50.81,
    't': 50.97,
    'c': 'the'
  },
  {
    'f': 50.97,
    't': 51.15,
    'c': 'journey!'
  }
];

export const sampleProps = Object.assign(preset_aesthetic, { subtitlesContent });
