import { useEffect, useState } from 'react';

const ProgressPercentage = ({ interval = 1500 }: { interval?: number }) => {
  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setPercentage((prevPercentage) => {
        if (prevPercentage < 90) {
          return prevPercentage + Math.ceil(Math.random() * 10);
        } else {
          clearInterval(timer);
          return prevPercentage;
        }
      });
    }, interval);

    return () => clearInterval(timer);
  }, [interval]);

  return percentage + '%';
};

export default ProgressPercentage;
