import {
  AIOverlayText,
  isOverlayTextModalOpen
} from '../../recoil/atoms/overlayText.atoms';
import {
  Asset,
  AssetType,
  DimensionType,
  GenerateSlideshowVideoConfig,
  StockDimensionType
} from '../../slideshow/types';
import { TextOutput } from '../../audiogram/components/TextSequence';
import { Transcript } from './Transcript';
import {
  addOnHighlightedClass,
  cleanupSelection,
  clearSelection,
  findNextHighlightedToDivider,
  getCurrentHighlightedDivs,
  getFirstAndLastElementFromSelection,
  getTextBetweenDivs,
  hasStocksInBetween,
  isAtLineEnd,
  isDividerDiv,
  isFirstOnLine,
  loopThroughDivs,
  toggleHighlightClass
} from '../../utils/divs';
import { calculatePromptTime } from '@/utils/prompt';
import {
  getAdjustedPosition,
  getElementOffset,
  getHighlightedTextSentence,
  getSilenceIdsFromDivs,
  isMediaAGif,
  isMediaAWebImage,
  isOverlay,
  seekToSecond
} from '../../utils/generalUtilities';

import {
  AdjustHighlighterProvider,
  useAdjustHighlight
} from '@/hooks/useAdjustHighlight';
import {
  assetSummaryDropdownSettings,
  setDropdownLoading
} from '@/recoil/atoms/dropdown.atoms';
import { isAudioToVideo } from '../../recoil/atoms/slideShowMode.atom';
import {
  isMainVideo,
  isStockVideo,
  isUserUploadedVideo
} from '@/slideshow/utils/asset';
import { isUploadedUrl } from '@/slideshow/utils/s3';
import { reverse } from 'lodash';
import { toast } from 'react-toastify';
import { transcriptContainsNonEnglishCharacters } from '../..//recoil/atoms/exportSlideshow.atom';
import { useAsyncFileUploader } from '@/hooks/useAsyncFileUploader';
import { useGalleryModal } from '@/hooks/useGalleryPickerModal';
import { useModal } from '@/hooks/useModal';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { useRouter } from 'next/router';
import { useUser } from '../../hooks/useUser';
import { useVideoPreview } from '@/hooks/useVideoPreview';
import Dropdown, {
  DEFAULT_DROPDOWN_SETTINGS,
  DropdownOptions,
  IDropdownSettings
} from '../Shared/Dropdown';
import Icon, { IconType } from '../Shared/Icon';
import Media from '../../models/Media';
import ProgressPercentage from '../Shared/ProgressPercentage';
import React, { useEffect, useMemo, useState } from 'react';
import RegenerateTranscriptModal from '../Modals/RegenerateTranscriptModal';
import posthog from 'posthog-js';

interface AssetSummaryProps {
  slideshow: GenerateSlideshowVideoConfig;
  updateSlideshow: (
    newSlideshowData: Partial<GenerateSlideshowVideoConfig>
  ) => void;
  subtitlesObj: TextOutput[];
}

interface HighlightableDivProps {
  className?: string;
  popupEditMenu?: (from: Element, to: Element) => void;
  popupCreateMenu?: (from: Element, to: Element) => void;
  children: React.ReactNode;
  containsNonEglishChar?: boolean;
}

const getActiveAsset = ({
  assets = [],
  from,
  to
}: {
  assets: Asset[];
  from: string;
  to: string;
}) => {
  return reverse(assets).find(
    (a) =>
      parseFloat(a.f) === parseFloat(from) && parseFloat(a.t) === parseFloat(to)
  );
};

function HighlightableDiv(props: HighlightableDivProps) {
  const { popupEditMenu, popupCreateMenu, containsNonEglishChar } = props;
  const { motherDivRef } = useAdjustHighlight();
  return (
    <div
      className={`py-4 ${containsNonEglishChar ? 'font-noto' : ''}`}
      aria-label="generated transcript container"
      data-highlight="main"
      ref={motherDivRef}
      data-testid="generated-transcript-container"
      onContextMenu={(e) => e.preventDefault()}
      onMouseUp={(e) => {
        const selection = window.getSelection();
        const target = e.target as HTMLElement;
        /* Clicking instead of highlighting */
        if (!selection || selection.isCollapsed) {
          if (!target.classList.contains('highlighted')) return;

          /* Handle Adjust Div Logic */
          if (target.classList.contains('highlight-divider')) {
            return;
          }
          const { startingDiv, endingDiv } = getCurrentHighlightedDivs(
            e.target as HTMLElement
          );
          popupEditMenu(startingDiv, endingDiv);
          return;
        }
        const { firstElement, endingElement } =
          getFirstAndLastElementFromSelection(selection);
        /* Highlighting highlighted text*/
        if (
          firstElement.classList.contains('highlighted') ||
          endingElement.classList.contains('highlighted')
        ) {
          const { startingDiv, endingDiv } = getCurrentHighlightedDivs(
            firstElement as HTMLElement
          );
          popupEditMenu(startingDiv, endingDiv);
          return;
        }
        /* Highlighting unhighlighted text*/
        popupCreateMenu(firstElement, endingElement);
      }}
    >
      {props.children}
    </div>
  );
}

const LoadingText = (dropdownSettings: IDropdownSettings) => {
  const { uploadPercent } = useAsyncFileUploader();
  const { loadingType } = dropdownSettings;
  let sentenceDuration = 0;
  if (dropdownSettings.fromElement && dropdownSettings.toElement) {
    sentenceDuration =
      parseFloat(dropdownSettings.toElement.getAttribute('data-to')) -
      parseFloat(dropdownSettings.fromElement.getAttribute('data-from'));
  }
  switch (loadingType) {
    case 'randomize':
      return 'Choosing new visual...';
    case 'delete':
      return 'Deleting...';
    case 'create': {
      const interval = calculatePromptTime(sentenceDuration);
      return (
        <div className="flex w-44 justify-start">
          Generating visual... <ProgressPercentage interval={interval} />
        </div>
      );
    }
    case 'analyze':
      return 'Analyzing content...';
    // case 'edit-keyword':
    //   return 'Analyzing keyword...';
    case 'loading-user-upload': {
      const percentText =
        dropdownSettings.loadingType === 'loading-user-upload' &&
        uploadPercent?.length
          ? ` ${Math.round(uploadPercent[0])}%`
          : '';

      return 'Uploading... ' + percentText;
    }
    case 'gallery-picker':
      return 'Applying changes...';
    default:
      return 'Loading...';
  }
};

export const PARAGRAPH_SEPARATOR = 'paragraph-separator';
function addParagraphSeparators(words) {
  let newWords = []; // Array to hold the new list of words with paragraph separators
  let sentenceWords = 0; // Counter for tracking words in the current sentence
  let paragraphWords = 0; // Counter for tracking words in the current paragraph
  for (let i = 0; i < words.length; i++) {
    const wordObj = words[i];
    newWords.push(wordObj);
    sentenceWords++;
    paragraphWords++;

    const content = wordObj.content || wordObj.c;

    // Check if the content ends with a period or a question mark
    if (content.endsWith('.') || content.endsWith('?')) {
      // Check if the current paragraph is long enough or the sentence has too many words
      if (paragraphWords >= 30 || sentenceWords > 50) {
        newWords.push({ c: PARAGRAPH_SEPARATOR }); // Insert a paragraph separator
        paragraphWords = 0; // Reset the paragraph word counter
      }

      sentenceWords = 0; // Reset the sentence word counter
    } else if (paragraphWords >= 50 && sentenceWords <= 50) {
      // If the current paragraph has reached its maximum length and the sentence is not too long,
      // Insert a paragraph separator
      newWords.splice(newWords.length - sentenceWords, 0, {
        c: 'paragraph-separator'
      });
      paragraphWords = sentenceWords; // Update the paragraph word counter
    }
  }
  return newWords;
}

const AssetSummary = (props: AssetSummaryProps) => {
  const { videoPreviewRef } = useVideoPreview();
  const router = useRouter();
  const { user, hasPremium } = useUser();
  const { openModal } = useModal();
  const { createDividerDiv } = useAdjustHighlight();
  const [dropdownSettings, setDropdown] = useRecoilState(
    assetSummaryDropdownSettings
  );
  const isAudioMode = useRecoilValue(isAudioToVideo);

  const setOverlayOpen = useSetRecoilState(isOverlayTextModalOpen);
  const setAiText = useSetRecoilState(AIOverlayText);
  const { setIsGalleryModalOpen, setGalleryMode } = useGalleryModal();
  const [subtitles, setSubtitles] = useState([]);

  const { startUpload, uploadPercent } = useAsyncFileUploader();
  const resetDropdown = () => setDropdown(DEFAULT_DROPDOWN_SETTINGS);
  const subtitlesText = useMemo(() => {
    return props.subtitlesObj.map((s) => s.c).join(' ');
  }, [props.subtitlesObj]);
  const cleanupPosition = () => {
    document
      .querySelectorAll('.highlighted-from-divider')
      .forEach((element) => {
        if (isAtLineEnd(element)) {
          const br = document.createElement('br');
          element.parentNode.insertBefore(br, element);
        }

        const highlightedToElement = findNextHighlightedToDivider(element);
        if (highlightedToElement && isFirstOnLine(highlightedToElement)) {
          const br = document.createElement('br');
          element.parentNode.insertBefore(
            br,
            highlightedToElement.previousElementSibling
          );
        }
      });
  };
  const highlightWords = (
    fromElement: Element,
    toElement: Element,
    assetId: string,
    isSwap = false
  ): void => {
    fromElement.classList.add('highlighted-from');
    toElement.classList.add('highlighted-to');
    loopThroughDivs(fromElement, toElement, (currentDiv: Element) => {
      currentDiv.classList.add('highlighted');
    });
    createDividerDiv(
      fromElement,
      'highlighted-from',
      onTranscriptHover,
      assetId
    );
    createDividerDiv(toElement, 'highlighted-to', onTranscriptHover, assetId);
    cleanupPosition();
  };
  const unhighlightWords = (fromElement: Element, toElement: Element) => {
    fromElement.classList.remove('highlighted-from');
    toElement.classList.remove('highlighted-to');
    loopThroughDivs(fromElement, toElement, (currentDiv) => {
      currentDiv.classList.remove('highlighted');
    });
    if (isDividerDiv(fromElement)) {
      fromElement.parentElement.removeChild(fromElement);
    }
    if (isDividerDiv(toElement)) {
      toElement.parentElement.removeChild(toElement);
    }
  };

  const isDropdownLoading = () => dropdownSettings.mode === 'loading';

  const setAiSummary = async () => {
    if (!dropdownSettings.fromElement || !dropdownSettings.toElement) return;
    const isSwap = dropdownSettings.childMenuOpened;
    // Get all silence ids from the divs
    const silenceIds = getSilenceIdsFromDivs(
      dropdownSettings.fromElement,
      dropdownSettings.toElement
    );
    if (
      isSwap &&
      hasStocksInBetween(
        dropdownSettings.fromElement,
        dropdownSettings.toElement
      )
    ) {
      setDropdown((prevState) => ({
        ...prevState,
        childMenuOpened: false
      }));
      await deleteStockMedia(null, true);
    }
    if (isDropdownLoading()) return;
    setDropdownLoading(setDropdown, true, 'analyze');
    let summary = '';
    // Prefer to get the summary of the entire highlighted content
    if (
      silenceIds.length === 1 &&
      dropdownSettings.fromElement === dropdownSettings.toElement
    ) {
      summary = 'silence';
    } else {
      const { slideshowId } = router.query;
      const content = getTextBetweenDivs(
        dropdownSettings.fromElement,
        dropdownSettings.toElement
      );
      const entireHighlightedContent = getHighlightedTextSentence({
        content: subtitlesText,
        text: content.replace(/\s+/g, ' ')
      });
      const { summary: responseSummary = '' } = await Media.textSummary({
        userId: user.id,
        slideshowId: slideshowId as string,
        promptId: 'text_summary',
        content: content,
        context: entireHighlightedContent
      });
      if (!responseSummary.length) {
        cleanupSelection();
        throw new Error('Something went wrong. Please try highlighting again');
      }
      summary = responseSummary;
    }
    setAiText(summary);
    setDropdown((prevState) => ({
      ...prevState,
      opened: false
    }));
    setOverlayOpen(true);
  };
  const addStockMedia = async ({
    type,
    keyword
  }: {
    type: AssetType;
    keyword?: string;
  }) => {
    try {
      const isSwap = dropdownSettings.childMenuOpened;
      if (!dropdownSettings.fromElement || !dropdownSettings.toElement) return;
      if (
        isSwap &&
        hasStocksInBetween(
          dropdownSettings.fromElement,
          dropdownSettings.toElement
        )
      ) {
        setDropdown((prevState) => ({
          ...prevState,
          childMenuOpened: false
        }));
        await deleteStockMedia(null, true);
      }
      if (isDropdownLoading()) return;
      setDropdownLoading(setDropdown, true, 'analyze');
      const { slideshowId } = router.query;
      if (videoPreviewRef.current) videoPreviewRef.current.pause();
      if (!user.id || !slideshowId) return toast.error('Params not found');
      // Get all silence ids from the divs
      const silenceIds = getSilenceIdsFromDivs(
        dropdownSettings.fromElement,
        dropdownSettings.toElement
      );
      let content = '';
      // If there silence Id alone highlighted, we don't need to get the content between the divs else use the highlighted content
      if (
        silenceIds.length === 1 &&
        dropdownSettings.fromElement === dropdownSettings.toElement
      ) {
        content = 'silence';
      } else {
        content = getTextBetweenDivs(
          isSwap
            ? dropdownSettings.fromElement.nextElementSibling
            : dropdownSettings.fromElement,
          isSwap
            ? dropdownSettings.toElement.previousElementSibling
            : dropdownSettings.toElement
        );
      }
      const dimension =
        props.slideshow.preferences.dimensionType || DimensionType['portrait'];
      setDropdownLoading(setDropdown, true, 'create');
      let entireHighlightedContent = '';
      // If there silence Id alone highlighted, we don't need to get the content between the divs else use the highlighted content
      if (
        silenceIds.length === 1 &&
        dropdownSettings.fromElement === dropdownSettings.toElement
      ) {
        entireHighlightedContent = 'silence';
      } else {
        entireHighlightedContent = getHighlightedTextSentence({
          content: subtitlesText,
          text: content.replace(/\s+/g, ' ')
        });
      }
      console.log(entireHighlightedContent);
      console.log(content);
      const {
        new_assets = [],
        sentences = [],
        all_assets = []
      } = await Media.textToMedia(
        {
          userId: user.id,
          slideshowId: slideshowId as string,
          dimension: dimension as unknown as StockDimensionType,
          promptId: router.query.promptId as string | null,
          assetType: type,
          isSwap,
          ...(keyword ? { keyword } : {}),
          context: entireHighlightedContent,
          content,
          silenceIds
        },
        props.slideshow
      );
      let textToMediaErrors = [];
      if (!new_assets?.length || !sentences?.length) {
        cleanupSelection();
        /* Track stock Image, stock Video Error events */
        const metadata = {
          slideshowId: router.query.slideshowId,
          highlightType: type,
          userId: user.id,
          status: 'failed',
          created_at: new Date().toISOString(),
          isAudioToVideo: isAudioMode,
          error: `api error`
        };
        posthog.capture(`stock-${type}-highlight-error-event`, metadata);
        throw new Error('Something went wrong. Please try highlighting again');
      }
      new_assets.forEach((assetOrError, i) => {
        if (assetOrError.success) {
          const fromElement = document.querySelector(
            `div[data-from="${Number(sentences[i].from)}"]`
          );
          const toElement = document.querySelector(
            `div[data-to="${Number(sentences[i].to)}"]`
          );
          if (isSwap) {
            fromElement.setAttribute('data-asset-id', assetOrError.asset.id);
            toElement.setAttribute('data-asset-id', assetOrError.asset.id);
          } else {
            // highlightWords(fromElement, toElement, assetOrError.asset.id);
          }
        } else {
          textToMediaErrors.push(assetOrError.error);
          console.error(assetOrError.error);
          cleanupSelection();
        }
      });

      if (textToMediaErrors.length) {
        /* Track stock Image, stock Video Error events */
        const metadata = {
          slideshowId: router.query.slideshowId,
          highlightType: type,
          userId: user.id,
          status: 'partially failed',
          created_at: new Date().toISOString(),
          isAudioToVideo: isAudioMode,
          error: `we couldn't find visuals for some of the highlights.`
        };
        posthog.capture(`stock-${type}-highlight-error-event`, metadata);
        toast.warn(
          `Sorry, we couldn't find visuals for some of the highlights. Please try re-highlighting for those parts!`
        );
        cleanupSelection();
      }
      clearSelection();

      // When multiple sentences are highlighted, we are showing rating dropdown of last sentence
      // So, activeAsset should be last sentence
      const lastSentence = sentences[sentences.length - 1];
      const from = lastSentence.from;
      const to = lastSentence.to;
      const assetOfHighlightedSentence =
        new_assets[new_assets.length - 1]?.asset;
      // When multiple sentences are highlighted, we make sure the video
      // starts from the beggining of first sentence
      const firstSentenceFrom =
        dropdownSettings.fromElement.getAttribute('data-from');

      seekToSecond(firstSentenceFrom, videoPreviewRef);
      const lastSentenceFrom = document.querySelector(
        `div[data-from="${lastSentence.from}"]`
      );
      const lastSentenceTo = document.querySelector(
        `div[data-to="${lastSentence.to}"]`
      );
      if (lastSentenceFrom && lastSentenceTo) {
        toggleHighlightClass({
          from: isSwap ? lastSentenceFrom.nextElementSibling : lastSentenceFrom,
          to: isSwap
            ? lastSentenceTo.nextElementSibling
            : lastSentenceTo /* There are 2 consecutive found element with the same data-to, and we want to highlight till the second one, which is the | */,
          isActive: true,
          className: 'highlight-focus'
        });
      }
      setDropdown({
        mode: 'edit',
        fromElement: lastSentenceFrom,
        toElement: lastSentenceTo,
        opened: true,
        position: dropdownSettings.position,
        activeAsset: assetOfHighlightedSentence
      });
      /* Track stock Image, stock Video events */
      const metadata = {
        slideshowId: router.query.slideshowId,
        highlightType: type,
        userId: user.id,
        startsFrom: firstSentenceFrom,
        endsAt: lastSentenceTo.getAttribute('data-to'),
        status: 'succeeded',
        created_at: new Date().toISOString(),
        isAudioToVideo: isAudioMode
      };
      posthog.capture(`stock-${type}-highlight-event`, metadata);
      return {
        newAssets: new_assets,
        allAssets: all_assets,
        activeAsset: assetOfHighlightedSentence
      };
    } catch (err) {
      /* Track stock Image, stock Video Error events */
      const metadata = {
        slideshowId: router.query.slideshowId,
        highlightType: type,
        userId: user.id,
        status: 'failed',
        created_at: new Date().toISOString(),
        isAudioToVideo: isAudioMode,
        error: String(err.message)
      };
      posthog.capture(`stock-${type}-highlight-error-event`, metadata);
      toast.error(err.message);
      cleanupSelection();
      clearSelection();
      resetDropdown();
    }
  };

  const deleteStockMedia = async (assets?: Asset[], isSwap = false) => {
    const slideshowAssets = assets ?? props.slideshow.assets;
    try {
      setDropdownLoading(setDropdown, true, 'delete');
      if (!slideshowAssets) return;
      const from = dropdownSettings.fromElement.getAttribute('data-from');
      const to = dropdownSettings.toElement.getAttribute('data-to');
      const assets = slideshowAssets.filter(
        // When multiple assets have same from and to times
        // remove the asset which is added fist
        (asset) => {
          const isAssetRelevant = isSwap
            ? asset.id !== dropdownSettings?.activeAsset?.id
            : !(
                parseFloat(asset.f) === parseFloat(from) &&
                parseFloat(asset.t) === parseFloat(to)
              );

          return !!asset && isAssetRelevant;
        }
      );
      await props.updateSlideshow({ assets });
      if (!isSwap)
        unhighlightWords(
          dropdownSettings.fromElement,
          dropdownSettings.toElement
        );
      seekToSecond(from, videoPreviewRef);
    } finally {
      if (!dropdownSettings.childMenuOpened) {
        resetDropdown();
      }
      setDropdownLoading(setDropdown, false);
      const metadata = {
        sessionId: router.query.slideshowId
      };
      posthog.capture('delete-highlight-event', metadata);
    }
  };

  const onTranscriptHover = (e, isActive = true) => {
    if (e.target === null || !e.target.classList.contains('highlighted'))
      return;

    const { startingDiv, endingDiv } = getCurrentHighlightedDivs(
      e.target as HTMLElement
    );
    toggleHighlightClass({
      from: startingDiv,
      to: endingDiv,
      isActive,
      className: 'highlight-hover'
    });
  };

  const containsNonEnglish = useRecoilValue<boolean>(
    transcriptContainsNonEnglishCharacters
  );

  const createOptions: DropdownOptions[] = [
    {
      name: 'Generate image',
      icon: 'mi-image',
      onClick: () => addStockMedia({ type: 'image' })
    },
    {
      name: 'Generate video',
      icon: 'mi-webcam',
      onClick: () => addStockMedia({ type: 'video' }),
      pillText: 'Popular'
    },
    {
      name: 'Search web image',
      icon: IconType.SearchDropdown,
      onClick: () => {
        setGalleryMode('googleImage');
        setIsGalleryModalOpen(true);
        setDropdown((prevState) => ({
          ...prevState,
          opened: false
        }));
      },
      applyPremiumGate: true
    },
    {
      name: 'Add GIF',
      icon: IconType.GIFDropdown,
      onClick: () => {
        setGalleryMode('gif');
        setIsGalleryModalOpen(true);
        setDropdown((prevState) => ({
          ...prevState,
          opened: false
        }));
      }
    },
    {
      name: 'Upload image/video',
      icon: 'mi-cloud-upload',
      onClick: () => {
        setGalleryMode('upload');
        setIsGalleryModalOpen(true);
        setDropdown((prevState) => ({
          ...prevState,
          opened: false
        }));
      }
    },
    {
      name: 'Generate text overlay',
      icon: 'ft-type',
      onClick: () => {
        setAiSummary();
      }
    }
  ];
  const loadingOptions: DropdownOptions[] = [
    {
      name: LoadingText(dropdownSettings),
      icon: 'loading',
      onClick: () => {}
    }
  ];

  const manageUserUploadOptions: DropdownOptions[] = [
    {
      name: 'Pick another one',
      icon: 'ft-search',
      onClick: () => {
        setGalleryMode('upload');
        setIsGalleryModalOpen(true);
        setDropdown((prevState) => ({
          ...prevState,
          opened: false
        }));
      }
    },
    {
      name: 'Remove B-roll',
      icon: 'mi-delete',
      onClick: () => deleteStockMedia(),
      settings: { color: 'danger' }
    }
  ];

  const isAssetAGif = isMediaAGif(dropdownSettings);
  const isAssetAWebImage = isMediaAWebImage(dropdownSettings);
  const isAssetOverlay = isOverlay(dropdownSettings);
  const getCurrentAssetEditOptionName = () => {
    if (isAssetAGif) {
      return 'Pick another GIF';
    } else if (isAssetOverlay) {
      return 'Edit Text Overlay';
    } else {
      return 'Search another one';
    }
  };
  const getCurrentAssetEditOptionIcon = () => {
    if (isAssetAGif) {
      return IconType.GIFDropdown;
    } else if (isAssetOverlay) {
      return 'ft-edit';
    } else {
      return 'ft-search';
    }
  };
  const editOptions: DropdownOptions[] = useMemo(() => {
    const defaultEditOptions: DropdownOptions[] = [
      {
        name: getCurrentAssetEditOptionName(),
        icon: getCurrentAssetEditOptionIcon(),
        onClick: () => {
          if (isAssetAGif) {
            setGalleryMode('gif');
            setIsGalleryModalOpen(true);
          } else if (isAssetAWebImage) {
            setGalleryMode('googleImage');
            setIsGalleryModalOpen(true);
          } else if (isAssetOverlay) {
            setIsGalleryModalOpen(false);
            setAiText(dropdownSettings?.activeAsset?.original_keyword);
            setOverlayOpen(true);
          } else {
            setGalleryMode('stockLibrary');
            setIsGalleryModalOpen(true);
          }
          setDropdown((prevState) => ({
            ...prevState,
            opened: false
          }));
        }
      },
      {
        name: 'Switch to other B-roll',
        icon: 'ft-arrow-right',
        childMenu: true,
        onClick: () => {}
      },
      {
        name: 'Remove B-roll',
        icon: 'mi-delete',
        onClick: () => deleteStockMedia(),
        settings: { color: 'danger' }
      }
    ];
    return defaultEditOptions;
  }, [dropdownSettings]);

  useEffect(() => {
    if (!props?.subtitlesObj) return;

    const words = addParagraphSeparators(props.subtitlesObj);
    setSubtitles(words);
  }, [props.subtitlesObj]);
  const isMainVideo = (asset: Asset) => {
    return (
      !!asset &&
      !!asset.url &&
      isUploadedUrl(asset.url) &&
      !isStockVideo(asset.url) &&
      !isUserUploadedVideo(asset.url)
    );
  };
  useEffect(() => {
    if (!props.slideshow || !props.slideshow.assets || !subtitles.length)
      return;
    [...props.slideshow.assets]
      .sort((a, b) => Number(a.f) - Number(b.f))
      .map((a) => {
        if (isMainVideo(a)) return;
        const firstElement = document.querySelector(
          `[data-from="${Number(a.f).toFixed(2)}"]:not([data-item*="divider"])`
        );
        const endingElement = document.querySelector(
          `[data-to="${Number(a.t).toFixed(2)}"]:not([data-item*="divider"])`
        );
        if (firstElement && endingElement) {
          highlightWords(firstElement, endingElement, a.id);
        }
      });
  }, [subtitles, props.slideshow?.assets.length]);
  useEffect(() => {
    // Handler to close dropdown when clicking outside
    const handleClickOutside = (event: MouseEvent) => {
      const dropdownContainer = document.getElementById(
        'mainDropdownContainer'
      );
      if (
        (dropdownSettings.opened || dropdownSettings.childMenuOpened) &&
        !isDropdownLoading() &&
        dropdownContainer &&
        !dropdownContainer.contains(event.target as Node)
      ) {
        setDropdown({
          ...dropdownSettings,
          opened: false,
          childMenuOpened: false
        });
        cleanupSelection();
      }
    };

    // Add event listener when dropdown is opened
    if (dropdownSettings.opened || dropdownSettings.childMenuOpened) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownSettings.opened, dropdownSettings.childMenuOpened]);

  return (
    <div className="relative">
      {dropdownSettings.opened && (
        <div
          className={`fixed left-0 top-0 z-50 h-full w-full`}
          aria-label="highlightDropdownMenuContainer"
          id="mainDropdownContainer"
          style={{
            ...getAdjustedPosition(
              dropdownSettings.position,
              dropdownSettings.mode
            )
          }}
          onClick={(event) => {
            if (event.target === event.currentTarget && !isDropdownLoading()) {
              // The mother div was clicked
              setDropdown({ ...dropdownSettings, opened: false });
              cleanupSelection();
            }
          }}
        >
          <Dropdown
            open={dropdownSettings.opened}
            id="mainDropdown"
            options={(() => {
              switch (dropdownSettings.mode) {
                case 'delete-user-upload':
                  return manageUserUploadOptions;
                case 'create':
                  return createOptions;
                case 'loading':
                  return loadingOptions;
                case 'edit':
                default:
                  return editOptions;
              }
            })()}
          />
          <>
            {dropdownSettings.childMenuOpened && (
              <span
                style={{
                  marginLeft:
                    document.getElementById('mainDropdown')?.offsetWidth + 10
                }}
              >
                <Dropdown
                  open={dropdownSettings.childMenuOpened}
                  options={createOptions}
                />
              </span>
            )}
          </>
        </div>
      )}
      <div className="flex flex-row justify-between pb-2 pt-4 text-sm font-medium leading-4 text-gray-500">
        Transcript
        <div
          className="flex cursor-pointer flex-row text-grey-400"
          onClick={() => {
            openModal(<RegenerateTranscriptModal />, {
              width: 460,
              transparentBackdrop: true
            });
            posthog.capture('regenerate-transcript');
          }}
        >
          <Icon
            type="ft-help-circle"
            strokeWidth={2}
            size={16}
            className="mr-1"
          />
          Something wrong? Regenerate
        </div>
      </div>

      <HighlightableDiv
        containsNonEglishChar={containsNonEnglish}
        popupEditMenu={(from, to) => {
          const fromTime = from.getAttribute('data-from');
          const toTime = to.getAttribute('data-to');
          cleanupSelection();
          toggleHighlightClass({
            from,
            to,
            isActive: true,
            className: 'highlight-focus'
          });
          const position = getElementOffset(to);
          const assetOfHighlightedSentence = getActiveAsset({
            assets: props.slideshow.assets,
            from: fromTime,
            to: toTime
          });
          setDropdown({
            mode:
              isUserUploadedVideo(assetOfHighlightedSentence?.url) &&
              !assetOfHighlightedSentence?.sample
                ? 'delete-user-upload'
                : 'edit',
            fromElement: from,
            toElement: to,
            opened: true,
            position,
            activeAsset: assetOfHighlightedSentence
          });
        }}
        popupCreateMenu={(from, to) => {
          cleanupSelection();
          addOnHighlightedClass({ from, to });
          const position = getElementOffset(to);
          setDropdown({
            mode: 'create',
            fromElement: from,
            toElement: to,
            opened: true,
            position
          });
        }}
        className="highlightable-container h-[300px] overflow-scroll"
      >
        {subtitles && subtitles.length > 0 ? (
          <Transcript
            subtitles={subtitles}
            videoPreviewRef={videoPreviewRef}
            onTranscriptHover={onTranscriptHover}
            silenceAssets={props.slideshow.silence_assets || []}
          />
        ) : null}
      </HighlightableDiv>
    </div>
  );
};

const AssetSummaryWrapper = (props: AssetSummaryProps) => (
  <AdjustHighlighterProvider>
    <AssetSummary {...props} />
  </AdjustHighlighterProvider>
);
export { AssetSummaryWrapper as AssetSummary };
