import { HIGHLIGHT_DIVIDER } from '@/hooks/useAdjustHighlight';
import { PARAGRAPH_SEPARATOR } from '../components/ExportSlideshow/AssetSummary';
function getTextBetweenDivs(startDiv: Element, endDiv: Element) {
  if (!startDiv || !endDiv) return;
  if (startDiv === endDiv) {
    if (startDiv.getAttribute('data-silence')) {
      return 'silence';
    }
    return startDiv.textContent.trim();
  }

  var divsBetween = [];
  var currentDiv = startDiv.nextSibling;
  if (!startDiv.getAttribute('data-silence')) {
    divsBetween.push(startDiv.textContent.trim());
  }
  while (currentDiv && currentDiv !== endDiv) {
    if (
      currentDiv instanceof Element &&
      currentDiv.getAttribute('data-silence')
    ) {
      currentDiv = currentDiv.nextSibling;
      continue;
    }
    if (currentDiv.nodeName === 'DIV') {
      divsBetween.push(currentDiv.textContent.trim());
    }
    currentDiv = currentDiv.nextSibling;
  }
  if (
    !(currentDiv instanceof Element && currentDiv.getAttribute('data-silence'))
  )
    divsBetween.push(currentDiv.textContent.trim());

  return divsBetween.join(' '); // Combine the divs as a single string separated by a space
}
function getCurrentHighlightedDivs(target: Element, debug = false) {
  const dataItem = target.getAttribute('data-item');
  if (!dataItem) return;
  let startingDiv: Element = target;
  let endingDiv: Element = target;

  const isHighlightDivider = (element: Element | null): boolean => {
    return element !== null && element.classList.contains(HIGHLIGHT_DIVIDER);
  };
  /* Find starting element (left |) */
  if (!target.classList.contains('highlighted-from-divider')) {
    let prevElement: Element | null = target.previousElementSibling;
    while (prevElement) {
      if (
        prevElement.classList.contains(PARAGRAPH_SEPARATOR) ||
        prevElement.tagName === 'BR'
      ) {
        prevElement = prevElement.previousElementSibling;
        continue;
      }

      if (
        isHighlightDivider(prevElement) &&
        prevElement.classList.contains('highlighted-from-divider')
      ) {
        startingDiv = prevElement;
        break;
      }
      if (!prevElement.classList.contains('highlighted')) break;
      prevElement = prevElement.previousElementSibling;
    }
  }

  /* Find ending element (right |) */
  if (!target.classList.contains('highlighted-to-divider')) {
    let nextElement: Element | null = target.nextElementSibling;
    while (nextElement) {
      if (
        nextElement.classList.contains(PARAGRAPH_SEPARATOR) ||
        nextElement.tagName === 'BR'
      ) {
        nextElement = nextElement.nextElementSibling;
        continue;
      }
      if (nextElement.classList.contains(PARAGRAPH_SEPARATOR)) continue;
      if (
        isHighlightDivider(nextElement) &&
        nextElement.classList.contains('highlighted-to-divider')
      ) {
        // if (debug) console.log('60 ' + nextElement.getAttribute('data-item'));
        endingDiv = nextElement;
        break;
      }
      if (!nextElement.classList.contains('highlighted')) break;
      nextElement = nextElement.nextElementSibling;
    }
  }
  return { startingDiv, endingDiv };
}

function loopThroughDivs(
  fromElement: Element,
  toElement: Element,
  fn: (div: Element) => void
) {
  if (
    !fromElement ||
    !toElement ||
    !fromElement.parentElement ||
    !toElement.parentElement
  )
    return;

  const parent = fromElement.parentElement;
  const children = Array.from(parent.children);
  const fromIndex = children.indexOf(fromElement);
  const toIndex = children.indexOf(toElement);

  if (fromIndex === -1 || toIndex === -1) return;

  const isForward = fromIndex < toIndex;

  let div = fromElement;
  while (div !== null) {
    if (
      (div.tagName === 'BR' || div.classList.contains(PARAGRAPH_SEPARATOR)) &&
      div !== toElement &&
      div !== fromElement
    ) {
      div = isForward ? div.nextElementSibling : div.previousElementSibling;
      continue;
    }
    fn(div);
    if (div === toElement) break;
    div = isForward ? div.nextElementSibling : div.previousElementSibling;
  }
}

const getFirstAndLastElementFromSelection = (selection: Selection) => {
  // if the cursor is stopped on the paragrah separator div,
  // make sure the word before the paragraph separator is considered
  const temp1: Element = (selection.anchorNode as Element)?.classList?.contains(
    PARAGRAPH_SEPARATOR
  )
    ? (selection.anchorNode.nextSibling as Element)
    : (selection.anchorNode.parentElement as Element);

  const temp2: Element = (selection.focusNode as Element)?.classList?.contains(
    PARAGRAPH_SEPARATOR
  )
    ? (selection.focusNode.previousSibling as Element)
    : (selection.focusNode.parentElement as Element);

  const temp1Index = parseInt(temp1.getAttribute('data-item'));
  const temp2Index = parseInt(temp2.getAttribute('data-item'));
  if (temp1Index > temp2Index) {
    return { firstElement: temp2, endingElement: temp1 };
  }
  return { firstElement: temp1, endingElement: temp2 };
};

function clearSelection() {
  if (window.getSelection) {
    if (window.getSelection().empty) {
      // Chrome
      window.getSelection().empty();
    } else if (window.getSelection().removeAllRanges) {
      // Firefox
      window.getSelection().removeAllRanges();
    }
  } else if (document && (document as any).selection) {
    // IE?
    (document as any).selection.empty();
  }
}

function cleanupSelection() {
  const parentDiv = document.querySelector(`div[data-highlight='main']`);
  if (!parentDiv) return;
  // Get the first child element of the parent div
  const from = parentDiv.querySelector(':first-child');
  const to = parentDiv.querySelector(':last-child');
  loopThroughDivs(from, to, (currentDiv) => {
    currentDiv.classList.remove('on-highlighted');
    currentDiv.classList.remove('highlight-focus');
  });
  clearSelection();
}

const addOnHighlightedClass = ({
  from,
  to
}: {
  from: Element;
  to: Element;
}) => {
  loopThroughDivs(from, to, (currentDiv) => {
    currentDiv.classList.add('on-highlighted');
  });
};

const toggleHighlightClass = ({
  from,
  to,
  className,
  isActive
}: {
  from: Element;
  to: Element;
  className: string;
  isActive: boolean;
}) => {
  if (isActive) {
    loopThroughDivs(from, to, (currentDiv) => {
      if (currentDiv.classList.contains('dragging')) return;
      if (currentDiv.classList.contains('paragraph-separator')) return;
      currentDiv.classList.add(className);
    });
  } else {
    loopThroughDivs(from, to, (currentDiv) => {
      if (currentDiv.classList.contains('dragging')) return;
      if (currentDiv.classList.contains('paragraph-separator')) return;
      currentDiv.classList.remove(className);
    });
  }
};

function isElementBefore(element, sibling) {
  return (
    element.compareDocumentPosition(sibling) & Node.DOCUMENT_POSITION_FOLLOWING
  );
}

function isElementAfter(element, sibling) {
  return (
    element.compareDocumentPosition(sibling) & Node.DOCUMENT_POSITION_PRECEDING
  );
}

const HOVER_CLASS_LIST = ['highlighted', 'highlight-hover'];
const HIGHLIGHT_DIVIDER_CLASS_LIST = [
  HIGHLIGHT_DIVIDER,
  '!cursor-col-resize',
  'highlighted',
  'inline-block',
  'py-[2px]',
  'text-base',
  'font-500'
];

const findFurtherestDiv = (startingDiv: Element, direction: 'from' | 'to') => {
  if (!startingDiv) return;
  let currentDiv = startingDiv as Element;
  const furtherestElement =
    direction === 'from'
      ? startingDiv.parentElement.firstElementChild
      : startingDiv.parentElement.lastElementChild;
  const startingDivIndex = startingDiv.getAttribute('data-item');
  while (currentDiv && currentDiv !== furtherestElement) {
    if (
      currentDiv.classList.contains(HIGHLIGHT_DIVIDER) &&
      currentDiv.getAttribute('data-item') !== `${startingDivIndex}-divider`
    )
      break;
    currentDiv =
      direction === 'from'
        ? currentDiv.previousElementSibling
        : currentDiv.nextElementSibling;
  }
  const currentHighlights = getCurrentHighlightedDivs(
    startingDiv as HTMLDivElement
  );
  if (direction === 'from') {
    return {
      leftmostDiv: ignoreParagraphSeparator(
        currentDiv || furtherestElement,
        'to'
      ),
      rightmostDiv: ignoreParagraphSeparator(
        currentHighlights.endingDiv.previousElementSibling,
        'from'
      )
    };
  } else {
    return {
      leftmostDiv: ignoreParagraphSeparator(
        currentHighlights.startingDiv.nextElementSibling,
        'to'
      ),
      rightmostDiv: ignoreParagraphSeparator(
        currentDiv || furtherestElement,
        'from'
      )
    };
  }
};

const isDividerDiv = (element: Element | null): boolean => {
  return element !== null && element.classList.contains(HIGHLIGHT_DIVIDER);
};

const ignoreParagraphSeparator = (elem: Element, direction: 'from' | 'to') => {
  if (!elem?.classList.contains(PARAGRAPH_SEPARATOR)) return elem;

  const nextElement =
    direction === 'from'
      ? elem.previousElementSibling
      : elem.nextElementSibling;
  return nextElement ? ignoreParagraphSeparator(nextElement, direction) : elem;
};

const ignoreBR = (elem: Element, direction: 'from' | 'to') => {
  if (!(elem instanceof HTMLBRElement)) return elem;

  const nextElement =
    direction === 'from'
      ? elem.previousElementSibling
      : elem.nextElementSibling;
  return nextElement ? ignoreBR(nextElement, direction) : elem;
};
function getRightWithoutPadding(element: HTMLElement) {
  const rect = element.getBoundingClientRect();
  const style = window.getComputedStyle(element);

  // Parse the padding values
  const paddingRight = parseFloat(style.paddingRight);

  // Calculate the right edge without padding
  return rect.right - paddingRight;
}

function isAtLineEnd(element) {
  const rect = element.getBoundingClientRect();
  const next = element.nextElementSibling;

  if (!next) return true; // If no next sibling, it's considered the end of the line

  const nextRect = next.getBoundingClientRect();

  // If the `top` position of the next element is different, the current element is at the end of the line
  return rect.top !== nextRect.top;
}

function isFirstOnLine(element) {
  const rect = element.getBoundingClientRect();
  const previous = element.previousElementSibling;

  if (!previous) return true; // If no previous sibling, it's the first on the line

  const prevRect = previous.getBoundingClientRect();

  // If the `top` of the current element is different from the previous, it's on a new line
  return rect.top !== prevRect.top;
}

function findNextHighlightedToDivider(currentElement) {
  let nextElement = currentElement.nextElementSibling;

  // Traverse the DOM until a highlighted-to div is found or no more siblings exist
  while (nextElement) {
    if (nextElement.classList.contains('highlighted-to-divider')) {
      return nextElement; // Found the first highlighted-to element
    }
    nextElement = nextElement.nextElementSibling;
  }

  return null; // No highlighted-to element found after the current element
}

const hasStocksInBetween = (fromElement: Element, toElement: Element) => {
  let hasCurrentStocks = false;
  loopThroughDivs(fromElement, toElement, (currentDiv) => {
    if (currentDiv.classList.contains('highlighted')) hasCurrentStocks = true;
  });
  return hasCurrentStocks;
};

export {
  getRightWithoutPadding,
  isAtLineEnd,
  isFirstOnLine,
  findNextHighlightedToDivider,
  cleanupSelection,
  clearSelection,
  getTextBetweenDivs,
  loopThroughDivs,
  findFurtherestDiv,
  addOnHighlightedClass,
  isElementBefore,
  isElementAfter,
  getCurrentHighlightedDivs,
  getFirstAndLastElementFromSelection,
  HIGHLIGHT_DIVIDER_CLASS_LIST,
  toggleHighlightClass,
  isDividerDiv,
  ignoreParagraphSeparator,
  ignoreBR,
  HOVER_CLASS_LIST,
  hasStocksInBetween
};
