interface Option {
  type: string;
  text: string;
}

interface LanguageOption extends Option {
  model: Model
}

type Model = 'whisper' | 'aws';

const MODEL_OPTIONS: Option[] = [
  {
    type: 'whisper',
    text: 'Whisper <div className="inline text-grey-400 duration-0 group-hover:text-inherit">(Recommended)</div>'
  },
  {
    type: 'aws',
    text: 'AWS <div className="inline text-grey-400 duration-0 group-hover:text-inherit">(Alternative)</div>'
  }
];

const LANGUAGE_OPTIONS: Record<Model, LanguageOption[]> = {
  whisper:
    [
      { type: 'auto', text: 'Detected Language', model: 'whisper' },
      { type: 'af', text: 'Afrikaans', model: 'whisper' },
      { type: 'sq', text: 'Albanian', model: 'whisper' },
      { type: 'am', text: 'Amharic', model: 'whisper' },
      { type: 'ar', text: 'Arabic', model: 'whisper' },
      { type: 'hy', text: 'Armenian', model: 'whisper' },
      { type: 'as', text: 'Assamese', model: 'whisper' },
      { type: 'az', text: 'Azerbaijani', model: 'whisper' },
      { type: 'ba', text: 'Bashkir', model: 'whisper' },
      { type: 'eu', text: 'Basque', model: 'whisper' },
      { type: 'be', text: 'Belarusian', model: 'whisper' },
      { type: 'bn', text: 'Bengali', model: 'whisper' },
      { type: 'bs', text: 'Bosnian', model: 'whisper' },
      { type: 'br', text: 'Breton', model: 'whisper' },
      { type: 'bg', text: 'Bulgarian', model: 'whisper' },
      { type: 'my', text: 'Burmese', model: 'whisper' },
      { type: 'yue', text: 'Cantonese', model: 'whisper' },
      { type: 'es', text: 'Castilian', model: 'whisper' },
      { type: 'ca', text: 'Catalan', model: 'whisper' },
      { type: 'zh', text: 'Chinese', model: 'whisper' },
      { type: 'hr', text: 'Croatian', model: 'whisper' },
      { type: 'cs', text: 'Czech', model: 'whisper' },
      { type: 'da', text: 'Danish', model: 'whisper' },
      { type: 'nl', text: 'Dutch', model: 'whisper' },
      { type: 'en', text: 'English', model: 'whisper' },
      { type: 'et', text: 'Estonian', model: 'whisper' },
      { type: 'fo', text: 'Faroese', model: 'whisper' },
      { type: 'fi', text: 'Finnish', model: 'whisper' },
      { type: 'nl', text: 'Flemish', model: 'whisper' },
      { type: 'fr', text: 'French', model: 'whisper' },
      { type: 'gl', text: 'Galician', model: 'whisper' },
      { type: 'ka', text: 'Georgian', model: 'whisper' },
      { type: 'de', text: 'German', model: 'whisper' },
      { type: 'el', text: 'Greek', model: 'whisper' },
      { type: 'gu', text: 'Gujarati', model: 'whisper' },
      { type: 'ht', text: 'Haitian', model: 'whisper' },
      { type: 'ha', text: 'Hausa', model: 'whisper' },
      { type: 'haw', text: 'Hawaiian', model: 'whisper' },
      { type: 'he', text: 'Hebrew', model: 'whisper' },
      { type: 'hi', text: 'Hindi', model: 'whisper' },
      { type: 'hu', text: 'Hungarian', model: 'whisper' },
      { type: 'is', text: 'Icelandic', model: 'whisper' },
      { type: 'id', text: 'Indonesian', model: 'whisper' },
      { type: 'it', text: 'Italian', model: 'whisper' },
      { type: 'ja', text: 'Japanese', model: 'whisper' },
      { type: 'jw', text: 'Javanese', model: 'whisper' },
      { type: 'kn', text: 'Kannada', model: 'whisper' },
      { type: 'kk', text: 'Kazakh', model: 'whisper' },
      { type: 'km', text: 'Khmer', model: 'whisper' },
      { type: 'ko', text: 'Korean', model: 'whisper' },
      { type: 'lo', text: 'Lao', model: 'whisper' },
      { type: 'la', text: 'Latin', model: 'whisper' },
      { type: 'lv', text: 'Latvian', model: 'whisper' },
      { type: 'lb', text: 'Letzeburgesch', model: 'whisper' },
      { type: 'ln', text: 'Lingala', model: 'whisper' },
      { type: 'lt', text: 'Lithuanian', model: 'whisper' },
      { type: 'lb', text: 'Luxembourgish', model: 'whisper' },
      { type: 'mk', text: 'Macedonian', model: 'whisper' },
      { type: 'mg', text: 'Malagasy', model: 'whisper' },
      { type: 'ms', text: 'Malay', model: 'whisper' },
      { type: 'ml', text: 'Malayalam', model: 'whisper' },
      { type: 'mt', text: 'Maltese', model: 'whisper' },
      { type: 'zh', text: 'Mandarin', model: 'whisper' },
      { type: 'mi', text: 'Maori', model: 'whisper' },
      { type: 'mr', text: 'Marathi', model: 'whisper' },
      { type: 'ro', text: 'Moldavian', model: 'whisper' },
      { type: 'mn', text: 'Mongolian', model: 'whisper' },
      { type: 'my', text: 'Myanmar', model: 'whisper' },
      { type: 'ne', text: 'Nepali', model: 'whisper' },
      { type: 'no', text: 'Norwegian', model: 'whisper' },
      { type: 'nn', text: 'Nynorsk', model: 'whisper' },
      { type: 'oc', text: 'Occitan', model: 'whisper' },
      { type: 'pa', text: 'Panjabi', model: 'whisper' },
      { type: 'ps', text: 'Pashto', model: 'whisper' },
      { type: 'fa', text: 'Persian', model: 'whisper' },
      { type: 'pl', text: 'Polish', model: 'whisper' },
      { type: 'pt', text: 'Portuguese', model: 'whisper' },
      { type: 'pa', text: 'Punjabi', model: 'whisper' },
      { type: 'ps', text: 'Pushto', model: 'whisper' },
      { type: 'ro', text: 'Romanian', model: 'whisper' },
      { type: 'ru', text: 'Russian', model: 'whisper' },
      { type: 'sa', text: 'Sanskrit', model: 'whisper' },
      { type: 'sr', text: 'Serbian', model: 'whisper' },
      { type: 'sn', text: 'Shona', model: 'whisper' },
      { type: 'sd', text: 'Sindhi', model: 'whisper' },
      { type: 'si', text: 'Sinhala', model: 'whisper' },
      { type: 'si', text: 'Sinhalese', model: 'whisper' },
      { type: 'sk', text: 'Slovak', model: 'whisper' },
      { type: 'sl', text: 'Slovenian', model: 'whisper' },
      { type: 'so', text: 'Somali', model: 'whisper' },
      { type: 'es', text: 'Spanish', model: 'whisper' },
      { type: 'su', text: 'Sundanese', model: 'whisper' },
      { type: 'sw', text: 'Swahili', model: 'whisper' },
      { type: 'sv', text: 'Swedish', model: 'whisper' },
      { type: 'tl', text: 'Tagalog', model: 'whisper' },
      { type: 'tg', text: 'Tajik', model: 'whisper' },
      { type: 'ta', text: 'Tamil', model: 'whisper' },
      { type: 'tt', text: 'Tatar', model: 'whisper' },
      { type: 'te', text: 'Telugu', model: 'whisper' },
      { type: 'th', text: 'Thai', model: 'whisper' },
      { type: 'bo', text: 'Tibetan', model: 'whisper' },
      { type: 'tr', text: 'Turkish', model: 'whisper' },
      { type: 'tk', text: 'Turkmen', model: 'whisper' },
      { type: 'uk', text: 'Ukrainian', model: 'whisper' },
      { type: 'ur', text: 'Urdu', model: 'whisper' },
      { type: 'uz', text: 'Uzbek', model: 'whisper' },
      { type: 'ca', text: 'Valencian', model: 'whisper' },
      { type: 'vi', text: 'Vietnamese', model: 'whisper' },
      { type: 'cy', text: 'Welsh', model: 'whisper' },
      { type: 'yi', text: 'Yiddish', model: 'whisper' },
      { type: 'yo', text: 'Yoruba', model: 'whisper' }
    ],
  aws: [
    { type: 'auto', text: 'Detected Language', model: 'aws' },
    { text: 'Abkhaz', type: 'ab-GE', model: 'aws' },
    { text: 'Afrikaans', type: 'af-ZA', model: 'aws' },
    { text: 'Arabic, Gulf', type: 'ar-AE', model: 'aws' },
    { text: 'Arabic, Modern Standard', type: 'ar-SA', model: 'aws' },
    { text: 'Armenian', type: 'hy-AM', model: 'aws' },
    { text: 'Asturian', type: 'ast-ES', model: 'aws' },
    { text: 'Azerbaijani', type: 'az-AZ', model: 'aws' },
    { text: 'Bashkir', type: 'ba-RU', model: 'aws' },
    { text: 'Basque', type: 'eu-ES', model: 'aws' },
    { text: 'Belarusian', type: 'be-BY', model: 'aws' },
    { text: 'Bengali', type: 'bn-IN', model: 'aws' },
    { text: 'Bosnian', type: 'bs-BA', model: 'aws' },
    { text: 'Bulgarian', type: 'bg-BG', model: 'aws' },
    { text: 'Catalan', type: 'ca-ES', model: 'aws' },
    { text: 'Central Kurdish, Iran', type: 'ckb-IR', model: 'aws' },
    { text: 'Central Kurdish, Iraq', type: 'ckb-IQ', model: 'aws' },
    { text: 'Chinese, Simplified', type: 'zh-CN', model: 'aws' },
    { text: 'Chinese, Traditional', type: 'zh-TW', model: 'aws' },
    { text: 'Croatian', type: 'hr-HR', model: 'aws' },
    { text: 'Czech', type: 'cs-CZ', model: 'aws' },
    { text: 'Danish', type: 'da-DK', model: 'aws' },
    { text: 'Dutch', type: 'nl-NL', model: 'aws' },
    { text: 'English, Australian', type: 'en-AU', model: 'aws' },
    { text: 'English, British', type: 'en-GB', model: 'aws' },
    { text: 'English, Indian', type: 'en-IN', model: 'aws' },
    { text: 'English, Irish', type: 'en-IE', model: 'aws' },
    { text: 'English, New Zealand', type: 'en-NZ', model: 'aws' },
    { text: 'English, Scottish', type: 'en-AB', model: 'aws' },
    { text: 'English, South African', type: 'en-ZA', model: 'aws' },
    { text: 'English, US', type: 'en-US', model: 'aws' },
    { text: 'English, Welsh', type: 'en-WL', model: 'aws' },
    { text: 'Estonian', type: 'et-ET', model: 'aws' },
    { text: 'Farsi', type: 'fa-IR', model: 'aws' },
    { text: 'Finnish', type: 'fi-FI', model: 'aws' },
    { text: 'French', type: 'fr-FR', model: 'aws' },
    { text: 'French, Canadian', type: 'fr-CA', model: 'aws' },
    { text: 'Galician', type: 'gl-ES', model: 'aws' },
    { text: 'Georgian', type: 'ka-GE', model: 'aws' },
    { text: 'German', type: 'de-DE', model: 'aws' },
    { text: 'German, Swiss', type: 'de-CH', model: 'aws' },
    { text: 'Greek', type: 'el-GR', model: 'aws' },
    { text: 'Gujarati', type: 'gu-IN', model: 'aws' },
    { text: 'Hausa', type: 'ha-NG', model: 'aws' },
    { text: 'Hebrew', type: 'he-IL', model: 'aws' },
    { text: 'Hindi, Indian', type: 'hi-IN', model: 'aws' },
    { text: 'Hungarian', type: 'hu-HU', model: 'aws' },
    { text: 'Icelandic', type: 'is-IS', model: 'aws' },
    { text: 'Indonesian', type: 'id-ID', model: 'aws' },
    { text: 'Italian', type: 'it-IT', model: 'aws' },
    { text: 'Japanese', type: 'ja-JP', model: 'aws' },
    { text: 'Kabyle', type: 'kab-DZ', model: 'aws' },
    { text: 'Kannada', type: 'kn-IN', model: 'aws' },
    { text: 'Kazakh', type: 'kk-KZ', model: 'aws' },
    { text: 'Kinyarwanda', type: 'rw-RW', model: 'aws' },
    { text: 'Korean', type: 'ko-KR', model: 'aws' },
    { text: 'Kyrgyz', type: 'ky-KG', model: 'aws' },
    { text: 'Latvian', type: 'lv-LV', model: 'aws' },
    { text: 'Lithuanian', type: 'lt-LT', model: 'aws' },
    { text: 'Luganda', type: 'lg-IN', model: 'aws' },
    { text: 'Macedonian', type: 'mk-MK', model: 'aws' },
    { text: 'Malay', type: 'ms-MY', model: 'aws' },
    { text: 'Malayalam', type: 'ml-IN', model: 'aws' },
    { text: 'Maltese', type: 'mt-MT', model: 'aws' },
    { text: 'Marathi', type: 'mr-IN', model: 'aws' },
    { text: 'Meadow Mari', type: 'mhr-RU', model: 'aws' },
    { text: 'Mongolian', type: 'mn-MN', model: 'aws' },
    { text: 'Norwegian Bokmål', type: 'no-NO', model: 'aws' },
    { text: 'Odia/Oriya', type: 'or-IN', model: 'aws' },
    { text: 'Pashto', type: 'ps-AF', model: 'aws' },
    { text: 'Polish', type: 'pl-PL', model: 'aws' },
    { text: 'Portuguese', type: 'pt-PT', model: 'aws' },
    { text: 'Portuguese, Brazilian', type: 'pt-BR', model: 'aws' },
    { text: 'Punjabi', type: 'pa-IN', model: 'aws' },
    { text: 'Romanian', type: 'ro-RO', model: 'aws' },
    { text: 'Russian', type: 'ru-RU', model: 'aws' },
    { text: 'Serbian', type: 'sr-RS', model: 'aws' },
    { text: 'Sinhala', type: 'si-LK', model: 'aws' },
    { text: 'Slovak', type: 'sk-SK', model: 'aws' },
    { text: 'Slovenian', type: 'sl-SI', model: 'aws' },
    { text: 'Somali', type: 'so-SO', model: 'aws' },
    { text: 'Spanish', type: 'es-ES', model: 'aws' },
    { text: 'Spanish, US', type: 'es-US', model: 'aws' },
    { text: 'Sundanese', type: 'su-ID', model: 'aws' },
    { text: 'Swahili, Kenya', type: 'sw-KE', model: 'aws' },
    { text: 'Swahili, Burundi', type: 'sw-BI', model: 'aws' },
    { text: 'Swahili, Rwanda', type: 'sw-RW', model: 'aws' },
    { text: 'Swahili, Tanzania', type: 'sw-TZ', model: 'aws' },
    { text: 'Swahili, Uganda', type: 'sw-UG', model: 'aws' },
    { text: 'Swedish', type: 'sv-SE', model: 'aws' },
    { text: 'Tagalog/Filipino', type: 'tl-PH', model: 'aws' },
    { text: 'Tamil', type: 'ta-IN', model: 'aws' },
    { text: 'Tatar', type: 'tt-RU', model: 'aws' },
    { text: 'Telugu', type: 'te-IN', model: 'aws' },
    { text: 'Thai', type: 'th-TH', model: 'aws' },
    { text: 'Turkish', type: 'tr-TR', model: 'aws' },
    { text: 'Ukrainian', type: 'uk-UA', model: 'aws' },
    { text: 'Uyghur', type: 'ug-CN', model: 'aws' },
    { text: 'Uzbek', type: 'uz-UZ', model: 'aws' },
    { text: 'Vietnamese', type: 'vi-VN', model: 'aws' },
    { text: 'Welsh', type: 'cy-WL', model: 'aws' },
    { text: 'Wolof', type: 'wo-SN', model: 'aws' },
    { text: 'Zulu', type: 'zu-ZA', model: 'aws' }
  ]
}

export { MODEL_OPTIONS, LANGUAGE_OPTIONS };
export type { Option, Model, LanguageOption };
