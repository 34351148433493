import { ModalProvider } from '../../hooks/useModal';
import { NewJPButton, Size } from '../GeneralComponents';
import { PageNames } from '../../utils/generalUtilities';
import { WaitlistModal } from '../AiVideoHome/WaitlistModal';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { useUser } from '../../hooks/useUser';
import Image from 'next/image';
import Menu from '../Shared/Menu';
const mic = require('../../assets/images/landing/mic.svg');
const slideshow = require('../../assets/images/landing/slideshow.svg');

const CreateNewButton = ({
  withIcon,
  size,
  width = 'full'
}: {
  withIcon?: boolean;
  size?: Size;
  width?: 'fit' | 'full';
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const router = useRouter();
  const { isOldPaidUser, user } = useUser();

  // Wait for user data to load before enabling the button
  useEffect(() => {
    if (user !== undefined) {
      setIsLoading(false);
    }
  }, [user]);

  const updateModalOpen = (value: boolean) => {
    setIsModalOpen(value);
  };

  const menuOptions = isOldPaidUser ? [
    {
      title: 'Magic Edit',
      isVisible: true,
      menuItemClass: 'hover:!bg-purple-100',
      image: <span className="mr-3 text-2xl">🎩</span>,
      onClick: () => router.push({ pathname: PageNames.MAGIC })
    },
    {
      title: 'AI Video',
      isVisible: true,
      image: (
        <Image
          className="mr-3"
          alt="slideshow"
          loading={'lazy'}
          src={slideshow}
        />
      ),
      onClick: () => router.push({ pathname: PageNames.CREATE_SLIDESHOW })
    },
    {
      title: 'Audiogram',
      isVisible: true,
      image: <Image className="mr-3" alt="mic" loading={'lazy'} src={mic} />,
      onClick: () => router.push({ pathname: PageNames.CREATE })
    }
  ] : [
    {
      title: 'Magic Edit',
      isVisible: true,
      menuItemClass: 'hover:!bg-purple-100',
      image: <span className="mr-3 text-2xl">🎩</span>,
      onClick: () => router.push({ pathname: PageNames.MAGIC })
    }
  ];

  return (
    <div className="relative w-full">
      <NewJPButton
        size={size || 'md'}
        text={isLoading ? 'Loading...' : 'Create new'}
        width={width}
        icon={withIcon ? (isLoading ? 'ft-loader' : 'mi-add') : null}
        disabled={isLoading}
        onClick={() => {
          if (isOldPaidUser) {
            setIsMenuOpen(!isMenuOpen);
          } else {
            router.push({ pathname: PageNames.MAGIC });
          }
        }}
        className="justify-center px-5 py-2.5"
      />
      {isMenuOpen ? (
        <Menu
          isMenuOpen={isMenuOpen}
          onLeave={() => setIsMenuOpen(false)}
          customContainerStyle={{
            top: 60,
            right: 0,
            width: 163
          }}
          menuOptions={menuOptions}
        />
      ) : null}

      {isModalOpen && (
        <WaitlistModal
          isModalOpen={isModalOpen}
          updateModalOpen={updateModalOpen}
        />
      )}
    </div>
  );
};

export default CreateNewButton;
