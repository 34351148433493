import { FPS, MAX_NUMBER_OF_LINES } from '../Video';
import {
  GenerateSlideshowVideoParams,
  SubtitleItemWithPreference
} from '../types';
import { interpolate, useCurrentFrame, useVideoConfig } from 'remotion';

const createSpotifySubtitleArray = (
  subtitlesContent: SubtitleItemWithPreference[]
): SubtitleItemWithPreference[][] => {
  if (!subtitlesContent || subtitlesContent.length === 0) return [];
  let collatedSubs: SubtitleItemWithPreference[][] = [];
  for (let i = 0; i < subtitlesContent.length; i += MAX_NUMBER_OF_LINES)
    collatedSubs.push(subtitlesContent.slice(i, i + MAX_NUMBER_OF_LINES));
  return collatedSubs;
};

const useCalculateOpacity = () => {
  const frame = useCurrentFrame();
  const videoConfig = useVideoConfig();
  return interpolate(
    frame,
    [videoConfig.durationInFrames - 25, videoConfig.durationInFrames - 15],
    [1, 0],
    {
      extrapolateLeft: 'clamp',
      extrapolateRight: 'clamp'
    }
  );
};

/**
 * Get  the duration in seconds of the video
 * @async
 * @param src path to the audio file
 * @return {number} duration of the audio file in seconds
 */
const getVideoDuration = (inputProps: GenerateSlideshowVideoParams) => {
  if (!inputProps || !inputProps.assets) return 10;
  if (inputProps.duration) return inputProps.duration;
  const duration = Number(inputProps.duration) || 10; const assetDuration = inputProps.assets.reduce((maxDuration, { f, t }) => {
    if (f === 'Infinity' || t === 'Infinity') {
      return maxDuration; // Skip this iteration if either is Infinity
    }

    const duration = Number(t) - Number(f);
    return Math.max(maxDuration, duration);
  }, 0);
  return duration > assetDuration ? duration : assetDuration;
  // if (!inputProps.assets || !inputProps.assets.length) return FPS;
  // const latestAsset = inputProps.assets.reduce((prev, current) =>
  //   prev.f > current.f ? prev : current
  // );
  // return Number(latestAsset.t);
  // const latestScript = inputProps.subtitlesContent.reduce((prev, current) =>
  //   prev.f > current.f ? prev : current
  // );
  // if (!latestAsset && !latestAsset) return 10;
  // const res = [latestAsset, latestScript].reduce((prev, current) =>
  //   Number(prev.f) + Number(prev.t) > Number(current.f) + Number(current.t)
  //     ? prev
  //     : current
  // );
  // return Number(res.f) + Number(res.t);
};

export { createSpotifySubtitleArray, useCalculateOpacity, getVideoDuration };
