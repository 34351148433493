import { Asset } from '../types';
import {
  BLENDED_TOP_CONTAINER_HEIGHT,
  MASK_IMAGE_GRADIENT
} from '../utils/constant';
import {
  Loop,
  OffthreadVideo,
  Sequence,
  continueRender,
  delayRender,
  interpolate,
  spring,
  useCurrentFrame,
  useVideoConfig
} from 'remotion';
import { OffthreadVideoProps } from 'remotion/dist/cjs/video/props';
import { PausableVideo } from './PausableVideo';
import { cacheS3Url } from '../../utils/s3';
import { getCompressedVideoUrl, isUserUploadedVideo } from '../utils/asset';
import { getVideoMetadata } from '@remotion/media-utils';
import { isVideoBuffering } from '../../recoil/atoms/previewPanel.atoms';
import { useRecoilState } from 'recoil';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import calculateFPS from '../utils/FPS';
import usePromisedVideoCache from '../../hooks/useVideoCache';

// Add animation presets at the top of the file
export const ANIMATION_PRESETS = {
  'scale-up': {
    transform: (frame: number, fps: number): string =>
      `scale(${spring({
        fps,
        frame,
        config: { damping: 12, mass: 0.5 },
        from: 0.8,
        to: 1
      })})`,
    opacity: (frame: number): number =>
      interpolate(frame, [0, 15], [0, 1], {
        extrapolateRight: 'clamp'
      })
  },
  'scale-down': {
    transform: (frame: number, fps: number): string =>
      `scale(${spring({
        fps,
        frame,
        config: { damping: 12, mass: 0.5 },
        from: 1.2,
        to: 1
      })})`,
    opacity: (frame: number): number =>
      interpolate(frame, [0, 15], [0, 1], {
        extrapolateRight: 'clamp'
      })
  },
  'slide-from-left': {
    transform: (frame: number, fps: number): string => {
      const x = spring({
        fps,
        frame,
        config: { damping: 15, mass: 0.8 },
        from: -100,
        to: 0
      });
      return `translateX(${x}%) scale(1)`;
    },
    opacity: (frame: number): number =>
      interpolate(frame, [0, 15], [0, 1], {
        extrapolateRight: 'clamp'
      })
  },
  'slide-from-right': {
    transform: (frame: number, fps: number): string => {
      const x = spring({
        fps,
        frame,
        config: { damping: 15, mass: 0.8 },
        from: 100,
        to: 0
      });
      return `translateX(${x}%) scale(1)`;
    },
    opacity: (frame: number): number =>
      interpolate(frame, [0, 15], [0, 1], {
        extrapolateRight: 'clamp'
      })
  },
  'quick-zoom-in': {
    transform: (frame: number, fps: number): string => {
      const zoomDuration = 1; // Duration of the zoom-in animation in seconds
      const zoomFrames = calculateFPS(zoomDuration); // Number of frames for the zoom-in animation
      const finalZoom = 1.3;
      if (frame < zoomFrames) {
        const zoomIn = spring({
          fps,
          frame,
          config: { damping: 25, stiffness: 180 },
          from: 1,
          to: finalZoom
        });
        return `scale(${zoomIn})`;
      } else {
        return `scale(${finalZoom})`; // Maintain the zoomed-in state after the animation
      }
    },
    opacity: (): number => 1
  },
  'zoom-in-zoom-out': {
    transform: (
      frame: number,
      fps: number,
      durationInFrames: number
    ): string => {
      const zoomInDuration = 1; // Duration of the zoom-in animation in seconds
      const zoomOutDuration = 1; // Duration of the zoom-out animation in seconds
      const zoomInFrames = calculateFPS(zoomInDuration); // Number of frames for the zoom-in animation
      const zoomOutFrames = calculateFPS(zoomOutDuration); // Number of frames for the zoom-out animation
      const initialZoom = 1.3;

      if (frame < zoomInFrames) {
        const zoomIn = spring({
          fps,
          frame,
          config: { damping: 25, stiffness: 180 },
          from: 1,
          to: initialZoom
        });
        return `scale(${zoomIn})`;
      } else if (frame >= durationInFrames - zoomOutFrames) {
        const zoomOut = spring({
          fps,
          frame: frame - (durationInFrames - zoomOutFrames),
          config: { damping: 25, stiffness: 180 },
          from: initialZoom,
          to: 1
        });
        return `scale(${zoomOut})`;
      } else {
        return `scale(${initialZoom})`; // Maintain the zoomed-in state between zoom-in and zoom-out
      }
    },
    opacity: (): number => 1
  },
  'quick-zoom-out': {
    transform: (frame: number, fps: number): string => {
      const zoomDuration = 1; // Duration of the zoom-in animation in seconds
      const zoomFrames = calculateFPS(zoomDuration); // Number of frames for the zoom-in animation
      const initialZoom = 2;
      const finalZoom = 1;
      if (frame < zoomFrames) {
        const zoomOut = spring({
          fps,
          frame,
          config: { damping: 25, stiffness: 180 },
          from: initialZoom,
          to: finalZoom
        });
        return `scale(${zoomOut})`;
      } else {
        return `scale(${finalZoom})`; // Maintain the zoomed-in state after the animation
      }
    },
    opacity: (): number => 1
  }
};

export const LoopedOffthreadVideo: React.FC<
  OffthreadVideoProps & {
    src: string;
  }
> = ({ src, ...props }) => {
  const [handle] = useState(() => delayRender());
  const [durationInSeconds, setDurationInSeconds] = useState(null);

  const fetchData = useCallback(async () => {
    try {
      const { durationInSeconds: d } = await Promise.race([
        getVideoMetadata(src) as any,
        new Promise((resolve) =>
          setTimeout(() => resolve({ durationInSeconds: 5 }), 10000)
        )
      ]);
      setDurationInSeconds(d);
    } catch (error) {
      setDurationInSeconds(5);
    } finally {
      continueRender(handle);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, []);
  if (durationInSeconds === null) {
    return null;
  }
  return (
    <Loop durationInFrames={calculateFPS(durationInSeconds)}>
      <OffthreadVideo src={src} {...props} />
    </Loop>
  );
};

const VideoContainer = ({
  asset,
  index,
  muted,
  durationInFrames,
  isMainVideo,
  filter,
  startFrom,
  endAt,
  preferredTransition,
  renderType
}: {
  asset: Asset;
  index?: number;
  muted?: boolean;
  durationInFrames: number;
  isMainVideo: boolean;
  filter?: {
    filter: string;
    category: string;
  };
  startFrom?: number;
  endAt?: number;
  preferredTransition?: keyof typeof ANIMATION_PRESETS | false;
  renderType: 'player' | 'export';
}) => {
  const [url, setUrl] = useState(
    cacheS3Url(asset.url) ||
      'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/blank-white.png'
  );
  const [isVideoBufferingGetter, isVideoBufferingSetter] =
    useRecoilState(isVideoBuffering);
  const { videoBlobUrl, fetchVideoBlob } = usePromisedVideoCache(url);

  useEffect(() => {
    const fetchVideo = async () => {
      try {
        isVideoBufferingSetter(true);
        await fetchVideoBlob();
      } catch (error) {
        console.log('error fetching videoContainer', error);
      } finally {
        isVideoBufferingSetter(false);
      }
    };
    if (!url || renderType === 'export') return;
    fetchVideo();
  }, [asset.url]);

  useEffect(() => {
    if (!videoBlobUrl || renderType === 'export') return;
    setUrl(videoBlobUrl);
  }, [videoBlobUrl]);

  const frame = useCurrentFrame();
  const { fps } = useVideoConfig();

  // Add a state to track the current transition type
  const [currentTransition, setCurrentTransition] = useState<string | null>(
    null
  );

  // Select animation preset based on preferredTransition or randomize if not provided
  const [animationPreset] = useState(() => {
    if (preferredTransition) {
      return ANIMATION_PRESETS[preferredTransition];
    }
    const isBrollBlendedTop =
      asset.preferences?.object_fit === 'contain-blended-top';
    const presets = isBrollBlendedTop
      ? [ANIMATION_PRESETS['scale-up'], ANIMATION_PRESETS['scale-down']]
      : Object.values(ANIMATION_PRESETS);
    const selectedPreset = presets[Math.floor(Math.random() * presets.length)];

    // Set the current transition type
    setCurrentTransition(
      Object.keys(ANIMATION_PRESETS).find(
        (key) => ANIMATION_PRESETS[key] === selectedPreset
      ) || null
    );

    return selectedPreset;
  });

  // Calculate transform based on selected preset and preferredTransition
  const transform = useMemo(() => {
    if (isMainVideo) return 'scale(1)';
    if (!preferredTransition) return 'scale(1)';
    return animationPreset.transform(frame, fps, durationInFrames);
  }, [frame, fps, isMainVideo, animationPreset, preferredTransition]);

  // Calculate opacity combining fade-in, fade-out and transition settings
  const opacity = useMemo(() => {
    if (isMainVideo) return 1;
    if (!preferredTransition) return 1;
    // Skip fade-in and fade-out for quickZoomIn
    if (currentTransition === 'quick-zoom-in') return 1;
    if (currentTransition === 'quick-zoom-out') return 1;

    const fadeIn = animationPreset.opacity(frame);
    const fadeOut = interpolate(
      frame,
      [durationInFrames - 15, durationInFrames],
      [1, 0],
      {
        extrapolateLeft: 'clamp',
        extrapolateRight: 'clamp'
      }
    );

    return fadeIn * fadeOut;
  }, [
    frame,
    durationInFrames,
    isMainVideo,
    animationPreset,
    preferredTransition
  ]);

  const style: React.CSSProperties = useMemo(() => {
    switch (asset.preferences?.object_fit) {
      case 'contain-blended-top':
        return {
          width: '100%',
          height: BLENDED_TOP_CONTAINER_HEIGHT,
          objectFit: 'cover',
          position: 'absolute',
          top: '0%',
          left: '0%',
          maskImage: MASK_IMAGE_GRADIENT,
          filter: filter?.filter,
          transform,
          opacity
        };
      default:
        return {
          width:
            asset.preferences && asset.preferences.w
              ? `${asset.preferences.w}%`
              : '100%',
          height:
            asset.preferences && asset.preferences.h
              ? `${asset.preferences.h}%`
              : '100%',
          top:
            asset.preferences && asset.preferences.y
              ? `${asset.preferences.y}%`
              : 0,
          left:
            asset.preferences && asset.preferences.x
              ? `${asset.preferences.x}%`
              : 0,
          objectFit: 'cover',
          position: 'absolute',
          filter: filter?.filter,
          transform,
          opacity
        };
    }
  }, [asset.preferences, transform, opacity, filter]);

  // You can now use currentTransition for additional logic
  useEffect(() => {
    if (currentTransition) {
      console.log(`Current transition: ${currentTransition}`);
      // Add any additional logic based on the current transition
    }
  }, [currentTransition]);

  return (
    <Sequence key={`video-container-${index}`}>
      {isMainVideo ? (
        <PausableVideo
          startFrom={startFrom}
          endAt={endAt}
          style={style}
          src={
            url ||
            'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/10_sec.mp4'
          }
          onError={() =>
            setUrl(
              'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/10_sec.mp4'
            )
          }
        />
      ) : (
        <LoopedOffthreadVideo
          startFrom={startFrom}
          endAt={endAt}
          muted={muted !== undefined ? muted : true}
          style={style}
          src={
            url ||
            'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/10_sec.mp4'
          }
          onError={() =>
            setUrl(
              'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/10_sec.mp4'
            )
          }
        />
      )}
    </Sequence>
  );
};

export default VideoContainer;
