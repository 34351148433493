import { DimensionType } from '../slideshow/types';
import { LocalStorageItems, getItem } from './generalUtilities';
import {
  MAGIC_VIDEO_MAX_DURATION,
  MAGIC_VIDEO_MIN_DURATION,
  MAX_FILE_DURATION_IN_MINS,
  MAX_FILE_SIZE_IN_MB
} from '@/models/constant';
import { MediaFileType } from '@/types/media';
import { audiourlToBlob } from '../components/Shared/RecorderComponents';
import { cacheS3Url } from './s3';
import { toast } from 'react-toastify';
import { v4 as uuidv4 } from 'uuid';
import AudioHelper, { AudioDTO } from '../models/Audio';
import Media from '../models/Media';
import locale from '../locales/en/NativeLanding';
import mime from 'mime';

const blobToFile = async (blob: Blob) => {
  if (!blob || !blob.type) return null;
  const ext = blob.type.split('.').pop() || 'txt'; // get the file extension
  const filename = new Date().toISOString() + `.${ext}`;
  return new File([blob], filename, {
    type: blob.type
  });
};

const uploadAudioAsset = async (
  urlOrFile: File,
  isPremium: boolean = false
): Promise<AudioDTO> => {
  try {
    let file: File;
    if (urlOrFile instanceof File) file = urlOrFile;
    else file = await audiourlToBlob(urlOrFile);
    const audioId = uuidv4();
    const resUpload = await Media.uploadFile(
      getItem(LocalStorageItems.ACCESS_TOKEN),
      file,
      `${audioId}.${getFileExtensionFromFileName(file.name)}`
    );
    const answerRecording = resUpload.postResponse.location as string;

    const info = await isFileValid(file);
    if (!info.success) return;
    const audio = await AudioHelper.createAudio(answerRecording, audioId);
    return audio;
  } catch (err) {
    console.error(err);
    return err;
  }
};

interface FileInfo {
  success: boolean;
  duration: number;
  width?: number;
  height?: number;
}

const isFileLengthValid = async (file: File): Promise<FileInfo> =>
  new Promise(async (resolve, reject) => {
    if (file.type.startsWith('audio')) {
      let aElement: HTMLAudioElement | HTMLVideoElement;
      aElement = document.createElement('audio');
      let reader = new FileReader();
      reader.onload = function (e) {
        aElement.src = e.target.result as string;
        aElement.addEventListener(
          'loadedmetadata',
          function () {
            // Obtain the duration in seconds of the audio file (with milliseconds as well, a float value)
            var duration = aElement.duration;

            console.log(
              'The duration of the song is of: ' + duration + ' seconds'
            );
            if (duration == Infinity)
              return resolve({ success: false, duration });
            if (duration && duration < MAX_FILE_DURATION_IN_MINS * 60)
              return resolve({ success: true, duration });
            return resolve({ success: false, duration });
          },
          false
        );
        aElement.onerror = function (e) {
          return reject(e);
        };
      };
      reader.readAsDataURL(file);
    } else {
      let vElement: HTMLVideoElement;
      vElement = document.createElement('video');
      vElement.preload = 'metadata';
      vElement.onloadedmetadata = () => {
        URL.revokeObjectURL(vElement.src);
        if (vElement.duration == Infinity)
          return resolve({ success: false, duration: vElement.duration });
        if (
          vElement.duration &&
          vElement.duration < MAX_FILE_DURATION_IN_MINS * 60
        ) {
          return resolve({
            success: true,
            duration: vElement.duration,
            width: vElement.videoWidth,
            height: vElement.videoHeight
          });
        }
        return resolve({
          success: false,
          duration: vElement.duration,
          width: vElement.videoWidth,
          height: vElement.videoHeight
        });
      };
      vElement.onerror = (e) => {
        URL.revokeObjectURL(vElement.src);
        reject(e);
      };
      vElement.src = URL.createObjectURL(file);
    }
  });

type IValidateFileSuccess = {
  success: true;
  data: FileInfo;
  fileType: MediaFileType;
};
export type IValidateFile = IValidateFileSuccess | { success: false };

const isFileUrlValid = async (url: string): Promise<IValidateFile> => {
  let blob = await fetch(url).then((r) => r.blob());
  return await isFileValid(blob as File);
};
const isFileValid = async (
  file: File,
  isMagic = false
): Promise<IValidateFile> => {
  try {
    const sizeValid = isFileSizeValid(file);
    if (!sizeValid) return { success: false };

    /* Length Check */
    const isLengthValid = await isFileLengthValid(file);
    if (!isLengthValid || !isLengthValid.success) {
      if (isLengthValid.duration != Infinity) {
        toast.error(locale.toaster.infinity_duration_error);
        return { success: false };
      }
    }

    // Add magic-specific duration checks
    if (isMagic) {
      if (isLengthValid.duration < MAGIC_VIDEO_MIN_DURATION) {
        toast.error('Video must be at least 5 seconds long');
        return { success: false };
      }
      if (isLengthValid.duration > MAGIC_VIDEO_MAX_DURATION) {
        toast.error('Video must be less than 3 minutes long');
        return { success: false };
      }
    }

    /* File Type Check */
    const temp = file.type.split(';')[0]; // extract the MIME type without the charset
    const fileType = temp.split('/')[0] as MediaFileType;
    if (fileType === 'image') {
      toast.error(locale.toaster.wrong_file_format_error);
      return { success: false };
    }
    return { success: true, data: isLengthValid, fileType };
  } catch (err) {
    console.error(err);
    toast.error(err.message || locale.toaster.infinity_duration_error);
    return { success: false };
  }
};

const isFileSizeValid = (file: File) => {
  if (file.type === 'video/quicktime' && getFileSizeInMB(file.size) > 100) {
    toast.error(locale.toaster.mov_too_large_error);
    return false;
  }
  if (getFileSizeInMB(file.size) > MAX_FILE_SIZE_IN_MB) {
    toast.error(locale.toaster.big_file_size_error);
    return false;
  }
  return true;
};

const calculateDimension = ({
  width,
  height
}: {
  width: number;
  height: number;
}): DimensionType => {
  if (!width || !height) return DimensionType.portrait;
  const temp = width / height;
  if (temp < 0.9) return DimensionType.portrait;
  if (temp > 1.1) return DimensionType.landscape;
  return DimensionType.square;
};

const getFileExtensionFromFileName = (pathName: string) => {
  try {
    const lastDotIndex = pathName.lastIndexOf('.');
    if (lastDotIndex > 0 && lastDotIndex < pathName.length - 1) {
      return pathName.substring(lastDotIndex + 1);
    }
  } catch (error) {
    console.error('Error parsing URL for extension:', error);
  }
  return '';
};

function getFileExtensionFromUrl(url: string) {
  try {
    // Create a URL object to easily access parts of the URL.
    const urlObj = new URL(url);

    // Isolate the pathname, which includes the filename.
    const pathname = urlObj.pathname;

    // Find the last dot in the pathname to locate the extension.
    const lastDotIndex = pathname.lastIndexOf('.');

    // Ensure there is a dot, and it is not the last character in the pathname.
    if (lastDotIndex > 0 && lastDotIndex < pathname.length - 1) {
      // Extract from the dot to the end of the pathname or next slash.
      const endOfExtensionIndex = pathname.indexOf('/', lastDotIndex);
      if (endOfExtensionIndex === -1) {
        return pathname.substring(lastDotIndex + 1);
      } else {
        return pathname.substring(lastDotIndex + 1, endOfExtensionIndex);
      }
    }
  } catch (error) {
    console.error('Error parsing URL:', error);
  }
  return '';
}

const getMediaTypeFromFileUrl = async (url: string): Promise<MediaFileType> => {
  try {
    const response = await fetch(url);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const contentType = response.headers.get('Content-Type');
    const temp = contentType.split(';')[0]; // extract the MIME type without the charset
    return temp.split('/')[0] as MediaFileType;
  } catch (error) {
    console.error('Error fetching file:', error);
  }
};

function splitFile(file, partSize) {
  const fileParts = [];
  let partStart = 0;

  while (partStart < file.size) {
    const partEnd = Math.min(partStart + partSize, file.size);
    const filePart = file.slice(partStart, partEnd);
    fileParts.push(filePart);
    partStart = partEnd;
  }

  return fileParts;
}

async function getContentTypeFromRemoteUrl(remoteUrl) {
  try {
    const response = await fetch(remoteUrl);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const contentType = response.headers.get('content-type');

    if (contentType) {
      return contentType;
    } else {
      throw new Error('Content-Type header not found in the response.');
    }
  } catch (error) {
    console.error('Error fetching content type:', error);
    return null; // Return null in case of an error
  }
}
function getFileSizeInMB(fileSize: number): number {
  const sizeInMB = parseFloat((fileSize / (1024 * 1024)).toFixed(2));
  return sizeInMB;
}

function getImageFileDimensions(file: File) {
  let height = null;
  let width = null;
  const reader = new FileReader();
  reader.onload = function (e) {
    const img = new Image();
    img.onload = function () {
      // Set the dimensions
      width = img.width;
      height = img.height;
    };
  };
  reader.readAsDataURL(file);
  return { height, width };
}
const isFileDurationValid = (duration: number) => {
  // Check if the duration is a valid number
  if (isNaN(duration) || !isFinite(duration) || duration <= 0) {
    return false; // Duration is invalid
  }

  return true;
};

const getMediaDurationFromUrl = async (url: string): Promise<number> => {
  try {
    const blob = await fetch(url).then((r) => r.blob());
    const lengthValidation = await isFileLengthValid(blob as File);

    if (!lengthValidation.success) {
      if (lengthValidation.duration === Infinity) {
        toast.error(locale.toaster.infinity_duration_error);
      }
      throw new Error('Invalid media duration');
    }

    if (!isFileDurationValid(lengthValidation.duration)) {
      toast.error(locale.toaster.infinity_duration_error);
      throw new Error('Invalid media duration');
    }

    return lengthValidation.duration;
  } catch (err) {
    console.error('Error getting media duration:', err);
    throw err;
  }
};

export {
  getMediaTypeFromFileUrl,
  getFileExtensionFromFileName,
  getFileExtensionFromUrl,
  uploadAudioAsset,
  isFileValid,
  isFileUrlValid,
  calculateDimension,
  blobToFile,
  splitFile,
  getContentTypeFromRemoteUrl,
  getFileSizeInMB,
  getImageFileDimensions,
  isFileSizeValid,
  isFileDurationValid,
  isFileLengthValid,
  getMediaDurationFromUrl
};

export type { IValidateFileSuccess };
