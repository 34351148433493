import { AbsoluteFill, Audio } from 'remotion';
import {
  Asset,
  Dimension,
  DimensionType,
  GenerateSlideshowVideoParams,
  SlideshowPreferences
} from '../types';
import { AudioContainer, ImageContainer } from '../components';
import {
  DEFAULT_SUBTITLES_STYLES_OPTIONS,
  FONT_FAMILY,
  SLIDESHOW_FILLER_SECONDS
} from '../utils/constant';
import { DefaultOption } from './DefaultOption';
import { FPS } from '../Video';
import { Footer } from '../components/Footer';
import { Gif } from '@remotion/gif';
import { PlayerRef } from '@remotion/player';
import { Sequence, getRemotionEnvironment } from 'remotion';
import { SpringText } from '../components/SpringText';
import { SubtitleOptions } from '../../types/MediaManager';
import { TextOutput } from '../../audiogram/components/TextSequence';
import { fetchAndCacheVideoBlob } from '@/utils/cache';
import {
  getDimensionByName,
  horizontalSpaceMultiplier
} from '../utils/dimension';
import { isStockVideo, isUserUploadedVideo } from '../utils/asset';
import { isUploadedUrl } from '../utils/s3';
import { isVideoBuffering } from '../../recoil/atoms/previewPanel.atoms';
import { sortBy } from 'lodash';
import { toast } from 'react-toastify';
import { useCalculateOpacity } from '../utils/general';
import { useRecoilValue } from 'recoil';
import EffectContainer from '../components/EffectContainer';
import React, { FunctionComponent, useEffect, useMemo } from 'react';
import Spinner from '../..//components/Shared/Spinner';
import VideoContainer from '../components/VideoContainer';
import calculateFPS from '../utils/FPS';

export const PODART_FONT_SIZE = 54;

const AssetContainer = ({
  asset,
  id,
  dimensionInPx,
  filter,
  slideshowPreferences
}: {
  asset: Asset;
  id: number;
  dimensionInPx: Dimension;
  filter: {
    filter: string;
    category: string;
  };
  slideshowPreferences: SlideshowPreferences;
}) => {
  let type = asset.type;
  /* Using Image instead of GIF if asset is webp */
  if (asset.url && asset.url.includes('.webp')) type = 'image';

  const { isRendering, isPlayer, isStudio } = getRemotionEnvironment();

  const isMainVideo = useMemo(() => {
    // const durationMatches =
    //   Number(asset.t) - Number(asset.f) === Number(totalDuration);
    return (
      !!asset &&
      !!asset.url &&
      isUploadedUrl(asset.url) &&
      !isStockVideo(asset.url) &&
      !isUserUploadedVideo(asset.url)
    );
  }, [asset]);

  if (asset.hidden) return null;

  switch (type) {
    case 'image':
    case 'web_image': {
      const from = Math.max(0, Number(asset.f) - SLIDESHOW_FILLER_SECONDS);
      const to = Number(asset.t) + SLIDESHOW_FILLER_SECONDS;
      const durationInFrames = calculateFPS(to - from);

      return (
        <Sequence
          premountFor={90}
          name={`Image ${id}`}
          key={id}
          from={calculateFPS(from)}
          durationInFrames={durationInFrames}
          style={{
            zIndex:
              asset.preferences && asset.preferences.z
                ? asset.preferences.z
                : '2'
          }}
        >
          <ImageContainer index={id} asset={asset} />
        </Sequence>
      );
    }
    case 'video': {
      let from = Math.max(0, Number(asset.f) - SLIDESHOW_FILLER_SECONDS);
      let to = Number(asset.t);
      if (!isMainVideo) {
        to = to + SLIDESHOW_FILLER_SECONDS;
      }

      // Extend duration if less than 1 second
      if (to - from < 1) {
        from = Math.max(0, from - 1);
        to = to + 1;
      }

      const durationInFrames = calculateFPS(to - from);
      return (
        <Sequence
          // premountFor={150}
          name={`Video ${id}`}
          premountFor={90}
          key={id}
          from={isMainVideo ? 0 : calculateFPS(Number(from))}
          durationInFrames={durationInFrames}
          style={{
            zIndex:
              asset.preferences && asset.preferences.z
                ? asset.preferences.z
                : isMainVideo
                ? '0'
                : '2'
          }}
        >
          {isPlayer && (
            <>
              <VideoContainer
                muted={!isMainVideo}
                index={id}
                asset={asset}
                isMainVideo={isMainVideo}
                durationInFrames={Math.max(1, durationInFrames)}
                filter={filter}
                {...(!slideshowPreferences?.activateTransition && {
                  preferredTransition: false
                })}
                renderType={'player'}
              />
            </>
          )}

          {(isStudio || isRendering) && (
            <VideoContainer
              muted={!isMainVideo}
              index={id}
              asset={asset}
              isMainVideo={isMainVideo}
              filter={filter}
              durationInFrames={Math.max(1, durationInFrames)}
              {...(!slideshowPreferences?.activateTransition && {
                preferredTransition: false
              })}
              renderType={'export'}
            />
          )}
        </Sequence>
      );
    }
    case 'audio':
      return <AudioContainer index={id} asset={asset} />;
    case 'gif': {
      const from = Math.max(0, Number(asset.f) - SLIDESHOW_FILLER_SECONDS);
      const to = Number(asset.t) + SLIDESHOW_FILLER_SECONDS;

      // Check if the video is uploaded by user
      const durationInFrames = calculateFPS(to - from);
      return (
        <Sequence
          premountFor={90}
          name={`Gif ${id}`}
          key={id}
          from={calculateFPS(Number(from))}
          durationInFrames={durationInFrames}
          style={{
            zIndex:
              asset.preferences && asset.preferences.z
                ? asset.preferences.z
                : '0',
            position: 'fixed',
            backgroundColor: 'black'
          }}
        >
          <Gif
            src={asset.url}
            width={dimensionInPx.W}
            height={dimensionInPx.H}
            fit="contain"
          />
        </Sequence>
      );
    }
    case 'hook': {
      const from = Math.max(0, Number(asset.f));
      const to = Number(asset.t);
      const durationInFrames = calculateFPS(to - from);

      if (!slideshowPreferences?.activateHook) return null;

      return (
        <Sequence
          name={`Hook ${id}`}
          key={id}
          from={calculateFPS(Number(from + 1))}
          durationInFrames={durationInFrames}
          style={{
            zIndex: asset.preferences?.z || '10'
          }}
        >
          <SpringText
            text={asset.text}
            durationInFrames={durationInFrames}
            type="hook"
          />
        </Sequence>
      );
    }
    case 'effect': {
      return (
        <EffectContainer
          asset={asset}
          id={id}
          slideshowPreferences={slideshowPreferences}
          renderType={isPlayer ? 'player' : 'export'}
        />
      );
    }
    case 'overlay': {
      const from = Math.max(0, Number(asset.f));
      const to = Number(asset.t);
      const durationInFrames = calculateFPS(to - from);

      return (
        <Sequence
          premountFor={90}
          name={`Overlay ${id}`}
          key={id}
          from={calculateFPS(Number(from))}
          durationInFrames={durationInFrames}
          style={{
            zIndex:
              asset.preferences && asset.preferences.z
                ? asset.preferences.z
                : '3',
            fontFamily: asset.overlay_preferences.fontFamily
          }}
        >
          <AbsoluteFill
            style={{
              backgroundColor:
                asset.overlay_preferences?.backgroundType === 'full'
                  ? asset.overlay_preferences.backgroundColor
                  : 'transparent'
            }}
            className="relative w-full"
          >
            <div className={`flex`}>
              <div
                style={{
                  ...asset.overlay_preferences.textPosition,
                  fontSize: asset.overlay_preferences.fontSize || 74,
                  position: 'absolute',
                  top: '5%',
                  left: '5%',
                  right: '5%',
                  bottom: '5%',
                  display: 'flex'
                }}
                className={'font-bold leading-relaxed'}
              >
                <div
                  style={{
                    textAlign: 'center',
                    color: asset.overlay_preferences.textColor,
                    backgroundColor:
                      asset.overlay_preferences.backgroundType === 'block'
                        ? asset.overlay_preferences.backgroundColor
                        : 'transparent',
                    position: 'relative',
                    display: 'flex',
                    fontFamily: FONT_FAMILY(
                      asset?.overlay_preferences?.fontFamily
                    )
                  }}
                >
                  {asset.original_keyword || 'Text Overlay Preview'}
                </div>
              </div>
            </div>
          </AbsoluteFill>
        </Sequence>
      );
    }
    case 'emoji': {
      // Don't render if emoji is disabled
      if (!slideshowPreferences?.activateEmoji) {
        return <></>;
      }

      const from = Math.max(0, Number(asset.f));
      const to = Number(asset.t);
      const durationInFrames = calculateFPS(to - from);
      const rotationValues = [10, 20, -10, 0, -20];
      const rotation = rotationValues[id % 5];

      const leftValues = [18, 48, 78, 28, 58, 68];
      const bottomValues = [31, 29, 30, 34, 32, 33];
      const left = leftValues[id % 6];
      const bottom = bottomValues[id % 6];

      return (
        <Sequence
          premountFor={90}
          name={`Emoji ${id}`}
          key={id}
          from={calculateFPS(Number(from))}
          durationInFrames={durationInFrames}
          style={{
            zIndex: asset.preferences?.z || '30'
          }}
        >
          <div
            style={{
              transform: `rotate(${rotation}deg)`,
              bottom: `${bottom}%`,
              left: `${left}%`,
              filter: 'drop-shadow(2px 2px 4px rgba(0,0,0,0.3))',
              position: 'absolute'
            }}
          >
            <SpringText
              text={asset.e}
              durationInFrames={durationInFrames}
              style={{
                fontSize: '130px',
                color: '#FFFFFF'
              }}
              fromSize={0.3}
              type="emoji"
            />
          </div>
        </Sequence>
      );
    }
    // case 'a-roll-transition': {
    //   if (slideshowPreferences?.activateTransition) {
    //     return null;
    //   }
    //   let from = Math.max(0, Number(asset.f));
    //   let to = Number(asset.t);
    //   // Extend duration if less than 1 second
    //   if (to - from < 1) {
    //     from = Math.max(0, from - 1);
    //     to = to + 1;
    //   }

    //   const durationInFrames = calculateFPS(to - from);
    //   return (
    //     <Sequence
    //       premountFor={90}
    //       name={`A-Roll Video Transition ${asset.id}`}
    //       from={calculateFPS(from)}
    //       durationInFrames={durationInFrames}
    //     >
    //       <ARollVideoTransitionContainer asset={asset} from={from} to={to} />
    //     </Sequence>
    //   );
    // }
    case 'outro': {
      const from = Math.max(0, Number(asset.f));
      const to = Number(asset.t);
      const durationInFrames = calculateFPS(to - from);

      return (
        <Sequence
          name={`Outro ${id}`}
          key={id}
          from={calculateFPS(Number(from))}
          durationInFrames={durationInFrames}
          style={{
            zIndex: '1000'
          }}
        >
          {asset.mediaType === 'video' ? (
            <VideoContainer
              muted={true} // mute outro videos
              index={id}
              asset={asset}
              isMainVideo={false}
              durationInFrames={Math.max(1, durationInFrames)}
              preferredTransition={false} // No transition for outros
              renderType={isPlayer ? 'player' : 'export'}
            />
          ) : (
            <ImageContainer index={id} asset={asset} />
          )}
        </Sequence>
      );
    }
    default:
      return <div key={id}></div>;
  }
};

const syncAdjacentAssets = ({
  assets,
  subtitles
}: {
  assets: Asset[];
  subtitles: TextOutput[];
}) => {
  /* TODO: Fix the case where subtitles are not passed */
  if (!subtitles || !subtitles.length) return assets;
  return assets.map((currentAsset, i) => {
    if (i === 0) return currentAsset; // No adjustments needed for the first asset

    const prevAsset = assets[i - 1];

    // Find the words corresponding to the assets
    const prevWordIndex = subtitles.findIndex(
      (sub) => parseFloat(sub.t) === parseFloat(prevAsset.t)
    );
    const currentWordIndex = subtitles.findIndex(
      (sub) => parseFloat(sub.f) === parseFloat(currentAsset.f)
    );

    // If the current word is immediately after the previous word
    if (
      prevWordIndex >= 0 &&
      currentWordIndex >= 0 &&
      currentWordIndex === prevWordIndex + 1
    ) {
      return {
        ...currentAsset,
        f: (parseFloat(prevAsset.t) + 0.01).toString()
      };
    }

    return currentAsset;
  });
};

export const SlideshowRender: FunctionComponent<any> = (
  props: GenerateSlideshowVideoParams & {
    videoPreviewRef: React.RefObject<PlayerRef>;
  }
) => {
  useEffect(() => {
    if (!props) return;
  }, [props]);

  const { subtitlesContent, assets, preferences } = props;

  const _assets = useMemo(() => {
    return syncAdjacentAssets({
      assets: sortBy(assets, (a) => parseFloat(a.f)),
      subtitles: subtitlesContent
    });
  }, [assets, subtitlesContent?.length]);

  const isVideoBufferingGetter = useRecoilValue(isVideoBuffering);

  const updatedPreferencesWithDefaults = {
    ...DEFAULT_SUBTITLES_STYLES_OPTIONS,
    ...preferences
  };

  const getSelectedSubtitleConfiguration = () => {
    switch (preferences.subtitleType) {
      case SubtitleOptions.NONE:
        return null;
      case SubtitleOptions.CC:
      case SubtitleOptions.REELS:
      default:
        return (
          <DefaultOption
            preferences={updatedPreferencesWithDefaults}
            subtitlesContent={subtitlesContent}
          />
        );
    }
  };

  const watermark = props.watermark?.url
    ? {
        url: props.watermark.url,
        size: props.watermark.size || 50,
        position: props.watermark.position || {
          justifyContent: 'flex-end',
          alignItems: 'flex-end'
        }
      }
    : null;

  const dimensionType = useMemo(() => {
    return preferences && preferences.dimensionType
      ? preferences.dimensionType
      : DimensionType.square;
  }, [preferences?.dimensionType]);

  const opacity = useCalculateOpacity();

  const dimensionInPx = useMemo(() => {
    return getDimensionByName(dimensionType);
  }, [dimensionType]);

  if (!props || !preferences || !assets) return <></>;

  return (
    <div className="flex h-full w-full items-center bg-black">
      {props?.music?.url && props.preferences?.activateBgm && (
        <Audio
          loop
          src={props?.music.url}
          volume={(props?.music?.volume || 5) / 100}
          startFrom={0}
          endAt={props.duration * FPS}
        />
      )}

      <div
        className="relative flex flex-1 flex-col"
        style={{
          fontFamily: FONT_FAMILY(preferences.fontFamily),
          opacity: opacity
        }}
      >
        <div
          style={{
            height: dimensionInPx.H
          }}
          className={`relative flex flex-col items-center justify-center pl-[${
            48 * horizontalSpaceMultiplier(dimensionType)
          }] pr-[${48 * horizontalSpaceMultiplier(dimensionType)}]`}
        >
          {isVideoBufferingGetter && (
            <div className="absolute z-50 flex h-full w-full items-center justify-center bg-black bg-opacity-20">
              <Spinner className="!h-24 !w-24" />
            </div>
          )}

          {_assets.map((asset, index) => (
            <AssetContainer
              dimensionInPx={dimensionInPx}
              asset={asset}
              id={index}
              key={`asset-container-${index}`}
              filter={props?.filter}
              slideshowPreferences={preferences}
            />
          ))}
          {preferences.hideSubtitles ? null : (
            <div className="flex h-[18%] max-w-[1552px] items-center">
              {getSelectedSubtitleConfiguration()}
            </div>
          )}
        </div>
        <Footer
          dimensionType={dimensionType}
          hidePromotion={preferences.hidePromotion}
          watermark={watermark}
          horizontalPadding={48 * horizontalSpaceMultiplier(dimensionType)}
        />
      </div>
    </div>
  );
};
