const DEFAULT_AVATAR = require('./images/default_avatar.svg');
const DEFAULT_AVATAR_URL =
  'https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/landing/default_avatar.svg';
const recordButton = require('./images/icons/record/recordButton.svg');
const stopButton = require('./images/icons/record/stopButton.svg');
const pauseButton = require('./images/icons/record/pauseButton.svg');
const playButton = require('./images/icons/record/playButton.svg');

const googleIcon = require('./images/icons/googleIcon.svg');
const emailIcon = require('./images/icons/emailIcon.svg');
const pauseIcon = require('./images/icons/pauseIcon.svg');
const playIcon = require('./images/icons/playIcon.svg');
const waveformIcon = require('./images/icons/waveformIcon.svg');

const chromeLogo = require('./images/icons/chromeLogo.svg');
const safariLogo = require('./images/icons/safariLogo.svg');

const editIcon = require('./images/icons/editIcon.svg');
const shareIcon = require('./images/icons/shareIcon.svg');
const closeButton = require('./images/icons/close__grey.svg');
const copyIcon = require('./images/icons/copyIcon.svg');

const selectedCheck = require('./images/icons/selectedCheck.svg');
const unselectedCheck = require('./images/icons/unselectedCheck.svg');

const tickYellow = require('./images/icons/tick__yellow.svg');

const logoHorizontal = require('./images/LogoHori.png');
const logoSquare = require('./images/LogoSquare.svg');

const loadingSvg = require('./images/loading.svg');
const loadingWhiteSvg = require('./images/loading__white.svg');

const videoTemplateDefault = require('./images/template/videoTemplateDefault.jpg');
const videoTemplateWithImage = require('./images/template/videoTemplateWithImage.jpg');
const videoTemplateProductHunt = require('./images/template/videoTemplateProductHunt.jpg');
const videoTemplateFullSize = require('./images/template/videoTemplateFullSize.jpg');
const videoTemplatePodcast = require('./images/template/videoTemplatePodcast.jpg');
const videoTemplatePodArt = require('./images/template/videoTemplatePodArt.jpg');
const videoTemplateDiagonal = require('./images/template/videoTemplateDiagonal.jpg');
const videoTemplateCurvyLine = require('./images/template/videoTemplateCurvyLine.jpg');

const sampleJupitrrSharing = require('./images/landing-old/sample.png');
const sampleSlideshowSharing = require('./images/landing/sample.png');
const curlyArrowDown = require('./images/create/arrow.svg');

const metaTag = `https://jupitrr-admin.s3.ap-southeast-1.amazonaws.com/website/SEO/metaTag.jpg`;

const france = require('./images/flags/France.jpg');
const germany = require('./images/flags/Germany.jpg');
const india = require('./images/flags/India.jpg');
const italy = require('./images/flags/Italy.jpg');
const russia = require('./images/flags/Russia.jpg');
const southKorea = require('./images/flags/SouthKorea.jpg');
const spain = require('./images/flags/Spain.jpg');
const unitedStates = require('./images/flags/USA.jpg');

const dimensionInstagram = require('./images/dimensions/dimensionInstagram.svg');
const dimensionYouTube = require('./images/dimensions/dimensionYoutube.svg');
const dimensionTikTok = require('./images/dimensions/dimensionTikTok.svg');

const dimensionInstagramSelected = require('./images/dimensions/dimensionInstagramSelected.svg');
const dimensionYouTubeSelected = require('./images/dimensions/dimensionYoutubeSelected.svg');
const dimensionTikTokSelected = require('./images/dimensions/dimensionTikTokSelected.svg');

const proPlanIcon = require('./images/icons/ProPlanIcon.svg');
const premiumBannerPic = require('./images/premium_banner.png');

const logoWithName = require('./images/icons/new_jupitrr_logo.svg');


export {
  DEFAULT_AVATAR,
  DEFAULT_AVATAR_URL,
  recordButton,
  stopButton,
  pauseButton,
  playButton,
  googleIcon,
  emailIcon,
  pauseIcon,
  playIcon,
  waveformIcon,
  chromeLogo,
  safariLogo,
  editIcon,
  shareIcon,
  closeButton,
  copyIcon,
  selectedCheck,
  unselectedCheck,
  logoHorizontal,
  logoSquare,
  loadingSvg,
  loadingWhiteSvg,
  videoTemplateDefault,
  videoTemplateWithImage,
  videoTemplateProductHunt,
  videoTemplateFullSize,
  videoTemplatePodcast,
  sampleJupitrrSharing,
  sampleSlideshowSharing,
  videoTemplatePodArt,
  videoTemplateDiagonal,
  videoTemplateCurvyLine,
  curlyArrowDown,
  metaTag,
  france,
  germany,
  india,
  italy,
  russia,
  southKorea,
  spain,
  unitedStates,
  dimensionInstagram,
  dimensionYouTube,
  dimensionTikTok,
  dimensionInstagramSelected,
  dimensionYouTubeSelected,
  dimensionTikTokSelected,
  tickYellow,
  proPlanIcon,
  premiumBannerPic,
  logoWithName
};
