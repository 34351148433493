import { IAssetFS, IAssetOverlay, OverlayStyle } from '../slideshow/types';
import { LocalStorageItems, getItem } from '../utils/generalUtilities';
import { MediaFileType } from '@/types/media';
import {
  blobToFile,
  getFileExtensionFromFileName,
  getMediaTypeFromFileUrl
} from '../utils/file';
import { firestore } from '../models/firebase';
import { toast } from 'react-toastify';
import Media from '../models/Media';
import _ from 'lodash';
import shortUUID from 'short-uuid';
import slideshowFirestoreHelper from './slideshowHelper';

const AssetManager = {
  data: null,
  collectionPath: 'assets',
  createAssetOverlay: async function (
    textData: string,
    userId: string,
    assetId: string,
    slideshowId: string,
    overlay_style: OverlayStyle
  ): Promise<{ success: boolean; asset: IAssetOverlay }> {
    try {
      const newAsset: IAssetOverlay = {
        id: assetId,
        slideshow_id: slideshowId,
        author_id: userId,
        type: 'overlay',
        url: '',
        status: 'uploaded',
        subtitle_status: { raw_uploaded: new Date().toISOString() },
        created_at: new Date().toISOString(),
        last_updated: new Date().toISOString(),
        overlay_preferences: overlay_style,
        textData: textData
      };
      this.data = newAsset;
      await firestore
        .collection(this.collectionPath)
        .doc(newAsset.id)
        .set(this.data);
      return { success: true, asset: newAsset };
    } catch (err) {
      console.error(err);
      return err;
    }
  },
  createAssetViaBlob: async function ({
    userId,
    assetId,
    slideshowId,
    fileUrl,
    fileType,
    hdUrl,
    sdUrl,
    source,
    silenceIds
  }: {
    userId: string;
    assetId: string;
    slideshowId: string;
    fileUrl: string;
    fileType: MediaFileType;
    hdUrl?: string;
    sdUrl?: string;
    source?: 'getty' | 'pexels' | 'web_image' | 'gif';
    silenceIds?: string[];
  }): Promise<{ success: boolean; asset: IAssetFS }> {
    try {
      const newAsset: IAssetFS = {
        id: assetId,
        slideshow_id: slideshowId,
        author_id: userId,
        type: fileType,
        url: fileUrl,
        hd_url: hdUrl || fileUrl,
        sd_url: sdUrl || fileUrl,
        status: 'uploaded',
        subtitle_status: { raw_uploaded: new Date().toISOString() },
        created_at: new Date().toISOString(),
        last_updated: new Date().toISOString(),
        silenceIds: silenceIds || [],
        ...(source && { source })
      };
      this.data = newAsset;
      await firestore
        .collection(this.collectionPath)
        .doc(newAsset.id)
        .set(this.data);
      return { success: true, asset: newAsset };
    } catch (err) {
      console.error(err);
      return err;
    }
  },
  createAssetViaRemoteUrl: async function (
    userId: string,
    slideshowId: string,
    url: string,
    uploadToS3: boolean = false
  ): Promise<{ success: boolean; asset: IAssetFS }> {
    try {
      let fileUrl: string = url;
      const response = await fetch(url);
      const blob = await response.blob();
      const assetId = shortUUID.generate();

      if (uploadToS3) {
        const file: File = await blobToFile(blob);
        const resUpload = await Media.uploadFile(
          getItem(LocalStorageItems.ACCESS_TOKEN),
          file,
          `slideshow-${slideshowId}--${assetId}.${getFileExtensionFromFileName(
            file.name
          )}`
        );
        fileUrl = resUpload.postResponse.location as string;
      }

      let fileType = await getMediaTypeFromFileUrl(fileUrl);

      const newAsset: IAssetFS = {
        id: assetId,
        slideshow_id: slideshowId,
        author_id: userId,
        type: fileType,
        url: fileUrl,
        subtitle_status: { raw_uploaded: new Date().toISOString() },
        status: 'uploaded',
        created_at: new Date().toISOString(),
        last_updated: new Date().toISOString()
      };
      this.data = newAsset;
      await firestore
        .collection(this.collectionPath)
        .doc(this.data.id)
        .set(this.data);
      if (fileType === 'audio' || fileType === 'video') {
        await Media.requestSubtitle(userId, slideshowId, fileUrl);
      }
      return { success: true, asset: newAsset };
    } catch (err) {
      console.error(err);
      return err;
    }
  },
  updateAssetStatus: async function (assetId: string, status: string) {
    await firestore
      .collection(this.collectionPath)
      .doc(assetId)
      .update({ status, last_updated: new Date().toISOString() });
  },
  randomizeAsset: async function (assetId: string): Promise<IAssetFS> {
    const snap = await firestore
      .collection(this.collectionPath)
      .doc(assetId)
      .get();
    const asset = snap.data() as IAssetFS;
    if (!asset || !asset.potential_medias || !asset.potential_medias.length) {
      toast.error('No other available videos found for this asset');
      return null;
    }
    if (!asset.abandoned_medias) asset.abandoned_medias = [];
    asset.abandoned_medias.push(asset.url);
    const slideshow = await slideshowFirestoreHelper.getDataFromFirestore(
      asset.slideshow_id
    );
    let newMedia = asset.potential_medias.find(
      (m) =>
        (m.hd_url || m.url) &&
        (m.hd_url || m.url) !== asset.url &&
        !asset.abandoned_medias.includes(m.hd_url || m.url) &&
        slideshow.assets.every(
          (asset) => asset.url && asset.url !== (m.hd_url || m.url)
        )
    );
    if (!newMedia) newMedia = asset.potential_medias[0];
    let valuesToUpdate = {
      abandoned_medias: asset.abandoned_medias,
      url: newMedia.url,
      last_updated: new Date().toISOString()
    };
    await firestore
      .collection(this.collectionPath)
      .doc(assetId)
      .update(valuesToUpdate);
    return _.merge(asset, valuesToUpdate);
  },
  updateAsset: async function (
    assetId: string,
    valuesToUpdate: Partial<IAssetFS>
  ): Promise<void> {
    try {
      await firestore
        .collection(this.collectionPath)
        .doc(assetId)
        .update(valuesToUpdate);
    } catch (err) {
      console.error(err);
    }
  }
};
export default AssetManager;
