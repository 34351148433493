// db.ts
import { IDBPDatabase, openDB } from 'idb';

const DB_NAME = 'video-cache';
const STORE_NAME = 'videos';

const MAX_VIDEOS = 6; // 5 assets + 1 main video.

export const initDB = async (): Promise<IDBPDatabase> => {
  return openDB(DB_NAME, 1, {
    upgrade(db) {
      if (!db.objectStoreNames.contains(STORE_NAME)) {
        db.createObjectStore(STORE_NAME);
      }
    }
  });
};

export const cacheVideoBlob = async (key: string, blob: Blob): Promise<IDBValidKey> => {
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);
  // This is intentional and we want to store all videos for the current slideshow.
  const result = await store.put({ blob, timestamp: Date.now() }, key);
  await tx.done;
  return result;
};

export const getCachedVideoBlob = async (key: string): Promise<Blob | undefined> => {
  if (!key) return undefined;
  const db = await initDB();
  const tx = db.transaction(STORE_NAME, 'readonly');
  const store = tx.objectStore(STORE_NAME);
  const result = await store.get(key);
  await tx.done;
  return result;
};

export const clearCache = async () => {
  const db = await initDB();
  await db.clear(STORE_NAME);
};
