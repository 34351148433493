import { CallbackListener, PlayerRef } from '@remotion/player';
import { useEffect, useState } from 'react';

export const useCurrentPlayerFrame = (
  ref: React.RefObject<PlayerRef>,
  isMainVideoLoaded: boolean
) => {
  const [currentFrame, setCurrentFrame] = useState<number>(0);

  useEffect(() => {
    const player = ref.current;
    if (!player) return;

    const handleFrameUpdate: CallbackListener<'frameupdate'> = ({ detail }) => {
      setCurrentFrame(detail.frame);
    };

    player.addEventListener('frameupdate', handleFrameUpdate);

    return () => {
      player.removeEventListener('frameupdate', handleFrameUpdate);
    };
  }, [ref, isMainVideoLoaded]);

  return currentFrame;
};
