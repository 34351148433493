import * as Select from '@radix-ui/react-select';
import {
  LANGUAGE_OPTIONS,
  LanguageOption,
  MODEL_OPTIONS,
  Option
} from '@/models/languages';
import { NewJPButton } from '../GeneralComponents';
import { PageNames } from '@/utils/generalUtilities';
import { getMediaTypeFromFileUrl } from '@/utils/file';
import { toast } from 'react-toastify';
import { useModal } from '@/hooks/useModal';
import { useState } from 'react';
import { useUser } from '@/hooks/useUser';
import HTMLReactParser from 'html-react-parser';
import Icon, { IconType } from '../Shared/Icon';
import Media from '@/models/Media';
import Router, { useRouter } from 'next/router';
import SelectItem from '../Shared/SelectItem';
import locale from '@/locales/en/NativeLanding';
import slideshowFirestoreHelper from '@/helpers/slideshowHelper';
const LanguageModelOptions = (props: {
  model: Option;
  setModel: (model: Option) => void;
}) => {
  const { model, setModel } = props;

  return (
    <div className="mb-2.5 flex w-full items-center justify-between">
      <span className="text-sm font-medium text-gray-500">Model</span>
      <div>
        <Select.Root
          value={model.type}
          onValueChange={(value) => {
            setModel(MODEL_OPTIONS.find((m) => m.type === value));
            return;
          }}
        >
          <Select.Trigger
            className="flex h-9 flex-row items-center justify-between gap-4 rounded-lg border border-solid border-gray-200 bg-white px-2.5 text-sm font-normal leading-none text-gray-700 hover:bg-gray-100 focus:border focus:border-solid focus:border-gray-200 data-[placeholder]:text-gray-700"
            aria-label="Model Type"
          >
            <Select.Value>
              <div className="space-between flex flex-row items-center">
                <div className="mr-2"> {HTMLReactParser(model.text)}</div>
                <Select.Icon className="text-gray-400">
                  <Icon type={IconType.Dropdown_Open} color="#9ca3af" />
                </Select.Icon>
              </div>
            </Select.Value>
          </Select.Trigger>
          <Select.Portal>
            <Select.Content className="z-[1000] rounded-md bg-white shadow-lg">
              <Select.ScrollUpButton className="cursor-pointer">
                <Icon type="ft-chevron-up" className="mx-auto my-2" />
              </Select.ScrollUpButton>
              <Select.Viewport className="SelectViewport">
                <Select.Group>
                  {MODEL_OPTIONS.map((option) => (
                    <SelectItem
                      key={option.type}
                      value={option.type}
                      className="group mr-auto text-left"
                    >
                      {HTMLReactParser(option.text)}
                    </SelectItem>
                  ))}
                </Select.Group>
              </Select.Viewport>
              <Select.ScrollDownButton className="cursor-pointer">
                <Icon type="ft-chevron-down" className="mx-auto my-2" />
              </Select.ScrollDownButton>
            </Select.Content>
          </Select.Portal>
        </Select.Root>
      </div>
    </div>
  );
};
const LanguageOptions = (props: {
  setLanguage: (model: LanguageOption) => void;
  language: LanguageOption;
  model: Option;
}) => {
  const { setLanguage, model, language } = props;
  return (
    <div className="mb-2.5 flex w-full items-center justify-between">
      <span className="text-sm font-medium text-gray-500">Language</span>
      <div>
        <Select.Root
          value={language.type}
          onValueChange={(value) => {
            const languageOption = LANGUAGE_OPTIONS[model.type].find(
              (m) => m.type === value
            );
            setLanguage(languageOption);
            return;
          }}
        >
          <Select.Trigger
            className="flex h-9 flex-row items-center justify-between gap-4 rounded-lg border border-solid border-gray-200 bg-white px-2.5 text-sm font-normal leading-none text-gray-700 hover:bg-gray-100 focus:border focus:border-solid focus:border-gray-200 data-[placeholder]:text-gray-700"
            aria-label="Model Type"
          >
            <Select.Value>
              <div className="space-between flex flex-row items-center">
                <div className="mr-2"> {HTMLReactParser(language.text)}</div>
                <Select.Icon className="text-gray-400">
                  <Icon type={IconType.Dropdown_Open} color="#9ca3af" />
                </Select.Icon>
              </div>
            </Select.Value>
          </Select.Trigger>
          <Select.Portal>
            <Select.Content className="z-[1000] rounded-md bg-white shadow-lg">
              <Select.ScrollUpButton className="cursor-pointer">
                <Icon type="ft-chevron-up" className="mx-auto my-2" />
              </Select.ScrollUpButton>
              <Select.Viewport className="SelectViewport">
                <Select.Group>
                  {LANGUAGE_OPTIONS[model.type].map((option) => (
                    <SelectItem
                      value={option.type}
                      className="group mr-auto text-left"
                      key={option.type}
                    >
                      {option.text}
                    </SelectItem>
                  ))}
                </Select.Group>
              </Select.Viewport>
              <Select.ScrollDownButton className="cursor-pointer">
                <Icon type="ft-chevron-down" className="mx-auto my-2" />
              </Select.ScrollDownButton>
            </Select.Content>
          </Select.Portal>
        </Select.Root>
      </div>
    </div>
  );
};
const RegenerateTranscriptModal = ({}: {}) => {
  const [model, setModel] = useState<Option>(MODEL_OPTIONS[0]);
  const [language, setLanguage] = useState(LANGUAGE_OPTIONS['whisper'][0]);
  const { closeModal } = useModal();
  const { user } = useUser();
  const router = useRouter();
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async () => {
    try {
      if (isLoading) return;
      setIsLoading(true);
      if (!user || !user.id) throw new Error('User not found');
      if (!router.query.slideshowId) throw new Error('Slideshow not found');
      const fileUrl = await slideshowFirestoreHelper.locateMainVideo();
      slideshowFirestoreHelper.data.assets = [fileUrl];
      await slideshowFirestoreHelper.updateFirestore();
      await Media.requestSubtitle(
        user.id,
        router.query.slideshowId as string,
        fileUrl.url,
        true,
        language.type,
        model.type
      );
      toast.success('Transcript regeneration has been initiated');
      const fileType = await getMediaTypeFromFileUrl(fileUrl.url);
      return Router.push({
        pathname: PageNames.CREATE_SLIDESHOW,
        query: {
          slideshowId: router.query.slideshowId as string,
          retry: true,
          type: fileType === 'audio' ? 'audio' : 'video'
        }
      });
    } catch (err) {
      toast.error(err.message);
    } finally {
      setIsLoading(false);
      closeModal();
    }
  };
  return (
    <div className="rounded-lg bg-white p-6">
      <div className="mt-2 flex items-start justify-between">
        <h5 className="text-lg font-medium leading-7">
          {locale.regenerate_transcript_modal.header}
        </h5>
        <button onClick={closeModal}>
          <Icon type={IconType.CrossLight} size={16} />
        </button>
      </div>
      <p className="mt-2 text-sm text-[#667085]">
        {locale.regenerate_transcript_modal.description}
      </p>
      <div className="my-5">
        <LanguageModelOptions
          model={model}
          setModel={(m) => {
            setModel(m);
            setLanguage(LANGUAGE_OPTIONS[m.type][0]);
          }}
        />
        <LanguageOptions
          language={language}
          setLanguage={setLanguage}
          model={model}
        />
      </div>
      <div className="mb-5 text-grey-400">
        {locale.regenerate_transcript_modal.remarks}
      </div>
      <div className="flex flex-row justify-between">
        <NewJPButton
          className="justify-center"
          size="md"
          width="full"
          colorType={'white'}
          text={locale.regenerate_transcript_modal.cancel}
          onClick={closeModal}
        />
        <div className="w-[15px]"></div>
        <NewJPButton
          className="justify-center"
          size="md"
          width="full"
          text={locale.regenerate_transcript_modal.submit}
          icon={isLoading ? IconType.Loading : null}
          onClick={onSubmit}
        />
      </div>
    </div>
  );
};

export default RegenerateTranscriptModal;
