import { DropdownMode, IDropdownSettings } from '@/components/Shared/Dropdown';
import { SetterOrUpdater, atom, useRecoilValue } from 'recoil';
import { cleanupSelection } from '@/utils/divs';

export const assetSummaryDropdownSettings = atom<IDropdownSettings>({
  key: 'assetSummaryDropdownSettings',
  default: {
    fromElement: null,
    toElement: null,
    mode: null,
    opened: false,
    childMenuOpened: false,
    position: { x: null, y: null }
  }
});

// Utility function to setup dropdown mode
const setupDropdownMode = (previousMode: DropdownMode): DropdownMode => {
  if (['randomize', 'delete'].includes(previousMode)) return 'loading';
  return previousMode;
};

// Function to set dropdown loading state
export const setDropdownLoading = (
  set: SetterOrUpdater<IDropdownSettings>,
  setIsLoading: boolean,
  loadingType?: DropdownMode
) => {
  if (!setIsLoading) cleanupSelection(); // Assuming cleanupSelection is imported

  set((prevState) => {
    if (setIsLoading) {
      return { ...prevState, mode: 'loading', loadingType };
    }
    return {
      ...prevState,
      mode: setupDropdownMode(prevState.loadingType),
      loadingType: ''
    };
  });
};