
import { MagicEditFonts, SubtitleAnimationType, SubtitleWordLimit, VideoTemplateFonts } from '../../slideshow/types';
import { SubtitleOptions } from '../../types/MediaManager';
import { atom } from 'recoil';


export const subtitlesWordLimit = atom({
    key: 'subtitlesWordLimit',
    default: SubtitleWordLimit.MORE
});

export const reelsSubtitlesPositionY = atom({
    key: 'reelsSubtitlesPositionY',
    default: 20
});

export const subtitlesFontSize = atom({
    key: 'subtitlesFontSize',
    default: 74
});


export const subtitlesStyle = atom({
    key: 'subtitlesStyle',
    default: SubtitleOptions.REELS
});

export const subtitlesHighlightColor = atom({
    key: 'subtitlesHighlightColor',
    default: '#FDE047'
});

export const subtitlesPrimaryTextColor = atom({
    key: 'subtitlesPrimaryTextColor',
    default: '#FFFFFF'
});

export const subtitlesFontFamily = atom<VideoTemplateFonts | MagicEditFonts>({
    key: 'subtitlesFontFamily',
    default: VideoTemplateFonts.PoetsenOne
});

export const subtitlesCapitalisationMode = atom({
    key: 'subtitlesCapitalisationMode',
    default: false
});

export const subtitleAnimationType = atom({
    key: 'animationType',
    default: SubtitleAnimationType.BoxHighlight
});

export const subtitleAnimationColor = atom({
    key: 'animationColor',
    default: '#a955f7'
})
