import { Asset } from '@/slideshow/types';
import {
  HIGHLIGHT_DIVIDER_CLASS_LIST,
  HOVER_CLASS_LIST,
  cleanupSelection,
  findFurtherestDiv,
  getCurrentHighlightedDivs,
  ignoreBR,
  ignoreParagraphSeparator,
  isDividerDiv,
  loopThroughDivs,
  toggleHighlightClass
} from '@/utils/divs';
import { PARAGRAPH_SEPARATOR } from '@/components/ExportSlideshow/AssetSummary';
import {
  assetSummaryDropdownSettings,
  setDropdownLoading
} from '@/recoil/atoms/dropdown.atoms';
import { getElementOffset, seekToSecond } from '@/utils/generalUtilities';
import { isUserUploadedVideo } from '@/slideshow/utils/asset';
import { toast } from 'react-toastify';
import { useRecoilState } from 'recoil';
import { useRouter } from 'next/router';
import { useVideoPreview } from './useVideoPreview';
import React, {
  RefObject,
  createContext,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import slideshowFirestoreHelper from '@/helpers/slideshowHelper';

export interface AdjustHighlightContextValue {
  createDividerDiv: (
    referenceElement: Element,
    highlightClass: string,
    onTranscriptHover: (e: any, isActive?: boolean) => void,
    assetId: string
  ) => Element;
  motherDivRef: RefObject<HTMLDivElement>;
}

const AdjustHighlighterContext = createContext<
  AdjustHighlightContextValue | undefined
>(undefined);

/* The Divider div will have the same highlighted-from or data-to as the original highlight,
 * depends on whether the referenceElement is fromElement or toElement */
const checkIfOriginalDividerExists = (
  referenceElement: Element,
  highlightClass: 'highlighted-from' | 'highlighted-to'
) => {
  const refDataItem = referenceElement.getAttribute('data-item');
  if (refDataItem.includes(`${highlightClass}-divider`)) return true;
  const dataItem = `${refDataItem}-divider`;

  const parent = document.querySelector("div[data-highlight='main']");
  const dividerElements = Array.from(
    parent.querySelectorAll(`[data-item='${dataItem}']`)
  ).filter((c) => c.className.includes(`${highlightClass}-divider`));
  return dividerElements.length > 0;
};

export const HIGHLIGHT_DIVIDER = 'highlight-divider';
const createDividerDiv = (
  originalWordDiv: Element,
  highlightClass: 'highlighted-from' | 'highlighted-to',
  onTranscriptHover: (e: any, isActive?: boolean) => void,
  assetId: string
): Element | undefined => {
  if (checkIfOriginalDividerExists(originalWordDiv, highlightClass)) return;

  const addBefore = highlightClass === 'highlighted-from';
  const addAfter = highlightClass === 'highlighted-to';
  const div = document.createElement('div');
  div.innerText = '|';
  div.classList.add(...HIGHLIGHT_DIVIDER_CLASS_LIST, 'pr-1', highlightClass);
  div.onmouseover = (e) => onTranscriptHover(e, true);
  div.onmouseleave = (e) => onTranscriptHover(e, false);
  div.setAttribute('data-asset-id', assetId);

  /* Separating into 2 diff cases as 1 originalWordDiv can contain both from and to attributes */
  if (addBefore) {
    div.classList.add('pr-1');

    Array.from(originalWordDiv.attributes).forEach((attr: Attr) => {
      if (attr.name.startsWith('data-')) {
        div.setAttribute(attr.name, attr.value);
        if (attr.name === 'data-item') {
          div.setAttribute('data-item', `${attr.value}-divider`);
        }
      }
    });

    div.classList.remove('highlighted-from');
    div.classList.add('highlighted-from-divider', '-mr-[2px]');
    originalWordDiv.parentNode?.insertBefore(div, originalWordDiv);
  }

  if (addAfter) {
    div.classList.add('pl-[4px]');

    Array.from(originalWordDiv.attributes).forEach((attr) => {
      if (attr.name.startsWith('data-')) {
        div.setAttribute(attr.name, attr.value);
        if (attr.name === 'data-item') {
          div.setAttribute('data-item', `${attr.value}-divider`);
        }
      }
    });

    div.classList.remove('highlighted-to');
    div.classList.add('highlighted-to-divider');
    originalWordDiv.parentNode?.insertBefore(div, originalWordDiv.nextSibling);
  }
};

export const AdjustHighlighterProvider = (props: any) => {
  const { videoPreviewRef } = useVideoPreview();
  const motherDivRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const [dropdown, setDropdown] = useRecoilState(assetSummaryDropdownSettings);
  const [isDraggingMode, setIsDraggingMode] = useState<
    'dragging' | 'releasing' | 'default'
  >('default');
  const [furtherestDiv, setFurtherestDiv] = useState<{
    leftmostDiv: Element;
    rightmostDiv: Element;
  }>();
  const [dividerDiv, setDividerDiv] = useState<Element | null>(null);
  const [mouseDownWordDiv, setMouseDownDiv] = useState<Element | null>(null);
  const [highlightedDiv, setHighlightedDiv] = useState<{
    leftmostDiv: Element;
    rightmostDiv: Element;
  }>(null);
  const getDataItem = (e: Element) => {
    return e.getAttribute('data-item').includes('divider')
      ? e.className.includes('highlighted-from-divider')
        ? parseInt(e.getAttribute('data-item').split('-')[0]) - 0.5
        : parseInt(e.getAttribute('data-item').split('-')[0]) + 0.5
      : parseInt(e.getAttribute('data-item'));
  };

  /* Handle Cursor UI */
  useEffect(() => {
    if (!motherDivRef.current) return;
    motherDivRef.current.querySelectorAll('*').forEach((child) => {
      if (isDraggingMode === 'dragging') {
        (child as HTMLElement).classList.add('!cursor-col-resize');
      } else {
        if (!child.classList.contains(HIGHLIGHT_DIVIDER)) {
          (child as HTMLElement).classList.remove('!cursor-col-resize');
        }
      }
    });
  }, [isDraggingMode]);

  /* Logics on pressing mouse */
  useEffect(() => {
    const handleMouseDown = (event: MouseEvent) => {
      const element = document.elementFromPoint(event.clientX, event.clientY);
      if (!element || !element.classList.contains(HIGHLIGHT_DIVIDER)) return;
      startDragging(event, element as Element);
      setDividerDiv(element as Element);
      setIsDraggingMode('dragging');
    };

    const handleMouseUp = async () =>
      isDraggingMode === 'dragging' ? setIsDraggingMode('releasing') : null;

    const handleMouseMove = (event: MouseEvent) => {
      if (isDraggingMode !== 'dragging' || !dividerDiv) return;
      onDraggingDivider(event, dividerDiv);
      // throttle(() => onDraggingDivider(event, dividerDiv), 100);
    };

    const motherDiv = motherDivRef.current;
    if (motherDiv) {
      motherDiv.addEventListener('mousedown', handleMouseDown);
      motherDiv.addEventListener('mouseup', handleMouseUp);
      motherDiv.addEventListener('mousemove', handleMouseMove);
    }

    return () => {
      if (motherDiv) {
        motherDiv.removeEventListener('mousedown', handleMouseDown);
        motherDiv.removeEventListener('mouseup', handleMouseUp);
        motherDiv.removeEventListener('mousemove', handleMouseMove);
      }
    };
  }, [isDraggingMode]);

  const startDragging = (event: MouseEvent, originalDividerDiv: Element) => {
    event.preventDefault();
    const dividerType = originalDividerDiv.classList.contains(
      'highlighted-from-divider'
    )
      ? 'from'
      : 'to';

    const temp =
      dividerType === 'to'
        ? ignoreParagraphSeparator(
            ignoreBR(originalDividerDiv.previousElementSibling, 'from'),
            'from'
          )
        : ignoreParagraphSeparator(
            ignoreBR(originalDividerDiv.nextElementSibling, 'to'),
            'to'
          );
    setMouseDownDiv(temp);
    setFurtherestDiv(findFurtherestDiv(temp, dividerType));

    originalDividerDiv.classList.add('highlight-hover', 'dragging');
  };

  function insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
  }

  /**
   * Cleans up divs after dragging operation is completed.
   *
   * @param {Element} pointedElement - The element where the dragging operation ended.
   * @param {Element} originalDividerDiv - The original divider div that was being dragged.
   */
  const cleanupDivsPostDragging = (
    pointedElement: Element,
    originalDividerDiv: Element,
    isDebug = false
  ) => {
    if (pointedElement === originalDividerDiv) return;
    const dividerType = originalDividerDiv.classList.contains(
      'highlighted-from-divider'
    )
      ? 'from'
      : 'to';

    if (
      dividerType === 'from' &&
      ignoreBR(
        pointedElement?.previousElementSibling,
        'from'
      )?.classList.contains('highlight-from-divider')
    )
      return;
    if (
      dividerType === 'to' &&
      ignoreBR(pointedElement?.nextElementSibling, 'to')?.classList.contains(
        'highlight-to-divider'
      )
    )
      return;
    const currentHighlights = getCurrentHighlightedDivs(
      originalDividerDiv,
      true
    );

    if (
      isDividerDiv(pointedElement) ||
      (!pointedElement?.classList.contains(PARAGRAPH_SEPARATOR) &&
        !pointedElement?.getAttribute('data-item'))
    )
      return;
    if (dividerType === 'to') {
      const withinRange =
        getDataItem(furtherestDiv.rightmostDiv) >=
          getDataItem(pointedElement) &&
        getDataItem(furtherestDiv.leftmostDiv) < getDataItem(pointedElement);
      const afterRightmostDiv =
        getDataItem(pointedElement) >= getDataItem(furtherestDiv.rightmostDiv);
      if (!furtherestDiv) return;
      /*
       * This logic must be run before insertBefore as it is based on the most updated position of the divider
       * There will be a paragraph divider in the ending for highlighting, check AssetSummary.tsx
       * */
      const isDividerGoingToBeLastChild =
        pointedElement ===
        motherDivRef.current.lastElementChild.previousElementSibling;
      const isDividerLastChild =
        originalDividerDiv ===
        motherDivRef.current.lastElementChild.previousElementSibling;
      originalDividerDiv.getAttribute('data-item') === '0-divider';
      mouseDownWordDiv?.parentElement?.insertBefore(
        originalDividerDiv,
        withinRange
          ? pointedElement
          : !afterRightmostDiv
          ? ignoreParagraphSeparator(
              ignoreBR(furtherestDiv.leftmostDiv.nextElementSibling, 'to'),

              'to'
            )
          : isDividerGoingToBeLastChild
          ? motherDivRef.current.lastElementChild
          : furtherestDiv.rightmostDiv
      );

      let destiniationDiv: Element = isDividerLastChild
        ? ignoreParagraphSeparator(pointedElement, 'from')
        : withinRange
        ? isDividerGoingToBeLastChild
          ? ignoreBR(pointedElement.previousElementSibling, 'from')
          : ignoreParagraphSeparator(
              ignoreBR(pointedElement.previousElementSibling, 'from'),
              'from'
            )
        : afterRightmostDiv
        ? isDividerGoingToBeLastChild
          ? ignoreBR(
              motherDivRef.current.lastElementChild.previousElementSibling,
              'from'
            )
          : ignoreParagraphSeparator(
              ignoreBR(
                furtherestDiv.rightmostDiv.previousElementSibling,
                'from'
              ),
              'from'
            )
        : ignoreParagraphSeparator(
            ignoreBR(furtherestDiv.leftmostDiv.nextElementSibling, 'to'),
            'to'
          );
      loopThroughDivs(
        ignoreParagraphSeparator(
          ignoreBR(furtherestDiv.leftmostDiv.previousElementSibling, 'from'),
          'from'
        ),
        destiniationDiv,
        (div) => {
          div.classList.add(...HOVER_CLASS_LIST);
        }
      );
      if (!isDividerGoingToBeLastChild) {
        loopThroughDivs(
          ignoreParagraphSeparator(
            ignoreBR(destiniationDiv.nextElementSibling, 'to'),
            'to'
          ),
          ignoreParagraphSeparator(
            ignoreBR(furtherestDiv.rightmostDiv.previousElementSibling, 'from'),
            'from'
          ),
          (div) => {
            if (afterRightmostDiv) return;
            if (div.classList.contains(HIGHLIGHT_DIVIDER)) return;
            div.classList.remove(...HOVER_CLASS_LIST);
          }
        );
      }

      setHighlightedDiv({
        leftmostDiv: currentHighlights.startingDiv,
        rightmostDiv: destiniationDiv
      });
    }
    if (dividerType === 'from') {
      const isDividerFirstChild =
        originalDividerDiv.getAttribute('data-item') === '0-divider';
      const withinRange =
        getDataItem(furtherestDiv.rightmostDiv) > getDataItem(pointedElement) &&
        getDataItem(furtherestDiv.leftmostDiv) <= getDataItem(pointedElement);
      const beforeLeftmostDiv =
        getDataItem(furtherestDiv.leftmostDiv) > getDataItem(pointedElement);

      if (!furtherestDiv) return;

      mouseDownWordDiv?.parentElement?.insertBefore(
        originalDividerDiv,
        withinRange
          ? isDividerFirstChild
            ? ignoreBR(pointedElement.nextElementSibling, 'to')
            : pointedElement
          : beforeLeftmostDiv
          ? ignoreParagraphSeparator(
              ignoreBR(furtherestDiv.leftmostDiv.nextElementSibling, 'to'),
              'to'
            )
          : furtherestDiv.rightmostDiv
      );

      let destiniationDiv: Element = withinRange
        ? isDividerFirstChild
          ? pointedElement.nextElementSibling
          : ignoreParagraphSeparator(
              ignoreBR(pointedElement.previousElementSibling, 'from'),
              'from'
            )
        : beforeLeftmostDiv
        ? ignoreParagraphSeparator(
            ignoreBR(furtherestDiv.leftmostDiv.nextElementSibling, 'to'),
            'to'
          )
        : ignoreParagraphSeparator(
            ignoreBR(furtherestDiv.rightmostDiv.previousElementSibling, 'from'),
            'from'
          );

      loopThroughDivs(
        destiniationDiv,
        ignoreParagraphSeparator(
          ignoreBR(furtherestDiv.rightmostDiv.nextElementSibling, 'to'),
          'to'
        ),
        (div) => {
          div.classList.add(...HOVER_CLASS_LIST);
        }
      );

      loopThroughDivs(
        ignoreParagraphSeparator(
          ignoreBR(destiniationDiv.previousElementSibling, 'from'),
          'from'
        ),
        ignoreParagraphSeparator(
          isDividerFirstChild
            ? motherDivRef.current.firstElementChild
            : ignoreBR(furtherestDiv.leftmostDiv.nextElementSibling, 'to'),
          'to'
        ),
        (div) => {
          if (beforeLeftmostDiv) return;
          if (div.classList.contains(HIGHLIGHT_DIVIDER)) return;
          div.classList.remove(...HOVER_CLASS_LIST);
        }
      );
      setHighlightedDiv({
        leftmostDiv: destiniationDiv,
        rightmostDiv: currentHighlights.endingDiv
      });
    }
  };
  function findNearestWordElement(
    x: number,
    y: number
  ): { pointedElement: Element; isHoveringOnMotherDiv: boolean } | null {
    const container = document.querySelector('[data-highlight="main"]');
    if (!container) return null;

    const pointedElement = document.elementFromPoint(x, y);
    if (pointedElement.getAttribute('data-item'))
      return { pointedElement, isHoveringOnMotherDiv: false };
    if (pointedElement.className.includes('paragraph-separator'))
      return { pointedElement, isHoveringOnMotherDiv: false };
    const words = Array.from(container.querySelectorAll('div[data-item]'));
    if (words.length === 0) return null;

    const verticalTolerance = 10; // Adjust this value as needed

    let nearestWord: Element | null = null;
    let minDistance = Infinity;

    for (let i = 0; i < words.length; i++) {
      const word = words[i];
      const rect = word.getBoundingClientRect();
      const centerX = rect.left + rect.width / 2;
      const centerY = rect.top + rect.height / 2;

      // Check if the word is within the vertical tolerance
      if (Math.abs(y - centerY) <= verticalTolerance) {
        const horizontalDistance = Math.abs(x - centerX);

        if (horizontalDistance < minDistance) {
          minDistance = horizontalDistance;
          nearestWord = word;
        }
      }
    }
    /* Handling the use case on hovering the blank space after each paragraph*/
    if (
      ignoreBR(nearestWord?.nextElementSibling, 'to')?.className.includes(
        'paragraph-separator'
      )
    )
      nearestWord = ignoreBR(nearestWord?.nextElementSibling, 'to');
    return { isHoveringOnMotherDiv: true, pointedElement: nearestWord };
  }
  const onDraggingDivider = (
    event: MouseEvent,
    originalDividerDiv: Element
  ) => {
    setDropdown((prevState) => ({
      ...prevState
    }));
    const nearestWord = findNearestWordElement(event.clientX, event.clientY);
    cleanupDivsPostDragging(nearestWord.pointedElement, originalDividerDiv);
  };

  /* Logics on releasing mouse */
  useEffect(() => {
    if (
      !highlightedDiv ||
      !highlightedDiv.leftmostDiv ||
      !highlightedDiv.rightmostDiv ||
      isDraggingMode !== 'releasing'
    ) {
      return;
    }
    onSave();
  }, [
    isDraggingMode,
    highlightedDiv?.leftmostDiv,
    highlightedDiv?.rightmostDiv
  ]);

  const onSave = async () => {
    setDropdown({
      mode: 'adjust-timestamp',
      fromElement: highlightedDiv.leftmostDiv,
      toElement: highlightedDiv.rightmostDiv,
      opened: true,
      position: getElementOffset(highlightedDiv.rightmostDiv)
    });

    setDropdownLoading(setDropdown, true, 'adjust-timestamp');
    const assetId =
      highlightedDiv.leftmostDiv.getAttribute('data-asset-id') ||
      highlightedDiv.rightmostDiv.getAttribute('data-asset-id');
    /* Save the settings */
    let asset: Asset;
    await Promise.allSettled(
      Object.values(highlightedDiv).map(async (div) => {
        const copyElementFrom = div.classList.contains(
          'highlighted-from-divider'
        )
          ? ignoreParagraphSeparator(
              ignoreBR(div.nextElementSibling, 'to'),
              'to'
            )
          : ignoreParagraphSeparator(
              ignoreBR(div.previousElementSibling, 'from'),
              'from'
            );
        /* Check if has change or not */
        if (getDataItem(div) === getDataItem(copyElementFrom)) return;
        /* Update the divs settings */
        Array.from(copyElementFrom.attributes).forEach((attr: Attr) => {
          if (attr.name.startsWith('data-')) {
            div.setAttribute(attr.name, attr.value);
            if (attr.name === 'data-item') {
              div.setAttribute('data-item', `${attr.value}-divider`);
            }
          }
        });
        div.classList.remove('dragging');
        cleanupDivsPostDragging(
          div,
          div.classList.contains('highlighted-to-divider')
            ? ignoreParagraphSeparator(
                ignoreBR(div.nextElementSibling, 'to'),
                'to'
              )
            : ignoreParagraphSeparator(
                ignoreBR(div.previousElementSibling, 'from'),
                'from'
              ),
          true
        );
        /* Update on Firestore */
        await slideshowFirestoreHelper.getDataFromFirestore(
          router.query.slideshowId as string
        );
        if (!slideshowFirestoreHelper.data)
          return toast.error('Error on adjusting highlight range');
        console.log(JSON.stringify(slideshowFirestoreHelper.data.assets));
        asset = await slideshowFirestoreHelper.updateAssetTimestamp(assetId, {
          f: ignoreBR(
            highlightedDiv.leftmostDiv.nextElementSibling,
            'to'
          ).getAttribute('data-from'),
          t: ignoreBR(
            highlightedDiv.rightmostDiv.previousElementSibling,
            'from'
          ).getAttribute('data-to')
        });
      })
    );
    reset();
    await onSuccess(
      asset,
      highlightedDiv.leftmostDiv,
      highlightedDiv.rightmostDiv
    );
    seekToSecond(asset.f, videoPreviewRef);
  };

  const onSuccess = async (asset: Asset, from: Element, to: Element) => {
    cleanupSelection();

    toggleHighlightClass({
      from,
      to,
      isActive: false,
      className: 'highlight-hover'
    });
    toggleHighlightClass({
      from,
      to,
      isActive: true,
      className: 'highlight-focus'
    });

    setDropdown((prevState) => ({
      ...prevState,
      mode: isUserUploadedVideo(asset?.url) ? 'delete-user-upload' : 'edit',
      opened: true,
      activeAsset: asset
    }));
  };

  const reset = () => {
    setIsDraggingMode('default');
    setDividerDiv(null);
    setFurtherestDiv(null);
    setHighlightedDiv(null);
  };
  return (
    <AdjustHighlighterContext.Provider
      value={{
        createDividerDiv,
        motherDivRef
      }}
    >
      {props.children}
    </AdjustHighlighterContext.Provider>
  );
};

export const useAdjustHighlight = () => {
  const context = useContext(AdjustHighlighterContext);
  if (context === undefined) {
    throw new Error(
      'useAdjustHighlighter must be used within a AdjustHighlighterContext'
    );
  }
  return context;
};
