import { API_URL } from './constant';
import { LocalStorageItems, getItem } from '../utils/generalUtilities';
import fetchWrapper, { MethodType } from '../utils/fetchWrapper';

/*
 * Definitions
 */
export enum AudioStatus {
  'published' = 'published',
  'deleted' = 'deleted'
}

export interface Play {
  count: number;
  by_users: {
    [key: string]: number;
  };
}

export interface Audio {
  id: string /* Same as audio_id */;
  audio_id: string;
  title: string;
  author_id: string;
  status: AudioStatus;
  audio_url: string; // either text or url to the content
  play?: Play;
  created_at: Date;
  last_updated: Date;
}

export interface AudioDTO extends Audio {}

export interface UpdatablePartOfAudio {
  title: string;
  status: AudioStatus;
  audio_url: string;
  play?: Play;
  last_updated: Date;
}

export const AUDIO_COLLECTION_NAME = 'audios';

export interface Result {
  success: boolean;
}

const AudioHelper = {
  name: '/questions',
  route: '',

  /**
   * Create question.
   * @param audioId string
   * @param title string
   * @return Promise<any> success boolean and inserted document
   */
  patchAudio(
    audioId: string,
    title: string
  ): Promise<{
    success: boolean;
    audio: AudioDTO;
  }> {
    return new Promise((resolve, reject) => {
      this.route = `${API_URL + this.name}/${audioId}`;

      const body = JSON.stringify({
        title
      });
      const token = getItem(LocalStorageItems.ACCESS_TOKEN);

      fetchWrapper(this.route, MethodType.PATCH, body, token)
        .then((res: any) => {
          resolve(res);
        })
        .catch((error) => {
          console.log(error);
          return reject(error);
        });
    });
  },

  /**
   * Create an audio
   * @param audioUrl string
   * @param audioId string
   * @return Promise<AudioDTO> success boolean and inserted document
   */
  createAudio(audioUrl: string, audioId: string): Promise<AudioDTO> {
    return new Promise((resolve, reject) => {
      this.route = `${API_URL + this.name}/`;
      const body: any = {
        audio_url: audioUrl,
        id: audioId
      };
      const token = getItem(LocalStorageItems.ACCESS_TOKEN);
      fetchWrapper(this.route, MethodType.POST, JSON.stringify(body), token)
        .then((res: { audio: AudioDTO }) => {
          resolve(res.audio);
        })
        .catch((error) => {
          console.log(error);
          return reject(error);
        });
    });
  },
  /**
   * Delete a question.
   * @param questionId string
   * @param token string
   * @return Promise<any>
   */
  // deleteQuestion(questionId: string, token: string): Promise<any> {
  //   return new Promise((resolve, reject) => {
  //     this.route = `${API_URL + this.name}/${questionId}`;
  //     fetchWrapper(this.route, MethodType.DELETE, null, token)
  //       .then((res) => {
  //         resolve(res);
  //       })
  //       .catch((error) => {
  //         console.log(error);
  //         return reject(error);
  //       });
  //   });
  // },

  /**
   * Get a question by questionId.
   * @param audioId string
   * @param token string
   * @method GET
   * @response Promise<AudioDTO>
   */
  getAudioById(audioId: string, token?: string): Promise<AudioDTO> {
    return new Promise((resolve) => {
      this.route = `${API_URL + this.name}/${audioId}`;

      fetchWrapper(this.route, MethodType.GET, null, null).then((json) => {
        resolve(json as AudioDTO);
      });
    });
  }
};

export default AudioHelper;
