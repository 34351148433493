import { AbsoluteFill, OffthreadVideo, Sequence, prefetch } from 'remotion';
import { Asset, MagicSlideshowPreferences } from '../types';
import React, { useEffect, useState } from 'react';
import VideoContainer from '../components/VideoContainer';
import calculateFPS from '../utils/FPS';

const FILM_BURN_URL =
  'https://jupitrr-staging-playground.s3.ap-southeast-1.amazonaws.com/burn-small.mov';

const FilmBurnEffect = ({
  asset,
  id,
  slideshowPreferences
}: {
  asset: Asset;
  id: number;
  slideshowPreferences: MagicSlideshowPreferences;
}) => {
  const [isFilmBurnLoaded, setIsFilmBurnLoaded] = useState(false);

  // Prefetch film burn video on mount
  useEffect(() => {
    if (!slideshowPreferences?.activateInitialAnimation) return;

    const { waitUntilDone } = prefetch(FILM_BURN_URL);
    waitUntilDone().then(() => {
      setIsFilmBurnLoaded(true);
    });
  }, [slideshowPreferences?.activateInitialAnimation]);
  if (!slideshowPreferences?.activateInitialAnimation || !isFilmBurnLoaded) {
    return null;
  }

  const from = Math.max(0, Number(asset.f));
  const to = Number(asset.t);
  const durationInFrames = calculateFPS(to - from);

  return (
    <Sequence
      name={`Film Burn ${id}`}
      from={calculateFPS(from)}
      premountFor={90}
      durationInFrames={durationInFrames}
    >
      <AbsoluteFill style={{ zIndex: 20, mixBlendMode: 'plus-lighter' }}>
        <OffthreadVideo
          src={FILM_BURN_URL}
          style={{
            width: '100%',
            height: '100%'
          }}
          muted={true}
        />
      </AbsoluteFill>
    </Sequence>
  );
};

const EffectContainer = ({
  asset,
  id,
  slideshowPreferences,
  renderType
}: {
  asset: Asset;
  id: number;
  slideshowPreferences: any;
  renderType: 'player' | 'export';
}) => {
  if (asset.preferences?.effect === 'film-burn') {
    return (
      <FilmBurnEffect
        asset={asset}
        id={id}
        slideshowPreferences={slideshowPreferences}
      />
    );
  } else if (asset.preferences?.effect === 'quick-zoom-out') {
    const from = Math.max(0, Number(asset.f));
    const to = from + 3; // Force duration to 3 seconds
    const durationInFrames = calculateFPS(to - from);

    return (
      <Sequence
        name={`Quick Zoom Out ${id}`}
        from={calculateFPS(from)}
        durationInFrames={durationInFrames}
        premountFor={90}
      >
        <VideoContainer
          asset={asset}
          startFrom={from}
          endAt={calculateFPS(to)}
          durationInFrames={durationInFrames}
          preferredTransition="quick-zoom-out"
          isMainVideo={false}
          renderType={renderType}
        />
      </Sequence>
    );
  }
  return null;
};

export default EffectContainer;
