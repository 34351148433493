import moment from 'moment';

// const GOOGLE_SERVICE_DEV_CLIENT_ID = '814873421385-7jut0r2j44jfucfgl76sippu81pcv41j.apps.googleusercontent.com';
// const GOOGLE_SERVICE_PROD_CLIENT_ID = '137786246442-42f0b31uro3c59m8gv5v313c1qddstih.apps.googleusercontent.com';

const IS_DEV_ENV =
  process.env && process.env.NEXT_PUBLIC_VERCEL_ENV !== 'production';

const API_DEV =
  'https://6lbq5zyct8.execute-api.ap-southeast-1.amazonaws.com/dev';
const API_PROD =
  'https://4i20to0y70.execute-api.ap-southeast-1.amazonaws.com/production';

const API_URL = IS_DEV_ENV ? API_DEV : API_PROD;
const MEDIAS_LAMBDA_API_URL = IS_DEV_ENV
  ? 'https://lezlseu7veywvejjomhlwgaerm0magwt.lambda-url.ap-southeast-1.on.aws/apis'
  : // 'http://localhost:3000/dev/apis'
    'https://fotskflnekewredevyoekny45y0tkghl.lambda-url.ap-southeast-1.on.aws/apis';
// const GOOGLE_SERVICE_CLIENT_ID = IS_DEV_ENV ? GOOGLE_SERVICE_DEV_CLIENT_ID : GOOGLE_SERVICE_PROD_CLIENT_ID;

const VIDEO_ROUTE = IS_DEV_ENV
  ? 'https://us-central1-jpt-dev-bd5c3.cloudfunctions.net/videoRoute/apis'
  : 'https://us-central1-jpt-prod.cloudfunctions.net/videoRoute/apis';

const UPLOAD_BUCKET = IS_DEV_ENV ? 'jupitrr-staging-playground' : 'jupitrr-ugc';

const MIXPANEL_KEY = IS_DEV_ENV
  ? 'e17e6e35081f4865d3fcae317acdab24'
  : '86ab2cba6d80ba1859ab83d8840f2d01';

const UNSPLASH_ACCESS_KEY = 'Nq6lm9V-GlGP92zU02aCh3naUJsKfWCyhXWm3KGzx_w';

//TODO: migrate this to backend for safe practice
const PEXELS_KEY = 't89U1XKMHqKxLV7CWe13AHIsuje9gw1A2AHf7G8gfZqJtz5cRHENu1Wm';

const DEMO_VIDEO_URL =
  'https://www.loom.com/embed/cd2cdbb78cad48d79fb6a19802ef15e0?sid=84fa2bfd-62d7-4c16-8ca0-2649320bc56f';

type FeatureType =
  | 'ai-video'
  | 'audiogram'
  | 'watermark'
  | 'extra-transcription'
  | 'language'
  | 'resolution'
  | 'stock'
  | 'file-size';

const LINKS = {
  TERMS: `https://jupitrr.notion.site/Terms-of-Service-42977fa1c8ca4074a5b297534a997797`,
  FAQ: `https://jupitrr.notion.site/Frequently-Asked-Questions-FAQs-88870971db934c56a09bbda163d2371f`,
  WHATS_NEW: `https://jupitrr.notion.site/Changelog-44ea837a82304bf0b096265b13ceffee`,
  PRIVACY: `https://jupitrr.notion.site/Privacy-Policy-0f03c99eb41a4d5ba039c075e7d9345e`,
  ABOUT: `https://jupitrr.notion.site/About-Us-309dd51df0b84853b85baf15eb886529`
};

const MAX_QUESTION_LENGTH = 85; // 85 for all templates.

const MAX_FILE_SIZE_IN_MB = 300;
const MAX_FILE_DURATION_IN_MINS = 30;
const MAGIC_VIDEO_MIN_DURATION = 5; // 5 seconds
const MAGIC_VIDEO_MAX_DURATION = 180; // 3 minutes

const HIDDEN_NAVBAR_ROUTES = [
  { path: '/', exact: true, isHeaderHidden: false },
  { path: '/export-slideshow', exact: false, isHeaderHidden: true },
  { path: '/export', exact: false, isHeaderHidden: false },
  { path: '/login', exact: false, isHeaderHidden: true },
  { path: '/email-login', exact: false, isHeaderHidden: true },
  { path: '/signup', exact: false, isHeaderHidden: true },
  { path: '/email-signup', exact: false, isHeaderHidden: true },
  { path: '/blog', exact: false, isHeaderHidden: true },
  { path: '/pricing', exact: false, isHeaderHidden: true },
  { path: '/about-us', exact: false, isHeaderHidden: true },
  { path: '/change-log', exact: false, isHeaderHidden: true },
  { path: '/terms-and-conditions', exact: false, isHeaderHidden: true },
  { path: '/privacy-policy', exact: false, isHeaderHidden: true },
  { path: '/404', exact: false, isHeaderHidden: true },
  { path: '/guide', exact: false, isHeaderHidden: true },
  { path: '/set-profile', exact: false, isHeaderHidden: true },
  { path: '/set-slug', exact: false, isHeaderHidden: true },
  { path: '/tryout-call', exact: false, isHeaderHidden: true },
  { path: '/waitlist', exact: false, isHeaderHidden: true },
  { path: '/audiogram', exact: false, isHeaderHidden: true },
  { path: '/product', exact: false, isHeaderHidden: true },
  { path: '/create-slideshow', exact: false, isHeaderHidden: false },
  { path: '/generate-slideshow', exact: false, isHeaderHidden: false },
  { path: '/create', exact: false, isHeaderHidden: false },
  { path: '/generate', exact: false, isHeaderHidden: false },
  { path: '/onboarding', exact: false, isHeaderHidden: true },
  { path: '/mobilealert', exact: false, isHeaderHidden: true },
  { path: '/magic', exact: false, isHeaderHidden: false },
  { path: '/magic-preview', exact: false, isHeaderHidden: false }
];

export {
  API_URL,
  MEDIAS_LAMBDA_API_URL,
  UPLOAD_BUCKET,
  IS_DEV_ENV,
  UNSPLASH_ACCESS_KEY,
  PEXELS_KEY,
  MIXPANEL_KEY,
  LINKS,
  VIDEO_ROUTE,
  MAX_QUESTION_LENGTH,
  MAX_FILE_DURATION_IN_MINS,
  MAX_FILE_SIZE_IN_MB,
  DEMO_VIDEO_URL,
  HIDDEN_NAVBAR_ROUTES,
  MAGIC_VIDEO_MIN_DURATION,
  MAGIC_VIDEO_MAX_DURATION
};
