import { atom } from 'recoil';

export const videoPreviewPlaying = atom<boolean>({
    key: 'videoPreviewPlaying',
    default: false
});

export const isVideoBuffering = atom<boolean>({
    key: 'isVideoBuffering',
    default: false
});
