import { AudioProcessingStatus } from '../types/MediaManager';
import { TextOutput } from '@/audiogram/components/TextSequence';

type SecondsInTwoDecimalPlaces = string;
type DateString = String;

export interface SilenceAsset {
  silence_id: string;
  subtitleAsset: TextOutput;
  type: 'silence';
  created_at: DateString;
  last_updated: DateString;
}

export interface IAssetOverlay
  extends Omit<
    IAssetFS,
    | 'abandoned_medias'
    | 'potential_medias'
    | 'mediaconvert_job_id'
    | 'mediaconvert_job_status'
  > {
  textData?: string;
  overlay_preferences?: OverlayStyle;
}

export interface Asset
  extends Omit<
    IAssetFS,
    | 'created_at'
    | 'last_updated'
    | 'slideshow_id'
    | 'author_id'
    | 'status'
    | 'subtitle_status'
    | 'abandoned_medias'
    | 'potential_medias'
  > {
  f: SecondsInTwoDecimalPlaces;
  t: SecondsInTwoDecimalPlaces;
  preferences?: VisibleAssetPreference;
  original_keyword?: string;
  stock_media_source?: string;
  overlay_preferences?: OverlayStyle;
  type: AssetType;
  text?: string;
  e?: string;
  hidden?: boolean;
  mediaType?: 'video' | 'image';
}

export enum GenericSubtitleStatus {
  'tbd' = 'tbd',
  'in_progress' = 'in_progress',
  'generated' = 'generated',
  'error' = 'error'
}

export interface VideoData {
  id: string;
  width?: number;
  height?: number;
  sd_url?: string;
  hd_url?: string;
  url: string; //legacy
  duration: number;
  relevant_tags?: string[];
  source: 'pexels' | 'getty' | 'upload';
  type?: 'video';
}

export interface ImageData {
  id: string;
  width?: number;
  height?: number;
  sd_url?: string;
  hd_url?: string;
  url: string;
  description?: string;
  source: 'pexels' | 'getty' | 'web_image' | 'upload';
  type?: 'image';
}
export interface IPrompt {
  id: string;
  author_id: string;
  prompt_type: any;
  slideshow_id: string;
  openai_response: any;
  openai_clean_response?: string;
  text_source: string;
  medias?: VideoData[];
}

export type AssetType = 'audio' | 'video' | 'image' | 'unknown' | 'chart' | 'gif' | 'web_image' | 'overlay' | 'hook' | 'emoji' | 'a-roll-transition' | 'effect' | 'outro';;
export type StockMediaSource = 'pexels' | 'getty' | 'web_image' | 'unsplash' | 'upload' | 'gif'
export type MagicStockMediaSource = 'getty' | 'pexels' | 'giphy' | 'web_image' | 'upload';

export interface IAssetFS {
  id: string;
  author_id: string;
  type: AssetType | 'hook' | 'emoji';
  url: string;
  sd_url?: string;
  hd_url?: string;
  slideshow_id: string;
  status: 'uploaded' | 'subtitle-processing' | 'subtitle-processed';
  subtitle_status: { [key in AudioProcessingStatus]?: DateString };
  prompt?: IPrompt;
  created_at: DateString;
  last_updated: DateString;
  potential_medias?: VideoData[];
  abandoned_medias?: string[];
  rating?: number | any;
  manual_keyword?: string;
  sample?: boolean;
  mediaconvert_job_id?: string;
  mediaconvert_job_status?: string;
  source?: StockMediaSource;
  silenceIds?: string[];
}

export type AudioProcessingStatusWrapper = {
  [key in AudioProcessingStatus]?: DateString;
};

/*
 * w, h, x and y will be parsed as percentage according to total width and height
 * z is the z-index of the asset
 * w, h default to 100
 * x, y default to 0
 */
export type ObjectFitType = 'cover' | 'contain' | 'contain-blended-top' | 'contain-background-blur';
interface VisibleAssetPreference {
  w?: number;
  h?: number;
  x?: number;
  y?: number;
  z?: number;
  object_fit?: ObjectFitType
  effect?: 'film-burn' | 'quick-zoom-out'
}

export interface TextPosition {
  justifyContent: 'flex-start' | 'center';
  alignItems: 'flex-start' | 'center';
}
export type BackgroundType = 'full' | 'block' | 'transparent';

export interface OverlayStyle {
  backgroundType?: BackgroundType;
  backgroundColor?: string;
  textColor?: string;
  textPosition?: TextPosition;
  fontSize?: number;
  fontFamily?: VideoTemplateFonts;
}

export interface TextPreference {
  fontFamily?: VideoTemplateFonts | MagicEditFonts;
  subtitleType?: SubtitleOptions;
  color?: string;
  capitalization?: boolean;
  primaryColor?: string;
  wordLimit?: SubtitleWordLimit;
  positionY?: number;
  fontSize?: number;
  animation?: SubtitleAnimationType;
  animationColor?: string;
  fontBordered?: string;
  fontWeight?: string | number;
  wordSpacing?: number;
}

export interface WatermarkPosition {
  justifyContent: 'flex-start' | 'center' | 'flex-end';
  alignItems: 'flex-start' | 'center' | 'flex-end';
}

export interface WatermarkPreference {
  position?: WatermarkPosition;
  size?: number;
  url?: string;
}

export enum SubtitleOptions {
  NONE = 'None',
  CC = 'Closed Caption',
  BIG_TITLE = 'Big Title',
  WORD_BY_WORD = 'Word by Word',
  REELS = 'Reels'
}

export enum SubtitleWordLimit {
  MORE = 35,
  LESS = 20,
  LESS_2 = 15,
  ONE = 1
}

export enum SubtitleAnimationType {
  BoxHighlight = 'Box Highlight',
  None = 'None'
}

interface SubtitleItem {
  f: string;
  t: string;
  c: string;
}

export type SubtitleItemWithPreference = SubtitleItem & TextPreference;

interface GeneralPreference {
  templateColor?: VideoTemplateColours | string;
  dimensionType?: DimensionType;
  hidePromotion?: boolean;
  hideSubtitles?: boolean;
  hideOnboarding?: boolean;
  is_deleted?: boolean;
}

export enum VideoTemplateColours {
  BLACK = 'black', // only used for textColor
  WHITE = 'white', // white is default.
  HONEY_YELLOW = 'honey_yellow',
  VIVID_BLUE = 'vivid_blue',
  CORAL_RED = 'coral_red',
  GRADIENT_GREY = 'gradient_grey',
  GRADIENT_GREEN = 'gradient_green',
  GRADIENT_BLUE = 'gradient_blue',
  GRADIENT_ORANGE = 'gradient_orange',
  GRADIENT_PURPLE = 'gradient_purple',
  GRADIENT_BLUE_GREEN = 'gradient_blue_green',
  GRADIENT_LIGHT_PINK_YELLOW = 'gradient_light_pink_yellow',
  GRADIENT_YELLOW_PINK = 'gradient_yellow_pink'
}

export type SlideshowPreferences = TextPreference & GeneralPreference & MagicSlideshowPreferences;

export interface MagicSlideshowPreferences {
  activateBgm?: boolean;
  activateTransition?: boolean;
  activateEmoji?: boolean;
  activateInitialAnimation?: boolean;
  activateHook?: boolean;
  activateColorFilter?: boolean;
}

export interface GenerateSlideshowVideoConfig {
  id: string;
  author_id: string;
  assets: Asset[];
  preferences: SlideshowPreferences;
  watermark: WatermarkPreference;
  subtitle_status: GenericSubtitleStatus;
  initial_asset_status: GenericSubtitleStatus;
  silence_assets: SilenceAsset[];
  generate_initial_assets: boolean;
  slideshow_context?: IPrompt | null;
  type?: 'magic' | 'slideshow';
  hook?: string;
  music?: {
    url: string;
    category?: string;
    volume?: number;
  };
  emojis?: {
    e: string;
    c: string;
  }[];
  filter?: {
    filter: string;
    category: string;
  };
  created_at: string;
  last_updated: string;
  duration: number;
  video_title: string;
  is_deleted?: boolean;
  url?: string;
  thumbnail_url?: string;
  is_debian?: boolean;
  file_size_in_MB?: number;
  preset?: string
}

export interface GenerateSlideshowVideoParams
  extends GenerateSlideshowVideoConfig {
  subtitlesContent: SubtitleItemWithPreference[];
}

/*
 * Type
 */
export interface Dimension {
  W: number;
  H: number;
}

export enum StockDimensionType {
  'landscape' = 'landscape',
  'portrait' = 'portrait'
}
export enum DimensionType {
  'landscape' = 'landscape',
  'portrait' = 'portrait',
  'portrait720p' = 'portrait720p',
  'square720p' = 'square720p',
  'square' = 'square',
  'landscape720p' = 'landscape720p'
}

export type Sentence = {
  from: string;
  to: string;
  text: string;
  context: string;
};

export enum VideoTemplateFonts {
  'Circular Std' = 'Circular Std',
  'Unbounded' = 'Unbounded',
  'Solitreo' = 'Solitreo',
  'Playfair Display' = 'Playfair Display',
  'Quicksand' = 'Quicksand',
  'Lexend' = 'Lexend',
  'Dancing Script' = 'Dancing Script',
  'PoetsenOne' = 'PoetsenOne',
  'Noto' = 'Noto Sans'
}
export enum MagicEditFonts {
  'None' = 'None',
  'Inter' = 'Inter',
  'Montserrat' = 'Montserrat',
  'Poppins' = 'Poppins',
  'Anton' = 'Anton',
}

export enum videoGenerationStatus {
  tbd = 'tbd', // to be determined
  blocked_by_transcribe_pretty_completed = 'blocked_by_transcribe_pretty_completed',
  in_progress = 'in_progress',
  generated = 'generated',
  '10%' = '10%',
  '20%' = '20%',
  '30%' = '30%',
  '40%' = '40%',
  '50%' = '50%',
  '60%' = '60%',
  '70%' = '70%',
  '80%' = '80%',
  '90%' = '90%'
}

export enum SlideshowCRFValue {
  Studio = 10,
  SocialMedia = 20,
  Web = 30,
}