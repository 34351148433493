import { AssetType, DimensionType, GenerateSlideshowVideoConfig, StockDimensionType } from '@/slideshow/types';
import { TextOutput } from '@/audiogram/components/TextSequence';
import { fetchSlideshowSubtitleIfTheyExist, getHighlightedTextSentence } from './generalUtilities';
import Media, { VisualGenerationResult } from '@/models/Media';
const generateVisualsForSubtitles = async (slideshow: GenerateSlideshowVideoConfig) => {
  const subtitleJson = (await fetchSlideshowSubtitleIfTheyExist(
    slideshow.author_id,
    slideshow.id
  )) as TextOutput[];

  // Group subtitles by minute
  const groupedSubtitles = subtitleJson.reduce((acc, each) => {
    const minute = Math.floor((each.from ?? 0) / 60);
    const key = minute.toString();
    const content = each.c || each.content || '';
    if (acc[key]) {
      acc[key] += ` ${content}`;
    } else {
      acc[key] = content;
    }
    return acc;
  }, {} as { [key: string]: string });

  const commonProps = {
    userId: slideshow.author_id,
    slideshowId: slideshow.id,
    dimension: slideshow.preferences.dimensionType || DimensionType['portrait'],
    assetType: 'video' as AssetType,
    isSwap: false
  };

  // Create a list of promises for each group
  const mediaPromises = Object.values(groupedSubtitles).map(groupedText => {
    return Media.textToMedia({
      ...commonProps,
      context: groupedText.trim(),
      content: groupedText.trim(),
      dimension: slideshow.preferences.dimensionType === 'portrait' ? StockDimensionType.portrait : StockDimensionType.landscape
    }, slideshow, false);
  });

  // Wait for all promises to settle
  const results = await Promise.allSettled(mediaPromises);

  // Process results
  const successfulResults = results.filter(result => result.status === 'fulfilled').map(result => (result as PromiseFulfilledResult<VisualGenerationResult>).value);
  const errors = results.filter(result => result.status === 'rejected').map(result => (result as PromiseRejectedResult).reason);

  // Here you can decide what to do with successfulResults and errors
  return {
    successfulResults,
    errors
  };
}

const calculatePromptTime = (sentenceDuration: number) => Math.min((sentenceDuration * 1000) / 5, 1500)

export { generateVisualsForSubtitles, calculatePromptTime }