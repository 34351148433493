import { Colours } from '../Colours';
import { selectedCheck, unselectedCheck } from '../../assets/generalAssets';
import { tailwindToColorCode } from '../../utils/colour';
import Icon, { IconType } from './Icon';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';

interface JPInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  text?: string;
  onChangeValue?: (value: string) => void;
  placeholder: string;
  ref?: any;
  type?: string;
  name?: string;
  isValid?: boolean;
  disabled?: boolean;
  spellCheck?: boolean;
  submit?: () => void;
  showCheckMark?: boolean;
  className?: string;
  width?: string;
  styleType?: 'default' | 'old';
}

const defaultClassName = `w-full flex items-center rounded-lg border border-solid border-gray-200 bg-white px-[14px] py-[10px]  text-gray-900 shadow-xs`;
const oldClassName = `w-full flex items-center bg-transparent px-[14px] py-[10px] text-gray-900`;

const JPInput = ({
  text,
  placeholder,
  ref,
  type,
  name,
  size = 12,
  isValid = true,
  disabled,
  spellCheck,
  submit,
  showCheckMark,
  onChangeValue,
  className: injectedClassName,
  width,
  styleType,
  ...props
}: JPInputProps): JSX.Element => {
  const handleKeyDown = (event) => {
    if (submit && event.key === 'Enter') submit();
  };
  const [className, setClassName] = useState(injectedClassName);
  useEffect(() => {
    let temp = `${
      styleType === 'old' ? oldClassName : defaultClassName
    } ${injectedClassName}`;
    if (!width) temp += ` max-w-full `;
    setClassName(temp);
  }, [width, injectedClassName]);
  return (
    <div className={className}>
      <input
        name={name}
        ref={ref}
        onKeyDown={handleKeyDown}
        spellCheck={spellCheck}
        type={type || 'text'}
        disabled={disabled}
        className="flex w-full border-none bg-transparent outline-none"
        placeholder={placeholder}
        value={text}
        onChange={(e) => (onChangeValue ? onChangeValue(e.target.value) : null)}
        {...props}
      />
      {showCheckMark ? (
        <Icon color={tailwindToColorCode('blue-600')} type={IconType.Tick} />
      ) : null}
    </div>
  );
};

export default JPInput;
