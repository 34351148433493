import { ButtonVariant, JPButton } from '../OldGeneralComponents';
import { MAX_FILE_SIZE_IN_MB } from '@/models/constant';
import { getFileSizeInMB } from '@/utils/file';
import { isIOS } from '@/utils/generalUtilities';
import { recordButton, stopButton } from '../../assets/generalAssets';
import { toast } from 'react-toastify';
import Image from 'next/image';
import React from 'react';
import locale from '../../locales/en/NativeLanding';

const recordButtonSize = 145;

interface AudioButtonProps {
  src: string;
  onClick: () => void;
}

export const AudioButton = ({
  src,
  onClick
}: AudioButtonProps): JSX.Element => (
  <Image
    onClick={onClick}
    alt={'audio button state'}
    width={recordButtonSize}
    height={recordButtonSize}
    src={src}
  />
);

// TODO add request reset permissions instructions on click here.
//  ref: https://help.daily.co/en/articles/2528184-unblock-camera-mic-access-on-a-computer
//  or https://help.daily.co/en/articles/3388632-unblock-your-camera-microphone-on-mobile-android-and-ios
const PermissionDenied = (): JSX.Element => (
  <>{locale.toaster.permission_denied_error}</>
);

const audiourlToBlob = async (mediaBlobUrl) => {
  let blob = await fetch(mediaBlobUrl).then((r) => r.blob());
  return new File([blob], new Date().toISOString() + '_recording.mp3', {
    type: 'audio/mpeg'
  });
};

const formatTimeToString = (time: number): string => {
  const getSeconds = `0${time % 60}`.slice(-2);
  const minutes = `${Math.floor(time / 60)}`;
  // @ts-ignore
  const getMinutes = `0${minutes % 60}`.slice(-2);
  // const getHours = `0${Math.floor(timer / 3600)}`.slice(-2);
  // return `${getHours} : ${getMinutes} : ${getSeconds}`;
  return `${getMinutes}:${getSeconds}`;
};

interface RecordViewProps {
  onStartRecording: () => void;
  onEndRecording: (mediaBlobUrl: string) => void;
}

export const isAndroidInAppBrowser = (): boolean => {
  const ua = navigator.userAgent || navigator.vendor;
  return ua.includes('Instagram') || ua.includes('FB');
};

const RecordView = (props: RecordViewProps): JSX.Element => (
  <></>
  // <ReactMediaRecorder
  //   blobPropertyBag={{ type: 'audio/mpeg' }}
  //   audio
  //   askPermissionOnMount={false}
  //   onStop={(blobUrl, blob) => props.onEndRecording(blobUrl)}
  //   render={({ status, startRecording, stopRecording }) => {
  //     if (status === 'permission_denied') return <PermissionDenied />;
  //     if (status === 'idle')
  //       return (
  //         <AudioButton
  //           onClick={() => {
  //             props.onStartRecording();
  //             startRecording();
  //           }}
  //           src={recordButton}
  //         />
  //       );
  //     if (status === 'recording')
  //       return <AudioButton onClick={() => stopRecording()} src={stopButton} />;
  //     return <></>;
  //   }}
  // />
);

interface UploadFileFormWrapperProps {
  isFileUploading: boolean;
  onFinish: (file: File) => void;
}

const UploadFileForm = (props: UploadFileFormWrapperProps) => {
  const acceptAttribute = isIOS
    ? 'audio/mp3,audio/mpeg,audio/ma4,audio/wav'
    : 'audio/*';

  return (
    <>
      {/* <P1>{locale.recorder.upload.description}</P1> */}
      <label
        style={{ width: '100%', display: 'flex', justifyContent: 'center' }}
        htmlFor="upload-audio"
      >
        <JPButton
          style={{ marginTop: 16 }}
          variant={ButtonVariant.blue}
          isLoading={props.isFileUploading}
          text={locale.create.upload}
          onClick={() => {}}
        />
      </label>
      <input
        id="upload-audio"
        accept={acceptAttribute}
        multiple={false}
        type="file"
        value=""
        autoComplete={'new-password'}
        style={{ display: 'none' }}
        onChange={(e) => {
          if (getFileSizeInMB(e.target.files[0].size) > MAX_FILE_SIZE_IN_MB) {
            return toast.error(locale.toaster.big_file_size_error);
          }

          props.onFinish(e.target.files[0]);
        }}
      />
    </>
  );
};

export { audiourlToBlob, formatTimeToString, RecordView, UploadFileForm };
