import { atom } from 'recoil';

export const isOverlayTextModalOpen = atom({
    key: 'isOverlayTextModalOpen',
    default: false
});

export const AIOverlayText = atom({
    key: 'AIOverlayText',
    default: ''
});