import { Colours } from '../Colours';
import { P1, TransparentClickableModal } from '../OldGeneralComponents';
import React from 'react';
import styled from 'styled-components';

export interface MenuOptions {
  title: string;
  isVisible: boolean;
  onClick: any;
  isDestructive?: boolean;
  menuItemClass?: string;
  image?: JSX.Element;
}

interface MenuProps {
  isMenuOpen?: boolean;
  isHoverable?: boolean;
  onLeave?: () => void;
  menuOptions: MenuOptions[];
  customContainerStyle?: React.CSSProperties;
  customTextStyle?: React.CSSProperties;
}

const MenuItem = styled.div`
  &:hover {
    background: ${Colours.OffWhite};
  }
`;

const Menu = ({
  isMenuOpen,
  isHoverable,
  onLeave,
  menuOptions,
  customContainerStyle,
  customTextStyle
}: MenuProps): JSX.Element => (
  <>
    {isMenuOpen ? <TransparentClickableModal onClick={onLeave} /> : null}
    <div
      style={customContainerStyle}
      className={`absolute right-1 top-5 z-[200] rounded-lg bg-white py-[6px] shadow-lg ${
        !isMenuOpen && isHoverable ? 'hidden group-hover:block' : ''
      }`}
    >
      {menuOptions.map((options, index) => {
        if (!options.isVisible) return <div key={index}></div>;
        return (
          <div key={index}>
            <MenuItem
              onClick={options.onClick}
              className={`flex cursor-pointer flex-row items-center px-6 py-3 ${options.menuItemClass}`}
            >
              {options.image}
              <P1
                style={Object.assign(
                  {
                    color: options.isDestructive
                      ? Colours.AccentCoral
                      : Colours.grey[700]
                  },
                  customTextStyle
                )}
              >
                {options.title}
              </P1>
            </MenuItem>
            {menuOptions.length - 1 !== index ? <Divider /> : null}
          </div>
        );
      })}
    </div>
  </>
);

interface DividerProps {
  color?: string;
}

export const Divider = ({ color = Colours.NewWhite }: DividerProps) => (
  <div
    style={{
      borderTop: `2px solid ${color}`
    }}
  />
);

export default Menu;
