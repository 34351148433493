import { GenerateSlideshowVideoConfig } from '@/slideshow/types';
import {
  VisualGenerationReqBody,
  VisualGenerationResult
} from '@/models/Media';
import { firestore } from '../models/firebase';
import Email, { SIB_EVENTS } from '../models/Email';
import shortUUID from 'short-uuid';

export interface TestCase {
  id: string;
  enabled: boolean;
  url: string;
  method: 'GET' | 'POST';
  body: string;
  token?: string;
  last_updated: Date;
  slideshow_id: string;
  slideshow_before_error: string;
  slideshow_after_error?: string;
  author_id: string;
  error: any;
}

const testHelper = {
  data: null,
  collectionPath: 'tests',
  async checkCurrentlyExist(
    slideshowId: string,
    authorId: string,
    content: string
  ) {
    const snap = await firestore
      .collection(this.collectionPath)
      .where('slideshow_id', '==', slideshowId)
      .where('author_id', '==', authorId)
      .get();
    if (snap.empty) return false;
    let exists = false;
    snap.docs.map((d) => {
      if ((d.data().body as VisualGenerationReqBody).content === content)
        exists = true;
    });
    return exists;
  },
  async createOnFirestore(newTest: Omit<TestCase, 'id'>) {
    try {
      const exists = await this.checkCurrentlyExist(
        newTest.slideshow_id,
        newTest.author_id,
        JSON.parse(newTest.body).content
      );
      if (exists) return;
      const slideshowSnap = await firestore
        .collection('slideshows')
        .doc(newTest.slideshow_id)
        .get();
      const slideshow = slideshowSnap.data();
      this.data = {
        ...newTest,
        id: shortUUID.generate(),
        last_updated: new Date(),
        slideshow_after_error: JSON.stringify(slideshow)
      };

      // TODO: debug the s.indexOf error while setting the data
      await firestore
        .collection(this.collectionPath)
        .doc(this.data.id)
        .set(this.data);
      Email.track(SIB_EVENTS.VISUALS_ERROR, this.data);
      this.data = null;
    } catch (err) {
      console.error('Error occured in createOnFirestore method: ', err);
    }
  },
  async isError(json: VisualGenerationResult) {
    const { new_assets = [], sentences = [] } = json;
    let hasError = false;
    if (!new_assets?.length || !sentences?.length) {
      hasError = true;
    }
    for (let i = 0; i < new_assets.length; i++) {
      const assetOrError = new_assets[i];
      if (assetOrError.error) {
        hasError = true;
        break;
      }
    }
    return hasError;
  }
};

export default testHelper;
