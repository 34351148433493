async function measureLatency(endpoint: string) {
  const startTime = performance.now();

  try {
    // Send a HEAD request to the endpoint
    const response = await fetch(endpoint, {
      method: 'GET',
      mode: 'no-cors'
    });
    const endTime = performance.now();
    return endTime - startTime; // Latency in milliseconds
  } catch (error) {
    console.error(`Error pinging ${endpoint}:`, error);
    return Infinity; // Return a high value if the request fails
  }
}

export async function findClosestRegion() {
  const regions = {
    asia: 'https://s3.ap-southeast-1.amazonaws.com',
    northAmerica: 'https://s3.us-east-1.amazonaws.com'
  };

  const latencies = await Promise.all(
    Object.entries(regions).map(async ([region, endpoint]) => {
      const latency = await measureLatency(endpoint);
      return { region, latency };
    })
  );

  // Find the region with the lowest latency
  latencies.sort((a, b) => a.latency - b.latency);
  return latencies[0]; // Closest region
}
