import * as Select from '@radix-ui/react-select';
import React from 'react';
import classNames from 'classnames';

interface SelectItemProps {
  children: React.ReactNode;
  className?: string;
  value: string;
}
const SelectItem = React.forwardRef<HTMLDivElement, SelectItemProps>(
  ({ children, className, ...props }, forwardedRef) => {
    return (
      <Select.Item
        className={classNames(
          'data-[disabled]:text-mauve8 relative flex h-10 cursor-pointer select-none items-center rounded-[3px] pl-[25px] pr-[35px] text-sm leading-none text-gray-700 data-[disabled]:pointer-events-none data-[highlighted]:bg-blue-600 data-[highlighted]:text-white data-[highlighted]:outline-none',
          className
        )}
        {...props}
        ref={forwardedRef}
      >
        <Select.ItemText>{children}</Select.ItemText>
      </Select.Item>
    );
  }
);

export default SelectItem;
