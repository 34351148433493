import React, { RefObject, createContext, useContext } from 'react';

// Create Context
export const TabContainerRefContext = createContext<{
  tabContainerRef: RefObject<HTMLDivElement> | null;
  isManualScrollActive: boolean;
} | null>(null);

export const useTabContainer = () => {
  const context = useContext(TabContainerRefContext);
  if (!context) {
    throw new Error(
      'useTabContainer must be used within a TabContainerRefContext.Provider'
    );
  }
  return context;
};
