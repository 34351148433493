import 'firebase/compat/auth';
import {
  LocalStorageItems,
  PageNames,
  getItem,
  hideNewNav,
  setItem
} from '../../utils/generalUtilities';
import { NewJPButton } from '../GeneralComponents';
import { P1 } from '../OldGeneralComponents';
import { ScreenTypes, useScreenType } from '../../hooks/useScreenType';
import { UpgradeSubscriptionModal } from '../Modals/UpgradeSubscriptionModal';
import { logoWithName } from '@/assets/generalAssets';
import { useModal } from '@/hooks/useModal';
import { useRouter } from 'next/router';
import { useUser } from '@/hooks/useUser';
import AvatarWithDropdown from '../JPHeader/AvatarWithDropdown';
import CreateNewButton from '../JPHeader/CreateNewButton';
import Icon, { IconType } from './Icon';
import Image from 'next/image';
import JPHeader, { Announcement } from './JPHeader';
import Link from 'next/link';
import React, { useEffect, useRef, useState } from 'react';
import User from '../../models/User';
import locale from '../../locales/en/NativeLanding';

const DashboardHeader = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [avatar, setAvatar] = useState('');
  const [userName, setUserName] = useState('');
  const [slug, setSlug] = useState<string>(getItem(LocalStorageItems.URL_SLUG));
  const [isSlideshow, setIsSlideshow] = useState(false);
  const [isNavOpen, setIsNavOpen] = useState(false); // initiate isNavOpen state with false
  const [isLoaded, setIsLoaded] = useState(false);
  const screenType = useScreenType();
  const router = useRouter();
  const appHeaderRef = useRef<HTMLDivElement>();
  const [sideNavHeight, setSideNavHeight] = useState(null);

  const { hasPremium } = useUser();
  const { openModal } = useModal();

  useEffect(() => setIsLoggedIn(!!getItem(LocalStorageItems.ACCESS_TOKEN)), []);
  useEffect(() => {
    setTimeout(() => setIsLoaded(true), 500);
  }, []);

  const displayMyProfile = async () => {
    const accessToken = getItem(LocalStorageItems.ACCESS_TOKEN);
    if (!accessToken) return;
    try {
      const me = await User.me(getItem(LocalStorageItems.ACCESS_TOKEN));
      if (me === null) return;
      setIsSlideshow(me.email.includes('slideshow@jupitrr'));
      setUserName(me?.display_name || me?.email || '');

      if (getItem(LocalStorageItems.URL_SLUG)) {
        setSlug(getItem(LocalStorageItems.URL_SLUG));
      } else {
        if (me.avatar) setAvatar(me.avatar);
        if (me.url_slug) setSlug(me.url_slug);
        setItem(LocalStorageItems.AVATAR, me.avatar || '');
        setItem(LocalStorageItems.URL_SLUG, me.url_slug);
      }
    } catch (err) {
      // New account creations take time to create the mongoDb instance -- suppress gracefully.
      console.log(err.code);
    }
  };

  useEffect(() => {
    displayMyProfile().then();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const height = window.innerHeight;
      setSideNavHeight(height - appHeaderRef.current?.clientHeight);
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      handleResize();
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isLoaded]);

  const isDesktop =
    screenType === ScreenTypes.web || screenType === ScreenTypes.desktop;

  const { isInternalHeaderHidden, isPublicHeaderHidden } = hideNewNav(
    router.pathname
  );
  if (isInternalHeaderHidden && isPublicHeaderHidden) return <></>;
  if (isInternalHeaderHidden) return <JPHeader />;

  return (
    <>
      {/* DashboardHeader */}
      <div className="sticky top-0 z-50">
        {/* <Announcement /> */}
        <div className="bg-white">
          <div className="relative flex flex-col">
            <div className="bg-color-white flex w-full justify-between px-8 py-[18px]">
              <div className="z-30 flex w-full flex-row justify-between">
                <div
                  className="flex cursor-pointer flex-row items-center justify-start"
                  onClick={() => router.push(PageNames.ROOT)}
                >
                  <Image
                    priority
                    onClick={() => router.push(PageNames.ROOT)}
                    src={logoWithName}
                    height={37}
                    className={`h-[37px] w-full`}
                    alt={'jupitrr logo'}
                  />
                  {/* {isDesktop ? <DesktopLinks /> : null} */}
                </div>
                <div className="relative flex flex-row items-center">
                  {isLoggedIn ? (
                    <>
                      {hasPremium === 'free' && (
                        <NewJPButton
                          className="mr-4"
                          text={'Upgrade'}
                          size="md"
                          colorType="orange-light"
                          onClick={() =>
                            openModal(<UpgradeSubscriptionModal />, {
                              width: 400,
                              shouldCloseOnOverlayClick: true
                            })
                          }
                        />
                      )}

                      <div className="mr-4">
                        <CreateNewButton />
                      </div>
                      <AvatarWithDropdown
                        avatar={avatar}
                        isLoggedIn={isLoggedIn}
                        slug={slug}
                        isSlideshow={isSlideshow}
                        userName={userName}
                      />
                    </>
                  ) : (
                    <>
                      {isDesktop ? (
                        <>
                          <a
                            onClick={() => router.push(PageNames.LOGIN)}
                            className="mr-[14px] cursor-pointer"
                          >
                            <P1 className="rounded-md px-[18px] py-[10px] text-grey-500 hover:bg-blue-50">
                              {locale.common.login}
                            </P1>
                          </a>
                          <NewJPButton
                            text={locale.common.sign_up}
                            onClick={() => router.push(PageNames.SIGNUP)}
                            size="md"
                          />
                        </>
                      ) : (
                        <div
                          className="absolute -right-[10px] cursor-pointer transition-all"
                          onClick={() => setIsNavOpen(!isNavOpen)}
                        >
                          {isNavOpen ? (
                            <Icon type={IconType.Cross} size={24} />
                          ) : (
                            <Icon type={IconType.Hamburger} size={24} />
                          )}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DashboardHeader;
