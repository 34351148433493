import { Asset } from '../types';
import { Audio, Sequence } from 'remotion';
import { EMPTY_AUDIO } from '../utils/constant';
import { FPS } from '../Video';
import React, { useEffect, useState } from 'react';
import calculateFPS from '../utils/FPS';

const AudioContainer = ({ asset, index }: { asset: Asset; index?: number }) => {
  let durationInFrames = calculateFPS(Number(asset.t) - Number(asset.f));
  const [url, setUrl] = useState(asset.url);
  useEffect(() => setUrl(asset.url), [asset.url]);
  return (
    <Sequence
      name={`Audio ${index}`}
      key={index}
      from={calculateFPS(Number(asset.f))}
      durationInFrames={Math.max(1, durationInFrames)}
    >
      <Audio
        src={url}
        pauseWhenBuffering
        onError={(e) => {
          console.error('eror spotted getting audio');
          console.error(e);
          setUrl(EMPTY_AUDIO);
        }}
      />
    </Sequence>
  );
};

export default AudioContainer;
