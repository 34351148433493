import { PlayerRef } from '@remotion/player';
import React, {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef
} from 'react';

interface VideoPreviewContextType {
  videoPreviewRef: React.RefObject<PlayerRef>;
}

const VideoPreviewContext = createContext<VideoPreviewContextType | undefined>(
  undefined
);

interface VideoPreviewProviderProps {
  children: ReactNode;
}

export const VideoPreviewProvider: React.FC<VideoPreviewProviderProps> = ({
  children
}) => {
  const videoPreviewRef = useRef<PlayerRef>(null);
  useEffect(() => {
    if (videoPreviewRef.current) {
      console.log(videoPreviewRef.current);
    }
  }, [videoPreviewRef]);
  return (
    <VideoPreviewContext.Provider value={{ videoPreviewRef }}>
      {children}
    </VideoPreviewContext.Provider>
  );
};

export const useVideoPreview = () => {
  const context = useContext(VideoPreviewContext);
  if (context === undefined) {
    throw new Error(
      'useVideoPreview must be used within a VideoPreviewProvider'
    );
  }
  return context;
};
