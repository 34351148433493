import { Colours } from '../../components/Colours';
import { DimensionType, WatermarkPosition } from '../types';
import { FONT_FAMILY, fontFamilyLight } from '../utils/constant';
import { Img } from 'remotion';
import React, { useState } from 'react';

const JUPITRR_WATERMARK = () => {
  const [logo, setLogo] = useState(
    'https://jupitrr-staging-playground.s3.ap-southeast-1.amazonaws.com/new_jupitrr_logo.svg'
  );
  const watermarkStyles = {
    container: {
      position: 'relative' as const,
      display: 'flex',
      height: '6rem', // h-24
      flexDirection: 'column',
      alignItems: 'flex-start',
      borderRadius: '0.5rem',
      backgroundColor: 'white',
      padding: '0.75rem',
      fontSize: '1.5rem',
      boxShadow: '0px 24px 48px -12px rgba(16,24,40,0.18)',
      fontFamily: FONT_FAMILY()
    },
    text: {
      fontSize: '0.875rem', // text-sm
      color: '#6B7280' // assuming this is the light-gray color
    },
    image: {
      marginTop: '5px',
      height: '3rem' // h-12
    }
  };
  return (
    <div
      style={{ ...watermarkStyles.container, flexDirection: 'column' as const }}
    >
      <div style={watermarkStyles.text}>CREATED WITH</div>
      <Img
        style={watermarkStyles.image}
        alt="Made with Jupitrr"
        src={logo}
        onError={() =>
          setLogo(
            'https://jupitrr-staging-playground.s3.ap-southeast-1.amazonaws.com/new_jupitrr_logo.svg'
          )
        }
      />
    </div>
  );
};

// Add these styles
const footerStyles = {
  base: {
    position: 'absolute' as const,
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontSize: '1.5rem', // text-2xl
    color: 'white'
  },
  landscape: {
    bottom: '90px',
    right: '195px',
    transform: 'scale(1.3)'
  },
  portrait: {
    bottom: '175px',
    right: '310px',
    transform: 'scale(1.8)'
  },
  square: {
    bottom: '70px',
    left: '65px'
  }
};

export const Footer: React.FC<{
  horizontalPadding?: number;
  hidePromotion?: boolean;
  dimensionType: DimensionType;
  watermark?: {
    url: string;
    size: number; // Percentage size relative to the video dimensions
    position: WatermarkPosition; // Position name (e.g., "bottom-right")
  };
}> = ({
  horizontalPadding = 48,
  hidePromotion = false,
  dimensionType = DimensionType.square,
  watermark
}) => {
  if (watermark?.url)
    return (
      <>
        {watermark?.url && (
          <div
            style={{
              position: 'absolute',
              zIndex: 5,
              top: '0',
              left: '0',
              width: '100%',
              display: 'flex',
              height: '100%',
              paddingLeft: '3rem',
              paddingRight: '3rem',
              paddingTop: '5rem',
              paddingBottom: '10rem',
              justifyContent: watermark.position.justifyContent,
              alignItems: watermark.position.alignItems
            }}
          >
            <img
              src={watermark.url}
              alt="Watermark"
              style={{
                width: `${watermark.size}%`,
                height: 'auto',
                objectFit: 'contain'
              }}
            />
          </div>
        )}{' '}
      </>
    );
  return (
    <div
      style={{
        ...footerStyles.base,
        ...(dimensionType === DimensionType.landscape ||
        dimensionType === DimensionType.landscape720p
          ? footerStyles.landscape
          : dimensionType === DimensionType.portrait ||
            dimensionType === DimensionType.portrait720p
          ? footerStyles.portrait
          : footerStyles.square),
        paddingLeft: horizontalPadding,
        paddingRight: horizontalPadding,
        zIndex: 4
      }}
    >
      <div></div>
      {/* Conditional rendering for the promotion */}
      {hidePromotion ? null : JUPITRR_WATERMARK()}
      {/* Render the watermark if provided */}
    </div>
  );
};
