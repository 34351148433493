// usePromisedVideoCache.ts
import { cacheVideoBlob, getCachedVideoBlob } from '../utils/indexDBCache';
import { useCallback, useState } from 'react';

interface UsePromisedVideoCacheResult {
  videoBlobUrl: string | null;
  fetchVideoBlob: () => Promise<void>;
}

const usePromisedVideoCache = (videoUrl: string): UsePromisedVideoCacheResult => {
  const [videoBlobUrl, setVideoBlobUrl] = useState<string | null>(null);

  const fetchVideoBlob = useCallback(async () => {
    const cachedBlob = await getCachedVideoBlob(videoUrl);
    if (cachedBlob) {
      setVideoBlobUrl(URL.createObjectURL(cachedBlob));
      return;
    }

    const response = await fetch(videoUrl);
    const blob = await response.blob();

    await cacheVideoBlob(videoUrl, blob);

    setVideoBlobUrl(URL.createObjectURL(blob));
  }, [videoUrl]);

  return { videoBlobUrl, fetchVideoBlob };
};

export default usePromisedVideoCache;
