import { Dimension, DimensionType } from '../types';

/*
 * Constant
 */
const DEFAULT_DIMENSIONS: Dimension = { W: 1080, H: 1080 };
const DIMENSIONS_OPTIONS: { [key in DimensionType]: Dimension } = {
  landscape: {
    W: 1920,
    H: 1080
  },
  landscape720p: {
    W: 1280,
    H: 720
  },
  portrait: {
    W: 1080,
    H: 1920
  },
  portrait720p: {
    W: 720,
    H: 1280
  },
  square: DEFAULT_DIMENSIONS,
  square720p: {
    W: 720,
    H: 720
  }
};

/*
 * Fns
 */
const getDimensionByName = (name: string): Dimension | null => {
  return DIMENSIONS_OPTIONS.hasOwnProperty(name)
    ? DIMENSIONS_OPTIONS[name]
    : null;
};

const horizontalSpaceMultiplier = (dimensionType: DimensionType): number => {
  return dimensionType === DimensionType.landscape || DimensionType.landscape720p ? 2 : 1;
};

export {
  DEFAULT_DIMENSIONS,
  DIMENSIONS_OPTIONS,
  DimensionType,
  horizontalSpaceMultiplier,
  getDimensionByName
};

export type { Dimension };
