import { TextOutput } from '@/audiogram/components/TextSequence';

import { Colours } from '../../components/Colours';
import { FONT_FAMILY } from '../utils/constant';
import { FPS } from '../Video';
import {
  MagicEditFonts,
  SlideshowPreferences,
  SubtitleAnimationType,
  SubtitleItemWithPreference,
  SubtitleOptions,
  VideoTemplateFonts
} from '../types';
import { Sequence } from 'remotion';
import { loadFont } from '@remotion/google-fonts/Inter';
import React, { useMemo } from 'react';
import nlpBase from 'compromise';
import plg from 'compromise-stats';

import {
  reelsSubtitlesPositionY,
  subtitleAnimationColor,
  subtitleAnimationType,
  subtitlesCapitalisationMode,
  subtitlesFontFamily,
  subtitlesFontSize,
  subtitlesHighlightColor,
  subtitlesPrimaryTextColor,
  subtitlesStyle
} from '../../recoil/atoms/subtitleStyleOptions.atoms';
import { transcriptContainsNonEnglishCharacters } from '../../recoil/atoms/exportSlideshow.atom';
import { useRecoilValue } from 'recoil';
import styles from './TextSequence.module.css';

nlpBase.plugin(plg);

const { fontFamily: interFontFamily } = loadFont();

const INTER_FONT_STYLES = {
  fontSize: 90,
  fontWeight: 900,
  fontStyle: 'normal',
  lineHeight: '108px',
  letterSpacing: '-1.8px',
  textShadow: '0px 10px 100px rgba(16, 24, 40, 0.6)',
  gap: '0.2em'
};

const MONTSERRAT_FONT_STYLES = {
  textTransform: 'uppercase',
  fontWeight: 900,
  fontSize: 90,
  textShadow: '0px 15px 20px rgba(16, 24, 40, 0.8)',
  lineHeight: 1,
  gap: '0.2em'
};

const ANTON_FONT_STYLES = {
  textTransform: 'uppercase',
  fontWeight: 400,
  color: '#fff',
  fontSize: 52,
  letterSpacing: '3px'
};

const POPPINS_FONT_COLOR = '#1c1e1d';

const POPPINS_FONT_STYLES = {
  letterSpacing: '-2px',
  fontSize: 52,
  background: '#e7e5e7',
  padding: '10px 24px',
  fontWeight: 700,
  borderRadius: '24px',
  color: POPPINS_FONT_COLOR
};

const SUBTITLE_VS_STYLE = {
  [SubtitleOptions.REELS]: {
    backgroundColor: Colours.Transparent,
    outline: '1px',
    outlineColor: 'black',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap'
  }
};

const SUBTITLE_CONTAINER_VS_STYLE = {
  [SubtitleOptions.REELS]: {
    left: '50%',
    transform: 'translate(-50%, 0%)',
    display: 'flex',
    justifyContent: 'center'
  }
};

const insertNewlineAtHalf = (sentence: string) => {
  const midIndex = Math.floor(sentence.length / 2);

  let newlineIndex = sentence.lastIndexOf(' ', midIndex);

  const beforeNewlineLength = newlineIndex;
  const afterNewlineLength = sentence.length - newlineIndex;

  if (beforeNewlineLength > afterNewlineLength) {
    newlineIndex = sentence.indexOf(' ', midIndex);
  }

  return `${sentence.substring(0, newlineIndex)}\n${sentence.substring(
    newlineIndex + 1
  )}`;
};

type HighlightWordProps = {
  text: SubtitleItemWithPreference | TextOutput;
  content: string;
  words: [string, number][];
  highlightColor: string;
  primaryColor: string;
  currentTime: number;
  animation: SubtitleAnimationType;
  animationColor: string;
  fontBordered: string;
  fontSize: number;
  fontFamily: string;
};

function highlightWord({
  text,
  content,
  words,
  highlightColor,
  primaryColor,
  animation,
  animationColor,
  currentTime,
  fontBordered,
  fontSize,
  fontFamily
}: HighlightWordProps) {
  const wordSet = new Set(
    words
      .map((word) => word[0].toLowerCase())
      .filter((word) => word.length >= 3 && /^[a-zA-Z]+$/.test(word))
  );
  const parts = content
    .split(/(\s+|\n)/)
    .filter((part) => part.trim() && part !== '\n');
  const highlightedWords = new Set();

  return parts.map((part, index) => {
    const isCurrentlyActive =
      'details' in text
        ? !!text.details.find(
            (detail) =>
              detail.c === part &&
              currentTime >= Number(detail.f) &&
              currentTime <= Number(detail.t)
          )
        : currentTime >= Number(text.f) && currentTime <= Number(text.t);

    const cleanedWord = nlpBase(part).normalize().out('text').toLowerCase();
    const isHighlighted =
      wordSet.has(cleanedWord) &&
      !highlightedWords.has(cleanedWord) &&
      cleanedWord.length > 3;

    if (isHighlighted) {
      highlightedWords.add(cleanedWord);
    }

    if (part === '<silence>') return null;
    return (
      <span
        key={index}
        data-text={part}
        data-font-family={fontFamily}
        className={fontBordered ? styles.textSequence : ''}
        style={{
          color: isHighlighted ? highlightColor : primaryColor,
          borderRadius: 20,
          padding:
            animation === 'Box Highlight'
              ? '10px 10px'
              : fontSize >= 90
              ? '0 12px'
              : fontSize > 70
              ? '0 10px'
              : '0 4px',
          margin: animation === 'Box Highlight' ? '0px 5px' : '0',
          backgroundColor:
            isCurrentlyActive && animation !== 'None'
              ? animationColor
              : 'transparent',
          textShadow: 'inherit'
        }}
      >
        {part}
      </span>
    );
  });
}

interface TextSequenceProps {
  text: SubtitleItemWithPreference | TextOutput;
  layout?: 'absolute-fill' | 'none';
  preferences: SlideshowPreferences;
  currentWordTiming?: SubtitleItemWithPreference | TextOutput;
  currentTime: number;
  subArrayPosition?: number;
  textSequenceArray?: number[];
}

export const TextSequence: React.FC<TextSequenceProps> = ({
  text,
  layout,
  preferences,
  currentWordTiming,
  currentTime,
  subArrayPosition,
  textSequenceArray
}) => {
  const recoilValues = {
    subtitlesPositionY: useRecoilValue(reelsSubtitlesPositionY),
    fontFamily: useRecoilValue(subtitlesFontFamily),
    subtitlesType: useRecoilValue(subtitlesStyle),
    capitalisationMode: useRecoilValue(subtitlesCapitalisationMode),
    highlightColor: useRecoilValue(subtitlesHighlightColor),
    primaryColor: useRecoilValue(subtitlesPrimaryTextColor),
    fontSize: useRecoilValue(subtitlesFontSize),
    animation: useRecoilValue(subtitleAnimationType),
    animationColor: useRecoilValue(subtitleAnimationColor),
    transcriptHaveNonEnglishChars: useRecoilValue(
      transcriptContainsNonEnglishCharacters
    )
  };

  const { pathname } = window.location;
  const isExportSlideshowOpened = pathname === '/export-slideshow';

  const values = {
    subtitlesPositionY: !isExportSlideshowOpened
      ? preferences.positionY
      : recoilValues.subtitlesPositionY,
    fontFamily: !isExportSlideshowOpened
      ? preferences.fontFamily
      : recoilValues.fontFamily,
    subtitlesType: !isExportSlideshowOpened
      ? preferences.subtitleType
      : recoilValues.subtitlesType,
    capitalisationMode: !isExportSlideshowOpened
      ? preferences.capitalization
      : recoilValues.capitalisationMode,
    highlightColor: !isExportSlideshowOpened
      ? preferences.color
      : recoilValues.highlightColor,
    primaryColor: !isExportSlideshowOpened
      ? preferences.primaryColor
      : recoilValues.primaryColor,
    fontSize: !isExportSlideshowOpened
      ? preferences.fontSize
      : recoilValues.fontSize,
    animation: !isExportSlideshowOpened
      ? preferences.animation
      : recoilValues.animation,
    animationColor: !isExportSlideshowOpened
      ? preferences.animationColor
      : recoilValues.animationColor
  };
  if (values.fontFamily === MagicEditFonts.Poppins) {
    values.primaryColor = POPPINS_FONT_COLOR;
  }

  const fontWeight = [
    VideoTemplateFonts.Quicksand,
    VideoTemplateFonts['Playfair Display'],
    VideoTemplateFonts['Dancing Script'],
    VideoTemplateFonts.Solitreo
  ].includes(values.fontFamily as VideoTemplateFonts)
    ? 700
    : 400;

  const fontFamilyToUse = useMemo(() => {
    if (recoilValues.transcriptHaveNonEnglishChars && !values.fontFamily) {
      return '"Noto Sans", sans-serif';
    }
    if (values.fontFamily === MagicEditFonts.Inter) {
      return interFontFamily;
    }
    return values.fontFamily || FONT_FAMILY();
  }, [values.fontFamily, recoilValues.transcriptHaveNonEnglishChars]);

  const content = useMemo(() => {
    if (values.subtitlesType === SubtitleOptions.CC) return text.c;

    return highlightWord({
      text,
      content: insertNewlineAtHalf(text.c),
      words: nlpBase(text.c)
        //@ts-ignore
        .tfidf()
        ?.filter((w: ['string', number]) => w[1] > 4)
        ?.filter((w: ['string', number]) => w[0].length >= 4)
        .slice(0, 1) as ['string', number][],
      highlightColor:
        values.fontFamily === MagicEditFonts.Poppins
          ? POPPINS_FONT_COLOR
          : values.highlightColor,
      primaryColor: values.primaryColor,
      currentTime,
      animation: values.animation,
      animationColor: values.animationColor,
      fontBordered: preferences.fontBordered,
      fontSize: values.fontSize,
      fontFamily: fontFamilyToUse
    });
  }, [currentTime, values, text.c]);

  const from = Number(text.f);
  const to = Number(text.t);

  const getTextStyles = () => {
    const isInter = values.fontFamily === MagicEditFonts.Inter;
    const isMontserrat = values.fontFamily === MagicEditFonts.Montserrat;
    const isPoppins = values.fontFamily === MagicEditFonts.Poppins;
    const isAnton = values.fontFamily === MagicEditFonts.Anton;
    const defaultStyles = isInter
      ? INTER_FONT_STYLES
      : isMontserrat
      ? MONTSERRAT_FONT_STYLES
      : isPoppins
      ? POPPINS_FONT_STYLES
      : isAnton
      ? ANTON_FONT_STYLES
      : {
          fontSize: values.fontSize || 74,
          fontWeight: fontWeight,
          textShadow: '1px 4px 10px #101828',
          letterSpacing: '-4px',
          gap: '0.2em'
        };

    return {
      fontFamily: fontFamilyToUse,
      textTransform: values.capitalisationMode ? 'uppercase' : 'none',
      color: (text as SubtitleItemWithPreference).color || values.primaryColor,
      ...SUBTITLE_VS_STYLE[values.subtitlesType],
      ...defaultStyles,
      fontWeight: preferences.fontWeight || defaultStyles.fontWeight,
      fontSize: values.fontSize || defaultStyles.fontSize,
      ...(preferences.fontBordered && {
        letterSpacing: isAnton ? '3px' : '-1px',
        textShadow: 0,
        '--stroke-color': preferences.fontBordered
      })
    };
  };

  const position = useMemo(() => {
    return {
      bottom: `${values.subtitlesPositionY}%`
    };
  }, [values.subtitlesPositionY, values.fontFamily, preferences.positionY]);

  return (
    <Sequence
      name={`Subtitle at ${from}`}
      layout={layout || 'absolute-fill'}
      from={Math.ceil(from) <= 0 ? 0 : Math.ceil(from * FPS)}
      durationInFrames={
        Math.ceil(to - from) <= 0 ? FPS : Math.ceil(to - from) * FPS
      }
      style={{ zIndex: 100 }}
    >
      <div
        style={{
          textAlign: 'center',
          color: 'white',
          fontFamily: fontFamilyToUse,
          position: 'absolute',
          width: '100%',
          ...position,
          ...SUBTITLE_CONTAINER_VS_STYLE[values.subtitlesType]
        }}
      >
        {content.length > 0 && (
          <span
            aria-label="preview player subtitle container"
            style={getTextStyles()}
            data-text={content}
          >
            {content}
          </span>
        )}
      </div>
    </Sequence>
  );
};
