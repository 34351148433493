import 'firebase/compat/firestore';
import {
  Asset,
  AssetType,
  GenerateSlideshowVideoConfig,
  GenericSubtitleStatus
} from '../slideshow/types';
import { DEFAULT_SUBTITLES_STYLES_OPTIONS } from '@/slideshow/utils/constant';
import { VIDEO_ROUTE } from '../models/constant';
import { VideoGenerationCheckerFS } from '../types/firestore/videoGenerationCheckerFS';
import { firestore } from '../models/firebase';
import { generateFormattedTime } from '../utils/generalUtilities';
import { getMediaDurationFromUrl } from '@/utils/file';
import { isJupitrrS3File } from '@/utils/s3';
import { isUserUploadedVideo } from '@/slideshow/utils/asset';
import { toast } from 'react-toastify';
import AssetManager from './assetHelper';
import _ from 'lodash';
import fetchWrapper, { MethodType } from '../utils/fetchWrapper';
import firebase from 'firebase/compat/app';

export enum VideoTemplateOptions {
  DEFAULT = 'default',
  WITH_IMAGE = 'with_image',
  PRODUCT_HUNT = 'product_hunt'
}

export enum VideoTemplateColours {
  BLACK = 'black', // only used for textColor
  WHITE = 'white', // white is default.
  HONEY_YELLOW = 'honey_yellow',
  VIVID_BLUE = 'vivid_blue',
  CORAL_RED = 'coral_red',
  GRADIENT_GREY = 'gradient_grey',
  GRADIENT_GREEN = 'gradient_green',
  GRADIENT_BLUE = 'gradient_blue',
  GRADIENT_ORANGE = 'gradient_orange',
  GRADIENT_PURPLE = 'gradient_purple',
  GRADIENT_BLUE_GREEN = 'gradient_blue_green',
  GRADIENT_LIGHT_PINK_YELLOW = 'gradient_light_pink_yellow',
  GRADIENT_YELLOW_PINK = 'gradient_yellow_pink'
}

export enum VideoTemplateNames {
  DEFAULT = 'DefaultRender',
  WITH_IMAGE = 'ImageRender',
  PRODUCT_HUNT = 'ProductHuntRender',
  FULL_SIZE = 'FullSizeRender',
  PODCAST = 'PodcastRender'
}

interface SlideshowFirestoreHelper {
  collectionPath: string;
  data: GenerateSlideshowVideoConfig | null;
  create(id: string, authorId: string): Promise<GenerateSlideshowVideoConfig>;
  generateVideo(
    userId: string,
    slideshowId: string,
    crfValue?: number
  ): Promise<
    | { success: true; data: VideoGenerationCheckerFS }
    | {
        success: false;
        error: { statusCode: number; code: string; message: string };
      }
  >;
  compressVideo(
    userId: string,
    slideshowId: string
  ): Promise<{ success: boolean; compression_status: 'STARTED' | 'NONE' }>;
  addAssetToSlideshow(id: string, url: string, type: AssetType): Promise<void>;
  updateFirestore(): Promise<void>;
  createOnFirestore(): Promise<void>;
  getDataFromFirestore(
    slideshowId: string
  ): Promise<GenerateSlideshowVideoConfig | null>;
  createAssetViaUrl(url: string): Promise<{ success: boolean }>;
  randomizeAsset(assetId: string): Promise<Asset>;
  rateAsset(from: string, to: string, rating: number): Promise<void>;
  cleanData(): void;
  deleteSlideShow(slideshowId: string): void;
  locateAssetByTimestamp(f: string, t: string): Asset;
  locateMainVideo(): Asset;
  updateAssetTimestamp(
    assetId: string,
    updateTo: { f?: string; t?: string }
  ): Promise<Asset>;
  generateThumbnail(slideshowId: string): Promise<void>;
  getPreviousWatermark(
    authorId: string
  ): Promise<{ url: string | null; position: any; size: number }>;
  updatePresetsInFirestore(
    slideshowId: string,
    presets: { [key: string]: { assets: Asset[] } }
  ): Promise<void>;
}

const slideshowFirestoreHelper: SlideshowFirestoreHelper = {
  collectionPath: 'slideshows',
  data: null,

  async getPreviousWatermark(authorId: string) {
    const defaultWatermark = {
      url: null,
      position: { justifyContent: 'flex-end', alignItems: 'flex-end' },
      size: 50
    };

    try {
      const querySnapshot = await firestore
        .collection(this.collectionPath)
        .where('author_id', '==', authorId)
        .orderBy('last_updated', 'desc')
        .limit(1)
        .get();
      if (!querySnapshot.empty) {
        const previousSlideshow = querySnapshot.docs[0].data();
        return previousSlideshow.watermark || defaultWatermark;
      }
      return defaultWatermark;
    } catch (error) {
      console.error('Error getting previous slideshow watermark:', error);
      return defaultWatermark;
    }
  },

  async create(id: string, authorId: string) {
    this.data = {
      id: id,
      author_id: authorId,
      assets: [],
      preferences: DEFAULT_SUBTITLES_STYLES_OPTIONS,
      subtitle_status: GenericSubtitleStatus.tbd,
      created_at: new Date().toISOString(),
      last_updated: new Date().toISOString(),
      duration: 10,
      video_title: '',
      initial_asset_status: GenericSubtitleStatus.tbd,
      generate_initial_assets: false,
      watermark: await this.getPreviousWatermark(authorId),
      silence_assets: []
    };
    return this.data;
  },

  async generateVideo(userId, slideshowId, crfValue) {
    try {
      const url = `${VIDEO_ROUTE}/generate/${userId}/${slideshowId}`;
      const res = await fetchWrapper(
        url,
        MethodType.POST,
        JSON.stringify({ crf: crfValue }),
        null,
        { forceResolve: true }
      );
      return res as any;
    } catch (err) {
      return { success: false, error: err };
    }
  },
  async compressVideo(userId, slideshowId) {
    try {
      const url = `${VIDEO_ROUTE}/compress/${userId}/${slideshowId}`;
      const res = await fetchWrapper(
        url,
        MethodType.POST,
        JSON.stringify({}),
        null,
        { forceResolve: true }
      );
      return res as any;
    } catch (err) {
      return { success: false, error: err };
    }
  },

  async generateThumbnail(slideshowId: string) {
    try {
      const url = `${VIDEO_ROUTE}/generate-thumbnail/${slideshowId}`;
      const res = await fetchWrapper(url, MethodType.POST, null, null);
      return res as any;
    } catch (err) {
      return { success: false, error: err };
    }
  },

  async addAssetToSlideshow(id, url, type) {
    const audioDuration = await getMediaDurationFromUrl(url);
    const newAsset: Asset = {
      f: '0',
      t: audioDuration.toString(),
      id: id,
      url: url,
      type: type
    };
    if (this.data === null) return;
    this.data.last_updated = new Date().toISOString();
    this.data.assets = this.data.assets || [];
    this.data.assets.push(newAsset);
    this.data.video_title = this.data.video_title ?? generateFormattedTime();
    return;
  },
  async updateFirestore() {
    if (this.data === null || !this.data.id) return;
    try {
      const dataToUpdate = _.cloneDeep(this.data);
      delete dataToUpdate.subtitle_status;
      await firestore
        .collection(this.collectionPath)
        .doc(this.data.id)
        .update(dataToUpdate);
    } catch (err) {
      console.log('failed to updateFirestore in slideshowHelper- ', this.data);
      console.error(err);
    }
  },
  async createOnFirestore() {
    if (this.data === null || !this.data.id) return;
    await firestore
      .collection(this.collectionPath)
      .doc(this.data.id)
      .set(this.data);
  },
  async getDataFromFirestore(slideshowId: string) {
    const doc = await firestore
      .collection(this.collectionPath)
      .doc(slideshowId)
      .get();
    if (doc.exists) {
      const data = doc.data();
      this.data = data as GenerateSlideshowVideoConfig;
      return data as GenerateSlideshowVideoConfig;
    }
    return null;
  },
  async createAssetViaUrl(url: string) {
    const createdAsset = await AssetManager.createAssetViaRemoteUrl(
      this.data.author_id,
      this.data.id,
      url,
      true
    );
    if (!createdAsset.success) {
      toast.error('Error uploading file');
      return { success: false };
    }
    this.addAssetToSlideshow(
      createdAsset.asset.id,
      createdAsset.asset.url,
      createdAsset.asset.type
    );
    return { success: true };
  },
  async randomizeAsset(assetId) {
    if (!this.data) {
      toast.error('Video not found');
      return;
    }
    const currentAsset = this.data.assets.find((asset) => asset.id === assetId);
    if (!currentAsset) {
      toast.error('Asset not found on randomizing videos');
      return;
    }

    const newAsset = await AssetManager.randomizeAsset(assetId);
    if (!newAsset) {
      /* Error toasted in AssetManager */
      return;
    }

    let matchedIndex = -1;
    for (let i = 0; i < this.data.assets.length; i++) {
      if (this.data.assets[i].id === assetId) {
        matchedIndex = i;
        this.data.assets[i].url = newAsset.url;
        delete this.data.assets[i].rating;
        break; // Assuming you want to replace the value for only one object
      }
    }
    await this.updateFirestore();
    /* Reset Ratings for new url */
    await AssetManager.updateAsset(assetId, {
      rating: firebase.firestore.FieldValue.delete(),
      last_updated: new Date().toISOString()
    });
    return this.data.assets[matchedIndex];
  },
  async rateAsset(from: string, to: string, rating: number) {
    if (!this.data) {
      toast.error('Video not found');
      return;
    }
    const asset = this.locateAssetByTimestamp(from, to);

    if (!asset) {
      console.error(
        'cannot find asset for from ' + from + 'at ' + JSON.stringify(this.data)
      );
      return;
    }

    for (let i = 0; i < this.data.assets.length; i++) {
      if (this.data.assets[i].id === asset.id) {
        this.data.assets[i].rating = rating;
        break; // Assuming you want to replace the value for only one object
      }
    }
    this.data.last_updated = new Date().toISOString();
    await this.updateFirestore();
    await AssetManager.updateAsset(asset.id, {
      rating: rating,
      last_updated: new Date().toISOString()
    });
    return;
  },

  locateAssetByTimestamp(f, t) {
    if (!this.data) return;
    return (
      this.data.assets.find(
        (a) =>
          parseFloat(a.f) === parseFloat(f) && parseFloat(a.t) === parseFloat(t)
      ) || null
    );
  },
  async updateAssetTimestamp(assetId, update) {
    if (!this.data) return;
    if (update.f === null || update.t === null) return;
    if (parseFloat(update.f) > parseFloat(update.t)) {
      toast.error(
        'There are some issues, we are refreshing the page so it will not happen again!'
      );
      setTimeout(() => window.location.reload(), 1000);
      return;
    }
    let updatedAsset = null;
    const assets = this.data.assets.map((a) => {
      if (a.id === assetId) {
        updatedAsset = {
          ...a,
          f: update.f,
          t: update.t
        };
        return updatedAsset;
      }
      return a;
    });
    this.data.assets = assets;
    await this.updateFirestore();
    return updatedAsset;
  },
  cleanData() {
    this.data = null;
  },
  async deleteSlideShow(slideshowId) {
    await firestore.collection(this.collectionPath).doc(slideshowId).delete();
    this.data = null;
  },
  locateMainVideo() {
    return this.data.assets.find((a) => {
      return !isUserUploadedVideo(a.url) && isJupitrrS3File(a.url);
    });
  },
  async updatePresetsInFirestore(slideshowId, presets) {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      console.error('No authenticated user');
      return;
    }

    try {
      const slideshowDoc = await firestore
        .collection(this.collectionPath)
        .doc(slideshowId)
        .get();

      if (!slideshowDoc.exists) {
        toast.error('Slideshow not found');
        return;
      }

      const updatePromises = Object.keys(presets).map(async (presetName) => {
        const presetRef = firestore
          .collection(this.collectionPath)
          .doc(slideshowId)
          .collection('presets')
          .doc(presetName);

        const updateData = {
          assets: presets[presetName].assets || [],
          last_updated: new Date().toISOString(),
          author_id: currentUser.uid // Added author_id to match isCurrentUser() rule
        };

        await presetRef.set(updateData, { merge: true });
      });

      await Promise.all(updatePromises);
    } catch (error) {
      console.error('Preset update error:', error);
    }
  }
};

const collectionPath = 'slideshows';

export type EnhancedSlideshow = GenerateSlideshowVideoConfig & {
  status: string;
  unsubscribe: () => void;
};

const getUserSlideshows = async (
  userId: string,
  limit: number = 100,
  lastUpdatedValue: string = null
): Promise<EnhancedSlideshow[]> => {
  let query = firestore
    .collection(collectionPath)
    .where('author_id', '==', userId)
    .orderBy('last_updated', 'desc');
  // Apply pagination if lastUpdatedValue exists
  if (lastUpdatedValue) {
    query = query.startAfter(lastUpdatedValue);
  }
  let querySnapshot = await query.limit(limit).get();
  if (querySnapshot.empty) return [];

  const slideshows = querySnapshot.docs.map((doc) => doc.data());

  // Set up real-time listeners for video status
  const videoStatusListeners = slideshows.map(
    (slideshow: GenerateSlideshowVideoConfig) => {
      return new Promise<EnhancedSlideshow>((resolve) => {
        const unsubscribe = firestore
          .collection('video_generation_checkers')
          .where('author_id', '==', userId)
          .where('slideshow_id', '==', slideshow.id)
          .limit(1)
          .onSnapshot((snapshot) => {
            if (!snapshot.empty) {
              const videoStatus = snapshot.docs[0].data();
              resolve({
                ...slideshow,
                status: videoStatus.current_status,
                type: slideshow.type || 'slideshow',
                unsubscribe
              });
            } else {
              resolve({
                ...slideshow,
                status: 'draft',
                type: slideshow.type || 'slideshow',
                unsubscribe
              });
            }
            // unsubscribe(); // Unsubscribe after getting the initial status
          });
      });
    }
  );

  return Promise.all(videoStatusListeners);
};

export default slideshowFirestoreHelper;
export { getUserSlideshows };
