import { FPS } from '../../slideshow/Video';
import { PARAGRAPH_SEPARATOR } from './AssetSummary';
import { PlayerRef } from '@remotion/player';
import { SilenceAsset } from '../../slideshow/types';
import { TextOutput } from '../../audiogram/components/TextSequence';
import { useCurrentPlayerFrame } from '../../hooks/useCurrentPlayerTime';
import { useSlideShow } from '../../contexts/SlideShowContext';
import { useTabContainer } from '../../hooks/useTabContainer';
import React, { useEffect, useRef } from 'react';

export const Transcript = ({
  subtitles,
  silenceAssets,
  videoPreviewRef,
  onTranscriptHover
}: {
  subtitles: TextOutput[];
  silenceAssets: SilenceAsset[];
  videoPreviewRef: React.RefObject<PlayerRef>;
  onTranscriptHover: (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    isActive: boolean
  ) => void;
}) => {
  const { isMainVideoLoaded } = useSlideShow();
  const currentFrame = useCurrentPlayerFrame(
    videoPreviewRef,
    isMainVideoLoaded
  );

  if (!subtitles || !subtitles?.length) return;

  const currentTime = currentFrame / FPS;

  const combinedArray = [];
  let subtitleIndex = 0;
  let silenceIndex = 0;

  while (
    subtitleIndex < subtitles.length ||
    silenceIndex < silenceAssets.length
  ) {
    const subtitle = subtitles[subtitleIndex];
    const silenceAsset = silenceAssets[silenceIndex];

    if (silenceAsset && subtitle) {
      if (Number(silenceAsset.subtitleAsset.f) < Number(subtitle.f)) {
        combinedArray.push({
          content: silenceAsset.subtitleAsset,
          type: 'silence',
          silenceId: silenceAsset.silence_id
        });
        silenceIndex++;
      } else {
        combinedArray.push({ content: subtitle, type: 'subtitle' });
        subtitleIndex++;
      }
    } else if (subtitle) {
      combinedArray.push({ content: subtitle, type: 'subtitle' });
      subtitleIndex++;
    } else if (silenceAsset) {
      console.log('silenceAsset', silenceAsset);
      combinedArray.push({
        content: silenceAsset.subtitleAsset,
        type: 'silence',
        silenceId: silenceAsset.silence_id
      });
      silenceIndex++;
    }
  }
  const highlightedIndex = combinedArray.findIndex(
    (item) =>
      currentTime >= Number(item.content.f) &&
      currentTime <= Number(item.content.t) &&
      isMainVideoLoaded
  );
  return (
    <>
      {combinedArray.map((item, index) => {
        return (
          <TranscriptContent
            totalItems={combinedArray.length} // Total items including silence
            key={`${item.type}-${index}`}
            type={item.type as 'subtitle' | 'silence'}
            id={index}
            subtitle={item.content} // Pass the TextOutput
            isHighlighted={
              item.type === 'subtitle' && index === highlightedIndex
            }
            videoPreviewRef={videoPreviewRef}
            onTranscriptHover={onTranscriptHover}
            silenceId={item.type === 'silence' ? item.silenceId : undefined}
          />
        );
      })}
    </>
  );
};

const TranscriptContent = React.memo(
  ({
    id,
    videoPreviewRef,
    subtitle,
    isHighlighted,
    onTranscriptHover,
    totalItems,
    type,
    silenceId
  }: {
    id: number;
    videoPreviewRef: React.RefObject<PlayerRef>;
    subtitle: TextOutput;
    isHighlighted: boolean;
    onTranscriptHover: (
      e: React.MouseEvent<HTMLDivElement, MouseEvent>,
      isActive?: boolean
    ) => void;
    totalItems: number;
    type: 'subtitle' | 'silence';
    silenceId?: string;
  }) => {
    const { tabContainerRef, isManualScrollActive } = useTabContainer();
    const subtitleRef = useRef<HTMLDivElement | null>(null);

    const renewVideoPreviewTimestamp = () => {
      if (!videoPreviewRef?.current) return;
      const from =
        subtitle.f !== undefined ? Number(subtitle.f) : subtitle.from;
      const timestamp = from * FPS + 1;
      videoPreviewRef.current.pause();
      videoPreviewRef.current.seekTo(timestamp);
    };
    useEffect(() => {
      if (!subtitleRef.current) return;

      if (isHighlighted) {
        subtitleRef.current.classList.add('currently-playing');

        if (!isManualScrollActive) {
          const rect = subtitleRef.current.getBoundingClientRect();
          // Only scroll, if the element goes below the visible part of screen
          if (rect.top >= tabContainerRef.current?.clientHeight) {
            // Scroll to the element if it is below the visible view
            subtitleRef.current?.scrollIntoView({ behavior: 'smooth' });
          }
        }
      } else {
        subtitleRef.current.classList.remove('currently-playing');
      }
    }, [isHighlighted]);
    if (subtitle.c === PARAGRAPH_SEPARATOR) {
      return (
        <div
          data-item={id.toString()}
          ref={subtitleRef}
          key={PARAGRAPH_SEPARATOR + id}
          className="paragraph-separator flex h-8 w-full"
        />
      );
    }

    return (
      <>
        <div
          className={`${id.toString()} my-[1px] inline-block py-[2px] text-base ${
            type === 'silence'
              ? 'border border-solid !border-gray-300 p-1 text-black shadow-lg'
              : ''
          }`}
          key={id}
          ref={subtitleRef}
          data-item={id.toString()}
          data-silence={type === 'silence' ? silenceId : undefined}
          data-from={Number(subtitle.f).toFixed(2).toString()}
          data-to={Number(subtitle.t).toFixed(2).toString()}
          onClick={() => renewVideoPreviewTimestamp()}
          onMouseOver={(e) => onTranscriptHover(e, true)}
          onMouseLeave={(e) => onTranscriptHover(e, false)}
        >
          {' '}
          {subtitle.c || subtitle.content}
        </div>
        {/* hack for making adjust highlight work for the last item */}
        {id === totalItems - 1 && (
          <>
            <div
              data-item={(id + 1).toString()}
              ref={subtitleRef}
              key={PARAGRAPH_SEPARATOR + id}
              className="paragraph-separator"
              style={{ userSelect: 'none' }}
            ></div>
            <div
              className={`${(id + 1).toString()} my-[1px] hidden`}
              key={id}
              data-item={(id + 1).toString()}
              data-from={subtitle.f.toString()}
              data-to={subtitle.t.toString()}
            ></div>
          </>
        )}
      </>
    );
  }
);
