import { BufferContext } from '../../hooks/BufferManager';
import {
  OffthreadVideo,
  RemotionMainVideoProps,
  RemotionVideoProps,
  Video,
  getRemotionEnvironment,
  useCurrentFrame
} from 'remotion';
import { isJupitrrS3File } from '../../utils/s3';
import React, {
  forwardRef,
  useContext,
  useEffect,
  useId,
  useImperativeHandle,
  useRef
} from 'react';

const PausableVideoFunction: React.ForwardRefRenderFunction<
  HTMLVideoElement,
  RemotionVideoProps & RemotionMainVideoProps
  // eslint-disable-next-line react/prop-types
> = ({ src, delayRenderTimeoutInMilliseconds, ...props }, ref) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const { isRendering } = getRemotionEnvironment();

  if (isRendering && isJupitrrS3File(src)) {
    return (
      <OffthreadVideo
        pauseWhenBuffering
        loop
        {...props}
        src={src as string}
      ></OffthreadVideo>
    );
  }

  /* Using preload auto so that it loads at premounting instead of when the video got displayed */
  return (
    <Video
      preload="auto"
      loop
      delayRenderRetries={5}
      // delayRenderTimeoutInMilliseconds={20000}
      pauseWhenBuffering
      ref={videoRef as any}
      src={src}
      {...props}
    ></Video>
  );
};

export const PausableVideo = forwardRef(PausableVideoFunction);
