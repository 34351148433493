import { MagicEditFonts, SubtitleOptions } from '../types';

const preset_clean = {
  silence_assets: [],
  id: '8rx1YuXhb6fxro28FcENLp',
  author_id: 'CCqRN8Z3vTh4n5gsio13qyZq4Hu2',
  created_at: '2025-03-11T04:07:03.760Z',
  generate_initial_assets: true,
  watermark: {
    size: 44,
    url: null,
    position: { justifyContent: 'flex-end', alignItems: 'flex-end' }
  },
  type: 'magic',
  duration: 70.779042,
  filter: {
    filter:
      'contrast(1.15) brightness(1.02) saturate(1.05) hue-rotate(-1deg) sepia(1%)',
    category: 'Lees garden'
  },
  video_title: 'Clip17',
  file_size_in_MB: 138.49,
  subtitle_status: 'generated',
  emojis: [
    { e: '💰', c: 'profit' },
    { e: '📦', c: 'Product' },
    { e: '🔄', c: 'process' },
    { e: '🧑‍🤝‍🧑', c: 'people' },
    { e: '🚀', c: 'zero to a million' },
    { e: '✅', c: 'highest priority' },
    { e: '⚡', c: 'game-changing' },
    { e: '🎯', c: 'focus' },
    { e: '👥', c: 'people' },
    { e: '📈', c: 'highest leverage' },
    { e: '🛠️', c: 'delivering the product' }
  ],
  initial_asset_status: 'generated',
  last_updated: '2025-03-11T04:08:49.056Z',
  hook: '🚀 3 P’s to Scale from $0 to $1M! 💼',
  thumbnail_url:
    'https://jupitrr-ugc-us-east.s3-accelerate.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/slideshow-8rx1YuXhb6fxro28FcENLp--nbGL9yGAx7pZfZG4gLoGrV.mp4',
  music: {
    volume: 0,
    url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/music/Corporate+Visions.mp3'
  },
  preferences: {
    positionY: 45,
    color: '#FCD34D',
    wordLimit: 20,
    fontSize: 71,
    dimensionType: 'portrait',
    activateBgm: false,
    subtitleType: 'Reels',
    capitalization: false,
    activateTransition: false,
    fontFamily: 'Inter',
    animationColor: '#a955f7',
    activateInitialAnimation: false,
    activateEmoji: false,
    primaryColor: 'white',
    animation: 'Box Highlight',
    hidePromotion: true,
    hideSubtitles: false
  },
  assets: [
    {
      f: '0',
      t: '70.779042',
      url: 'https://jupitrr-ugc-us-east.s3-accelerate.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/slideshow-8rx1YuXhb6fxro28FcENLp--nbGL9yGAx7pZfZG4gLoGrV.mp4',
      id: 'nbGL9yGAx7pZfZG4gLoGrV',
      type: 'video'
    },
    {
      f: '0',
      t: '3',
      type: 'hook',
      id: '1dQo3XETtxBtRK6UzqZoFJ',
      text: '🚀 3 P’s to Scale from $0 to $1M! 💼'
    },
    {
      f: '12.38',
      sd_url:
        'https://media.gettyimages.com/id/2184489426/video/business-growth-with-black-and-yellow-chart-for-portrait-video.mp4?b=1&s=192_srp&k=20&c=WlEZ8XNKW_Ucd5XzdiPU1SNWXGv8jyxvvSuNu8eUSQE=',
      original_keyword: 'startup growth chart with financial milestones',
      id: '2184489426',
      type: 'video',
      hd_url:
        'https://media.gettyimages.com/id/2184489426/video/business-growth-with-black-and-yellow-chart-for-portrait-video.mp4?b=1&s=mp4-640x640-gi&k=20&c=jX3ZYAoY9x-4ushn7vJkR6HeV77yK2Bj4dc0KJRx5Ss=',
      url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/trimmed-videos/2184489426.mp4%3Fresponse-content-disposition%3Dattachment%253B%2520filename%253DGettyImages-2184489426.mp4%26response-content-type%3Dvideo%252Fmp4%26Expires%3D1741670116%26Signature%3DAQVmAhS1gt4x~s09h9pxjY4TKFRyo9-I4q883yJ4lj6Cjo0b8hHAXXQgh9cU843zGr~RnhlqIEO6lm8nh7OI11gE60N3uhaCN4PtvXmUyK7BNHkUieJpH9GTIdLpMvI9u2c5j40Tb4mJBjWoHZHa1Y~WCvLGTYDZ5jzF7g604cnxfPUshmiJ~mr4v28fwt-Adyznbt3ggPgK7DD9gZqBMNskSKYsZ-qRABqTHBnLY~K7UNWXCFMCZMAuFuxQLR-WXIkedPKP1vAJbRHNjTkHCXdEGAGjKUV4eIWA~fStTqldmlLoDfVb3~vD0s2uKh4nWjAvoNzjGd1MKOxhMUjAmg__%26Key-Pair-Id%3DKQKI5W638ZZW8',
      source: 'getty',
      t: '14.5',
      stock_media_source:
        'https://media.gettyimages.com/id/2184489426/video/business-growth-with-black-and-yellow-chart-for-portrait-video.mp4?b=1&s=mp4-640x640-gi&k=20&c=jX3ZYAoY9x-4ushn7vJkR6HeV77yK2Bj4dc0KJRx5Ss='
    },
    {
      silenceIds: [],
      sd_url:
        'https://media.gettyimages.com/id/2166155743/video/chief-engineer-with-laptop-on-factory-analyzes-the-production-process-vertical-video.mp4?b=1&s=192_srp&k=20&c=19jqlYXs-iE7iAVx3dPzeaWahKbA6U2yEXSM-i2ec0Q=',
      id: '2166155743',
      original_keyword: 'warehouse or manufacturing product assembly line',
      url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/trimmed-videos/2166155743.mp4%3Fresponse-content-disposition%3Dattachment%253B%2520filename%253DGettyImages-2166155743.mp4%26response-content-type%3Dvideo%252Fmp4%26Expires%3D1741670116%26Signature%3DYZZd5lUusjcwS7YuxplxZ2cGpNsB9IhpvXyiHZQkCnAg8JAWU4QnnUU3RAJyZIGlMB8YPw2xJ6r2CADBF9jXcltb3wkfi6IxHiJ17XmfVJ3IgGIOCNa9zZY-7MEPerSF0itlxK7u~DxGGyly09DIV5CW7naXUb1bxkEDkb36ndrUBJ6Q-s~hpvZPaiDlTVRChJtDU7fZVgYaoYaZRSrVg8KtbERBUuzGVIWYZYSLqjjjSbgowJrrUH7JSW956rogzaZrSTxZO9Z7WX930krW8DN3vCEoLy6-dnttvrdJbV1rGKqagHFzSRe4JzZ6ItUZJ8wg58WV9d0yEG9k4VFY7w__%26Key-Pair-Id%3DKQKI5W638ZZW8',
      source: 'getty',
      t: '23.64',
      f: '20.54',
      manual_keyword: 'warehouse or manufacturing product assembly line',
      type: 'video',
      hd_url:
        'https://media.gettyimages.com/id/2166155743/video/chief-engineer-with-laptop-on-factory-analyzes-the-production-process-vertical-video.mp4?b=1&s=mp4-640x640-gi&k=20&c=DLFjMzU-3L2cSfVLHNYQ8UGt7frTknH1e3rwCUUnOig=',
      stock_media_source:
        'https://media.gettyimages.com/id/2166155743/video/chief-engineer-with-laptop-on-factory-analyzes-the-production-process-vertical-video.mp4?b=1&s=mp4-640x640-gi&k=20&c=DLFjMzU-3L2cSfVLHNYQ8UGt7frTknH1e3rwCUUnOig='
    },
    {
      silenceIds: [],
      original_keyword: 'team brainstorming business strategy on whiteboard',
      sd_url:
        'https://media.gettyimages.com/id/1454221610/video/female-executive-outlining-plan-on-a-glass-blackboard-drawing-with-marker-and-explaining.mp4?b=1&s=192_srp&k=20&c=dacsMS1lugthtCBh3G2kYLmojM5lzSp98pvgttnxrWM=',
      type: 'video',
      t: '35.94',
      f: '32.44',
      id: '1454221610',
      hd_url:
        'https://media.gettyimages.com/id/1454221610/video/female-executive-outlining-plan-on-a-glass-blackboard-drawing-with-marker-and-explaining.mp4?b=1&s=mp4-640x640-gi&k=20&c=2UPulL8vx9L8HCb4HZZAglnqUU3-u-5AP7a3HDZMyGY=',
      manual_keyword: 'team brainstorming business strategy on whiteboard',
      source: 'getty',
      url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/trimmed-videos/1454221610.mp4%3Fresponse-content-disposition%3Dattachment%253B%2520filename%253DGettyImages-1454221610.mp4%26response-content-type%3Dvideo%252Fmp4%26Expires%3D1741670116%26Signature%3DHcqS2bI~NWTaKIiaKdrlB7X0r2ZuLX0MOYG1zuTPT4FCDnlmBZ8pJKwGCUxV4dCgWbQ3imCqLzUFzUXwcJm6JSwmf3JlDGVjUMUH9vYPXVCVLI6B7sNNbz33lKUkDrQRgBj4~YYPw7mXtW21cIvcfGmI8nx1o-qZTmF6~~Ct10bKufrvNhl6UTWcjr41n07iQ4IMPB~rNMIHJbcnAxLSemjgqioU52Br2WGgUkzZ-dh-I2HohLgjk5hHzX7WVuh2plMRdTZ99GgCBkPOQkhGrV9KrC4qswkhu-5q~FLW9ttntIldm3wztAYWrOpxkoibvE-vtyiRYE6fmNIRM5J7Yw__%26Key-Pair-Id%3DKQKI5W638ZZW8',
      stock_media_source:
        'https://media.gettyimages.com/id/1454221610/video/female-executive-outlining-plan-on-a-glass-blackboard-drawing-with-marker-and-explaining.mp4?b=1&s=mp4-640x640-gi&k=20&c=2UPulL8vx9L8HCb4HZZAglnqUU3-u-5AP7a3HDZMyGY='
    },
    {
      f: '50.69',
      sd_url:
        'https://media.gettyimages.com/id/2166380269/video/four-multiracial-teammates-analyze-marketing-campaign-effectiveness.mp4?b=1&s=192_srp&k=20&c=rzATTXbovxshfL_58RXJpHTZfLCUUCiGH0KrYEefm8A=',
      source: 'getty',
      id: '2166380269',
      t: '54.03',
      url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/trimmed-videos/2166380269.mp4%3Fresponse-content-disposition%3Dattachment%253B%2520filename%253DGettyImages-2166380269.mp4%26response-content-type%3Dvideo%252Fmp4%26Expires%3D1741670116%26Signature%3DfaglaIn4t2fmErkySzN10N0zWDrlJ1MPXBALJrs-ZdX3lkbHbaONF8KG8hJ8LgH9bKBxje1LWDtlcfcFPf-ffCwmva5rzaNDxfMikrU4tWlCY1RUI1TzGXNUmkWoV5b9aiv3DdOY4SdwCQLNwnOv8FKZG~~UH35H3U-ztHsgWj8rzavOkbUGAHA-7LMHn27Ckh9YE7r4m6YXj~1DsAnAAcFeYNp6axuF5DdbQPSQVtC5lLbJ21~rKrdAWelHpjs9wiEsKlxi1B6sIsnGJIqB2ggAs0QyaRJF8StwvPlEEkerVH2U0Wm0e~LJKiHEmI2j7hlrNhYMRHXVihzS8nwWFQ__%26Key-Pair-Id%3DKQKI5W638ZZW8',
      original_keyword: 'employees collaborating in a modern office space',
      hd_url:
        'https://media.gettyimages.com/id/2166380269/video/four-multiracial-teammates-analyze-marketing-campaign-effectiveness.mp4?b=1&s=mp4-640x640-gi&k=20&c=DNeALfogVohZcotZhiWGDGQVXrQBboRh4zYIZn7HE_0=',
      type: 'video',
      stock_media_source:
        'https://media.gettyimages.com/id/2166380269/video/four-multiracial-teammates-analyze-marketing-campaign-effectiveness.mp4?b=1&s=mp4-640x640-gi&k=20&c=DNeALfogVohZcotZhiWGDGQVXrQBboRh4zYIZn7HE_0='
    },
    {
      f: '3.1',
      id: 'hus5otDh3rCyBXrCQEnbXi',
      type: 'emoji',
      t: '6.1',
      e: '🧑‍🤝‍🧑'
    },
    {
      t: '33.980000000000004',
      e: '✅',
      type: 'emoji',
      f: '30.98',
      id: '7HdC9aeYwkELThLXZKv1jJ'
    },
    {
      e: '🛠️',
      id: 'js8u7G5tYzeGEzjUcrerRG',
      t: '60.35',
      type: 'emoji',
      f: '57.35'
    }
  ],
  url: 'https://remotionlambda-d5o59m07tl.s3.ap-southeast-1.amazonaws.com/renders/yqlig9uric/out.mp4',
  _id: '8rx1YuXhb6fxro28FcENLp',
  __metadata: { segments: ['slideshows', '8rx1YuXhb6fxro28FcENLp'] },
  email: 'adrian@prxductionhouse.com'
};

const preset_subtle = {
  silence_assets: [],
  id: '8rx1YuXhb6fxro28FcENLp',
  author_id: 'CCqRN8Z3vTh4n5gsio13qyZq4Hu2',
  created_at: '2025-03-11T04:07:03.760Z',
  generate_initial_assets: true,
  watermark: {
    size: 44,
    url: null,
    position: { justifyContent: 'flex-end', alignItems: 'flex-end' }
  },
  type: 'magic',
  duration: 70.779042,
  filter: {
    filter:
      'contrast(1.15) brightness(1.02) saturate(1.05) hue-rotate(-1deg) sepia(1%)',
    category: 'Lees garden'
  },
  video_title: 'Clip17',
  file_size_in_MB: 138.49,
  subtitle_status: 'generated',
  emojis: [
    { e: '💰', c: 'profit' },
    { e: '📦', c: 'Product' },
    { e: '🔄', c: 'process' },
    { e: '🧑‍🤝‍🧑', c: 'people' },
    { e: '🚀', c: 'zero to a million' },
    { e: '✅', c: 'highest priority' },
    { e: '⚡', c: 'game-changing' },
    { e: '🎯', c: 'focus' },
    { e: '👥', c: 'people' },
    { e: '📈', c: 'highest leverage' },
    { e: '🛠️', c: 'delivering the product' }
  ],
  initial_asset_status: 'generated',
  last_updated: '2025-03-11T04:08:49.056Z',
  hook: '🚀 3 P’s to Scale from $0 to $1M! 💼',
  thumbnail_url:
    'https://jupitrr-ugc-us-east.s3-accelerate.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/slideshow-8rx1YuXhb6fxro28FcENLp--nbGL9yGAx7pZfZG4gLoGrV.mp4',
  music: {
    volume: 0,
    url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/music/Corporate+Visions.mp3'
  },
  preferences: {
    positionY: 85,
    color: '#fff',
    primaryColor: '#fff',
    fontBordered: 'black',
    fontWeight: 'normal',
    wordLimit: 35,
    fontSize: 45,
    dimensionType: 'portrait',
    activateBgm: false,
    subtitleType: 'Reels',
    capitalization: false,
    activateTransition: false,
    fontFamily: 'Inter',
    animationColor: '#fff',
    activateInitialAnimation: true,
    activateEmoji: false,
    animation: 'Box Highlight',
    hidePromotion: true,
    hideSubtitles: false
  },
  assets: [
    {
      f: '0',
      t: '70.779042',
      url: 'https://jupitrr-ugc-us-east.s3-accelerate.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/slideshow-8rx1YuXhb6fxro28FcENLp--nbGL9yGAx7pZfZG4gLoGrV.mp4',
      id: 'nbGL9yGAx7pZfZG4gLoGrV',
      type: 'video'
    },
    {
      f: '0',
      t: '3',
      type: 'effect',
      id: '1dQo3XETtxBtRK6UzqZoFJ',
      preferences: {
        effect: 'film-burn'
      }
    },
    {
      f: '0',
      t: '3',
      type: 'hook',
      id: '1dQo3XETtxBtRK6UzqZoFJ',
      text: '🚀 3 P’s to Scale from $0 to $1M! 💼',
      hidden: true
    },
    {
      f: '12.38',
      sd_url:
        'https://media.gettyimages.com/id/2184489426/video/business-growth-with-black-and-yellow-chart-for-portrait-video.mp4?b=1&s=192_srp&k=20&c=WlEZ8XNKW_Ucd5XzdiPU1SNWXGv8jyxvvSuNu8eUSQE=',
      original_keyword: 'startup growth chart with financial milestones',
      id: '2184489426',
      type: 'video',
      hd_url:
        'https://media.gettyimages.com/id/2184489426/video/business-growth-with-black-and-yellow-chart-for-portrait-video.mp4?b=1&s=mp4-640x640-gi&k=20&c=jX3ZYAoY9x-4ushn7vJkR6HeV77yK2Bj4dc0KJRx5Ss=',
      url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/trimmed-videos/2184489426.mp4%3Fresponse-content-disposition%3Dattachment%253B%2520filename%253DGettyImages-2184489426.mp4%26response-content-type%3Dvideo%252Fmp4%26Expires%3D1741670116%26Signature%3DAQVmAhS1gt4x~s09h9pxjY4TKFRyo9-I4q883yJ4lj6Cjo0b8hHAXXQgh9cU843zGr~RnhlqIEO6lm8nh7OI11gE60N3uhaCN4PtvXmUyK7BNHkUieJpH9GTIdLpMvI9u2c5j40Tb4mJBjWoHZHa1Y~WCvLGTYDZ5jzF7g604cnxfPUshmiJ~mr4v28fwt-Adyznbt3ggPgK7DD9gZqBMNskSKYsZ-qRABqTHBnLY~K7UNWXCFMCZMAuFuxQLR-WXIkedPKP1vAJbRHNjTkHCXdEGAGjKUV4eIWA~fStTqldmlLoDfVb3~vD0s2uKh4nWjAvoNzjGd1MKOxhMUjAmg__%26Key-Pair-Id%3DKQKI5W638ZZW8',
      source: 'getty',
      t: '14.5',
      stock_media_source:
        'https://media.gettyimages.com/id/2184489426/video/business-growth-with-black-and-yellow-chart-for-portrait-video.mp4?b=1&s=mp4-640x640-gi&k=20&c=jX3ZYAoY9x-4ushn7vJkR6HeV77yK2Bj4dc0KJRx5Ss='
    },
    {
      silenceIds: [],
      sd_url:
        'https://media.gettyimages.com/id/2166155743/video/chief-engineer-with-laptop-on-factory-analyzes-the-production-process-vertical-video.mp4?b=1&s=192_srp&k=20&c=19jqlYXs-iE7iAVx3dPzeaWahKbA6U2yEXSM-i2ec0Q=',
      id: '2166155743',
      original_keyword: 'warehouse or manufacturing product assembly line',
      url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/trimmed-videos/2166155743.mp4%3Fresponse-content-disposition%3Dattachment%253B%2520filename%253DGettyImages-2166155743.mp4%26response-content-type%3Dvideo%252Fmp4%26Expires%3D1741670116%26Signature%3DYZZd5lUusjcwS7YuxplxZ2cGpNsB9IhpvXyiHZQkCnAg8JAWU4QnnUU3RAJyZIGlMB8YPw2xJ6r2CADBF9jXcltb3wkfi6IxHiJ17XmfVJ3IgGIOCNa9zZY-7MEPerSF0itlxK7u~DxGGyly09DIV5CW7naXUb1bxkEDkb36ndrUBJ6Q-s~hpvZPaiDlTVRChJtDU7fZVgYaoYaZRSrVg8KtbERBUuzGVIWYZYSLqjjjSbgowJrrUH7JSW956rogzaZrSTxZO9Z7WX930krW8DN3vCEoLy6-dnttvrdJbV1rGKqagHFzSRe4JzZ6ItUZJ8wg58WV9d0yEG9k4VFY7w__%26Key-Pair-Id%3DKQKI5W638ZZW8',
      source: 'getty',
      t: '23.64',
      f: '20.54',
      manual_keyword: 'warehouse or manufacturing product assembly line',
      type: 'video',
      hd_url:
        'https://media.gettyimages.com/id/2166155743/video/chief-engineer-with-laptop-on-factory-analyzes-the-production-process-vertical-video.mp4?b=1&s=mp4-640x640-gi&k=20&c=DLFjMzU-3L2cSfVLHNYQ8UGt7frTknH1e3rwCUUnOig=',
      stock_media_source:
        'https://media.gettyimages.com/id/2166155743/video/chief-engineer-with-laptop-on-factory-analyzes-the-production-process-vertical-video.mp4?b=1&s=mp4-640x640-gi&k=20&c=DLFjMzU-3L2cSfVLHNYQ8UGt7frTknH1e3rwCUUnOig='
    },
    {
      silenceIds: [],
      original_keyword: 'team brainstorming business strategy on whiteboard',
      sd_url:
        'https://media.gettyimages.com/id/1454221610/video/female-executive-outlining-plan-on-a-glass-blackboard-drawing-with-marker-and-explaining.mp4?b=1&s=192_srp&k=20&c=dacsMS1lugthtCBh3G2kYLmojM5lzSp98pvgttnxrWM=',
      type: 'video',
      t: '35.94',
      f: '32.44',
      id: '1454221610',
      hd_url:
        'https://media.gettyimages.com/id/1454221610/video/female-executive-outlining-plan-on-a-glass-blackboard-drawing-with-marker-and-explaining.mp4?b=1&s=mp4-640x640-gi&k=20&c=2UPulL8vx9L8HCb4HZZAglnqUU3-u-5AP7a3HDZMyGY=',
      manual_keyword: 'team brainstorming business strategy on whiteboard',
      source: 'getty',
      url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/trimmed-videos/1454221610.mp4%3Fresponse-content-disposition%3Dattachment%253B%2520filename%253DGettyImages-1454221610.mp4%26response-content-type%3Dvideo%252Fmp4%26Expires%3D1741670116%26Signature%3DHcqS2bI~NWTaKIiaKdrlB7X0r2ZuLX0MOYG1zuTPT4FCDnlmBZ8pJKwGCUxV4dCgWbQ3imCqLzUFzUXwcJm6JSwmf3JlDGVjUMUH9vYPXVCVLI6B7sNNbz33lKUkDrQRgBj4~YYPw7mXtW21cIvcfGmI8nx1o-qZTmF6~~Ct10bKufrvNhl6UTWcjr41n07iQ4IMPB~rNMIHJbcnAxLSemjgqioU52Br2WGgUkzZ-dh-I2HohLgjk5hHzX7WVuh2plMRdTZ99GgCBkPOQkhGrV9KrC4qswkhu-5q~FLW9ttntIldm3wztAYWrOpxkoibvE-vtyiRYE6fmNIRM5J7Yw__%26Key-Pair-Id%3DKQKI5W638ZZW8',
      stock_media_source:
        'https://media.gettyimages.com/id/1454221610/video/female-executive-outlining-plan-on-a-glass-blackboard-drawing-with-marker-and-explaining.mp4?b=1&s=mp4-640x640-gi&k=20&c=2UPulL8vx9L8HCb4HZZAglnqUU3-u-5AP7a3HDZMyGY='
    },
    {
      f: '50.69',
      sd_url:
        'https://media.gettyimages.com/id/2166380269/video/four-multiracial-teammates-analyze-marketing-campaign-effectiveness.mp4?b=1&s=192_srp&k=20&c=rzATTXbovxshfL_58RXJpHTZfLCUUCiGH0KrYEefm8A=',
      source: 'getty',
      id: '2166380269',
      t: '54.03',
      url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/trimmed-videos/2166380269.mp4%3Fresponse-content-disposition%3Dattachment%253B%2520filename%253DGettyImages-2166380269.mp4%26response-content-type%3Dvideo%252Fmp4%26Expires%3D1741670116%26Signature%3DfaglaIn4t2fmErkySzN10N0zWDrlJ1MPXBALJrs-ZdX3lkbHbaONF8KG8hJ8LgH9bKBxje1LWDtlcfcFPf-ffCwmva5rzaNDxfMikrU4tWlCY1RUI1TzGXNUmkWoV5b9aiv3DdOY4SdwCQLNwnOv8FKZG~~UH35H3U-ztHsgWj8rzavOkbUGAHA-7LMHn27Ckh9YE7r4m6YXj~1DsAnAAcFeYNp6axuF5DdbQPSQVtC5lLbJ21~rKrdAWelHpjs9wiEsKlxi1B6sIsnGJIqB2ggAs0QyaRJF8StwvPlEEkerVH2U0Wm0e~LJKiHEmI2j7hlrNhYMRHXVihzS8nwWFQ__%26Key-Pair-Id%3DKQKI5W638ZZW8',
      original_keyword: 'employees collaborating in a modern office space',
      hd_url:
        'https://media.gettyimages.com/id/2166380269/video/four-multiracial-teammates-analyze-marketing-campaign-effectiveness.mp4?b=1&s=mp4-640x640-gi&k=20&c=DNeALfogVohZcotZhiWGDGQVXrQBboRh4zYIZn7HE_0=',
      type: 'video',
      stock_media_source:
        'https://media.gettyimages.com/id/2166380269/video/four-multiracial-teammates-analyze-marketing-campaign-effectiveness.mp4?b=1&s=mp4-640x640-gi&k=20&c=DNeALfogVohZcotZhiWGDGQVXrQBboRh4zYIZn7HE_0='
    },
    {
      f: '3.1',
      id: 'hus5otDh3rCyBXrCQEnbXi',
      type: 'emoji',
      t: '6.1',
      e: '🧑‍🤝‍🧑'
    },
    {
      t: '33.980000000000004',
      e: '✅',
      type: 'emoji',
      f: '30.98',
      id: '7HdC9aeYwkELThLXZKv1jJ'
    },
    {
      e: '🛠️',
      id: 'js8u7G5tYzeGEzjUcrerRG',
      t: '60.35',
      type: 'emoji',
      f: '57.35'
    }
  ],
  url: 'https://remotionlambda-d5o59m07tl.s3.ap-southeast-1.amazonaws.com/renders/yqlig9uric/out.mp4',
  _id: '8rx1YuXhb6fxro28FcENLp',
  __metadata: { segments: ['slideshows', '8rx1YuXhb6fxro28FcENLp'] },
  email: 'adrian@prxductionhouse.com'
};

const preset_aesthetic = {
  silence_assets: [],
  id: '8rx1YuXhb6fxro28FcENLp',
  author_id: 'CCqRN8Z3vTh4n5gsio13qyZq4Hu2',
  created_at: '2025-03-11T04:07:03.760Z',
  generate_initial_assets: true,
  watermark: {
    size: 44,
    url: null,
    position: { justifyContent: 'flex-end', alignItems: 'flex-end' }
  },
  type: 'magic',
  duration: 70.779042,
  filter: {
    filter:
      'contrast(1.15) brightness(1.02) saturate(1.05) hue-rotate(-1deg) sepia(1%)',
    category: 'Lees garden'
  },
  video_title: 'Clip17',
  file_size_in_MB: 138.49,
  subtitle_status: 'generated',
  emojis: [
    { e: '💰', c: 'profit' },
    { e: '📦', c: 'Product' },
    { e: '🔄', c: 'process' },
    { e: '🧑‍🤝‍🧑', c: 'people' },
    { e: '🚀', c: 'zero to a million' },
    { e: '✅', c: 'highest priority' },
    { e: '⚡', c: 'game-changing' },
    { e: '🎯', c: 'focus' },
    { e: '👥', c: 'people' },
    { e: '📈', c: 'highest leverage' },
    { e: '🛠️', c: 'delivering the product' }
  ],
  initial_asset_status: 'generated',
  last_updated: '2025-03-11T04:08:49.056Z',
  hook: '🚀 3 P’s to Scale from $0 to $1M! 💼',
  thumbnail_url:
    'https://jupitrr-ugc-us-east.s3-accelerate.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/slideshow-8rx1YuXhb6fxro28FcENLp--nbGL9yGAx7pZfZG4gLoGrV.mp4',
  music: {
    volume: 0,
    url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/music/Corporate+Visions.mp3'
  },
  preferences: {
    fontFamily: MagicEditFonts.Poppins,
    fontSize: 52,
    color: '#1c1e1d',
    primaryColor: '#1c1e1d',
    activateTransition: true,
    wordLimit: 35,
    dimensionType: 'portrait',
    subtitleType: 'Reels',
    capitalization: false,
    activateEmoji: true,
    animation: 'Box Highlight',
    hidePromotion: true,
    hideSubtitles: false
  },
  assets: [
    {
      f: '0',
      t: '70.779042',
      url: 'https://jupitrr-ugc-us-east.s3-accelerate.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/slideshow-8rx1YuXhb6fxro28FcENLp--nbGL9yGAx7pZfZG4gLoGrV.mp4',
      id: 'nbGL9yGAx7pZfZG4gLoGrV',
      type: 'video'
    },
    {
      f: '0',
      t: '3',
      type: 'effect',
      id: '1dQo3XETtxBtRK6UzqZoFJ',
      preferences: {
        effect: 'film-burn'
      }
    },
    {
      f: '0',
      t: '3',
      type: 'hook',
      id: '1dQo3XETtxBtRK6UzqZoFJ',
      text: '🚀 3 P’s to Scale from $0 to $1M! 💼',
      hidden: true
    },
    {
      f: '12.38',
      sd_url:
        'https://media.gettyimages.com/id/2184489426/video/business-growth-with-black-and-yellow-chart-for-portrait-video.mp4?b=1&s=192_srp&k=20&c=WlEZ8XNKW_Ucd5XzdiPU1SNWXGv8jyxvvSuNu8eUSQE=',
      original_keyword: 'startup growth chart with financial milestones',
      id: '2184489426',
      type: 'video',
      preferences: { object_fit: 'contain-blended-top' },
      hd_url:
        'https://media.gettyimages.com/id/2184489426/video/business-growth-with-black-and-yellow-chart-for-portrait-video.mp4?b=1&s=mp4-640x640-gi&k=20&c=jX3ZYAoY9x-4ushn7vJkR6HeV77yK2Bj4dc0KJRx5Ss=',
      url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/trimmed-videos/2184489426.mp4%3Fresponse-content-disposition%3Dattachment%253B%2520filename%253DGettyImages-2184489426.mp4%26response-content-type%3Dvideo%252Fmp4%26Expires%3D1741670116%26Signature%3DAQVmAhS1gt4x~s09h9pxjY4TKFRyo9-I4q883yJ4lj6Cjo0b8hHAXXQgh9cU843zGr~RnhlqIEO6lm8nh7OI11gE60N3uhaCN4PtvXmUyK7BNHkUieJpH9GTIdLpMvI9u2c5j40Tb4mJBjWoHZHa1Y~WCvLGTYDZ5jzF7g604cnxfPUshmiJ~mr4v28fwt-Adyznbt3ggPgK7DD9gZqBMNskSKYsZ-qRABqTHBnLY~K7UNWXCFMCZMAuFuxQLR-WXIkedPKP1vAJbRHNjTkHCXdEGAGjKUV4eIWA~fStTqldmlLoDfVb3~vD0s2uKh4nWjAvoNzjGd1MKOxhMUjAmg__%26Key-Pair-Id%3DKQKI5W638ZZW8',
      source: 'getty',
      t: '14.5',
      stock_media_source:
        'https://media.gettyimages.com/id/2184489426/video/business-growth-with-black-and-yellow-chart-for-portrait-video.mp4?b=1&s=mp4-640x640-gi&k=20&c=jX3ZYAoY9x-4ushn7vJkR6HeV77yK2Bj4dc0KJRx5Ss='
    },
    {
      silenceIds: [],
      sd_url:
        'https://media.gettyimages.com/id/2166155743/video/chief-engineer-with-laptop-on-factory-analyzes-the-production-process-vertical-video.mp4?b=1&s=192_srp&k=20&c=19jqlYXs-iE7iAVx3dPzeaWahKbA6U2yEXSM-i2ec0Q=',
      id: '2166155743',
      original_keyword: 'warehouse or manufacturing product assembly line',
      url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/trimmed-videos/2166155743.mp4%3Fresponse-content-disposition%3Dattachment%253B%2520filename%253DGettyImages-2166155743.mp4%26response-content-type%3Dvideo%252Fmp4%26Expires%3D1741670116%26Signature%3DYZZd5lUusjcwS7YuxplxZ2cGpNsB9IhpvXyiHZQkCnAg8JAWU4QnnUU3RAJyZIGlMB8YPw2xJ6r2CADBF9jXcltb3wkfi6IxHiJ17XmfVJ3IgGIOCNa9zZY-7MEPerSF0itlxK7u~DxGGyly09DIV5CW7naXUb1bxkEDkb36ndrUBJ6Q-s~hpvZPaiDlTVRChJtDU7fZVgYaoYaZRSrVg8KtbERBUuzGVIWYZYSLqjjjSbgowJrrUH7JSW956rogzaZrSTxZO9Z7WX930krW8DN3vCEoLy6-dnttvrdJbV1rGKqagHFzSRe4JzZ6ItUZJ8wg58WV9d0yEG9k4VFY7w__%26Key-Pair-Id%3DKQKI5W638ZZW8',
      preferences: { object_fit: 'contain-blended-top' },
      source: 'getty',
      t: '23.64',
      f: '20.54',
      manual_keyword: 'warehouse or manufacturing product assembly line',
      type: 'video',
      hd_url:
        'https://media.gettyimages.com/id/2166155743/video/chief-engineer-with-laptop-on-factory-analyzes-the-production-process-vertical-video.mp4?b=1&s=mp4-640x640-gi&k=20&c=DLFjMzU-3L2cSfVLHNYQ8UGt7frTknH1e3rwCUUnOig=',
      stock_media_source:
        'https://media.gettyimages.com/id/2166155743/video/chief-engineer-with-laptop-on-factory-analyzes-the-production-process-vertical-video.mp4?b=1&s=mp4-640x640-gi&k=20&c=DLFjMzU-3L2cSfVLHNYQ8UGt7frTknH1e3rwCUUnOig='
    },
    {
      silenceIds: [],
      original_keyword: 'team brainstorming business strategy on whiteboard',
      sd_url:
        'https://media.gettyimages.com/id/1454221610/video/female-executive-outlining-plan-on-a-glass-blackboard-drawing-with-marker-and-explaining.mp4?b=1&s=192_srp&k=20&c=dacsMS1lugthtCBh3G2kYLmojM5lzSp98pvgttnxrWM=',
      type: 'video',
      t: '35.94',
      f: '32.44',
      id: '1454221610',
      preferences: { object_fit: 'contain-blended-top' },
      hd_url:
        'https://media.gettyimages.com/id/1454221610/video/female-executive-outlining-plan-on-a-glass-blackboard-drawing-with-marker-and-explaining.mp4?b=1&s=mp4-640x640-gi&k=20&c=2UPulL8vx9L8HCb4HZZAglnqUU3-u-5AP7a3HDZMyGY=',
      manual_keyword: 'team brainstorming business strategy on whiteboard',
      source: 'getty',
      url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/trimmed-videos/1454221610.mp4%3Fresponse-content-disposition%3Dattachment%253B%2520filename%253DGettyImages-1454221610.mp4%26response-content-type%3Dvideo%252Fmp4%26Expires%3D1741670116%26Signature%3DHcqS2bI~NWTaKIiaKdrlB7X0r2ZuLX0MOYG1zuTPT4FCDnlmBZ8pJKwGCUxV4dCgWbQ3imCqLzUFzUXwcJm6JSwmf3JlDGVjUMUH9vYPXVCVLI6B7sNNbz33lKUkDrQRgBj4~YYPw7mXtW21cIvcfGmI8nx1o-qZTmF6~~Ct10bKufrvNhl6UTWcjr41n07iQ4IMPB~rNMIHJbcnAxLSemjgqioU52Br2WGgUkzZ-dh-I2HohLgjk5hHzX7WVuh2plMRdTZ99GgCBkPOQkhGrV9KrC4qswkhu-5q~FLW9ttntIldm3wztAYWrOpxkoibvE-vtyiRYE6fmNIRM5J7Yw__%26Key-Pair-Id%3DKQKI5W638ZZW8',
      stock_media_source:
        'https://media.gettyimages.com/id/1454221610/video/female-executive-outlining-plan-on-a-glass-blackboard-drawing-with-marker-and-explaining.mp4?b=1&s=mp4-640x640-gi&k=20&c=2UPulL8vx9L8HCb4HZZAglnqUU3-u-5AP7a3HDZMyGY='
    },
    {
      f: '50.69',
      preferences: { object_fit: 'contain-blended-top' },
      sd_url:
        'https://media.gettyimages.com/id/2166380269/video/four-multiracial-teammates-analyze-marketing-campaign-effectiveness.mp4?b=1&s=192_srp&k=20&c=rzATTXbovxshfL_58RXJpHTZfLCUUCiGH0KrYEefm8A=',
      source: 'getty',
      id: '2166380269',
      t: '54.03',
      url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/trimmed-videos/2166380269.mp4%3Fresponse-content-disposition%3Dattachment%253B%2520filename%253DGettyImages-2166380269.mp4%26response-content-type%3Dvideo%252Fmp4%26Expires%3D1741670116%26Signature%3DfaglaIn4t2fmErkySzN10N0zWDrlJ1MPXBALJrs-ZdX3lkbHbaONF8KG8hJ8LgH9bKBxje1LWDtlcfcFPf-ffCwmva5rzaNDxfMikrU4tWlCY1RUI1TzGXNUmkWoV5b9aiv3DdOY4SdwCQLNwnOv8FKZG~~UH35H3U-ztHsgWj8rzavOkbUGAHA-7LMHn27Ckh9YE7r4m6YXj~1DsAnAAcFeYNp6axuF5DdbQPSQVtC5lLbJ21~rKrdAWelHpjs9wiEsKlxi1B6sIsnGJIqB2ggAs0QyaRJF8StwvPlEEkerVH2U0Wm0e~LJKiHEmI2j7hlrNhYMRHXVihzS8nwWFQ__%26Key-Pair-Id%3DKQKI5W638ZZW8',
      original_keyword: 'employees collaborating in a modern office space',
      hd_url:
        'https://media.gettyimages.com/id/2166380269/video/four-multiracial-teammates-analyze-marketing-campaign-effectiveness.mp4?b=1&s=mp4-640x640-gi&k=20&c=DNeALfogVohZcotZhiWGDGQVXrQBboRh4zYIZn7HE_0=',
      type: 'video',
      stock_media_source:
        'https://media.gettyimages.com/id/2166380269/video/four-multiracial-teammates-analyze-marketing-campaign-effectiveness.mp4?b=1&s=mp4-640x640-gi&k=20&c=DNeALfogVohZcotZhiWGDGQVXrQBboRh4zYIZn7HE_0='
    },
    {
      f: '3.1',
      id: 'hus5otDh3rCyBXrCQEnbXi',
      type: 'emoji',
      t: '6.1',
      e: '🧑‍🤝‍🧑'
    },
    {
      t: '33.980000000000004',
      e: '✅',
      type: 'emoji',
      f: '30.98',
      id: '7HdC9aeYwkELThLXZKv1jJ'
    },
    {
      e: '🛠️',
      id: 'js8u7G5tYzeGEzjUcrerRG',
      t: '60.35',
      type: 'emoji',
      f: '57.35'
    },
    {
      id: '2BucS7cqz16V5qMk31rYc1',
      t: '70.779042',
      url: 'https://jupitrr-staging-playground-us-east.s3-accelerate.amazonaws.com/aqL6H1iA6jRabwNbSSmMSvuedYu2/outro-dfMHsBf7MnniZXhXSK6eKR--2BucS7cqz16V5qMk31rYc1--user-upload.jpeg',
      mediaType: 'image',
      f: '67.779042',
      type: 'outro'
    }
  ],
  url: 'https://remotionlambda-d5o59m07tl.s3.ap-southeast-1.amazonaws.com/renders/yqlig9uric/out.mp4',
  _id: '8rx1YuXhb6fxro28FcENLp',
  __metadata: { segments: ['slideshows', '8rx1YuXhb6fxro28FcENLp'] },
  email: 'adrian@prxductionhouse.com'
};

const preset_bold = {
  silence_assets: [],
  id: '8rx1YuXhb6fxro28FcENLp',
  author_id: 'CCqRN8Z3vTh4n5gsio13qyZq4Hu2',
  created_at: '2025-03-11T04:07:03.760Z',
  generate_initial_assets: true,
  watermark: {
    size: 44,
    url: null,
    position: { justifyContent: 'flex-end', alignItems: 'flex-end' }
  },
  type: 'magic',
  duration: 70.779042,
  filter: {
    filter:
      'contrast(1.15) brightness(1.02) saturate(1.05) hue-rotate(-1deg) sepia(1%)',
    category: 'Lees garden'
  },
  video_title: 'Clip17',
  file_size_in_MB: 138.49,
  subtitle_status: 'generated',
  emojis: [
    { e: '💰', c: 'profit' },
    { e: '📦', c: 'Product' },
    { e: '🔄', c: 'process' },
    { e: '🧑‍🤝‍🧑', c: 'people' },
    { e: '🚀', c: 'zero to a million' },
    { e: '✅', c: 'highest priority' },
    { e: '⚡', c: 'game-changing' },
    { e: '🎯', c: 'focus' },
    { e: '👥', c: 'people' },
    { e: '📈', c: 'highest leverage' },
    { e: '🛠️', c: 'delivering the product' }
  ],
  initial_asset_status: 'generated',
  last_updated: '2025-03-11T04:08:49.056Z',
  hook: '🚀 3 P’s to Scale from $0 to $1M! 💼',
  thumbnail_url:
    'https://jupitrr-ugc-us-east.s3-accelerate.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/slideshow-8rx1YuXhb6fxro28FcENLp--nbGL9yGAx7pZfZG4gLoGrV.mp4',
  music: {
    volume: 0,
    url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/music/Corporate+Visions.mp3'
  },
  preferences: {
    fontFamily: MagicEditFonts.Inter,
    fontSize: 55,
    color: 'white',
    primaryColor: 'white',
    animationColor: '#2031c6',
    subtitleType: SubtitleOptions.WORD_BY_WORD,
    positionY: 45,
    fontBordered: 'black',
    wordLimit: 20,
    capitalization: true,
    dimensionType: 'portrait',
    activateBgm: false,
    activateTransition: false,
    activateFilmBurn: true,
    activateEmoji: false,
    animation: 'Box Highlight',
    hidePromotion: true,
    hideSubtitles: false,
    wordSpacing: 2
  },
  assets: [
    {
      f: '0',
      t: '70.779042',
      url: 'https://jupitrr-ugc-us-east.s3-accelerate.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/slideshow-8rx1YuXhb6fxro28FcENLp--nbGL9yGAx7pZfZG4gLoGrV.mp4',
      id: 'nbGL9yGAx7pZfZG4gLoGrV',
      type: 'video'
    },
    {
      f: '0',
      t: '3',
      type: 'effect',
      id: '1dQo3XETtxBtRK6UzqZoFJ',
      preferences: {
        effect: 'film-burn'
      }
    },
    {
      f: '0',
      t: '3',
      type: 'hook',
      id: '1dQo3XETtxBtRK6UzqZoFJ',
      text: '🚀 3 P’s to Scale from $0 to $1M! 💼',
      hidden: true
    },
    {
      f: '12.38',
      sd_url:
        'https://media.gettyimages.com/id/2184489426/video/business-growth-with-black-and-yellow-chart-for-portrait-video.mp4?b=1&s=192_srp&k=20&c=WlEZ8XNKW_Ucd5XzdiPU1SNWXGv8jyxvvSuNu8eUSQE=',
      original_keyword: 'startup growth chart with financial milestones',
      id: '2184489426',
      type: 'video',
      hd_url:
        'https://media.gettyimages.com/id/2184489426/video/business-growth-with-black-and-yellow-chart-for-portrait-video.mp4?b=1&s=mp4-640x640-gi&k=20&c=jX3ZYAoY9x-4ushn7vJkR6HeV77yK2Bj4dc0KJRx5Ss=',
      url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/trimmed-videos/2184489426.mp4%3Fresponse-content-disposition%3Dattachment%253B%2520filename%253DGettyImages-2184489426.mp4%26response-content-type%3Dvideo%252Fmp4%26Expires%3D1741670116%26Signature%3DAQVmAhS1gt4x~s09h9pxjY4TKFRyo9-I4q883yJ4lj6Cjo0b8hHAXXQgh9cU843zGr~RnhlqIEO6lm8nh7OI11gE60N3uhaCN4PtvXmUyK7BNHkUieJpH9GTIdLpMvI9u2c5j40Tb4mJBjWoHZHa1Y~WCvLGTYDZ5jzF7g604cnxfPUshmiJ~mr4v28fwt-Adyznbt3ggPgK7DD9gZqBMNskSKYsZ-qRABqTHBnLY~K7UNWXCFMCZMAuFuxQLR-WXIkedPKP1vAJbRHNjTkHCXdEGAGjKUV4eIWA~fStTqldmlLoDfVb3~vD0s2uKh4nWjAvoNzjGd1MKOxhMUjAmg__%26Key-Pair-Id%3DKQKI5W638ZZW8',
      source: 'getty',
      t: '14.5',
      stock_media_source:
        'https://media.gettyimages.com/id/2184489426/video/business-growth-with-black-and-yellow-chart-for-portrait-video.mp4?b=1&s=mp4-640x640-gi&k=20&c=jX3ZYAoY9x-4ushn7vJkR6HeV77yK2Bj4dc0KJRx5Ss='
    },
    {
      silenceIds: [],
      sd_url:
        'https://media.gettyimages.com/id/2166155743/video/chief-engineer-with-laptop-on-factory-analyzes-the-production-process-vertical-video.mp4?b=1&s=192_srp&k=20&c=19jqlYXs-iE7iAVx3dPzeaWahKbA6U2yEXSM-i2ec0Q=',
      id: '2166155743',
      original_keyword: 'warehouse or manufacturing product assembly line',
      url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/trimmed-videos/2166155743.mp4%3Fresponse-content-disposition%3Dattachment%253B%2520filename%253DGettyImages-2166155743.mp4%26response-content-type%3Dvideo%252Fmp4%26Expires%3D1741670116%26Signature%3DYZZd5lUusjcwS7YuxplxZ2cGpNsB9IhpvXyiHZQkCnAg8JAWU4QnnUU3RAJyZIGlMB8YPw2xJ6r2CADBF9jXcltb3wkfi6IxHiJ17XmfVJ3IgGIOCNa9zZY-7MEPerSF0itlxK7u~DxGGyly09DIV5CW7naXUb1bxkEDkb36ndrUBJ6Q-s~hpvZPaiDlTVRChJtDU7fZVgYaoYaZRSrVg8KtbERBUuzGVIWYZYSLqjjjSbgowJrrUH7JSW956rogzaZrSTxZO9Z7WX930krW8DN3vCEoLy6-dnttvrdJbV1rGKqagHFzSRe4JzZ6ItUZJ8wg58WV9d0yEG9k4VFY7w__%26Key-Pair-Id%3DKQKI5W638ZZW8',
      source: 'getty',
      t: '23.64',
      f: '20.54',
      manual_keyword: 'warehouse or manufacturing product assembly line',
      type: 'video',
      hd_url:
        'https://media.gettyimages.com/id/2166155743/video/chief-engineer-with-laptop-on-factory-analyzes-the-production-process-vertical-video.mp4?b=1&s=mp4-640x640-gi&k=20&c=DLFjMzU-3L2cSfVLHNYQ8UGt7frTknH1e3rwCUUnOig=',
      stock_media_source:
        'https://media.gettyimages.com/id/2166155743/video/chief-engineer-with-laptop-on-factory-analyzes-the-production-process-vertical-video.mp4?b=1&s=mp4-640x640-gi&k=20&c=DLFjMzU-3L2cSfVLHNYQ8UGt7frTknH1e3rwCUUnOig='
    },
    {
      silenceIds: [],
      original_keyword: 'team brainstorming business strategy on whiteboard',
      sd_url:
        'https://media.gettyimages.com/id/1454221610/video/female-executive-outlining-plan-on-a-glass-blackboard-drawing-with-marker-and-explaining.mp4?b=1&s=192_srp&k=20&c=dacsMS1lugthtCBh3G2kYLmojM5lzSp98pvgttnxrWM=',
      type: 'video',
      t: '35.94',
      f: '32.44',
      id: '1454221610',
      hd_url:
        'https://media.gettyimages.com/id/1454221610/video/female-executive-outlining-plan-on-a-glass-blackboard-drawing-with-marker-and-explaining.mp4?b=1&s=mp4-640x640-gi&k=20&c=2UPulL8vx9L8HCb4HZZAglnqUU3-u-5AP7a3HDZMyGY=',
      manual_keyword: 'team brainstorming business strategy on whiteboard',
      source: 'getty',
      url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/trimmed-videos/1454221610.mp4%3Fresponse-content-disposition%3Dattachment%253B%2520filename%253DGettyImages-1454221610.mp4%26response-content-type%3Dvideo%252Fmp4%26Expires%3D1741670116%26Signature%3DHcqS2bI~NWTaKIiaKdrlB7X0r2ZuLX0MOYG1zuTPT4FCDnlmBZ8pJKwGCUxV4dCgWbQ3imCqLzUFzUXwcJm6JSwmf3JlDGVjUMUH9vYPXVCVLI6B7sNNbz33lKUkDrQRgBj4~YYPw7mXtW21cIvcfGmI8nx1o-qZTmF6~~Ct10bKufrvNhl6UTWcjr41n07iQ4IMPB~rNMIHJbcnAxLSemjgqioU52Br2WGgUkzZ-dh-I2HohLgjk5hHzX7WVuh2plMRdTZ99GgCBkPOQkhGrV9KrC4qswkhu-5q~FLW9ttntIldm3wztAYWrOpxkoibvE-vtyiRYE6fmNIRM5J7Yw__%26Key-Pair-Id%3DKQKI5W638ZZW8',
      stock_media_source:
        'https://media.gettyimages.com/id/1454221610/video/female-executive-outlining-plan-on-a-glass-blackboard-drawing-with-marker-and-explaining.mp4?b=1&s=mp4-640x640-gi&k=20&c=2UPulL8vx9L8HCb4HZZAglnqUU3-u-5AP7a3HDZMyGY='
    },
    {
      f: '50.69',
      sd_url:
        'https://media.gettyimages.com/id/2166380269/video/four-multiracial-teammates-analyze-marketing-campaign-effectiveness.mp4?b=1&s=192_srp&k=20&c=rzATTXbovxshfL_58RXJpHTZfLCUUCiGH0KrYEefm8A=',
      source: 'getty',
      id: '2166380269',
      t: '54.03',
      url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/trimmed-videos/2166380269.mp4%3Fresponse-content-disposition%3Dattachment%253B%2520filename%253DGettyImages-2166380269.mp4%26response-content-type%3Dvideo%252Fmp4%26Expires%3D1741670116%26Signature%3DfaglaIn4t2fmErkySzN10N0zWDrlJ1MPXBALJrs-ZdX3lkbHbaONF8KG8hJ8LgH9bKBxje1LWDtlcfcFPf-ffCwmva5rzaNDxfMikrU4tWlCY1RUI1TzGXNUmkWoV5b9aiv3DdOY4SdwCQLNwnOv8FKZG~~UH35H3U-ztHsgWj8rzavOkbUGAHA-7LMHn27Ckh9YE7r4m6YXj~1DsAnAAcFeYNp6axuF5DdbQPSQVtC5lLbJ21~rKrdAWelHpjs9wiEsKlxi1B6sIsnGJIqB2ggAs0QyaRJF8StwvPlEEkerVH2U0Wm0e~LJKiHEmI2j7hlrNhYMRHXVihzS8nwWFQ__%26Key-Pair-Id%3DKQKI5W638ZZW8',
      original_keyword: 'employees collaborating in a modern office space',
      hd_url:
        'https://media.gettyimages.com/id/2166380269/video/four-multiracial-teammates-analyze-marketing-campaign-effectiveness.mp4?b=1&s=mp4-640x640-gi&k=20&c=DNeALfogVohZcotZhiWGDGQVXrQBboRh4zYIZn7HE_0=',
      type: 'video',
      stock_media_source:
        'https://media.gettyimages.com/id/2166380269/video/four-multiracial-teammates-analyze-marketing-campaign-effectiveness.mp4?b=1&s=mp4-640x640-gi&k=20&c=DNeALfogVohZcotZhiWGDGQVXrQBboRh4zYIZn7HE_0='
    },
    {
      f: '3.1',
      id: 'hus5otDh3rCyBXrCQEnbXi',
      type: 'emoji',
      t: '6.1',
      e: '🧑‍🤝‍🧑'
    },
    {
      t: '33.980000000000004',
      e: '✅',
      type: 'emoji',
      f: '30.98',
      id: '7HdC9aeYwkELThLXZKv1jJ'
    },
    {
      e: '🛠️',
      id: 'js8u7G5tYzeGEzjUcrerRG',
      t: '60.35',
      type: 'emoji',
      f: '57.35'
    }
  ],
  url: 'https://remotionlambda-d5o59m07tl.s3.ap-southeast-1.amazonaws.com/renders/yqlig9uric/out.mp4',
  _id: '8rx1YuXhb6fxro28FcENLp',
  __metadata: { segments: ['slideshows', '8rx1YuXhb6fxro28FcENLp'] },
  email: 'adrian@prxductionhouse.com'
};

const preset_power = {
  silence_assets: [],
  id: '8rx1YuXhb6fxro28FcENLp',
  author_id: 'CCqRN8Z3vTh4n5gsio13qyZq4Hu2',
  created_at: '2025-03-11T04:07:03.760Z',
  generate_initial_assets: true,
  watermark: {
    size: 44,
    url: null,
    position: { justifyContent: 'flex-end', alignItems: 'flex-end' }
  },
  type: 'magic',
  duration: 70.779042,
  filter: {
    filter:
      'contrast(1.15) brightness(1.02) saturate(1.05) hue-rotate(-1deg) sepia(1%)',
    category: 'Lees garden'
  },
  video_title: 'Clip17',
  file_size_in_MB: 138.49,
  subtitle_status: 'generated',
  emojis: [
    { e: '💰', c: 'profit' },
    { e: '📦', c: 'Product' },
    { e: '🔄', c: 'process' },
    { e: '🧑‍🤝‍🧑', c: 'people' },
    { e: '🚀', c: 'zero to a million' },
    { e: '✅', c: 'highest priority' },
    { e: '⚡', c: 'game-changing' },
    { e: '🎯', c: 'focus' },
    { e: '👥', c: 'people' },
    { e: '📈', c: 'highest leverage' },
    { e: '🛠️', c: 'delivering the product' }
  ],
  initial_asset_status: 'generated',
  last_updated: '2025-03-11T04:08:49.056Z',
  hook: '🚀 3 P’s to Scale from $0 to $1M! 💼',
  thumbnail_url:
    'https://jupitrr-ugc-us-east.s3-accelerate.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/slideshow-8rx1YuXhb6fxro28FcENLp--nbGL9yGAx7pZfZG4gLoGrV.mp4',
  music: {
    volume: 0,
    url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/music/Corporate+Visions.mp3'
  },
  preferences: {
    fontFamily: MagicEditFonts.Montserrat,
    fontSize: 90,
    color: '#66ffc2',
    primaryColor: 'white',
    positionY: 25,
    capitalization: true,
    wordLimit: 20,
    dimensionType: 'portrait',
    activateBgm: false,
    subtitleType: 'Reels',
    activateTransition: false,
    animationColor: '#fff',
    activateFilmBurn: true,
    activateEmoji: false,
    animation: 'None',
    hidePromotion: true,
    hideSubtitles: false,
    fontWeight: '900'
  },
  assets: [
    {
      f: '0',
      t: '70.779042',
      url: 'https://jupitrr-ugc-us-east.s3-accelerate.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/slideshow-8rx1YuXhb6fxro28FcENLp--nbGL9yGAx7pZfZG4gLoGrV.mp4',
      id: 'nbGL9yGAx7pZfZG4gLoGrV',
      type: 'video'
    },
    {
      f: '0',
      t: '3',
      type: 'effect',
      id: '1dQo3XETtxBtRK6UzqZoFJ',
      url: 'https://jupitrr-ugc-us-east.s3-accelerate.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/slideshow-8rx1YuXhb6fxro28FcENLp--nbGL9yGAx7pZfZG4gLoGrV.mp4',
      preferences: {
        effect: 'quick-zoom-out'
      }
    },
    {
      f: '0',
      t: '3',
      type: 'hook',
      id: '1dQo3XETtxBtRK6UzqZoFJ',
      text: '🚀 3 P’s to Scale from $0 to $1M! 💼',
      hidden: true
    },
    {
      preferences: { object_fit: 'contain-background-blur' },
      f: '12.38',
      sd_url:
        'https://naras.a.bigcontent.io/v1/static/Taylor-Swift-2024-GettyImages-2158634495',
      original_keyword: 'startup growth chart with financial milestones',
      id: '2184489426',
      type: 'image',
      hd_url:
        'https://naras.a.bigcontent.io/v1/static/Taylor-Swift-2024-GettyImages-2158634495',
      url: 'https://naras.a.bigcontent.io/v1/static/Taylor-Swift-2024-GettyImages-2158634495',
      source: 'getty',
      t: '14.5',
      stock_media_source:
        'https://media.gettyimages.com/id/2184489426/video/business-growth-with-black-and-yellow-chart-for-portrait-video.mp4?b=1&s=mp4-640x640-gi&k=20&c=jX3ZYAoY9x-4ushn7vJkR6HeV77yK2Bj4dc0KJRx5Ss='
    },
    {
      preferences: { object_fit: 'contain' },
      silenceIds: [],
      sd_url:
        'https://media.gettyimages.com/id/2166155743/video/chief-engineer-with-laptop-on-factory-analyzes-the-production-process-vertical-video.mp4?b=1&s=192_srp&k=20&c=19jqlYXs-iE7iAVx3dPzeaWahKbA6U2yEXSM-i2ec0Q=',
      id: '2166155743',
      original_keyword: 'warehouse or manufacturing product assembly line',
      url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/trimmed-videos/2166155743.mp4%3Fresponse-content-disposition%3Dattachment%253B%2520filename%253DGettyImages-2166155743.mp4%26response-content-type%3Dvideo%252Fmp4%26Expires%3D1741670116%26Signature%3DYZZd5lUusjcwS7YuxplxZ2cGpNsB9IhpvXyiHZQkCnAg8JAWU4QnnUU3RAJyZIGlMB8YPw2xJ6r2CADBF9jXcltb3wkfi6IxHiJ17XmfVJ3IgGIOCNa9zZY-7MEPerSF0itlxK7u~DxGGyly09DIV5CW7naXUb1bxkEDkb36ndrUBJ6Q-s~hpvZPaiDlTVRChJtDU7fZVgYaoYaZRSrVg8KtbERBUuzGVIWYZYSLqjjjSbgowJrrUH7JSW956rogzaZrSTxZO9Z7WX930krW8DN3vCEoLy6-dnttvrdJbV1rGKqagHFzSRe4JzZ6ItUZJ8wg58WV9d0yEG9k4VFY7w__%26Key-Pair-Id%3DKQKI5W638ZZW8',
      source: 'getty',
      t: '23.64',
      f: '20.54',
      manual_keyword: 'warehouse or manufacturing product assembly line',
      type: 'video',
      hd_url:
        'https://media.gettyimages.com/id/2166155743/video/chief-engineer-with-laptop-on-factory-analyzes-the-production-process-vertical-video.mp4?b=1&s=mp4-640x640-gi&k=20&c=DLFjMzU-3L2cSfVLHNYQ8UGt7frTknH1e3rwCUUnOig=',
      stock_media_source:
        'https://media.gettyimages.com/id/2166155743/video/chief-engineer-with-laptop-on-factory-analyzes-the-production-process-vertical-video.mp4?b=1&s=mp4-640x640-gi&k=20&c=DLFjMzU-3L2cSfVLHNYQ8UGt7frTknH1e3rwCUUnOig='
    },
    {
      silenceIds: [],
      original_keyword: 'team brainstorming business strategy on whiteboard',
      sd_url:
        'https://media.gettyimages.com/id/1454221610/video/female-executive-outlining-plan-on-a-glass-blackboard-drawing-with-marker-and-explaining.mp4?b=1&s=192_srp&k=20&c=dacsMS1lugthtCBh3G2kYLmojM5lzSp98pvgttnxrWM=',
      type: 'video',
      t: '35.94',
      f: '32.44',
      id: '1454221610',
      hd_url:
        'https://media.gettyimages.com/id/1454221610/video/female-executive-outlining-plan-on-a-glass-blackboard-drawing-with-marker-and-explaining.mp4?b=1&s=mp4-640x640-gi&k=20&c=2UPulL8vx9L8HCb4HZZAglnqUU3-u-5AP7a3HDZMyGY=',
      manual_keyword: 'team brainstorming business strategy on whiteboard',
      source: 'getty',
      url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/trimmed-videos/1454221610.mp4%3Fresponse-content-disposition%3Dattachment%253B%2520filename%253DGettyImages-1454221610.mp4%26response-content-type%3Dvideo%252Fmp4%26Expires%3D1741670116%26Signature%3DHcqS2bI~NWTaKIiaKdrlB7X0r2ZuLX0MOYG1zuTPT4FCDnlmBZ8pJKwGCUxV4dCgWbQ3imCqLzUFzUXwcJm6JSwmf3JlDGVjUMUH9vYPXVCVLI6B7sNNbz33lKUkDrQRgBj4~YYPw7mXtW21cIvcfGmI8nx1o-qZTmF6~~Ct10bKufrvNhl6UTWcjr41n07iQ4IMPB~rNMIHJbcnAxLSemjgqioU52Br2WGgUkzZ-dh-I2HohLgjk5hHzX7WVuh2plMRdTZ99GgCBkPOQkhGrV9KrC4qswkhu-5q~FLW9ttntIldm3wztAYWrOpxkoibvE-vtyiRYE6fmNIRM5J7Yw__%26Key-Pair-Id%3DKQKI5W638ZZW8',
      stock_media_source:
        'https://media.gettyimages.com/id/1454221610/video/female-executive-outlining-plan-on-a-glass-blackboard-drawing-with-marker-and-explaining.mp4?b=1&s=mp4-640x640-gi&k=20&c=2UPulL8vx9L8HCb4HZZAglnqUU3-u-5AP7a3HDZMyGY='
    },
    {
      f: '50.69',
      sd_url:
        'https://media.gettyimages.com/id/2166380269/video/four-multiracial-teammates-analyze-marketing-campaign-effectiveness.mp4?b=1&s=192_srp&k=20&c=rzATTXbovxshfL_58RXJpHTZfLCUUCiGH0KrYEefm8A=',
      source: 'getty',
      id: '2166380269',
      t: '54.03',
      url: 'https://jupitrr-ugc.s3.ap-southeast-1.amazonaws.com/CCqRN8Z3vTh4n5gsio13qyZq4Hu2/trimmed-videos/2166380269.mp4%3Fresponse-content-disposition%3Dattachment%253B%2520filename%253DGettyImages-2166380269.mp4%26response-content-type%3Dvideo%252Fmp4%26Expires%3D1741670116%26Signature%3DfaglaIn4t2fmErkySzN10N0zWDrlJ1MPXBALJrs-ZdX3lkbHbaONF8KG8hJ8LgH9bKBxje1LWDtlcfcFPf-ffCwmva5rzaNDxfMikrU4tWlCY1RUI1TzGXNUmkWoV5b9aiv3DdOY4SdwCQLNwnOv8FKZG~~UH35H3U-ztHsgWj8rzavOkbUGAHA-7LMHn27Ckh9YE7r4m6YXj~1DsAnAAcFeYNp6axuF5DdbQPSQVtC5lLbJ21~rKrdAWelHpjs9wiEsKlxi1B6sIsnGJIqB2ggAs0QyaRJF8StwvPlEEkerVH2U0Wm0e~LJKiHEmI2j7hlrNhYMRHXVihzS8nwWFQ__%26Key-Pair-Id%3DKQKI5W638ZZW8',
      original_keyword: 'employees collaborating in a modern office space',
      hd_url:
        'https://media.gettyimages.com/id/2166380269/video/four-multiracial-teammates-analyze-marketing-campaign-effectiveness.mp4?b=1&s=mp4-640x640-gi&k=20&c=DNeALfogVohZcotZhiWGDGQVXrQBboRh4zYIZn7HE_0=',
      type: 'video',
      stock_media_source:
        'https://media.gettyimages.com/id/2166380269/video/four-multiracial-teammates-analyze-marketing-campaign-effectiveness.mp4?b=1&s=mp4-640x640-gi&k=20&c=DNeALfogVohZcotZhiWGDGQVXrQBboRh4zYIZn7HE_0='
    },
    {
      f: '3.1',
      id: 'hus5otDh3rCyBXrCQEnbXi',
      type: 'emoji',
      t: '6.1',
      e: '🧑‍🤝‍🧑'
    },
    {
      t: '33.980000000000004',
      e: '✅',
      type: 'emoji',
      f: '30.98',
      id: '7HdC9aeYwkELThLXZKv1jJ'
    },
    {
      e: '🛠️',
      id: 'js8u7G5tYzeGEzjUcrerRG',
      t: '60.35',
      type: 'emoji',
      f: '57.35'
    }
  ],
  url: 'https://remotionlambda-d5o59m07tl.s3.ap-southeast-1.amazonaws.com/renders/yqlig9uric/out.mp4',
  _id: '8rx1YuXhb6fxro28FcENLp',
  __metadata: { segments: ['slideshows', '8rx1YuXhb6fxro28FcENLp'] },
  email: 'adrian@prxductionhouse.com'
};

export {
  preset_clean,
  preset_subtle,
  preset_power,
  preset_bold,
  preset_aesthetic
};
