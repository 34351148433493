import { API_URL, UPLOAD_BUCKET } from './constant';
import {
  Asset,
  AssetType,
  GenerateSlideshowVideoConfig,
  ImageData,
  Sentence,
  StockDimensionType,
  VideoData
} from '../slideshow/types';
import {
  GalleryMediaSearchDimension,
  GalleryMediaSearchType
} from '@/types/galleryModal';
import { LocalStorageItems, getItem } from '../utils/generalUtilities';
import { SubtitleItem } from '../audiogram/components/Subtitles';
import { TextOutput } from '../audiogram/components/TextSequence';
import { toast } from 'react-toastify';
import fetchWrapper, { MethodType } from '../utils/fetchWrapper';
import moment from 'moment';
import testHelper, { TestCase } from '../helpers/testHelper';

interface SuccessResult {
  file_name: string;
  file_url: string;
  id: string;
  upload_url: string;
}

export interface ErrorResult {
  status: string;
  code: string;
  message: string;
}

export interface VisualGenerationReqBody {
  userId: string;
  slideshowId: string;
  dimension: StockDimensionType;
  promptId?: string | null;
  assetType: AssetType;
  keyword?: string;
  isSwap?: boolean;
  content: string;
  context: string;
  forceResolve?: boolean;
  silenceIds?: string[];
}

export interface TextSummaryReqBody {
  userId: string;
  slideshowId: string;
  promptId?: string | null;
  content: string;
  context: string;
}

export interface VisualGenerationResult {
  success: boolean;
  new_assets: Array<{ success: boolean; asset?: Asset; error?: any }>;
  all_assets: Asset[];
  sentences: Sentence[];
}

export interface TextSummaryResult {
  success: boolean;
  summary: string;
}

interface S3UploadResponse {
  postResponse: {
    bucket: string;
    key: string;
    location: string;
  };
}

const toDecimal = (input: string | number, decimal = 2) => {
  const numberInput = typeof input === 'string' ? parseFloat(input) : input;
  return parseFloat(numberInput.toFixed(decimal));
};

const Media = {
  name: '/medias',
  route: '',
  _uploadToS3(file: File, presignedData): Promise<S3UploadResponse> {
    return new Promise(async (resolve, reject) => {
      try {
        const response = await fetch(presignedData.upload_url, {
          method: MethodType.PUT,
          body: file,
          headers: {
            ACL: 'public-read',
            'Content-Type': file.type,
            'Content-Encoding': 'base64',
            'Cache-Control': 'max-age=31104000'
          }
        });

        if (!response.ok) {
          throw await response.text();
        }

        const splitFileUrl = presignedData.file_url.split(
          `${UPLOAD_BUCKET}.s3-ap-southeast-1.amazonaws.com/`
        );

        resolve({
          postResponse: {
            bucket: UPLOAD_BUCKET,
            key: splitFileUrl[1],
            location: presignedData.file_url
          }
        });
      } catch (err) {
        console.error(err);
        reject(err);
      }
    });
  },

  /**
   * Uploads any file via the media lambda. Currently, supports uploading avatar images and audio answers.
   * If you allow custom file names, then its generated from the front-end with uuid.
   * This is done because we are conforming the naming convention for audio answers, voice2text subtitles, and generated videos.
   * @param token string
   * @param mediaObject File
   * @param customFileName string
   * @return Promise<any>
   */
  uploadFile(
    token: string,
    mediaObject: File,
    customFileName: string = null,
    saveToMongoDB: boolean = false
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      (async () => {
        let file = {
          // `uri` can also be a file system path (i.e. file://)
          file_name:
            customFileName !== null ? customFileName : mediaObject.name,
          mime_type: mediaObject.type,
          custom_file_name: customFileName !== null
        };

        this.route = `${API_URL + this.name}`;
        const body = JSON.stringify(file);

        try {
          const json: SuccessResult = (await fetchWrapper(
            this.route,
            MethodType.POST,
            body,
            token
          )) as any;
          const data = await this._uploadToS3(mediaObject, json);
          resolve(data);
        } catch (error: any) {
          reject(error);
          console.error(error);
        }
      })();
    });
  },

  /**
   * Edit Subtitles -pretty file.
   * @param userId string
   * @param answerId string
   * @param subtitles Text[]
   * @param token string
   * @return Promise<{success: boolean, subtitles: Text[]}>
   */
  updatePrettySubtitles(
    userId: string,
    answerId: string,
    subtitles: TextOutput[],
    skipRateLimit?: boolean
  ): Promise<{ success: boolean; subtitles: TextOutput[] }> {
    if (!skipRateLimit) {
      if (!this.rateLimitingChecker()) return;
    }
    const token = getItem(LocalStorageItems.ACCESS_TOKEN);
    if (!token) {
      toast.error('There are some issues on your end... Refreshing...');
      window.location.reload();
      return;
    }
    return new Promise((resolve, reject) => {
      let route = `${
        API_URL + this.name
      }/subtitles/pretty/${userId}/${answerId}`;
      fetchWrapper(
        route,
        MethodType.PATCH,
        JSON.stringify({ subtitles }),
        token
      )
        .then((json: any) => resolve(json))
        .catch((error: Error) => reject(error));
    });
  },

  rateLimitingChecker(): boolean {
    const ONE_SECOND = 1;
    let lastUpdatedLargerThan5Seconds: boolean =
      moment().diff(moment(this.lastSubtitleUpdated), 'seconds', true) >
      ONE_SECOND;
    if (!lastUpdatedLargerThan5Seconds) return false;
    this.lastSubtitleUpdated = new Date().toISOString();
    return true;
  },

  lastSubtitleUpdated: new Date().toISOString(),
  /**
   * Edit Subtitles -source file.
   * @param userId string
   * @param answerId string
   * @param subtitles SubtitleItem[]
   * @param token string
   * @return Promise<{success: boolean, subtitles: SubtitleItem[]}>
   */
  updateSourceSubtitles(
    userId: string,
    answerId: string,
    subtitles: SubtitleItem[] | TextOutput[],
    skipRateLimit?: boolean,
    isSlideshow: boolean = false
  ): Promise<{ success: boolean; subtitles: SubtitleItem[] }> {
    const token = getItem(LocalStorageItems.ACCESS_TOKEN);
    if (!token) {
      toast.error('There are some issues on your end... Refreshing...');
      window.location.reload();
      return;
    }
    return new Promise((resolve, reject) => {
      if (!skipRateLimit) {
        if (!this.rateLimitingChecker()) return;
      }
      let route = `${
        API_URL + this.name
      }/subtitles/source/${userId}/${answerId}`;
      const tempSubtitles = subtitles.map((subtitle) => ({
        f: toDecimal(subtitle.f),
        t: toDecimal(subtitle.t),
        c: subtitle.c
      }));
      fetchWrapper(
        route,
        MethodType.PATCH,
        JSON.stringify({
          subtitles: tempSubtitles,
          is_slideshow: !!isSlideshow
        }),
        token
      )
        .then((json: any) => resolve(json))
        .catch((error: Error) => {
          toast.error(
            'There are some issues on updating subtitles... Please reach out to admin'
          );
          reject(error);
        });
    });
  },
  /**
   * Edit Subtitles -source file.
   * @param userId string
   * @param answerId string
   * @param subtitles SubtitleItem[]
   * @param token string
   * @return Promise<{success: boolean, subtitles: SubtitleItem[]}>
   */
  requestSubtitle(
    userId: string,
    slideshowId: string,
    fileUrl: string,
    retry: boolean = false,
    language?: string,
    model?: string
  ): Promise<{ success: boolean; message: string; data: any }> {
    const token = getItem(LocalStorageItems.ACCESS_TOKEN);
    if (!token) {
      toast.error('There are some issues on your end... Refreshing...');
      window.location.reload();
      return;
    }
    const body: any = {
      url: fileUrl,
      retry,
      language: language && language !== 'auto' ? language : undefined,
      model
    };
    let route = `${
      API_URL + this.name
    }/subtitles/request/${userId}/${slideshowId}`;
    return new Promise((resolve, reject) => {
      fetchWrapper(route, MethodType.POST, JSON.stringify(body), token)
        .then((json: any) => resolve(json))
        .catch((error: Error) => reject(error));
    });
  },
  /**
   * Try Sample
   * @param userId string
   * @return Promise<{success: boolean, subtitles: SubtitleItem[]}>
   */
  trySample(isAudioToVideo = false): Promise<{
    success: boolean;
    slideshow: GenerateSlideshowVideoConfig;
    asset: Asset;
    res: any[];
  }> {
    const token = getItem(LocalStorageItems.ACCESS_TOKEN);
    if (!token) {
      toast.error('There are some issues on your end... Refreshing...');
      window.location.reload();
      return;
    }

    let route = `${API_URL + this.name}/sample${
      isAudioToVideo ? '?type=audio' : ''
    }`;
    return new Promise((resolve, reject) => {
      fetchWrapper(route, MethodType.POST, JSON.stringify({}), token)
        .then((json: any) => resolve(json))
        .catch((error: Error) => reject(error));
    });
  },

  textToMedia(
    props: VisualGenerationReqBody,
    slideshowBeforeError: GenerateSlideshowVideoConfig,
    forceResolve: boolean = false
  ): Promise<VisualGenerationResult> {
    const token = getItem(LocalStorageItems.ACCESS_TOKEN);
    if (!token) {
      toast.error('There are some issues on your end... Refreshing...');
      window.location.reload();
      return;
    }

    let route = `${API_URL + this.name}/subtitles/prompt/${props.userId}/${
      props.slideshowId
    }`;

    let requestBody = props;
    return new Promise((resolve, reject) => {
      fetchWrapper(route, MethodType.POST, JSON.stringify(requestBody), token, {
        forceResolve
      })
        .then((json: VisualGenerationResult) => {
          resolve(json);
          const hasError = testHelper.isError(json);
          if (!hasError) return;
          const testCase: Omit<TestCase, 'id'> = {
            enabled: false,
            url: route,
            method: 'POST',
            body: JSON.stringify(requestBody),
            token,
            last_updated: new Date(),
            slideshow_id: props.slideshowId,
            author_id: props.userId,
            error: json, // or any other error detail you want to include,
            slideshow_before_error: JSON.stringify(slideshowBeforeError)
          };
          testHelper.createOnFirestore(testCase);
        })
        .catch((error: Error) => {
          resolve({
            success: false,
            new_assets: [{ success: false, error }],
            all_assets: [],
            sentences: []
          });
          try {
            const testCase: Omit<TestCase, 'id'> = {
              enabled: false,
              url: route,
              method: 'POST',
              body: JSON.stringify(requestBody),
              token,
              last_updated: new Date(),
              slideshow_id: props.slideshowId,
              author_id: props.userId,
              error: JSON.stringify(error), // or any other error detail you want to include
              slideshow_before_error: JSON.stringify(slideshowBeforeError)
            };
            testHelper.createOnFirestore(testCase);
          } catch (err) {
            console.error(err);
          }
        });
    });
  },
  textSummary(
    props: TextSummaryReqBody,
    forceResolve: boolean = false
  ): Promise<TextSummaryResult> {
    const token = getItem(LocalStorageItems.ACCESS_TOKEN);
    if (!token) {
      toast.error('There are some issues on your end... Refreshing...');
      window.location.reload();
      return;
    }

    let route = `${API_URL + this.name}/subtitles/prompt/overlay/${
      props.userId
    }/${props.slideshowId}`;

    let requestBody = props;
    return new Promise((resolve, reject) => {
      fetchWrapper(route, MethodType.POST, JSON.stringify(requestBody), token, {
        forceResolve
      })
        .then((json: TextSummaryResult) => {
          resolve(json);
          // const hasError = testHelper.isError(json);
          // if (!hasError) return;
          // const testCase: Omit<TestCase, 'id'> = {
          //   enabled: false,
          //   url: route,
          //   method: 'POST',
          //   body: JSON.stringify(requestBody),
          //   token,
          //   last_updated: new Date(),
          //   slideshow_id: props.slideshowId,
          //   author_id: props.userId,
          //   error: json, // or any other error detail you want to include,
          //   slideshow_before_error: JSON.stringify(slideshowBeforeError)
          // };
          // testHelper.createOnFirestore(testCase);
        })
        .catch((error: Error) => {
          resolve({
            success: false,
            summary: ''
          });
          // try {
          //   const testCase: Omit<TestCase, 'id'> = {
          //     enabled: false,
          //     url: route,
          //     method: 'POST',
          //     body: JSON.stringify(requestBody),
          //     token,
          //     last_updated: new Date(),
          //     slideshow_id: props.slideshowId,
          //     author_id: props.userId,
          //     error: JSON.stringify(error), // or any other error detail you want to include
          //     slideshow_before_error: JSON.stringify(slideshowBeforeError)
          //   };
          //   testHelper.createOnFirestore(testCase);
          // } catch (err) {
          //   console.error(err);
          // }
        });
    });
  },
  async searchGalleryAssets(
    params: GallerySearchReqBody
  ): Promise<{ availableAssets: Array<VideoData | ImageData> }> {
    const token = getItem(LocalStorageItems.ACCESS_TOKEN);
    let route = `${API_URL + this.name}/gallery/search`;

    try {
      const response = await fetchWrapper(
        route,
        MethodType.POST,
        JSON.stringify(params),
        token
      );
      return response as { availableAssets: Array<VideoData | ImageData> };
    } catch (error) {
      console.error('Gallery search failed:', error);
      return {
        availableAssets: [] as Array<VideoData | ImageData>
      };
    }
  },
  async getDimensions(
    url: string,
    fileType: 'video' | 'image'
  ): Promise<'landscape' | 'portrait'> {
    return new Promise((resolve) => {
      try {
        if (fileType === 'image') {
          const img = new Image();
          img.onload = () =>
            resolve(img.width >= img.height ? 'landscape' : 'portrait');
          img.onerror = () => resolve('landscape');
          img.src = url;
        } else {
          const video = document.createElement('video');
          video.onloadedmetadata = () =>
            resolve(
              video.videoWidth >= video.videoHeight ? 'landscape' : 'portrait'
            );
          video.onerror = () => resolve('landscape');
          video.src = url;
        }
      } catch {
        resolve('landscape');
      }
    });
  },
  async saveUploadedFile({
    url,
    fileType,
    title
  }: {
    url: string;
    fileType: 'video' | 'image';
    title: string;
  }): Promise<{ result: UploadedMedia }> {
    const token = getItem(LocalStorageItems.ACCESS_TOKEN);
    let route = `${API_URL + this.name}/gallery/uploads`;

    const dimension = await this.getDimensions(url, fileType);

    try {
      const response = await fetchWrapper(
        route,
        MethodType.POST,
        JSON.stringify({ url, file_type: fileType, title, dimension }),
        token
      );
      return response as { result: UploadedMedia };
    } catch (error) {
      console.error('Gallery uploaded files search failed:', error);
      throw error;
    }
  },
  async searchGalleryUploadedFiles(): Promise<{
    uploads: Array<UploadedMedia>;
  }> {
    const token = getItem(LocalStorageItems.ACCESS_TOKEN);
    let route = `${API_URL + this.name}/gallery/uploads`;

    try {
      const response = await fetchWrapper(route, MethodType.GET, null, token);
      return response as { uploads: Array<UploadedMedia> };
    } catch (error) {
      console.error('Gallery uploaded files search failed:', error);
      return {
        uploads: [] as Array<UploadedMedia>
      };
    }
  },
  async deleteUploadedFile(id: string): Promise<void> {
    const token = getItem(LocalStorageItems.ACCESS_TOKEN);
    let route = `${API_URL + this.name}/gallery/uploads/${id}`;
    await fetchWrapper(route, MethodType.DELETE, null, token);
  }
};

export interface UploadedMedia {
  _id: string;
  id: string;
  original_url: string;
  compressed_url?: string;
  author_id: string;
  file_type: 'video' | 'image';
  dimension: 'landscape' | 'portrait';
  title: string;
  deleted_at?: Date;
  created_at: Date;
  last_updated: Date;
  metadata: {
    width?: number;
    height?: number;
    duration?: number;
    mime_type: string;
  };
}

class GallerySearchReqBody {
  dimension?: GalleryMediaSearchDimension; // Use the enum for validation
  asset_type: GalleryMediaSearchType;
  keyword?: string;
  page_number?: number;
  provider?: string;
}

export default Media;
export { toDecimal };
