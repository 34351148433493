import { Asset } from '../types';
import { isUploadedUrl } from './s3';

const isUserUploadedVideo = url => url && url.includes('user-upload');
const isStockVideo = url => url && (url.includes('stocks') || url.includes('trimmed-videos/'));
// Add function to check if URL exists
const checkUrlExists = async (url: string): Promise<boolean> => {
  try {
    const response = await fetch(url, { method: 'HEAD' });
    return response.status === 200;
  } catch (error) {
    return false;
  }
};

const getCompressedVideoUrl = async (url: string) => {
  if (isUserUploadedVideo(url)) {
    const baseUrl = url.replace('.mp4', '');
    const compressedUrl = `${baseUrl}_compressed.mp4`;
    const compressedExists = await checkUrlExists(compressedUrl);
    return compressedExists ? compressedUrl : url;
  }
  return url;
};


const isMainVideo = (asset: Asset) => {
  return (
    !!asset &&
    !!asset.url &&
    isUploadedUrl(asset.url) &&
    !isStockVideo(asset.url) &&
    !isUserUploadedVideo(asset.url)
  );
};

export { isUserUploadedVideo, isStockVideo, getCompressedVideoUrl, isMainVideo };
